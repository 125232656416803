<app-confirm-dialog style="display: padding 10px; margin: 10px;" id="confirmDeleteBox"
  [modalTitles]="confirmDeleteBoxTitles">
</app-confirm-dialog>
<div class="title">
  <h1>Retirar Liquidación</h1>
</div>
<hr>
<div class="form-returns form-group form">
  <form [formGroup]="returnsFormGroup">
    <div class="flex-column">
      <label>Forma de retiro</label>
      <div class="flex-row">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="isTransfer" formControlName="isTransfer" [value]="false">
          <label class="form-check-label" for="flexRadioDefault3">
            Pago en Efectivo
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="isTransfer" formControlName="isTransfer" [value]="true">
          <label class="form-check-label" for="flexRadioDefault3">
            Transferencia
          </label>
        </div>
      </div>
    </div>
    <div class="flex-column">
      <label>Retira el propietario</label>
      <div class="flex-column">
        <select class="form-control form-select" id="withdrawer" formControlName="withdrawer"
          [ngModel]="returnData.owners[0]" [ngClass]="{'borderRed': validate('withdrawer')}">
          <option *ngFor="let owners of returnData.owners" [ngValue]="owners">{{owners.user.person.fullName}}</option>
        </select>
      </div>
    </div>
    <div class="flex-column">
      <label>Descripcion de la Liquidación</label>
      <textarea type="text" formControlName="description" class="form-control" style="resize: none;"></textarea>
    </div>
  </form>

  <div class="flex-row" style="margin: 1vh; width: 95%; justify-content: space-between; align-items: baseline;">
    <label>Fecha de pago (opcional):</label>
    <div class="input-group" style="margin-bottom: 1vh; width: fit-content;">
      <input class="form-control" style="max-width: 9rem;" placeholder="aaaa-mm-dd" name="dp" ngbDatepicker
        [(ngModel)]="returnDate" #d="ngbDatepicker" [minDate]="{year: 1999, month: 12, day: 31}" readonly>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
          <fa-icon [icon]="iconCalendar" size="1x"></fa-icon>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="returnData.ownerAmountInFavor > 0" class="flex-column">
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="checkbox" name="amountInFavor" [(ngModel)]="inFavor" checked>
      <label class="form-check-label">
        Cobrar monto a favor (${{returnData.ownerAmountInFavor}})
      </label>
    </div>
  </div>

  <form [formGroup]="additionalFormGroup">
    <div class="flex-column" style="row-gap: 0.5rem;">
      <h4>
        Cargos Adicionales
        <hr>
      </h4>
      <div class="form-floating">
        <textarea class="form-control" style="resize: none; height: 5rem;" formControlName="returnAdditionalDescription"
          id="desc" placeholder="Descripción"></textarea>
        <label for="desc">Escriba una descripción del cargo</label>
      </div>
      <div class="flex-row" style="column-gap: 0.5rem;">
        <select class="form-control form-select" style="width: fit-content;" formControlName="returnAdditionalSign">
          <option [ngValue]="false">Descuento</option>
          <option [ngValue]="true">Aumento</option>
        </select>
        <input formControlName="returnAdditionalAmount" class="form-control" placeholder="$">
        <button (click)="createAdditional()" type="button" class="btn btn-primary"> + </button>
      </div>
      <div class="flex-column">
        <div class="flex-row" *ngFor="let ra of returnAdditionals; let ind = index">
          <span [ngClass]="{'text-danger': !ra.sign, 'text-success': ra.sign}">
            {{ ra.sign ? '+' : '-' }}{{ ra.amount }}
          </span>
          <span class="truncate">
            {{ ra.detail }}
          </span>
          <fa-icon class="hoverred icons-phone" (click)="deleteAdditional(ind)" [icon]="iconTrash" size="1x"
            style="margin-left: auto; margin-right: 0.5rem;"></fa-icon>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="buttons-submitting" style="display: flex; justify-content: space-between; margin-top: 20px">
  <button class="btn btn-danger" [disabled]="submitted" (click)="cancel()">Cancelar</button>
  <button class="btn btn-primary" [disabled]="submitted" (click)="submitReturn()" *ngIf="!edit">Crear
    Liquidación</button>
  <button class="btn btn-primary" (click)="submitReturn()" *ngIf="edit">Editar</button>
</div>

<ng-template #noCashForm>
  <div class="noCashFormContainer" role="alert">
    <div style="display: flex; Flex-direction: row; align-items: center; justify-content: space-between;">
      <span style="font-size: 1.4rem; font-weight: 500;">Caja Requerida</span>
    </div>
    <hr>
    <p>El usuario debe tener una <a href="/cash/cash-register-list" class="alert-link">caja abierta para poder
        continuar.</a></p>
    <div class="flex-row" style="align-items: center; justify-content: flex-end;margin-top: 5px;">
      <button type="button" class="btn btn-secondary" (click)="ok()">Aceptar</button>
    </div>
  </div>
</ng-template>