import { Injectable } from '@angular/core';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { InstallmentWithRequirement } from '../core/models/InstallmentWithRequirement';
import {
  OperatorFunction,
  Observable,
  of,
  ReplaySubject,
  Subject,
  BehaviorSubject,
} from 'rxjs';
import { IPaginatedServiceParamters } from '../core/models/ITable';
import { Payment } from '../core/models/Payment';
import { ApiService } from './api.service';
import { ExpenditurePropertyToPay } from '../core/models/ExpenditurePropertyToPay';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  private InstallmentsObservable: BehaviorSubject<
    InstallmentWithRequirement[]
  > = new BehaviorSubject([]);

  constructor(private apiService: ApiService) {}

  public getInstallments(): Observable<InstallmentWithRequirement[]> {
    return this.InstallmentsObservable.asObservable();
  }

  public setInstallments(
    InstallmentWithRequirementList: InstallmentWithRequirement[],
  ): void {
    this.InstallmentsObservable.next(InstallmentWithRequirementList);
  }

  getAllPaginated(
    paginatedArguments: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<Payment[]>> {
    return this.apiService.getPaginated('/api/Payment', paginatedArguments);
  }

  createNewPayment(newPayment): Observable<any> {
    return this.apiService.post(`/api/Payment`, newPayment);
  }

  getPayment(id) {
    return this.apiService.get(`/api/Payment/${id}`);
  }

  getPaymentReceipt(id) {
    return this.apiService.get(`/api/Payment/PaymentReceipt/${id}`);
  }

  getPaymentReceiptView(id) {
    return this.apiService.get(`/api/Payment/ReceiptView/${id}`);
  }

  deletePayment(id) {
    return this.apiService.delete(`/api/Payment/${id}`);
  }

  getPropertyUnpaidExpenditures(
    propertyId,
  ): Observable<ExpenditurePropertyToPay[]> {
    return this.apiService.get(`/api/Payment/unpaidexpenditures/${propertyId}`);
  }

  addPayment(newPayment): Observable<any[]> {
    return this.apiService.post(`/api/Payment`, newPayment);
  }

  getPaymentPerYear() {
    return this.apiService.get(`/api/Payment/ByYear`);
  }

  getRequirementsForPayment(id) {
    return this.apiService.get(`/api/Requirement/Payment/${id}`);
  }
}
