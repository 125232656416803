import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { faEye, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PendingFeeService } from 'src/app/services/pending-fee.service';
import { ToastService } from 'src/app/services/toast.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogSettings,
} from 'src/app/shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-fees-form',
  templateUrl: './fee-form.component.html',
  styleUrls: ['./fee-form.component.css'],
})
export class FeeFormComponent implements OnInit {
  iconPlus = faPlus;
  iconSee = faEye;
  iconTrash = faTrash;
  submitted: boolean = false;
  feeText: string;

  confirmDeleteBoxTitles: ConfirmDialogSettings;

  @Input() edit?: boolean = false;
  @Input() editData?: any;
  @Input() createInMemory?: any;
  @Input() createInMemoryArray?: any;
  @Input() feeData?: any;
  @Input() payers?: any;

  @Output() closeAction = new EventEmitter();
  @Output() loadingScreen = new EventEmitter();

  @ViewChildren(ConfirmDialogComponent)
  confirmBox: QueryList<ConfirmDialogComponent>;
  @ViewChild('noCashForm') noCashForm: ElementRef;

  feesFormGroup = new UntypedFormGroup({
    payer: new UntypedFormControl('', Validators.required),
    isTransfer: new UntypedFormControl(false, Validators.required),
    detail: new UntypedFormControl(''),
  });

  constructor(
    public pendingFeeService: PendingFeeService,
    public modalService: NgbModal,
    private toast: ToastService,
  ) {}

  ngOnInit() {
    if (!this.feeData[0].hasOwnProperty('amount')) {
      let len = this.feeData.length;
      this.feeText =
        len == 1
          ? 'Desea cobrar este honorario?'
          : `Desea cobrar estos ${len} honorarios?`;
    } else if (this.feeData.length == 1) {
      let fee = this.feeData[0];
      const amountString = fee.amount.toLocaleString('es-AR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      this.feeText = `Desea saldar el honorario de ${fee.description.slice(
        0,
        -3,
      )} del 20${fee.description.slice(-2)} por el monto de $${amountString}?`;
    } else {
      let pendingAmount = 0;
      this.feeData.forEach((pf) => {
        pendingAmount += pf.amount;
      });
      const amountString = pendingAmount.toLocaleString('es-AR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      this.feeText = `Desea cobrar estos ${this.feeData.length} honorarios por $${amountString}?`;
    }
    this.confirmDeleteBoxTitles = {
      title: 'Honorarios',
      subtitle: this.feeText,
    };
  }

  close() {
    this.modalService.dismissAll();
  }

  submitFee() {
    if (this.feesFormGroup.invalid) {
      this.feesFormGroup.markAllAsTouched();
      return;
    }

    if (this.feeData.length == 1) {
      let fee = this.feeData[0];
      fee.detail = this.feesFormGroup.value.detail;
      fee.isTransfer = this.feesFormGroup.value.isTransfer;
      fee.payerId = this.feesFormGroup.value.payer.user.userId;

      this.confirmBox
        .toArray()[0]
        .openModal()
        .result.then(
          (result) => {
            if (result) {
              this.submitted = true;
              let event = fee.quantity == 1 ? 'total' : 'partial';
              this.loadingScreen.emit(event);
              this.pendingFeeService.addPendingFee(fee).subscribe(
                (newFeeId) => {
                  this.toast.showToast('success', 'Honorario Saldado');
                  this.closeAction.emit(newFeeId);
                },
                (error) => {
                  this.toast.showToast('error', 'Ha ocurrido un problema');
                  this.closeAction.emit(null);
                },
              );
            }
          },
          (reason) => {},
        );
    } else {
      if (Array.isArray(this.feeData)) {
        this.feeData.forEach((fee) => {
          fee['detail'] = this.feesFormGroup.value.detail;
          fee['isTransfer'] = this.feesFormGroup.value.isTransfer;
          fee['payerId'] = this.feesFormGroup.value.payer.user.userId;
        });
      } else {
        this.feeData = {
          detail: this.feesFormGroup.value.detail,
          isTransfer: this.feesFormGroup.value.isTransfer,
          payerId: this.feesFormGroup.value.payer.user.userId,
        };
      }
      console.log(this.feeData);
      this.confirmBox
        .toArray()[0]
        .openModal()
        .result.then(
          (result) => {
            if (result) {
              this.submitted = true;
              this.loadingScreen.emit('total');
              this.pendingFeeService.addPendingFees(this.feeData).subscribe(
                (cId) => {
                  this.toast.showToast('success', 'Honorarios Saldados');
                  this.closeAction.emit(cId);
                },
                (error) => {
                  this.toast.showToast('error', 'Ha ocurrido un problema');
                  this.closeAction.emit(null);
                },
              );
            }
          },
          (reason) => {},
        );
    }
    return 0;
  }

  validate(field: string) {
    return (
      this.feesFormGroup.controls[field].errors &&
      this.feesFormGroup.controls[field].touched
    );
  }

  ok() {
    this.modalService.dismissAll();
  }
}
