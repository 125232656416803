import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  faAngleRight,
  faBell,
  faPencilAlt,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { DateService } from 'src/app/services/date.service';
import { logBookService } from 'src/app/services/logBook.service';
import {
  logBookEntry,
  logBookEntryToSubmit,
} from 'src/app/core/models/logBook';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-log-book',
  templateUrl: './log-book.component.html',
  styleUrls: ['./log-book.component.css'],
})
export class LogBookComponent implements OnInit {
  @ViewChild('scroll', { static: false }) scrollMe: ElementRef;

  @Input() contractId: number;
  @Input() contractName: string = 'Baup';

  stickyNote = faPencilAlt;
  paperPlane = faAngleRight;
  bell = faBell;
  trash = faTrash;
  ringBell = faBell;
  times = faTimes;
  edit = false;

  openLog: boolean = false;
  entries: logBookEntry[] = [];
  newEntry: logBookEntryToSubmit = {
    contractId: 0,
    entry: '',
    ownerCanSee: false,
    tenantCanSee: false,
    date: new Date(),
  };

  entryFormGroup = new UntypedFormGroup({
    entry: new UntypedFormControl('', Validators.required),
  });

  entryEditFormGroup = new UntypedFormGroup({
    entry: new UntypedFormControl('', Validators.required),
  });

  query: string = 'CreatedOrNot=true';
  itemsPerPage: number = 30;
  page: number = 1;

  notificationsShown = false;

  constructor(
    private logBookService: logBookService,
    private dateService: DateService,
    private modalService: NgbModal,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.getEntries();
  }

  openLogBook() {
    if (!this.openLog) {
      this.openLog = true;
    } else {
      this.openLog = false;
    }
  }

  getEntries() {
    this.logBookService
      .getAllPaginated({
        query: this.query,
        pageNumber: this.page,
        pageSize: this.itemsPerPage,
        id: this.contractId,
      })
      .subscribe(
        (data: PaginatedResult<any[]>) => {
          this.entries = data.result;
          this.showNotifications(this.entries);
          this.notificationsShown = true;
        },
        (error) => {
          if ((error.status = 404)) {
            this.entries = [];
          }
        },
      );
  }

  sendEntry() {
    if (this.entryFormGroup.valid) {
      this.newEntry.contractId = this.contractId;
      this.newEntry.entry = this.entryFormGroup.value.entry;
      this.newEntry.ownerCanSee = false;
      this.newEntry.tenantCanSee = false;
      this.newEntry.date = new Date();
      this.logBookService.addEntry(this.newEntry).subscribe((response) => {
        this.toastService.showToast(
          'success',
          `Entrada agregada: ${this.newEntry.entry}`,
        );
        this.refresh();
      });
    }
  }

  scrollToBottom() {
    this.scrollMe.nativeElement.scrollTop =
      this.scrollMe.nativeElement.scrollHeight;
  }

  stringDate(date) {
    let strDate = this.dateService.transformToDateString(new Date(date));
    return strDate;
  }

  notification(id) {
    this.logBookService.notifyEntry(id).subscribe((response) => {
      this.refresh();
    });
  }

  editEntry(form, entry) {
    this.entryEditFormGroup.patchValue({
      entry: entry.entry,
    });
    this.modalService.open(form);
  }

  confirmEdit() {
    this.newEntry.contractId = this.contractId;
    this.newEntry.entry = this.entryEditFormGroup.value.entry;
    this.newEntry.ownerCanSee = false;
    this.newEntry.tenantCanSee = false;
    this.logBookService
      .editEntry(this.contractId, this.newEntry)
      .subscribe((response) => {
        this.modalService.dismissAll();
        this.refresh();
      });
  }

  deleteEntry(id) {
    this.logBookService.deleteEntry(id).subscribe((response) => {
      this.refresh();
    });
  }

  refresh() {
    this.getEntries();
    this.entryFormGroup.reset();
  }

  cancel() {
    this.modalService.dismissAll();
  }

  showNotifications(entries) {
    if (!this.notificationsShown) {
      entries.forEach((entry) => {
        if (entry.notify) {
          this.toastService.showToast('notify', entry.entry);
        }
      });
    }
  }

  deleteAllNotify() {
    this.logBookService
      .deleteAllNotify(this.contractId)
      .subscribe((response) => {
        this.refresh();
      });
  }
}
