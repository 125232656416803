import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, OperatorFunction } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  searchString: string = '';

  clearTooltip: string = 'Borrar Búsqueda';
  iconClear = faTimesCircle;
  iconSearch = faSearch;
  focus = false;
  currentInput = null;
  searching: boolean = false;

  @ViewChild('inputSearch') myInputField: ElementRef;

  @Input() gridMode: boolean = false;
  @Input() parentService: any; /* ¿Nueva interfaz? */
  @Input() personType: string;
  @Input() dashboardMode: boolean = false;
  @Input() headerMode: boolean = false;

  @Output() clearSearchEvent = new EventEmitter();
  @Output() setResultsEvent = new EventEmitter();

  constructor(public modalService: NgbModal) {}

  ngOnInit() {}

  search: OperatorFunction<any, any> = (text$: Observable<any>) =>
    text$.pipe(
      tap(() => (this.searching = true)),
      debounceTime(750), // DELAY DEL BUSCADOR EN PAGOS Y PANTALLA PRINCIPAL
      distinctUntilChanged(),
      switchMap((queryString) => {
        if (queryString.length > 2) {
          return this.parentService
            .searchTypeAhead(
              this.parentService.createQuery(queryString, this.personType),
            )
            .pipe(
              catchError((error) => {
                return of([]);
              }),
            );
        } else {
          return of([]);
        }
      }),
      tap(() => (this.searching = false)),
    );

  setResults(event) {
    /* Contrato Individual*/
    this.myInputField?.nativeElement.blur();
    this.setResultsEvent.emit(event.item);
  }

  clearSearch() {
    this.searchString = null;
    this.clearSearchEvent.emit();
  }

  changeFocus(value) {
    this.focus = value;
  }

  chooseFormatter(contract) {
    const SEPARATOR = ' • ';
    if (this.personType == 'IsTenant') {
      return [
        this.getPersons(contract.tenants),
        contract.property.propertyIdentification,
        this.formatDateRange(contract.fromDate, contract.toDate),
      ].join(SEPARATOR);
    }
    if (this.personType == 'IsOwner') {
      return [
        this.getPersons(contract.owners),
        contract.property.propertyIdentification,
        this.formatDateRange(contract.fromDate, contract.toDate),
      ].join(SEPARATOR);
    }
    if (this.personType == 'IsAddress') {
      return [
        contract.property.propertyIdentification,
        this.formatDateRange(contract.fromDate, contract.toDate),
        this.getPersons(contract.owners),
        this.getPersons(contract.tenants),
      ].join(SEPARATOR);
    }
  }

  getPersons(persons): string {
    return persons.map((p) => p.user.person.fullName).join(' · ');
  }

  formatResult(contract) {
    if (contract === '') {
      return '';
    }
    let address = contract.property.propertyIdentification;
    return address;
  }

  formatDateRange(fromDateStr: string, toDateStr: string): string {
    const months = [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
    ];

    const fromDate = new Date(fromDateStr);
    const toDate = new Date(toDateStr);

    if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
      return 'Fechas inválidas';
    }

    const fromMonth = months[fromDate.getMonth()];
    const fromYear = fromDate.getFullYear().toString().slice(-2);
    const toYear = toDate.getFullYear().toString().slice(-2);

    const formattedDateRange = `${fromMonth} ${fromYear} - ${
      fromYear !== toYear ? toYear : ''
    }`;

    return formattedDateRange;
  }
}
