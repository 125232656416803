import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Contract } from 'src/app/core/models/Contract';

@Injectable({
  providedIn: 'root',
})
export class ContractEditService {
  private contractForEdit: any = null;

  public contractForEditObservable: BehaviorSubject<any> = new BehaviorSubject(
    this.contractForEdit,
  );

  constructor() {}

  public setContract(contractForEdit: any): void {
    this.contractForEditObservable.next(contractForEdit);
  }
}
