<div class="title">
    <h1 *ngIf="!edit">Añadir Inquilino</h1>
    <h1 *ngIf="edit">Editar Inquilino</h1>
</div>
<form [formGroup]="tenantDataFormGroup" (ngSubmit)="onSubmit()">
    <div class="form-building form-group">
        <div class="container">
            <div class="row">
                <hr>
                <h3>Tipo de Propietario</h3>
            </div>
            <div class="row">
                <div class="col-sm">
                    <input type="radio" (click)="selectTenantType(tenantType.Person)" name="schemeType"
                        [checked]="tenantTypeCheck" [value]="1">
                    <span class="font-120" style="margin-right: 1vw;"> Persona</span>
                </div>
                <div class="col-sm">
                    <input type="radio" (click)="selectTenantType(tenantType.Bussiness)" name="schemeType"
                        [checked]="!tenantTypeCheck" [value]="2">
                    <span class="font-120" style="margin-right: 1vw;"> Persona Jurídica</span>
                </div>
            </div>
            <div class="row">
                <hr>
                <h3 *ngIf="tenantTypeToggle ==  tenantType.Person">Datos Personales</h3>
                <h3 *ngIf="tenantTypeToggle ==  tenantType.Bussiness">Datos Empresa</h3>
            </div>
            <div class="row" *ngIf="tenantTypeToggle == tenantType.Bussiness">
                <div class="col-sm">
                    <label>Razon Social</label>
                    <input placeholder="EmpresaSA" type="text" formControlName="socialReason" class="form-control"
                        [ngClass]="{'borderRed': validate('socialReason')}">
                    <span *ngIf="validate('socialReason') 
                  && !tenantDataFormGroup.value.socialReason" style="color: var(--border-red); font-size: 90%;">
                        Dato Faltante
                    </span>
                </div>
                <div class="col-sm">
                    <label>CUIT</label>                    
                    <input placeholder="27-12345678-8" type="text" formControlName="documentId" class="form-control"
                        [ngClass]="{'borderRed': validate('documentId')}">
                    <span *ngIf="validate('documentId') 
                            && !tenantDataFormGroup.value.documentId" style="color: var(--border-red); font-size: 90%;">
                    Dato Faltante
                    </span>
                    <span *ngIf="validate('documentId') 
                            && tenantDataFormGroup.value.documentId" style="color: var(--border-red); font-size: 90%;">
                      Dato Inválido
                    </span>
                </div>
                <div class="col-sm">
                    <div>
                        <label>Carácter frente a la AFIP</label>
                        <div class="flex-row" style="margin-bottom: 1vh;">
                          <select class="form-control form-select" id="taxRole" formControlName="taxRole" (change)="setTaxRole($event.target.value)">
                            <option [ngValue]="null">No aplica</option>
                            <option [value]="1">Responsable Monotributo</option>
                            <option [value]="2">Responsable Inscripto</option>
                          </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="tenantTypeToggle == tenantType.Person">
                <div class="col-sm">
                    <label>Nombre</label>
                    <input placeholder="Juan" type="text" formControlName="firstName" class="form-control"
                        [ngClass]="{'borderRed': validate('firstName')}">
                    <span *ngIf="validate('firstName')" style="color: var(--border-red); font-size: 90%;">
                        Dato Faltante
                    </span>
                </div>
                <div class="col-sm">
                    <label>Apellido</label>
                    <input placeholder="Pérez" type="text" formControlName="lastName" class="form-control"
                        [ngClass]="{'borderRed': validate('lastName')}">
                    <span *ngIf="validate('lastName')" style="color: var(--border-red); font-size: 90%;">
                        Dato Faltante
                    </span>
                </div>
                <div class="col-sm">
                    <label>Fecha Nacimiento</label>
                    <div class="input-group" style="margin-bottom: 1vh;">
                        <input class="form-control" placeholder="yyyy-mm-dd" formControlName="dateOfBirth" name="dp"
                            ngbDatepicker #d="ngbDatepicker" [minDate]="{year: 1000, month: 1, day: 1}">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                                <fa-icon [icon]="iconCalendar" size="1x"> </fa-icon>
                            </button>
                        </div>
                    </div>
                    <span class="flex-column text-danger"
                        *ngIf="tenantDataFormGroup.get('dateOfBirth').hasError('ngbDate')">Error de formato</span>
                </div>
            </div>
            <div class="row" *ngIf="tenantTypeToggle == tenantType.Person">
                <div class="col-sm">
                  <label>Carácter frente a la AFIP</label>
                  <div class="flex-row" style="margin-bottom: 1vh;">
                    <select class="form-control form-select" id="taxRole" formControlName="taxRole" (change)="setTaxRole($event.target.value)">
                      <option [ngValue]="null">No aplica</option>
                      <option [value]="1">Responsable Monotributo</option>
                      <option [value]="2">Responsable Inscripto</option>
                    </select>
                  </div>
                </div>                
                <div class="col-sm">
                    <div style="margin-bottom: 1vh;">
                        <label>Tipo</label>
                        <div class="flex-row">
                            <select class="form-control form-select" id="documentType" formControlName="documentType"
                              [ngClass]="{'borderRed': validate('documentType')}">
                                <option *ngFor="let documentType of documentTypes" [value]="documentType.value">
                                    {{documentType.text}}
                                </option>
                            </select>
                        </div>
                    </div>                    
                    <span *ngIf="validate('documentType') 
                        && !tenantDataFormGroup.value.documentType" style="color: var(--border-red); font-size: 90%;">
                        Dato Faltante
                    </span>  
                </div>
                <div class="col-sm">
                    <div>
                        <label>Numero Documento</label>
                        <input placeholder="12345678" type="text" formControlName="documentId" class="form-control"
                            [ngClass]="{'borderRed': validate('documentId')}">
                    </div>
                    <span *ngIf="validate('documentId') 
                        && !tenantDataFormGroup.value.documentId" style="color: var(--border-red); font-size: 90%;">
                        Dato Faltante
                    </span>
                    <span *ngIf="validate('documentId') 
                        && tenantDataFormGroup.value.documentId" style="color: var(--border-red); font-size: 90%;">
                        Dato Inválido
                    </span>
                </div>
            </div>
            <hr>
            <div class="row">
                <h3> Vivienda</h3>
            </div>
            <div class="row">
                <div class="col-sm">
                    <label>Calle y altura</label>
                    <input placeholder="Avenida Santa Fe 1234" type="text" formControlName="streetAddress"
                        class="form-control">
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <label>Provincia</label>
                    <input placeholder="CABA" type="text" formControlName="state" class="form-control">
                </div>
                <div class="col-sm">
                    <label>Ciudad</label>
                    <input placeholder="CABA" type="text" formControlName="city" class="form-control">
                </div>
                <div class="col-sm">
                    <label>Codigo Postal</label>
                    <input placeholder="1234" type="text" formControlName="zipCode" class="form-control">
                </div>
            </div>
            <hr>
            <div class="row">
                <h3> Información de Contacto</h3>
            </div>
            <div class="row">
                <div class="col-sm">
                    <label>Email</label>
                    <input placeholder="juanperez@gmail.com" type="email" formControlName="email" class="form-control">
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <label>Telefono</label>
                    <app-phonebook-container [phoneCards]="phoneContacts"></app-phonebook-container>
                </div>
            </div>
            <hr>
            <div class="row" >
                <h3> Detalle </h3>
            </div>
            <div class="row">
                <textarea class="desc form-control"  cols="98" rows="5" type="text" formControlName="description"></textarea>
            </div>
        </div>
    </div>
    <div class="buttons-submitting" style="display: flex; justify-content: space-between;">
        <button type="button" class="btn btn-danger" [disabled]="submitted" (click)="cancel()">Cancelar</button>
        <button class="btn btn-primary" [disabled]="submitted" *ngIf="!edit">Crear</button>
        <button class="btn btn-primary" *ngIf="edit">Editar</button>
      </div>
</form>