<ng-template #BuildingForm>
  <app-building-form  [edit]="edit" 
                      [editData]="dataForEdit"              
                      [createInMemoryArray]="contractInput"
                      (closeAction)="closeForm($event)"
                      (cancelAction)="cancelForm()"
                      [editContractMode]="true"></app-building-form>
</ng-template>

<ng-template #OwnerForm>
  <app-owner-form [edit]="edit" 
                  [editData]="dataForEdit"         
                  [createInMemoryArray]="contractInput"
                  (closeAction)="closeForm($event)"
                  (cancelAction)="cancelForm()"
                  [editContractMode]="true"></app-owner-form>
</ng-template>

 <ng-template #TennantForm>
  <app-tenant-form  [edit]="edit"
                    [editData]="dataForEdit"           
                    [createInMemoryArray]="contractInput"
                    (cancelAction)="cancelForm()"
                    (closeAction)="closeForm($event)"
                    [editContractMode]="true"></app-tenant-form>
</ng-template>

<ng-template #GuarantorForm>
    <app-guarantor-form [edit]="edit"
                        [editData]="dataForEdit"              
                        [createInMemoryArray]="contractInput"
                        (cancelAction)="cancelForm()"
                        (closeAction)="closeForm($event)"
                        [editContractMode]="true"></app-guarantor-form>
</ng-template> 
