import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AgencyIdGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (
      this.authService.user$.subscribe((user) => {
        user['https://baup.com.ar/agencyId'];
      })
    ) {
      return true;
    } else {
      this.router.navigateByUrl(`/no-user`);
    }
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (
      this.authService.user$.subscribe((user) => {
        user['https://baup.com.ar/agencyId'];
      })
    ) {
      return true;
    } else {
      this.router.navigateByUrl(`/no-user`);
    }
  }
}
