<loading-spinner [evaluation]="isLoaded" [liteMode]="true">
  <div class="flex-row shadow-sm border-5" style="white-space: nowrap; width: fit-content; background-color: white; padding: 10px;">
    <div class="flex-column"
      style="justify-content: space-between; align-items: center; padding: 3vh 0px; margin-top: -0.75vh;">
      <span *ngFor="let day of weekDays"
        style="font-size: 0.8rem; font-weight: 350; height: 12px; margin: 4px 0 4px 0; color: #797979;">{{day}}</span>
    </div>
    <div class="flex-column">
      <div class="flex-row" style="justify-content: space-evenly; align-items: center;">
        <span *ngFor="let month of orderedMonths" style="font-size: 0.96rem;
                  font-weight: 500;
                  transform: rotate(-5deg);
                  font-style: italic;
                  color: #6e6e75;">
          {{month}}
        </span>
      </div>
      <div class="flex-row" style="margin: 5px;">
        <div class="flex-column cameras" *ngFor="let semana of semanas; let ind = index">
          <button class="itam-heatmap" *ngFor="let dia of semana"
              [ngbTooltip]="'Cantidad: '+ dia.paymentQuantity + ' - ' +  this.parseDate(dia.date)" [ngClass]="{'card-color-lowest': dia.paymentQuantity <= 0, 
                                    'card-color-lower': dia.paymentQuantity >= 1 &&  dia.paymentQuantity <= 10,
                                    'card-color-low': dia.paymentQuantity >= 11 &&  dia.paymentQuantity <= 20,
                                    'card-color-mid': dia.paymentQuantity >= 21 &&  dia.paymentQuantity <= 30, 
                                    'card-color-high': dia.paymentQuantity >= 31 &&  dia.paymentQuantity <= 40,
                                    'card-color-higher': dia.paymentQuantity >= 41 &&  dia.paymentQuantity <= 50, 
                                    'card-color-highest': dia.paymentQuantity >= 51 &&  dia.paymentQuantity <= 60,
                                    'card-color-highest-final': dia.paymentQuantity >= 61}">
            </button>
        </div>
      </div>
    </div>
  </div>
</loading-spinner>