<div class="flex-col" style="margin: 1rem; width: 100%;">
    <div *ngFor="let link of linking; let f = first">
        <span style="color: white;" *ngIf="link.line">
            <br *ngIf="!f">
            {{link.text}}
        </span>
        <div class="hover-color" style="margin-left: 0.75vw; margin-top: 1.2vh; padding: 0.15rem 0.25rem; max-width: 80%" (click)="goToLink(link)"
            [ngClass]="{'activatedLink': isActive(link) || link.active}" *ngIf="!link.line">
            <fa-icon style="color: white; margin-right: 0.5vw;" [icon]="iconService.getIcon(link.icon)" size="1x"></fa-icon>
            <span style="color: white; font-size: 0.8rem; padding: 0.15rem 0.25rem" >{{link.title}}</span>
            <fa-icon style="color: white; margin-left: 0.5vw" [icon]="angleUp" size="1x"
                *ngIf="link.linkChildren?.length >= 1 && !link.showChildren(link)"></fa-icon>
            <fa-icon style="color: white; margin-left: 0.5vw" [icon]="angleDown" size="1x"
                *ngIf="link.linkChildren?.length >= 1 && link.showChildren(link)"></fa-icon>
        </div>
        <div class="flex-col" style="margin-left: 2vw;" *ngIf="link.linkChildren?.length > 0 && link.showChildren(link) || link.active && link.showChildren(link)">
            <div style="margin: 0.2rem 0; max-width: 83%;" *ngFor="let linkChildren of link.linkChildren" [ngClass]="{'activatedLink': isActive(linkChildren)}">
                <span class="hover-color" style="color: white; font-size: 0.8rem; padding: 0 0.5rem; display: inline-block;" 
                 (click)="goToLinkChildren(linkChildren, link)">{{linkChildren.title}}</span>
            </div>
        </div>        
    </div>
</div>