<app-confirm-dialog style="display: padding 10px; margin: 10px;" id="confirmDeleteBox" [modalTitles]="confirmDeleteBoxTitles">
</app-confirm-dialog>
<div class="title">
  <h1>Saldar Honorarios</h1>
</div>
<hr>
<form [formGroup]="feesFormGroup">
  <div class="form-fees form-group form">
    <div class="flex-column">
      <label>Forma de retiro</label>
      <div class="flex-row">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="isTransfer" formControlName="isTransfer" [value]="false">
          <label class="form-check-label" for="flexRadioDefault3">
            Pago en Efectivo
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="isTransfer" formControlName="isTransfer" [value]="true">
          <label class="form-check-label" for="flexRadioDefault3">
            Transferencia
          </label>
        </div>
      </div>
    </div>    
    <div class="flex-column">
      <label>Saldado por:</label>
      <div class="flex-column">
        <select class="form-control form-select" id="payer" formControlName="payer" [ngModel]="payers.length == 1 ? payers[0] : undefined"
          [ngClass]="{'borderRed': validate('payer')}">
          <option [ngValue]="undefined" disabled>...</option>
          <option *ngFor="let payer of payers" [ngValue]="payer">{{payer.user.person.fullName}}</option>
        </select>
      </div>
    </div>
    <div class="flex-column" style="margin-bottom: 20px">
      <label>Detalle de honorario</label>
      <textarea type="text" formControlName="detail" class="form-control" style="resize: none;"></textarea>
    </div>
  </div>
  <div class="buttons-submitting flex-row" style="justify-content: space-between; width: 100%;">
    <button class="btn btn-danger" [disabled]="submitted" (click)="close()">Cerrar</button>
    <button class="btn btn-primary" [disabled]="submitted" (click)="submitFee()" *ngIf="!edit">Crear</button>
    <button class="btn btn-primary" (click)="submitFee()" *ngIf="edit">Editar</button>
  </div>
</form>

