import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RouterEvent } from '@angular/router';
import { BehaviorSubject, Subject, Subscription, filter, map, takeUntil } from 'rxjs';
import { ConsortiumHolderService } from 'src/app/shared/consortium-holder/consortium-holder.service';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import {
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleXmark,
  faLock,
  faTrash,
  faUnlock,
} from '@fortawesome/free-solid-svg-icons';
import { IconService } from 'src/app/services/icon.service';
import { faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons';
import { ContractService } from 'src/app/services/contract.service';
import { Contract } from 'src/app/core/models/Contract';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  contractId = 0;
  id : number;
  contract$: BehaviorSubject<Contract | null> = new BehaviorSubject<Contract | null>(null);
  propertyStreetAddress$ = this.contract$.pipe(
    map(contract => contract?.property?.propertyIdentification || '')
  );
 
  private destroy$: Subject<void> = new Subject<void>();
  iconTrash = faTrash;
  angleUp = faAngleUp;
  angleDown = faAngleDown;
  lockIcon = faLock;
  unlockIcon = faUnlock;
  isMenuLocked = false;
  activeLink: any; 
  close = faCircleXmark
  arrowL = faCircleChevronLeft
  arrowR = faAngleRight
  arrowDown = faAngleDown
  sidebarHidden = false;
  
  @Output() sidebarState = new EventEmitter<boolean>();


  linking: any[] = [
    {
      line: true,
      text: 'INICIO',
    },
    {
      id: 1,
      url: '/home',
      title: 'Pantalla Principal',
      active: false,
      selected: false,
      linkChildren: [],
      icon: 'desktop',
      showChildren: (data) => {
        return true;
      },
    },
    {
      id: 2,
      url: '/contracts',
      title: 'Contratos',
      active: false,
      selected: false,
      icon: 'contract',
      linkChildren: [
        {
          id: 1,
          url: '/:id/detail',
          title: 'Dirección de la propiedad',
          active: false,
          selected: false,
        },
        // {
        //   id: 2,
        //   url: '/:id/detail',
        //   title: 'Detalle',
        //   active: false,
        //   selected: false,
        // },
        {
          id: 3,
          url: '/:id/installments',
          title: 'Cuotas',
          active: false,
          selected: false,
        },
        {
          id: 5,
          url: '/payments?ContractId=:id',
          title: 'Lista de Pagos',
          active: false,
          selected: false,
        },
        {
          id: 6,
          url: '/:id/additionalCharges',
          title: 'Cargos Adicionales',
          active: false,
          selected: false,
        },
        {
          id: 7,
          url: '/:id/liabilities',
          title: 'Obligaciónes',
          active: false,
          selected: false,
        },
      ],
      showChildren: (data) => {
        this.consortiumHolderService.consortiumActiveChange$.subscribe(
          (result) => {
            this.contractId = result;
          },
        );
        if (this.contractId > 0) {
          this.router.url.includes(data.url) ||
          this.router.url.includes('/payments')
          return true;
        } else {
          return false;
        }
      },
    },
    {
      line: true,
      text: 'OPERACION',
    },
    {
      id: 9,
      url: '/payments',
      title: 'Pagos',
      active: false,
      selected: false,
      icon: 'dollar-sign',
      linkChildren: [
        {
          id: 1,
          url: '/transfers', // Modificado aquí
          title: 'Transferencias',
          active: false,
          selected: false,
          icon: 'exchange',
        },
        {
          id: 2,
          url: '/payment-new',
          title: 'Nuevo Pago',
          active: false,
          selected: false,
        },
      ],
      showChildren: (data) => {
        return (
          this.router.url.includes(data.url) ||
          this.router.url.includes('/transfers')
        );
      },
    },
    {
      id: 10,
      url: '/returns',
      title: 'Liquidaciones',
      active: false,
      selected: false,
      icon: 'percentage',
      linkChildren: [
        {
          id: 1,
          url: '/historical-returns',
          title: 'Liquidaciones Saldadas',
          active: false,
          selected: false,
          icon: '',
        },
      ],
      showChildren: (data) => {
        return this.router.url.includes(data.url);
      },
    },
    {
      id: 11,
      url: '/fees',
      title: 'Honorarios',
      active: false,
      selected: false,
      icon: 'hand',
      linkChildren: [
        {
          id: 1,
          url: '/historical',
          title: 'Honorarios Saldados',
          active: false,
          selected: false,
        },
      ],
      showChildren: (data) => {
        return this.router.url.includes(data.url);
      },
    },
    {
      id: 14,
      url: '/additional-charges',
      title: 'Cargos Adicionales',
      active: false,
      selected: false,
      icon: 'plus',
      linkChildren: [],
      showChildren: (data) => {
        return true;
      },
    },
    {
      id: 19,
      url: '/installments-increases',
      title: 'Aumento de cuotas',
      active: false,
      selected: false,
      icon: 'calculator',
      linkChildren: [],
      showChildren: (data) => {
        return true;
      },
    },
    {
      id: 20,
      url: '/receipts',
      title: 'Recibos',
      active: false,
      selected: false,
      icon: 'bill',
      linkChildren: [],
      showChildren: (data) => {
        return true;
      },
    },
    {
      id: 21,
      url: '/facturas',
      title: 'Facturas',
      active: false,
      selected: false,
      icon: 'bill',
      linkChildren: [],
      showChildren: (data) => {
        return true;
      },
    },
    {
      id: 22,
      url: '/claims',
      title: 'Reclamos',
      active: false,
      selected: false,
      icon: 'circle-exclamation',
      linkChildren: [
        {
          id: 1,
          url: '/historical-list',
          title: 'Reclamos historicos',
          active: false,
          selected: false,
        },
      ],
      showChildren: (data) => {
        return this.router.url.includes(data.url);
      },
    },
    {
      line: true,
      text: 'SERVICIOS',
    },
    {
      id: 16,
      url: '/requirements',
      title: 'Requerimientos',
      active: false,
      selected: false,
      linkChildren: [],
      icon: 'faucet',
      showChildren: (data) => {
        return true;
      },
    },
    {
      id: 17,
      url: '/retention',
      title: 'Retenciones',
      active: false,
      selected: false,
      icon: 'money-bill',
      linkChildren: [
        {
          id: 1,
          url: '/retention-history',
          title: 'Retenciones historicas',
          active: false,
          selected: false,
        },
      ],
      showChildren: (data) => {
        return this.router.url.includes(data.url);
      },
    },
    {
      line: true,
      text: 'PERSONAS',
    },
    {
      id: 5,
      url: '/tenants',
      title: 'Inquilinos',
      active: false,
      selected: false,
      icon: 'user',
      linkChildren: [],
      showChildren: (data) => {
        return true;
      },
    },
    {
      id: 6,
      url: '/owners',
      title: 'Propietarios',
      active: false,
      selected: false,
      icon: 'user-tie',
      linkChildren: [],
      showChildren: (data) => {
        return true;
      },
    },
    {
      id: 7,
      url: '/properties',
      title: 'Propiedades',
      active: false,
      selected: false,
      icon: 'house',
      linkChildren: [],
      showChildren: (data) => {
        return true;
      },
    },
    {
      id: 8,
      url: '/guarantors',
      title: 'Garantes',
      active: false,
      selected: false,
      icon: 'users-friend',
      linkChildren: [],
      showChildren: (data) => {
        return true;
      },
    },
    {
      line: true,
      text: 'INMOBILIARIA',
    },
    {
      id: 18,
      url: '/agency',
      title: 'Agencia',
      active: false,
      selected: false,
      icon: 'building',
      linkChildren: [],
      showChildren: (data) => {
        return true;
      },
    },
    {
      id: 23,
      url: '/users',
      title: 'Usuarios',
      active: false,
      selected: false,
      icon: 'userTie',
      linkChildren: [],
      showChildren: (data) => {
        return true;
      },
    },
    {
      id: 24,
      url: '/configuration',
      title: 'Configuración',
      active: false,
      selected: false,
      icon: 'gear',
      linkChildren: [],
      showChildren: (data) => {
        return true;
      },
    }
  ];


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private consortiumHolderService: ConsortiumHolderService,
    private location: Location,
    public iconService: IconService, 
    public contractService: ContractService
  ) {  
    this.consortiumHolderService.consortiumActiveChange$.subscribe((result) => {
      this.contractId = result;
      console.log(this.contractId)  
    if (this.contractId) {
      this.contractService.getContract(this.contractId).subscribe((result: Contract) => {
        this.contract$.next(result);
        console.log(this.contract$.value);
      });
    } else {
      console.log('No se puede obtener el contrato porque contractId no tiene valor');
    }
  }); 
  };
  

  ngOnInit(): void {
    this.propertyStreetAddress$.pipe(takeUntil(this.destroy$)).subscribe((streetAddress) => {
      // Encuentra el objeto de dirección de la propiedad en el array linking
      const addressLink = this.linking.find(link => link.id === 2)?.linkChildren?.find(child => child.id === 1);
  
      // Si existe el objeto y la calle, actualiza el título con la dirección
      if (addressLink && streetAddress) {
        addressLink.title = "> " + streetAddress;
      } else {
        addressLink.title = 'Dirección de la propiedad';
      }
    });
  }
  
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  // getPropertyStreetAddress(): string {
  //   console.log(this.contract)
  //   return this.contract?.property?.address?.streetAddress || '';
  // }
  // NEW
  isActive(link) {
    let url = JSON.parse(JSON.stringify(link.url));
    if (this.contractId > 0) {
      url = url.replace(':id', this.contractId);
    }
    return this.router.url.includes(url);
  }

  goToLink(linkValue) {
    this.activateDeactivateLinks(linkValue.id, this.linking);
    linkValue.linkChildren.forEach((link) => {
      link.selected = false;
      link.active = false;
    });
    this.routeToLink(linkValue.url);
  }

  goToLinkChildren(linkChildren, linkParent) {
    linkParent.selected = false;
    this.activateDeactivateLinks(linkChildren.id, linkParent.linkChildren);
    console.log(linkChildren, linkParent)
    if (!linkChildren.url.includes('transfers') && !linkChildren.url.includes('payments')) {
      console.log(linkParent.url + linkChildren.url)
      this.routeToLink(linkParent.url + linkChildren.url);
    } else if(linkChildren.url.includes('transfers')){
      console.log(linkParent.url.slice(0, -9) + linkChildren.url)
      this.routeToLink(linkParent.url.slice(0, -9) + linkChildren.url);
    } else if (linkChildren.url.includes('payments')){
      console.log(linkParent.url.slice(0, -10) + linkChildren.url)
      this.routeToLink(linkParent.url.slice(0, -10) + linkChildren.url);
    }
  }

  activateDeactivateLinks(id, linkArray) {
    linkArray.forEach((link) => {
      if (link.id === id) {
        link.active = true;
        link.selected = true;
      } else {
        link.active = false;
        link.selected = false;
      }
    });
  }

  routeToLink(linkUrl) {
    let url = JSON.parse(JSON.stringify(linkUrl));
    const newLink = url.replace(':id', this.contractId);
    this.router.navigateByUrl(newLink);
  }

  toBeShow(itemMenu: any) {
    const ChildActive = itemMenu.links?.find((x) => x.active);
    return itemMenu.active || ChildActive;
  }

  redirect() {
    this.router.navigateByUrl('/home');
  }


showChildren(link: any): boolean {
  return link.selected;
}



}
