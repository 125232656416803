import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeAnimation } from 'src/app/Animation';
import jwt_decode from 'jwt-decode';
import { AuthService } from '@auth0/auth0-angular';
import { faCircleChevronLeft, faCircleChevronRight, faBars, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  animations: [fadeAnimation],
})
export class HomePageComponent implements OnInit  {
  tokenData = null;
  sidebarExpanded = false;
  opened: boolean = true;
  arrowL = faCircleChevronLeft
  arrowR = faCircleChevronRight
  bars = faBars;
  arrowDown = faCircleChevronRight
  close = faCircleXmark

  constructor(
    private route: ActivatedRoute,
    public auth: AuthService,
    public router: Router,
  ) {
    if (auth.user$) {
      auth.user$.subscribe((user) => {
        if (user['https://baup.com.ar/agencyId'] == null) {
          this.router.navigateByUrl(`/no-user`);
        }
      });
    }

    if (this.route.snapshot.data.token) {
      this.tokenData = jwt_decode(this.route.snapshot.data.token);
      localStorage.setItem('token', `${this.route.snapshot.data.token}`);
    }
  }

  ngOnInit(): void {
    // Read the saved state from sessionStorage and set the sidebarExpanded and sidebarIcon values
    const savedState = sessionStorage.getItem('sidebarState');
    this.sidebarExpanded = savedState === 'true';
  }  

  toggleSidebar() {
    this.sidebarExpanded = !this.sidebarExpanded;
    // Save the current state of the sidebar and sidebarIcon in sessionStorage
    sessionStorage.setItem('sidebarState', this.sidebarExpanded.toString());
  }  
}
