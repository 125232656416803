import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  faCalendar,
  faSearch,
  faPlus,
  faEye,
  faTrash,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Contract } from 'src/app/core/models/Contract';
import { ContractRequirementForAdd } from 'src/app/core/models/ContractRequirementForAdd';
import { IconService } from 'src/app/services/icon.service';
import { RequirementService } from 'src/app/services/requirement.service';

@Component({
  selector: 'app-contract-requirements-handler',
  templateUrl: './contract-requirements-handler.component.html',
  styleUrls: ['./contract-requirements-handler.component.css'],
})
export class ContractRequirementsHandlerComponent implements OnInit {
  iconCalendar = faCalendar;
  iconSearch = faSearch;
  iconPlus = faPlus;
  iconSee = faEye;
  iconTrash = faTrash;
  iconRight = faChevronRight;
  iconLeft = faChevronLeft;
  iconDown = faChevronDown;
  iconEdit = faPen;
  currentYear = new Date().getFullYear();
  dropzone = [];
  requirements: any = [];
  req: any;
  editData: any;
  selectedMonth: any = null;
  requirementMap: any = {};
  grabbing = false;
  activeIds = [];

  requirementForAdd: ContractRequirementForAdd = {
    requirementId: null,
    metadata: null,
    fromDate: null,
  };

  yearMonths = [
    { id: 1, month: 'Enero' },
    { id: 2, month: 'Febrero' },
    { id: 3, month: 'Marzo' },
    { id: 4, month: 'Abril' },
    { id: 5, month: 'Mayo' },
    { id: 6, month: 'Junio' },
    { id: 7, month: 'Julio' },
    { id: 8, month: 'Agosto' },
    { id: 9, month: 'Septiembre' },
    { id: 10, month: 'Octubre' },
    { id: 11, month: 'Noviembre' },
    { id: 12, month: 'Diciembre' },
  ];

  @Input() contractInput: Contract;

  @Output() closeAction = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    private requirementService: RequirementService,
    public iconService: IconService,
  ) {
    this.requirementService.getAllRequirements().subscribe((result: any[]) => {
      this.requirements = result;
    });
  }

  ngOnInit() {
    this.dropzone = [];
    this.dropzone = JSON.parse(JSON.stringify(this.contractInput.requirements));
    this.dropzone.forEach((req) => {
      this.activeIds.push(req.requirementId);
    });

    /* this.requirements.forEach(key => {
      this.requirementMap[key] = null;
    })
    this.requirementForAdd.requirementId = this.requirements.id
    if (this.editData) {
      if (this.editData.metadata) {
        let metadata = JSON.parse(this.editData.metadata)
        var keys = Object.keys(metadata);
        keys.forEach(keys => {
          this.requirementMap[keys] = metadata[keys];
        })
      }
      if (this.editData.fromDate) {
        let monthFromDate = new Date(this.editData.fromDate).getMonth()+1;
        this.yearMonths.forEach(monthObj => {
          monthObj.id === monthFromDate ? this.selectedMonth = monthObj.month : null; 
        })
      }
    }  */
  }

  //Otro
  onDrop(value, form, click = 0) {
    if (click == 0) {
      value = value.data;
    }
    this.requirementMap = {};
    let duplicated = false;
    this.dropzone.forEach((element) => {
      if (element.requirementId === value.id || element.id === value.id) {
        duplicated = true;
      }
    });
    if (!duplicated) {
      this.editData = null;
      this.req = null;
      this.selectedMonth = null;
      this.req = JSON.parse(JSON.stringify(value));
      this.modalService.open(form);
    }
  }

  removeItem(index, element) {
    this.dropzone.splice(index, 1);
    var temporalRequirements = JSON.parse(
      JSON.stringify(this.contractInput.requirements),
    );
    temporalRequirements.forEach((requirement, index) => {
      if (requirement.requirementId === element.requirementId) {
        this.contractInput.requirements.splice(index, 1);
        let idx = this.activeIds.indexOf(element.requirementId);
        if (idx > -1) {
          this.activeIds.splice(idx, 1);
        }
      }
    });
  }

  editRequirement(form, reqs) {
    this.req = null;
    this.editData = this.findContractRequirement(reqs.requirementId);
    this.currentYear = new Date().getFullYear();
    this.requirementMap = {};
    if (this.editData) {
      if (this.editData.metadata) {
        let metadata = JSON.parse(this.editData.metadata);
        var keys = Object.keys(metadata);
        keys.forEach((keys) => {
          this.requirementMap[keys] = metadata[keys];
        });
      }
      if (this.editData.fromDate) {
        let monthFromDate = new Date(this.editData.fromDate).getMonth() + 1;
        this.currentYear = new Date(this.editData.fromDate).getFullYear();
        this.yearMonths.forEach((monthObj) => {
          monthObj.id === monthFromDate
            ? (this.selectedMonth = monthObj.month)
            : null;
        });
      }

      this.req = {
        extraRequirements: keys,
        generationTypeId: this.editData.generationTypeId,
        icon: this.editData.icon,
        id: this.editData.requirementId,
        name: this.editData.requirement,
      };
    } else {
      this.req = JSON.parse(JSON.stringify(reqs));
    }
    this.modalService.open(form);
  }

  closeModal(value) {
    if (this.editData) {
      this.contractInput.requirements.forEach((requirement, index) => {
        if (requirement.requirementId === value.requirementId) {
          this.contractInput.requirements[index] = value;
        }
      });
    } else {
      let duplicate = this.findContractRequirement(value.requirementId);
      if (!duplicate) {
        this.dropzone.push(value);
        this.contractInput.requirements.push(value);
        this.activeIds.push(value.requirementId);
      }
    }

    this.modalService.dismissAll();
  }

  getFromDate(id) {
    let found = this.findContractRequirement(id);
    if (found) {
      let date = new Date(found.fromDate);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      return day + '/' + month + '/' + year;
    }
  }

  findContractRequirement(id) {
    let found = null;
    this.contractInput.requirements.forEach((requirement) => {
      if (requirement.requirementId === id) {
        found = requirement;
      }
    });
    return found;
  }

  accept() {
    let requirementForAdd = {
      contractId: this.contractInput.id,
      fromDate: null,
      requirementId: this.req.id,
      metadata: '',
      icon: this.req.icon,
      id: this.req.id,
      name: this.req.name
    };
    this.yearMonths.forEach((month) => {
      if (month.month == this.selectedMonth) {
        requirementForAdd.fromDate = new Date(
          this.currentYear,
          month.id - 1,
          1,
          0,
          0,
          0,
          0,
        );
      }
    });
    requirementForAdd.metadata = JSON.stringify(this.requirementMap);
    if (requirementForAdd.metadata && requirementForAdd.fromDate) {
      this.closeModal(requirementForAdd);
    }
  }

  nextDate() {
    this.currentYear += 1;
  }

  prevDate() {
    this.currentYear -= 1;
  }

  getReqProperties(requirement) {
    if (requirement.metadata !== '') {
      let reqArr = [];
      let jsonObj = JSON.parse(requirement.metadata);
      for (let property in jsonObj) {
        reqArr.push(`${property}: ${jsonObj[property]}`);
      }
      return reqArr;
    }
  }

  onGrabbing(event, bool) {
    this.grabbing = bool;
  }
}
