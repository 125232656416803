import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { faPlus, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ConfirmDialogComponent,
  ConfirmDialogSettings,
} from '../confirm-dialog/confirm-dialog.component';
import { FileService } from 'src/app/services/file.service';
import { ToastService } from 'src/app/services/toast.service';
import { FileForAdd } from 'src/app/core/models/FileForAdd';
import { BAUP } from 'src/app/core/models/File';
import { FileParentEnum } from 'src/app/core/models/FileParentEnum';
import { HttpEventType } from '@angular/common/http';

export interface FileViewerSettings {
  title: string;
  isDisplay: boolean;
  parent: FileParentEnum;
  id: number;
  contractId: number;
  modalComponent: boolean;
}

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit {
  @Input() settings: FileViewerSettings;
  @Input() fileArray: BAUP.File[] = [];
  @ViewChild(ConfirmDialogComponent) confirmBox: ConfirmDialogComponent;
  @Output() emitFileArray = new EventEmitter();

  MAX_SIZE = 2097152; // 2mb
  confirmBoxTitles: ConfirmDialogSettings;
  deleting: boolean = false;
  currentFile: BAUP.File;
  selectedFiles: number[] = [];
  filesInMemory: ((FileForAdd & { name: string }) | undefined)[] = [];

  iconX = faXmark;
  iconTrash = faTrash;
  iconPlus = faPlus;

  isLoading: boolean = false;
  uploadProgress: number = 0;

  constructor(
    private fileService: FileService,
    private modalService: NgbModal,
    private toastService: ToastService,
  ) {}

  ngOnInit() {}

  openForm(form) {
    this.filesInMemory = [];
    const mouseUpHandler = (event: MouseEvent) => {
      event.stopPropagation();
    };

    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.stopPropagation();
      }
    };

    document.addEventListener('mouseup', mouseUpHandler, true);
    document.addEventListener('keydown', keyDownHandler, true);

    this.modalService.open(form, {
      windowClass: 'modal-customSize',
      beforeDismiss: () => {
        document.removeEventListener('mouseup', mouseUpHandler, true);
        document.removeEventListener('keydown', keyDownHandler, true);
        return true;
      },
    });
  }

  onFileDropped(event) {
    let files = [];

    const promises = event.map((droppedFile) => {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        return new Promise<File>((resolve) => {
          fileEntry.file((file: File) => {
            resolve(file);
          });
        }).then((file) => files.push(file));
      }
    });

    Promise.all(promises).then(() => {
      this.parseFiles(files);
    });
  }

  parseFiles(files) {
    files.forEach((file) => {
      if (file) {
        if (file.size < this.MAX_SIZE) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            let newFile: FileForAdd & { name: string } = {
              type: file.type,
              data: file,
              size: file.size,
              name: file.name,
              parentEnum: this.settings.parent,
              parentId: this.settings.id,
              contractId: this.settings.contractId,
            };
            if (!this.fileInArray(this.filesInMemory, newFile)) {
              this.filesInMemory.push(newFile);
            }
          };
        } else {
          this.toastService.showToast(
            'warning',
            `El archivo ${file.name} es demasiado grande, maximo 2Mb`,
          );
        }
      }
    });
  }

  add() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*, application/pdf';
    input.multiple = true;
    input.onchange = (event: any) => {
      const files = [...event.target.files];
      this.parseFiles(files);
    };
    input.click();
  }

  upload() {
    let formData = new FormData();
    for (let i = 0; i < this.filesInMemory.length; i++) {
      let file = this.filesInMemory[i];
      formData.append('Files', file.data, file.name);
    }

    this.isLoading = true;

    this.fileService
      .addFiles(
        formData,
        this.settings.id,
        this.settings.parent,
        this.settings.contractId,
      )
      .subscribe(
        (result) => {
          if (result.type === HttpEventType.UploadProgress) {
            this.uploadProgress = Math.round(
              (result.loaded / result.total) * 100,
            );
          } else if (result.type === HttpEventType.Response) {
            const inMemoryArray = result.body.map((file: any) => {
              const transformedFile: BAUP.File = {
                id: file.id,
                url: file.url,
                date: file.date,
                realEstateAgentId: file.realEstateAgentId,
                realEstateAgencyId: file.realEstateAgencyId,
                type: file.type,
                size: file.size,
              };
              return transformedFile;
            });
            this.fileArray = this.fileArray.concat(inMemoryArray);
            this.filesInMemory = [];
            this.toastService.showToast(
              'success',
              'Archivos cargados exitosamente',
            );
            this.emitFileArray.emit(this.fileArray);
            this.isLoading = false;
            this.modalService.dismissAll();
          }
        },
        () => {
          this.isLoading = false;
          this.toastService.showToast('error', 'Ha ocurrido un error');
        },
      );
  }

  removeItem(index) {
    this.filesInMemory.splice(index, 1);
  }

  delete() {
    let len = this.selectedFiles.length;
    if (len > 0) {
      let text = len == 1 ? ' una imagen' : `n ${len} imagenes`;

      this.confirmBoxTitles = {
        title: 'Confirmar acción',
        subtitle: `Este proceso es irreversible, se eliminara${text}, ¿Desea continuar?`,
        bindLogic: true,
      };
      let modal = this.confirmBox.openModal();
      modal.result.then(
        (result) => {
          if (result) {
            let files = this.selectedFiles.map((n) => this.fileArray[n]);

            this.fileService.deleteFiles(files).subscribe(
              () => {
                this.selectedFiles.sort((n1, n2) => n2 - n1);
                this.selectedFiles.forEach((filePosition) => {
                  this.fileArray.splice(filePosition, 1);
                });
                this.emitFileArray.emit(this.fileArray);
                this.toastService.showToast(
                  'success',
                  'Archivos eliminados exitosamente',
                );
              },
              () => {
                this.toastService.showToast('error', 'Ha ocurrido un error');
              },
              () => {
                this.cancel();
                modal.dismiss();
              },
            );
          }
        },
        () => {},
      );
    }
  }

  cancel() {
    this.selectedFiles = [];
    this.deleting = !this.deleting;
  }

  open(form, file) {
    this.currentFile = file;
    const mouseUpHandler = (event: MouseEvent) => {
      event.stopPropagation();
    };

    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.stopPropagation();
      }
    };

    document.addEventListener('mouseup', mouseUpHandler, true);
    document.addEventListener('keydown', keyDownHandler, true);

    this.modalService.open(form, {
      windowClass: 'modal-customSize',
      beforeDismiss: () => {
        document.removeEventListener('mouseup', mouseUpHandler, true);
        document.removeEventListener('keydown', keyDownHandler, true);
        return true;
      },
    });
  }

  handleSelection(component, position) {
    if (component.checked && !this.selectedFiles.includes(position)) {
      this.selectedFiles.push(position);
    } else {
      let index = this.selectedFiles.indexOf(position);
      if (index !== -1) {
        this.selectedFiles.splice(index, 1);
      }
    }
  }

  fileInArray(fileArr, file) {
    let result = false;
    fileArr.forEach((f) => {
      if (
        f.type === file.type &&
        f.url === file.url &&
        f.size === file.size &&
        f.name === file.name
      ) {
        result = true;
      }
    });
    return result;
  }
}
