export interface Requirement {
  id: number;
  name: string;
  extraRequirements: string[];
  generationTypeId: GenerationTypeEnum;
  icon: string;
}

export enum GenerationTypeEnum {
  Mensual = 1,
  Bimestral = 2,
}
