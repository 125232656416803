import { Input } from '@angular/core';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  GridToggle,
  GridToggleSamePage,
  ToggleTitleAndUrl,
} from '../GridSettings';

@Component({
  selector: 'app-grid-toggle',
  templateUrl: './grid-toggle.component.html',
  styleUrls: ['./grid-toggle.component.css'],
})
export class ToggleGridComponent implements OnInit {
  @Output() toggleGridEvent = new EventEmitter<string>();

  @Input() toggleStatus: boolean = true;
  @Input() toggleSamePage: GridToggleSamePage;
  @Input() toggle: GridToggle;

  constructor() {}

  ngOnInit() {
    console.log(this.toggle, this.toggleSamePage);
  }

  toggleGrid(e) {
    // Alternar entre las versiones basado en la bandera
    if (this.toggle) {
      this.toggleStatus = !this.toggleStatus;
      const selectedTitleAndUrl: ToggleTitleAndUrl =
        this.toggle.titlesAndUrls.find(
          (titleAndUrl) => titleAndUrl.url === e.target.value,
        );
      this.toggleGridEvent.emit(selectedTitleAndUrl.url);
    } else if (this.toggleSamePage) {
      this.toggleStatus = !this.toggleStatus;
      return this.toggleGridEvent.emit(e.target.value);
    }
  }
}
