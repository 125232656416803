<div class="form-body">
  <div class="title">
    <h1 *ngIf="!edit">Crear Retencion</h1>
    <h1 *ngIf="edit">Editar Retencion</h1>
    <hr>
  </div>

  <div class="requirements" style="grid-area: req">
    <span style="font-size: 1.10rem; font-weight: 550; margin-bottom: 20px;">Servicios impagos</span>
    <div *ngIf="noReq == false" class="req-card-line border-5">
      <div class="req-card" *ngFor="let instReqName of reqAccKeys" [ngbTooltip]="instReqName"
              (click)="selectReq(instReqName)"
              placement="top"
              autoClose="outside"
              style="cursor: pointer;"> 
        <div class="position-relative">
          <span class="position-absolute top-0 start-10 badge rounded-pill text-bg-danger" style="color: white; z-index: 100;">
            {{reqAccumulator[instReqName].length}}
          </span>
          <fa-icon 
            [icon]="iconService.getIcon(reqAccumulator[instReqName][0]?.instReqState.requirementIcon)" 
            size="2x"
            style="opacity: 0.7;">
          </fa-icon>
        </div>
        <span class="badge text-bg-primary req-style-text">
            {{instReqName}}
        </span>
      </div>
    </div>
    
    
    <div *ngIf="noReq == true" class="border-5 table-empty">
      <fa-icon style="margin-bottom: 5px;"[icon]="iconQuestion" size="4x"></fa-icon>
      <span style="margin-bottom: 5px; text-align: center;">No hay servicios impagos de meses anteriores</span>
    </div>

  </div>
    <div *ngIf="selectedRequirement" class="installments" style="height: fit-content; min-height: fit-content; grid-area: inst">
      <span style="font-size: 1.10rem; font-weight: 550; margin-bottom: 20px;">Cuotas de {{requirementSelectedName}}</span>
      <div class="installment-list">
        <div class="inst-selector hover" *ngFor="let installment of reqAccumulator[requirementSelectedName].installments; let i = index" >
          <div (click)="addInstallment(i)">
            <span [ngClass]="{ 'text-bg-success' : installment.selected, 'text-bg-secondary' : !installment.selected}" 
                class="badge shadowframe" style="height: 20px; font-size: 110%; cursor: pointer;">
                <div class="flex-row centered" style="height: 100%; width: 100%;" *ngIf="!(installment.description == null || installment.description == '')">
                    {{installment.description | titlecase}} 
                </div>
                <div class="flex-row centered" style="height: 100%; width: 100%;" *ngIf="installment.description == null || installment.description == ''">
                    {{getDate(installment)}} 
                </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="installmentRequirements.length > 0" class="installment-payment-grid" style="grid-area: table; margin-top: 15px">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">REQUERIMIENTO</th>
            <th scope="col">MES</th>
            <th scope="col">MONTO</th>
            <th scope="col">FECHA</th>
            <th scope="col">DETALLE</th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr *ngFor="let instReq of installmentRequirements">    
            <th scope="row">{{instReq.reqName}}</th>
            <th scope="row" *ngIf="!(instReq.installment.description == null || instReq.installment.description == '')">{{instReq.installment.description | titlecase}}</th>
            <th scope="row" *ngIf="instReq.installment.description == null || instReq.installment.description == ''">{{getDate(instReq.installment)}}</th>
            <td><input placeholder="$" type="number" class="form-control" style="width: 5vw;" [(ngModel)]="instReq.amount"></td>
            <td>  
              <div class="flex-row centered">
                <input  class="form-control" 
                        placeholder="yyyy-mm-dd" 
                        name="dp"  
                        [(ngModel)]="instReq.dateTemp"
                        (ngModelChange)="changeFilterData(instReq)"
                        ngbDatepicker #a="ngbDatepicker">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary" (click)="a.toggle()" type="button">
                            <fa-icon [icon]="iconCalendar" size="1x"> </fa-icon>
                          </button>
                        </div>
                      </div>
                    </td>
                    <td><input placeholder="Detalle" type="text" class="form-control" style="width: 5vw;" [(ngModel)]="instReq.details"></td>
                  </tr>
                </tbody>
              </table>
            </div>

      <div style="grid-area: amount; margin-bottom: 15px;">
        <hr>
        <form [formGroup]="retentionFormGroup" style="width: 100%; display: flex; flex-direction: row; align-items: center; justify-content: space-evenly;" >
            <div class="flex-column">
              <label>Monto</label>
              <input type="number" formControlName="amount" class="form-control" [ngClass]="{'borderRed': retentionFormGroup.invalid && 
                          (retentionFormGroup.dirty || retentionFormGroup.touched) && !retentionFormGroup.value.amount}"
                          style="width: 15vw;">
              <span *ngIf="retentionFormGroup.invalid && (retentionFormGroup.dirty || retentionFormGroup.touched) && !retentionFormGroup.value.amount"
                style="color: var(--border-red); font-size: 90%;">Dato Faltante
              </span>
            </div>
          
            <div class="flex-column">
              <label>Detalle</label>
              <input type="text" formControlName="detail" class="form-control" [ngClass]="{'borderRed': retentionFormGroup.invalid && 
                          (retentionFormGroup.dirty || retentionFormGroup.touched) && !retentionFormGroup.value.detail}"
                          style="width: 15vw;">
              <span *ngIf="retentionFormGroup.invalid && (retentionFormGroup.dirty || retentionFormGroup.touched) && !retentionFormGroup.value.detail"
                style="color: var(--border-red); font-size: 90%;">Dato Faltante
              </span>
            </div>
          </form>
      </div>

      <div class="flex-row" style="width: 100%; justify-content: space-evenly; align-items: center;grid-area: buttons; margin-top: 20px">
        <button class="btn btn-primary" (click)="close()" *ngIf="editData">Cancelar</button>
        <button class="btn btn-success" type="button"  [disabled]="submitted" (click)="createRetention()">Crear Retención</button>
      </div>

</div>