import { Injectable } from '@angular/core';
import { User } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { IPaginatedServiceParamters } from '../core/models/ITable';
import { PaginatedResult } from '../shared/grid/paginate.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  query: string = '';
  pageNumber: number = 1;
  itemsPerPage: number = 50;

  public searchTypes = [
    { value: 'DocumentId', description: 'DNI' },
    { value: 'Name', description: 'Nombre' },
  ];

  constructor(private apiService: ApiService) {}

  getAllPaginated(
    paginatedArguments: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<User[]>> {
    return this.apiService.getPaginated(
      '/api/RealEstateAgent/GetAllAgents',
      paginatedArguments,
    );
  }

  getUsers(): Observable<User[]> {
    return this.apiService.get('/api/RealEstateAgent/GetAgents');
  }

  getUser(id) {
    return this.apiService.get(`/api/RealEstateAgent/${id}`);
  }

  deleteUser(id) {
    return this.apiService.delete(`/api/RealEstateAgent/${id}`);
  }

  updateUser(id, updateUser) {
    return this.apiService.put(`/api/RealEstateAgent/${id}`, updateUser);
  }

  createUser(newUser) {
    return this.apiService.post(`/api/RealEstateAgent`, newUser);
  }
}
