import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  AdditionalChargesSubmit,
  AdditionalCharges,
} from 'src/app/core/models/AdditionalCharges';
import { Installment } from 'src/app/core/models/Installment';
import { AdditionalChargesService } from 'src/app/services/additional-charges.service';
import { InstallmentService } from 'src/app/services/installment.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-additional-charges-card',
  templateUrl: './additional-charges-card.component.html',
  styleUrls: ['./additional-charges-card.component.scss'],
})
export class AdditionalChargesCardComponent implements OnInit {
  @Input() installments: Installment[];
  @Input() showPunitives: boolean = true;

  @Output() refreshAddCharges = new EventEmitter();

  additionalChargesFormGroup = new UntypedFormGroup({
    detail: new UntypedFormControl('', Validators.required),
    amount: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.min(0),
      Validators.max(2147483647),
    ]),
    sign: new UntypedFormControl('', Validators.required),
  });

  iconPen = faPen;
  iconDelete = faTrash;
  edit: boolean = false;
  chargeId: number = 0;
  installmentId: number = 0;
  invalidAmount: boolean = false;
  installment: Installment;

  constructor(
    private additionalChargeService: AdditionalChargesService,
    private installmentService: InstallmentService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    if (this.installments && this.installments.length > 0) {
      this.installment = this.installments[0];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.installments && changes.installments.currentValue) {
      this.installment = changes.installments.currentValue[0];
    }
  }

  onSubmit() {
    let additionalChargeToSubmit: AdditionalChargesSubmit = {
      detail: this.additionalChargesFormGroup.value.detail,
      amount: this.additionalChargesFormGroup.value.amount,
      sign: this.additionalChargesFormGroup.value.sign,
      installmentId: this.installment?.id,
    };

    if (
      this.additionalChargesFormGroup.invalid ||
      this.installment?.id == null
    ) {
      if (this.installment?.id == null) {
        this.invalidAmount = false;
        this.toastService.showToast('warning', 'Seleccione una cuota');
      } else if (
        this.additionalChargesFormGroup.get('amount').hasError('max') ||
        this.additionalChargesFormGroup.get('amount').hasError('min')
      ) {
        this.invalidAmount = true;
        this.toastService.showToast(
          'warning',
          'Revisar que los campos numericos tengan valores reales',
        );
      } else if (
        this.additionalChargesFormGroup.get('amount').hasError('pattern')
      ) {
        this.invalidAmount = true;
        this.toastService.showToast(
          'warning',
          'El monto tiene caracteres invalidos',
        );
      } else {
        this.invalidAmount = false;
        this.toastService.showToast('warning', 'Hay campos sin completar');
      }
    } else if (!this.edit) {
      this.additionalChargeService
        .addAdditionalCharge(additionalChargeToSubmit)
        .subscribe((response) => {
          this.toastService.showToast(
            'success',
            'Se ha generado el cargo adicional',
          );
          this.additionalChargesFormGroup.reset();
          this.getResults(this.installment.id);
        });
    } else if (this.edit) {
      this.additionalChargeService
        .editAdditionalCharge(this.chargeId, additionalChargeToSubmit)
        .subscribe((response) => {
          this.toastService.showToast(
            'success',
            'El cargo adicional ha sido editado',
          );
          this.additionalChargesFormGroup.reset();
          this.getResults(this.installment.id);
        });
    }
  }

  editCharge(charge: AdditionalCharges) {
    this.additionalChargesFormGroup.patchValue({
      detail: charge.detail,
      amount: charge.amount,
      sign: charge.sign,
    });
    this.edit = true;
    this.chargeId = charge.id;
  }

  deleteCharge(charge) {
    this.additionalChargeService
      .deleteAdditionalCharge(charge.id)
      .subscribe((response) => {
        this.getResults(this.installment.id);
      });
  }

  getResults(id) {
    this.installmentService
      .getInstallment(id)
      .subscribe((result: Installment) => {
        this.installment = result;
        this.edit = false;
        this.additionalChargesFormGroup.reset();
        this.refreshAddCharges.emit(result);
      });
  }

  resetForm() {
    this.chargeId = undefined;
    this.additionalChargesFormGroup.reset();
  }
}
