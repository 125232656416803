import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { IconService } from 'src/app/services/icon.service';

@Component({
  selector: 'app-icon-selector',
  templateUrl: './icon-selector.component.html',
  styleUrls: ['./icon-selector.component.css'],
})
export class IconSelectorComponent implements OnInit {
  iconList: IconDefinition[];
  @Output() selectIcon = new EventEmitter();

  selected: string;

  constructor(public iconListService: IconService) {
    this.iconList = this.iconListService.listIcons();
  }

  iconSelected(icon) {
    this.selected = icon.iconName;
    this.selectIcon.emit(this.selected);
  }

  ngOnInit() {}
}
