import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faEye, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { Installment } from 'src/app/core/models/Installment';
import { ContractService } from 'src/app/services/contract.service';
import { DateService } from 'src/app/services/date.service';
import { InstallmentService } from 'src/app/services/installment.service';
import { TranslateService } from 'src/app/services/translate.service';
import { GridSettings } from '../grid/GridSettings';
import { AdditionalChargesCardComponent } from './additional-charges-card/additional-charges-card.component';

@Component({
  selector: 'app-additional-charges',
  templateUrl: './additional-charges.component.html',
  styleUrls: ['./additional-charges.component.css'],
})
export class AdditionalChargesComponent implements OnInit {
  @Input() id?: number;

  @ViewChild(AdditionalChargesCardComponent)
  additionalCharges: AdditionalChargesCardComponent;

  _addCharge: BehaviorSubject<number> = new BehaviorSubject(0);

  iconRemove = faTrash;
  iconEdit = faPen;
  iconView = faEye;

  settings: GridSettings;
  toggleCondominiums: boolean;
  edit: boolean = false;
  expensesTitle: string;
  select: string;
  personType: string = 'IsAddress';
  selectedInstallments: any;
  contractId: number = null;
  search: boolean = true;
  hideChild: boolean = true;
  emptyMessage: string = 'No hay cuotas impagas en este contrato';
  installment: Installment[] = [];

  constructor(
    public installmentService: InstallmentService,
    public translateService: TranslateService,
    public modalService: NgbModal,
    private router: Router,
    public contractService: ContractService,
    private dateService: DateService,
  ) {
    this.settings = {
      columns: [
        {
          key: 'id',
          title: this.translateService.translate('header.id'),
        },
        {
          key: 'amount',
          title: this.translateService.translate('header.amount'),
        },
        {
          key: 'totalAdditionalCharges',
          title: this.translateService.translate('header.additionalCharges'),
        },
        {
          key: 'amountPaid',
          title: this.translateService.translate('header.amountPaid'),
        },
        {
          key: 'totalToPay',
          title: this.translateService.translate('header.totalToPay'),
          function: (val) => this.removeDecimals(val),
        },
        {
          key: 'fromDate',
          title: this.translateService.translate('header.fromDate'),
          function: (val) =>
            this.dateService.transformToDateStringFromString(val),
        },
        {
          key: 'endDate',
          title: this.translateService.translate('header.endDate'),
          function: (val) =>
            this.dateService.transformToDateStringFromString(val),
        },
        {
          key: 'expirationDate',
          title: this.translateService.translate('header.expirationDate'),
          function: (val) =>
            this.dateService.transformToDateStringFromString(val),
        },
      ],
      actions: [],
      buttontitle: 'tenant.btn.newTenant',
      search: [
        { title: 'Propiedad', type: 'IsAddress' },
        { title: 'Inquilino', type: 'IsTenant' },
        { title: 'Propietario', type: 'IsOwner' },
      ],
      withChecks: true,
    };
  }
  removeDecimals(val: number): {} {
    return Number(Math.round(parseFloat(val + 'e2')) + 'e-2').toFixed(2);
  }

  ngOnInit() {
    if (this.id) {
      this.contractId = this.id;
      this.search = false;
      this.hideChild = false;
    }
  }

  viewTenant(data) {
    return '';
  }

  setResults(data) {
    if (!(typeof data === 'string') && data) {
      this.contractId = data.id;
      this.hideChild = false;
    }
  }

  clearSearch() {
    this.hideChild = true;
    this.contractId = null;
    this.selectedInstallments = null;
    this.installment = [];
  }

  selectedElements(data) {
    this.installment = [data.data];
    this.additionalCharges.getResults(data.data.id);
    this.selectedInstallments = data;
  }

  /*   parseAdditionalCharges(val){
    var total = 0;
    val.forEach(charge => {
      total = total + charge.amount
    });
    return total
  } */

  refreshGrid(id) {
    this._addCharge.next(1);
  }
}
