import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AgencyIdGuard } from './core/guards/agencyId.guard';
import { TokenResolver } from './core/resolvers/token-resolver.service';
import { HomePageComponent } from './modules/home/pages/home-page/home-page.component';
import { NoUserComponent } from './modules/home/pages/no-user/no-user.component';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    canActivate: [AuthGuard, AgencyIdGuard],
    canActivateChild: [AuthGuard, AgencyIdGuard],
    loadChildren: () =>
      import('./modules/home/realEstate.module').then(
        (m) => m.RealEstateModule,
      ),
    resolve: { token: TokenResolver },
  },
  {
    path: 'no-user',
    component: NoUserComponent,
  },
  {
    path: '**',
    redirectTo: ''
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
