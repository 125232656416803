<div class="installment-list">
    <div class="inst-selector hover" *ngFor="let installment of installmentList" (click)="addInstallment(installment)">
        <span [ngClass]="{ 'badge-success' : installment.selected, 'badge-info' : !installment.selected}" 
            class="badge shadowframe" style="height: 15px; font-size: 75%; cursor: pointer;">
            <div class="flex-row centered" style="height: 100%; width: 100%;">
                {{installment.description}} {{installment.fromDate | date: 'shortDate'}}
            </div>
        </span>
    </div>
</div>
