import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@auth0/auth0-spa-js';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { PhoneNumber } from 'src/app/core/models/PhoneNumber';
import { PropertyTypeEnum } from 'src/app/core/models/PropertyType.enum';

@Component({
  selector: 'app-contract-card-v2',
  templateUrl: './contract-card-v2.component.html',
  styleUrls: ['./contract-card-v2.component.css'],
})
export class ContractCardV2Component implements OnInit {
  iconTrash = faTrash;
  iconPen = faPen;
  display: boolean = true;
  cardTitle: string;
  owner: boolean = false;
  phones: PhoneNumber[] = [];
  address: string = '';
  email: string = '';
  fullName: string = '';
  personUrl: string = '';
  id: number = 0;
  age: number = 0;
  propertyAddress: string = '';
  propertyEmail: string = '';
  area: string = '';
  identification: string = '';
  title: string = '';
  level: string = '';
  propertyTypeId : string = '';
  rooms : number;
  surface: string = '';

  @Input() cardData;
  @Input() type;
  @Input() userData: User;
  @Output() openEditForm = new EventEmitter();
  @Output() delEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {    
    if (this.type != 'building') {
      if (this.type == 'guarantor') {
        let address = this.cardData.person?.address || this.cardData;
        this.fullName = this.guarantorNameReturn();
        this.age = this.cardData.person?.age || this.cardData.dateOfBirth;
        this.address = this.getAddress(address);
        this.email = this.cardData.email || 'No hay direccion de email';
        this.phones = this.cardData.person?.phones || this.cardData.phones;
        this.id = this.cardData.id;
      } else {
        let email = this.cardData.user?.email || this.cardData.email;
        let address = this.cardData.user?.person?.address || this.cardData;
        this.fullName =
          this.cardData.user?.person.fullName || this.cardData.fullName;
        this.age = this.cardData.user?.person.age || this.cardData.dateOfBirth;
        this.address = this.getAddress(address);
        this.email = email || 'No hay direccion de email';
        this.phones = this.cardData.user?.person.phones || this.cardData.phones;
        this.id = this.cardData.id;
      }
    } else {
      if (this.cardData != null) {
        let address = this.cardData.address || this.cardData;
        let propertyType = this.cardData.propertyTypeId || this.cardData;
        this.address = this.getAddress(address);
        this.area = this.cardData.area;
        this.identification = this.cardData.identification;
        this.title = this.cardData.propertyIdentification;
        this.level = this.cardData.level;
        this.propertyTypeId = this.getPropertyType(propertyType);
        this.rooms = this.cardData.rooms;
        this.surface = this.cardData.surface;
      }
    }
  }

  formOpen() {
    this.openEditForm.emit();
  }

  showDelete(value) {
    this.display = value;
  }

  deleteEvent() {
    this.delEvent.emit();
  }

  getAddress(addressProperty) {
    let addresses: string =
      addressProperty.streetAddress
    if (addresses != '') {
      return addresses;
    }
    return 'No hay direccion';
  }

  getPropertyType(propertyType: PropertyTypeEnum) {
    if (propertyType != null) {
      if(propertyType == 1) {
        return 'Departamento'
      }
      else if(propertyType == 2) {
        return 'Casa'
      }
      else if(propertyType == 3) {
        return 'Ph'
      }
      else if(propertyType == 4) {
        return 'Oficina'
      }
      else if(propertyType == 5) {
        return 'Local'
      }
    }
  }

  guarantorNameReturn() {
    return this.cardData.person?.fullName || this.cardData.fullName;
  }
}
