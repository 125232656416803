import { Injectable } from '@angular/core';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { Observable, of } from 'rxjs';
import { IPaginatedServiceParamters } from '../core/models/ITable';
import { Property } from '../core/models/Property';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  constructor(private apiService: ApiService) {}

  getAllPaginated(
    paginatedArguments: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<Property[]>> {
    return this.apiService.getPaginated('/api/property', paginatedArguments);
  }

  searchProperties(
    query?,
    page?,
    itemsPerPage?,
  ): Observable<PaginatedResult<Property[]>> {
    var paginatedArguments: IPaginatedServiceParamters = {
      pageNumber: page,
      pageSize: itemsPerPage,
      query: query,
    };
    return query.substring(query.indexOf('=')+1).length > 2 ? this.apiService.getPaginated('/api/Property/Address', paginatedArguments) : of(null);
  }

  getProperty(id) {
    return this.apiService.get(`/api/property/${id}`);
  }

  deleteProperty(id) {
    return this.apiService.delete(`/api/property/${id}`);
  }

  updateProperty(id, updateProperty) {
    return this.apiService.put(`/api/property/${id}`, updateProperty);
  }

  createProperty(newProperty) {
    return this.apiService.post(`/api/property`, newProperty);
  }
}
