<ul class="property-list" style="list-style-type:none;">
    <li *ngFor="let item of listProperties; let ind = index">
        <div class="property-elements shadowframe" [ngStyle]="{'width': width, 'height': height}" >
            <div 
            class="property-list-item-id"
            [ngStyle]="{'width': height}">
                <span style="font-size: 150%; font-weight: bold;">{{(ind + 1)}}</span>
            </div>
            <div class="highlighted property-list-item" 
                (click)="selectRow(ind)"
                [ngClass]="{'selected-element': selected(item.id)}">
                <div class="triangle-right"
                [ngStyle]="{
                    'border-top': solidTransparent,
                    'border-left': solidTransparent2,
                    'border-bottom': solidTransparent}"
                ></div>
                <div class="properties-picker-text">
                    <span>{{item.description}}</span>
                </div>
            </div>
        </div>
    </li>
    <ngb-pagination (pageChange)="pageChanged($event)" [(page)]="page" [pageSize]="itemsPerPage"
        [collectionSize]="totalItems" [boundaryLinks]="true" [maxSize]="5">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination>
</ul>


