<form [formGroup]="increaseForm" (ngSubmit)="onSubmit()">
  <div class="title">
    <h1>{{edit ? "Editar período" : "Crear aumento"}}</h1>
    <a *ngIf="this.increaseType == 'ICL' || this.increaseType == 'Vivienda2019' || this.increaseType == 'Vivienda2023'"
      href="https://vivienda.buenosaires.gob.ar/calculadora_alquiler">Calculadora
      ICL</a>
    <a *ngIf="this.increaseType == 'IPC'" href="https://argentina.gg/calculadora-de-inflacion">Calculadora IPC</a>
  </div>
  <hr>

  <div class="form-building form-group">
    <div class="form-data">
      <div class="main-data">
        <div class="flex-row formrow">
          <span class="formfont">
            Monto inicial:
          </span>
          <span class="formfont" *ngIf="edit">
            {{oldIncrease.initialAmount}}
          </span>
          <span class="formfont" *ngIf="!edit && !isComercial">
            {{oldIncrease.initialAmount * (1 + oldIncrease.increaseValue/100)}}
          </span>
          <span class="formfont" *ngIf="isComercial">
            {{oldIncrease.amount}}
          </span>
        </div>
        <hr>
        <div class="flex-row formrow" *ngIf="edit">
          <span class="formfont">
            Incremento por modificar:
          </span>
          <span class="formfont">
            {{oldIncrease.increaseValue}}%
          </span>
        </div>
        <hr *ngIf="edit">
        <div class="flex-row formrow" *ngIf="edit">
          <span class="formfont">
            Monto actual:
          </span>
          <span class="formfont" *ngIf="isComercial" style="width: 50%;">
            {{(oldIncrease.increaseValue + 100) * oldIncrease.initialAmount / 100}}
          </span>
        </div>
        <hr *ngIf="edit">
        <div class="flex-row" style="align-items: baseline; justify-content: space-between; margin: -5px 0px;">
          <span class="formfont">
            Nuevo incremento:
          </span>
          <input type="number" formControlName="newIncrease" class="form-control" style="width: 50%;" [ngClass]="{'borderRed': increaseForm.invalid && (increaseForm.dirty || increaseForm.touched) 
              && !increaseForm.value.newIncrease}">
        </div>
        <hr *ngIf="isComercial">
        <div *ngIf="isComercial" class="flex-row"
          style="align-items: baseline; justify-content: space-between; margin: -5px 0px;">
          <span class="formfont">
            Cantidad de Cuotas:
          </span>
          <input type="number" formControlName="instQuantity" class="form-control" style="width: 50%;" [ngClass]="{'borderRed': increaseForm.invalid && (increaseForm.dirty || increaseForm.touched) 
              && !increaseForm.value.instQuantity}" max="{{ maxInstallments }}">
        </div>
        <hr>
        <div class="flex-row formrow">
          <span class="formfont">
            Nuevo monto:
          </span>
          <span class="formfont" *ngIf="edit && !isComercial">
            {{(increaseForm.value.newIncrease + 100) * oldIncrease.initialAmount / 100}}
          </span>
          <span class="formfont" *ngIf="!edit && !isComercial">
            {{(increaseForm.value.newIncrease + 100) * (oldIncrease.initialAmount * (1 + oldIncrease.increaseValue/100))
            / 100}}
          </span>
          <span class="formfont" *ngIf="isComercial">
            {{ oldIncrease.amount + (increaseForm.value.newIncrease * oldIncrease.amount / 100) }}
          </span>
        </div>
        <hr>
      </div>
    </div>
  </div>

  <div class="buttons-submitting"
    style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
    <button type="button" class="btn btn-danger" (click)="closeAction.emit()">Cancelar</button>
    <button class="btn btn-primary">{{edit ? "Editar" : "Crear"}}</button>
  </div>
</form>