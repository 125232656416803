<div class="smallCard" *ngIf="smallCard else longCard" >
    <div class="card border-20" (click)="navigateTo()">
        <div class="card-image">
            <div class="small-card-graph" 
            style="grid-column: 1/4;
            grid-row: 1/4;
            align-self: center;
            justify-self: center;">
                <round-progress
                [current]="68"
                [max]="100"
                [color]="'#45ccce'"
                [background]="'#eaeaea'"
                [radius]="37"
                [stroke]="12"
                [semicircle]="false"
                [rounded]="false"
                [clockwise]="true"
                [responsive]="false"
                [duration]="800"
                [animation]="'easeInOutQuart'"
                [animationDelay]="0">
                </round-progress>
            </div>
            <span
            style="grid-column: 2/3;
            grid-row: 2/3;
            align-self: center;
            justify-self: center;
            font-size: 150%;">{{cardData.payedExpenditurePercentage}}</span>
        </div>
        <div class="card-data">
            <div class="card-titles">
                <span style="font-size: small; font-weight: bold;">{{cardData.fullAddress}}</span>
                <span style="font-size: small;">{{cardData.description}}</span>
            </div>
            <div class="card-info">
                <span style="font-size: small;">{{'cardStaticPayed' | translate}}: </span> <span style="font-size: small;margin-right:2px">{{cardData.paidExpenditures}}  </span>  <br>
                <span style="font-size: small;">{{'cardStaticDefaulters' | translate}}: </span> <span style="font-size: small;">{{cardData.totalExpenditures}}  </span>  <br>
            </div>
        </div>
    </div>
</div>
<ng-template #longCard>
    <div class="longCard border-20" (click)="navigateTo()">
       <div class="longcard-graph">
           <div style="
           grid-column: 1/4;
           grid-row: 1/4;
           align-self: center;
           justify-self: center;">
                <round-progress
                [current]="68"
                [max]="100"
                [color]="'#45ccce'"
                [background]="'#eaeaea'"
                [radius]="35"
                [stroke]="12"
                [semicircle]="false"
                [rounded]="false"
                [clockwise]="true"
                [responsive]="false"
                [duration]="800"
                [animation]="'easeInOutQuart'"
                [animationDelay]="0">
                </round-progress>   
            </div>
          
            <span
            style="grid-column: 2/3;
            grid-row: 2/3;
            align-self: center;
            justify-self: center;
            font-size: 150%;">{{cardData.payedExpenditurePercentage}}</span>
       </div>
       <div class="longcard-body">
           <h3>{{cardData.description}}</h3>
           <hr>
           <div class="consortium-data">
               <span>{{cardData.fullAddress}}</span>
               <span></span>
               <span>{{'emitedExpenses' | translate}}: {{cardData.totalExpenditures}}%</span>
               <span></span>
               <span>{{'paidExpenses' | translate}}: {{cardData.paidExpenditures}}%</span>
           </div>
       </div>
    </div>
</ng-template>    

<!-- 
id: 1,
fullAddress: 'Fakestreet1234',
levels: 1,
description: 'Test Condo',
lastExpenditure: '12/12/1200',
totalExpenditures: 100,
payedExpenditures: 45,
payedExpenditurePercentage: 75,
condomiumType: {
id: 1,
type: 'Fake Condo', -->