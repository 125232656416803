
  <div class="body">
    <div class="title">
      <h1>Pagar servicios de {{reqName}}</h1>
      <hr>
    </div>

    <div class="create-retention">
        <div style="height: fit-content; min-height: fit-content;">
          <span style="font-size: 1.10rem; font-weight: 550; margin-bottom: 20px;">Cuotas</span>
          <div class="installment-list">
            <div class="inst-selector hover" *ngFor="let installment of installments" (click)="addInstallment(installment)">
                <span [ngClass]="{ 'text-bg-success' : installment.selected, 'text-bg-secondary' : !installment.selected}" 
                    class="badge shadowframe" style="height: 20px; font-size: 110%; cursor: pointer;">
                    <div class="flex-row centered" style="height: 100%; width: 100%;">
                        {{installment.description}} &nbsp; {{installment.fromDate | date: 'shortDate'}}
                    </div>
                </span>
            </div>
          </div>
        </div>
        <div *ngIf="showServiceForm" style="width: 100%;">
            <!-- <app-retention-form [installmentsReq]="this.installmentRequirements" (closeAction)="createdretention($event)"></app-retention-form> -->
            <form [formGroup]="retentionFormGroup" class="retention-form">
              <!-- 
                <h1>Crear Pago de Servicio</h1> -->
                <div class="flex-column">
                  <label>Monto</label>
                  <input type="number" formControlName="amount" class="form-control" [ngClass]="{'borderRed': retentionFormGroup.invalid && 
                              (retentionFormGroup.dirty || retentionFormGroup.touched) && !retentionFormGroup.value.amount}"
                              style="width: 15vw;">
                  <span *ngIf="retentionFormGroup.invalid && (retentionFormGroup.dirty || retentionFormGroup.touched) && !retentionFormGroup.value.amount"
                    style="color: var(--border-red); font-size: 90%;">Dato Faltante
                  </span>
                  <span *ngIf="retentionFormGroup.invalid && (retentionFormGroup.dirty || retentionFormGroup.touched) && retentionFormGroup.value.amount"
                    style="color: var(--border-red); font-size: 90%;">Dato Inválido
                  </span>
                </div>
              
                <div class="flex-column">
                  <label>Detalle</label>
                  <input type="text" formControlName="detail" class="form-control" [ngClass]="{'borderRed': retentionFormGroup.invalid && 
                              (retentionFormGroup.dirty || retentionFormGroup.touched) && !retentionFormGroup.value.detail}"
                              style="width: 15vw;">
                  <span *ngIf="retentionFormGroup.invalid && (retentionFormGroup.dirty || retentionFormGroup.touched) && !retentionFormGroup.value.detail"
                    style="color: var(--border-red); font-size: 90%;">Dato Faltante
                  </span>
                </div>
                <!-- <button class="btn btn-primary" type="button" (click)="createretention()"> Crear </button> -->
              </form>
          </div>
        <div class="installment-payment-grid">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">MES</th>
                <th scope="col">MONTO</th>
                <th scope="col">FECHA</th>
                <th scope="col">DETALLE</th>
                <th scope="col">PAGO PARCIAL</th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr *ngFor="let instReq of installmentRequirements">
                <th scope="row">{{instReq.installment.description}}</th>
                <td><input placeholder="$" type="text" class="form-control" style="width: 5vw;" [(ngModel)]="instReq.amount"></td>
                <td>  
                  <div class="flex-row centered">
                    <input  class="form-control" 
                            placeholder="yyyy-mm-dd" 
                            name="dp"  
                            [(ngModel)]="instReq.dateTemp"
                            (ngModelChange)="changeFilterData(instReq)"
                            ngbDatepicker #a="ngbDatepicker"
                            style="width: 5vw;">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" (click)="a.toggle()" type="button">
                        <fa-icon [icon]="iconCalendar" size="1x"> </fa-icon>
                      </button>
                    </div>
                  </div>
                </td>
                <td><input placeholder="Detalle" type="text" class="form-control" style="width: 5vw;" [(ngModel)]="instReq.details"></td>
                <td>
                  <div class="flex-row centered" style="width: 100%; height: 4vh;">
                    <div class="checkbox-container">
                      <label class="checkbox-label">
                          <input type="checkbox" #checkbox (change)="changePaid(checkbox.checked, instReq)">
                          <span class="checkbox-custom rectangular"></span>
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="flex-row" style="width: 100%; justify-content: space-evenly; align-items: center;" *ngIf="!showServiceForm">
          <button class="btn btn-success" type="button" (click)="pay()">Confirmar Pago de Cuotas</button>
        </div>
        <div class="flex-row" style="width: 100%; justify-content: space-evenly; align-items: center;" *ngIf="showServiceForm">
          <button class="btn btn-primary" (click)="close()" *ngIf="editData">Cancelar</button>
        </div>
    </div>
  </div>



