import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { faEnvelope, faHome } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-no-user',
  templateUrl: './no-user.component.html',
  styleUrls: ['./no-user.component.css'],
})
export class NoUserComponent implements OnInit {
  iconEnvelop = faEnvelope;
  iconHome = faHome;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public auth: AuthService,
    public router: Router,
  ) {    
    if (auth.user$) {
      auth.user$.subscribe((user) => {
        if (user['https://baup.com.ar/agencyId'] != null) {
          this.router.navigateByUrl(`/home`);
        }
      });
    }
  }

  ngOnInit() {}

  logOut() {
    localStorage.removeItem('token');
    this.auth.logout({ returnTo: document.location.origin });
  }
}
