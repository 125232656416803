import { EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  faCalendar,
  faSearch,
  faPlus,
  faEye,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { DocumentTypes } from 'src/app/core/models/DocumentTypes.enum';
import { GuarantorForEdit } from 'src/app/core/models/GuarantorForAdd';
import { GuarantorTypesEnum } from 'src/app/core/models/GuarantorTypesEnum.enum';
import { PhoneNumber } from 'src/app/core/models/PhoneNumber';
import { DateService } from 'src/app/services/date.service';
import { GuarantorService } from 'src/app/services/guarantor.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-guarantor-form',
  templateUrl: './guarantor-form.component.html',
  styleUrls: ['./guarantor-form.component.css'],
})
export class GuarantorFormComponent implements OnInit {
  iconCalendar = faCalendar;
  iconSearch = faSearch;
  iconPlus = faPlus;
  iconSee = faEye;
  iconTrash = faTrash;
  guarantorTypeCheck: boolean;
  display: boolean = true;
  guarantorTypeToggle: GuarantorTypesEnum = GuarantorTypesEnum.Person;
  guarantorType = GuarantorTypesEnum;
  phoneContacts: PhoneNumber[] = [];
  documentTypes: any[] = [];
  guarantorIndex: number;
  submitted: boolean = false;


  guarantorForSubmit: GuarantorForEdit;

  @Input() edit?: boolean;
  @Input() viewObject?: boolean = true;
  @Input() editData?: any;
  @Input() createInMemory?: any;
  @Input() createInMemoryArray?: any;
  @Input() editContractMode?: boolean = false;

  @Output() closeAction = new EventEmitter();
  @Output() cancelAction = new EventEmitter();

  guarantorPersonFormGroup = new UntypedFormGroup({
    dateOfBirth: new UntypedFormControl(''),
    firstName: new UntypedFormControl('', Validators.required),
    lastName: new UntypedFormControl('', Validators.required),
    documentId: new UntypedFormControl('', [Validators.minLength(7), Validators.maxLength(15)]),
    documentType: new UntypedFormControl(''),
    streetAddress: new UntypedFormControl(''),
    city: new UntypedFormControl(''),
    state: new UntypedFormControl(''),
    zipCode: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    socialReason: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl(''),
  });

  constructor(
    public guarantorService: GuarantorService,
    public dateService: DateService,
    private router: Router,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    for (let item in DocumentTypes) {
      if (isNaN(Number(item))) {
        this.documentTypes.push({ text: item, value: DocumentTypes[item] });
      }
    }

    if (this.edit) {
      if (!this.viewObject) {
        this.guarantorIndex = this.editData.id;
        this.setGuarantorTypeCheck(this.editData);
        this.patchValueAddObjectType();
        this.phoneContacts = this.editData.phones;
      } else {
        this.setGuarantorTypeCheck(this.editData.person);
        this.patchValueViewObjectType();
        this.phoneContacts = this.editData.person.phones;
      }
      let isPerson = this.editData.person?.isPerson || this.editData.isPerson;
      if (isPerson) {
        this.selectGuarantorType(2);
      } else {
        this.selectGuarantorType(1);
      }
    } else {
      this.selectGuarantorType();
      this.guarantorPersonFormGroup.reset();
    }
  }

  submitGuarantor() {
    if (this.guarantorPersonFormGroup.get('dateOfBirth').hasError('ngbDate')) {
      this.toastService.showToast(
        'warning',
        'Corrija el formato de la fecha a AAAA-MM-DD',
      );
      return;
    }

    if (this.guarantorPersonFormGroup.invalid) {
      this.guarantorPersonFormGroup.markAllAsTouched();
      return;
    }

    let date = this.guarantorPersonFormGroup.value.dateOfBirth
      ? this.guarantorPersonFormGroup.value.dateOfBirth
      : null;
    this.guarantorForSubmit = {
      email: this.guarantorPersonFormGroup.value.email
        ? this.guarantorPersonFormGroup.value.email
        : null,
      documentId: this.guarantorPersonFormGroup.value.documentId
        ? this.idParser(this.guarantorPersonFormGroup.value.documentId)
        : null,
      documentType: this.guarantorPersonFormGroup.value.documentType
        ? this.guarantorPersonFormGroup.value.documentType
        : null,
      dateOfBirth: date
        ? this.dateService.transformToDateObject(
            date?.day,
            date?.month,
            date?.year,
          )
        : null,
      firstName: this.guarantorPersonFormGroup.value.firstName
        ? this.guarantorPersonFormGroup.value.firstName
        : '',
      lastName: this.guarantorPersonFormGroup.value.lastName
        ? this.guarantorPersonFormGroup.value.lastName
        : '',
      streetAddress: this.guarantorPersonFormGroup.value.streetAddress
        ? this.guarantorPersonFormGroup.value.streetAddress
        : null,
      city: this.guarantorPersonFormGroup.value.city
        ? this.guarantorPersonFormGroup.value.city
        : null,
      state: this.guarantorPersonFormGroup.value.state
        ? this.guarantorPersonFormGroup.value.state
        : null,
      zipCode: this.guarantorPersonFormGroup.value.zipCode
        ? this.guarantorPersonFormGroup.value.zipCode
        : null,
      phones: this.phoneContacts,
      isPerson: this.guarantorTypeToggle == 2 ? true : false,
      businessName: this.guarantorPersonFormGroup.value.socialReason
        ? this.guarantorPersonFormGroup.value.socialReason
        : '',
      guarantorTypeId: this.guarantorTypeToggle
        ? GuarantorTypesEnum.Person
        : GuarantorTypesEnum.Bussiness,
      description: this.guarantorPersonFormGroup.value.description,
    };

    if (this.guarantorPersonFormGroup.invalid) {
    } else {
      if (this.edit) {
        if (this.createInMemory) {
          this.createInMemoryArray[this.guarantorIndex] =
            this.guarantorForSubmit;
          if (this.editContractMode) {
            this.editGuarantor(this.guarantorForSubmit);
          }
          this.closeAction.emit(this.guarantorForSubmit);
        } else {
          this.editGuarantor(this.guarantorForSubmit);
        }
      } else {
        if (this.createInMemory) {
          this.createInMemoryArray.push(this.guarantorForSubmit);
          if (this.editContractMode) {
            this.addGuarantor(this.guarantorForSubmit);
          }
          this.closeAction.emit(this.guarantorForSubmit);
        } else {
          this.submitted = true;
          this.addGuarantor(this.guarantorForSubmit);
        }
      }
    }
  }

  addGuarantor(guarantorForSubmit) {
    this.guarantorService
      .createGuarantor(guarantorForSubmit)
      .subscribe((response: number) => {
        this.toastService.showToast('success', 'Garante creado');
        if (this.editContractMode) {
          this.guarantorService.getGuarantor(response).subscribe((result) => {
            this.createInMemoryArray.push(result);
            this.closeAction.emit(result);
          });
        } else {
          this.closeAction.emit();
          this.router.navigateByUrl(`/guarantors/${response}`);
        }
      });
  }

  editGuarantor(guarantorForEdit: GuarantorForEdit) {
    guarantorForEdit.id = this.editData.id;
    this.guarantorService
      .updateGuarantor(this.editData.id, guarantorForEdit)
      .subscribe((response) => {
        this.toastService.showToast('success', 'Garante editado');
        if (this.editContractMode) {
          this.guarantorService
            .getGuarantor(this.editData.id)
            .subscribe((result) => {
              this.createInMemoryArray[this.guarantorIndex] = result;
              this.closeAction.emit(result);
            });
        } else {
          this.closeAction.emit(this.editData.id);
        }
      });
  }

  selectGuarantorType(value = 2) {
    if (value == 2) {
      this.guarantorPersonFormGroup.controls['socialReason'].clearValidators();
      this.guarantorPersonFormGroup.controls['socialReason'].patchValue(null);
      this.guarantorPersonFormGroup.controls['firstName'].addValidators(
        Validators.required,
      );
      this.guarantorPersonFormGroup.controls['lastName'].addValidators(
        Validators.required,
      );
    } else {
      this.guarantorPersonFormGroup.controls['firstName'].clearValidators();
      this.guarantorPersonFormGroup.controls['lastName'].clearValidators();
      this.guarantorPersonFormGroup.controls['firstName'].patchValue(null);
      this.guarantorPersonFormGroup.controls['lastName'].patchValue(null);
      this.guarantorPersonFormGroup.controls['dateOfBirth'].patchValue(null);
      this.guarantorPersonFormGroup.controls['socialReason'].addValidators(
        Validators.required,
      );
    }
    this.guarantorTypeToggle = value;
  }

  patchValueAddObjectType() {
    let date = this.editData.dateOfBirth;
    this.guarantorPersonFormGroup.patchValue({
      dateOfBirth: date
        ? this.dateService.transformToDateHash(new Date(date))
        : null,
      firstName: this.editData.firstName,
      lastName: this.editData.lastName,
      documentId: this.editData.documentId,
      documentType: this.editData.documentType,
      streetAddress: this.editData.streetAddress,
      city: this.editData.city,
      state: this.editData.state,
      zipCode: this.editData.zipCode,
      email: this.editData.email,
      socialReason: this.editData.businessName,
      description: this.editData.description,
    });
  }

  setGuarantorTypeCheck(data) {
    this.guarantorTypeCheck = data.isPerson;
    data.isPerson
      ? (this.guarantorTypeToggle = 2)
      : (this.guarantorTypeToggle = 1);
  }

  patchValueViewObjectType() {
    let date = this.editData.person.dateOfBirth;
    this.guarantorPersonFormGroup.patchValue({
      dateOfBirth: date
        ? this.dateService.transformToDateHash(new Date(date))
        : null,
      firstName: this.editData.person.firstName,
      lastName: this.editData.person.lastName,
      documentId: this.editData.person.documentId,
      documentType: this.editData.person.documentType,
      streetAddress: this.editData.person.address.streetAddress,
      city: this.editData.person.address.city,
      state: this.editData.person.address.state,
      zipCode: this.editData.person.address.zipCode,
      email: this.editData.email,
      socialReason: this.editData.person.businessName,
      description:
        this.editData.user?.person?.description || this.editData.description,
    });
  }

  validate(field: string) {
    return (
      this.guarantorPersonFormGroup.controls[field].errors &&
      this.guarantorPersonFormGroup.controls[field].touched
    );
  }

  idParser(id) {
    id = id.toString();
    let stringId: string = '';
    for (let i = 0; i < id.length; i++) {
      let char: string = id.charAt(i);
      if (parseInt(char) + 10) {
        stringId += char;
      }
    }
    if (stringId == '') {
      return null;
    }
    return Number(stringId);
  }

  
  cancel(){
    this.cancelAction.emit();
  }

}
