import { Injectable } from '@angular/core';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { Observable } from 'rxjs/internal/Observable';
import { IPaginatedServiceParamters } from '../core/models/ITable';
import { InstallmentWithRequirement } from '../core/models/InstallmentWithRequirement';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LiabilitiesService {
  public insWithReq: InstallmentWithRequirement[] = [];
  
  constructor(private apiService: ApiService) {}

  getAllPaginated(
    paginatedArguments: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<any[]>> {
    return this.apiService.getPaginated(
      '/api/InstallmentRequirement/' + paginatedArguments.id,
      paginatedArguments,
    );
  }

  getInstallmentsRequirements(
    paginatedArguments: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<any>> {
    return this.apiService.getPaginated(
      '/api/InstallmentRequirement',
      paginatedArguments,
    );
  }

  getInstallmentRequirement(Id) {
    return this.apiService.get(`/api/InstallmentRequirement/${Id}`);
  }

  getInstallmentRequirementContract(
    contractId,
  ): Observable<InstallmentWithRequirement[]> {
    return this.apiService.get(
      `/api/InstallmentRequirement/Contract/${contractId}`,
    );
  }

  createInstallmentRequirement(requirement) {
    return this.apiService.post(`/api/InstallmentRequirement`, requirement);
  }

  createInstallmentRequirementArray(requirement) {
    return this.apiService.post(
      `/api/InstallmentRequirement/Many`,
      requirement,
    );
  }

  updateInstallmentRequirement(requirement, id) {
    return this.apiService.put(
      `/api/InstallmentRequirement/${id}`,
      requirement,
    );
  }

  deleteInstallmentRequirement(id) {
    return this.apiService.delete(`/api/InstallmentRequirement/${id}`);
  }
}
