<div class="persons">

  <div class="persons-list">

    <div class="entity-direction">
      
      <div class="button-title">

        <span style="font-size: 1.2rem; font-weight: 500;">{{ title }}</span>
        <button class="btn btn-outline-secondary buttons-controls" style="grid-area: add;" (click)="openEntityForm()"
          [ngbTooltip]="addTooltip" placement="bottom" *ngIf="!(searchType == 'building' && entryArray[0] != null)">
          <fa-icon [icon]="iconPlus" size="1x"> </fa-icon>
        </button>  
        <button class="btn btn-outline-secondary buttons-controls" style="grid-area: search;" (click)="openSearch(search)"
        *ngIf="!(searchType == 'building' && entryArray[0] != null)">
          <fa-icon [icon]="iconSearch" size="1x"> </fa-icon>
        </button>
      </div>
      <div *ngIf="entryArray != null" class="search-add">
        <div *ngFor="let element of entryArray; let ind = index">
          <app-contract-card-v2 [cardData]="element" [type]="searchType" (delEvent)="deleteItem(ind)" (openEditForm)="openFormEdit(element, ind)"></app-contract-card-v2>
        </div>
      </div>

    </div>

  </div>


</div>

<ng-template #search>
  <app-entities-for-contract-search [searchType]="searchType" (selectEntity)="selectedSearchedElement($event)" >
  </app-entities-for-contract-search>
</ng-template>

<app-contract-templates-handler [templateType]="searchType" (closeFormEvent)="addEntityFromMemory($event)"></app-contract-templates-handler>