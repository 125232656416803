import { Injectable } from '@angular/core';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { Observable } from 'rxjs';
import { IPaginatedServiceParamters } from '../core/models/ITable';
import { Contract } from '../core/models/Contract';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class InstallmentsIncreasesService {
  constructor(private apiService: ApiService) {}

  getAllPaginated(
    paginatedArguments: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<Contract[]>> {
    return this.apiService.getPaginated(
      '/api/Contract/ToDue',
      paginatedArguments,
    );
  }

  addInstallmentIncrease(contractArray, increase) {
    return this.apiService.post(
      `/api/Contract/AddInstallmentIncrease?increaseValue=` + increase,
      contractArray,
    );
  }

  editInstallmentIncrease(contractId, increaseValue) {
    return this.apiService.put(
      `/api/Contract/EditInstallmentIncrease/${contractId}`,
      increaseValue,
    );
  }

  deleteInstallmentIncrease(contractId) {
    return this.apiService.delete(
      `/api/Contract/DeleteInstallmentIncrease/${contractId}`,
    );
  }
}
