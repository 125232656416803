import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BAUP } from '../core/models/File';
import { Observable, tap } from 'rxjs';
import { FileParentEnum } from '../core/models/FileParentEnum';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private apiService: ApiService) {}

  addFiles(files: FormData, parentId: number, parentType: FileParentEnum, contractId: number): Observable<HttpEvent<File[]>> {
    return this.apiService.formDataArrayUpload(`/api/File/Files?parentId=${parentId}&parentType=${parentType}&contractId=${contractId}`, files).pipe(
      tap(event => {
        if (event.type === HttpEventType.UploadProgress) {
          console.log(`Progreso de subida: ${Math.round((event.loaded / event.total) * 100)}%`);
        }
      })
    );
  }
  

  deleteFiles(files: BAUP.File[]) {
    return this.apiService.deleteMany('/api/File/Files', files);
  }
}
