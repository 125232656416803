<ng-template #name class="flex-row centered">

  <!-- <div class="pdfBody"  id='html-pdf'>
    <div class="header">
        <div class="header-info">
            <p>Recibo de Retorno</p>
            <p style="font-weight: bold">Nro: 123123</p>
            <p>Buenos Aires, Capital</p> 
            <p>{{date}}</p>
        </div>
    </div>
    
    <div class="main-data" style="border-top: 0.5px solid; border-bottom: 0.5px solid; padding-top: 10px;">
        <p>Recibo la suma de ${{return?.amount}}</p>
        <p>en concepto de Cobro de Retorno</p>
        <p>correspondiente al pago del periodo: {{paymentDate}} </p> 
        <p>por el inmueble ubicado en: {{return?.payments[0].contract.property.address.streetAddress}} {{return?.payments[0].contract.property.identification}}</p>
        <p>recibo emitido por ...</p>
    </div>
     -->
  <!-- <div class="details">

        <div style="display: flex; flex-direction: column; justify-content: flex-start; width: 33%; align-items: center;">
          <p>DETALLE DE RETORNO</p>
          <p>{{return?.detail}}</p>
        </div>

        <div style="width: 33%;border-left: 0.5px solid; border-right: 0.5px solid; display: flex; flex-direction: column; align-items: center;">

        </div>

        <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; width: 33%;">
            <div style="margin-right: 10px;" class="flex-column" >
                <p>Total a pagar</p>
                <p>Pagos anteriores</p>
                <br>
                <p style="font-weight: bold;">Pagado</p>
                <p>Resta</p>
            </div>
            <div class="flex-column">
                <p>{{return?.amount}}</p>
                <p>-</p>
                <br>
                <p style="font-weight: bold;">{{return?.amount}}</p>
                <p>-</p>
            </div>
        </div>
    
    </div> -->

  <!-- <div style="display: flex; flex-direction: column; margin-top: 5px; align-items: center;">
      <p style="font-weight: bold; margin-top: 6px;">DETALLES DE PAGOS</p>
      <div *ngFor="let payment of this.return?.payments; let i = index" class="flex-row" style="width: 100%; border-top: 0.5px solid;">
          <div style="display: flex; flex-direction: column; width: 30%; padding: 5px">
            <p>FECHA DE PAGO: <span style="font-weight: 550;">{{payment.date | date:'shortDate'}}</span></p>
            <div class="flex-row">
            <div class="flex-column" style="width: 100%; height: 100%;">
                <span> IMPORTE ALQUILER </span>
                <span> PUNITORIOS </span>
                <span> ADICIONALES </span>
                <span> DESCUENTOS </span>
              </div>
              <div class="flex-column" style="width: 100%; height: 100%; align-items: flex-end;">
                <span> {{paymentInstallmentTotalAmount[i]}} </span>
                <span> {{payment?.punitivesTotalAmount}} </span>
                <span> {{payment?.additionalChargesTotalAmount - payment?.punitivesTotalAmount}} </span>
                <span> {{payment?.contract.tenantAmountInFavor}} </span>
              </div>
              </div>
              <hr>
              <div class="flex-row">
                  <div class="flex-column" style="width: 100%; height: 100%;">
                    <span> TOTAL: </span>
                    <span> MONTO: </span>
                    <span> RESTANTE: </span>
                  </div>
                  <div class="flex-column" style="width: 100%; height: 100%; align-items: flex-end;">
                    <span> {{paymentInstallmentTotalAmount[i] + payment?.additionalChargesTotalAmount - payment?.punitivesTotalAmount + payment?.punitivesTotalAmount - payment?.contract.tenantAmountInFavor}} </span>
                    <span> {{payment?.amount}} </span>
                    <span> {{payment?.amount - paymentInstallmentTotalAmount[i] - payment?.additionalChargesTotalAmount - payment?.punitivesTotalAmount + payment?.punitivesTotalAmount - payment?.contract.tenantAmountInFavor}} </span>
                  </div>
              </div>
          </div>

            <div class="receipt" style="grid-area: stuff2; width: 100%; height: 100%;">
              <span class="font-title">ESTADO DE SERVICIOS:</span>
              <div class="flex-row" style="align-content: center; justify-items: flex-start;">
                <div *ngFor="let installment of payment?.installments" class="flex-column" style="margin: 3px 6px 3px 6px;">
                  <span style="margin-right: 5px;font-weight: 550;">{{installment.description}}</span>
                  <div  *ngFor="let instReq of  installment.installmentRequirements">
                    <div>
                      <span> {{instReq.contractRequirement.requirement}} </span>
                      <span *ngIf="instReq.installmentStatusId == 1" style="color: #3f753f; font-size: 450;"> PAGADO </span>
                      <span *ngIf="instReq.installmentStatusId == 2" style="color: #8a2929; font-size: 450;"> IMPAGO </span>
                      <span *ngIf="instReq.installmentStatusId == 3" style="color: #bbb129; font-size: 450;"> PAGO PARCIAL </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
        </div>
    </div>
    

    <hr>
    <div class="footer">
        <div style="margin-right: 20px;" class="flex-column">
            <p style="font-weight: bold; border-top: solid;width: 500px;">[Original]</p>
        </div>
        <div class="flex-column">
            <p style= "border-top: 0.5px solid;width: 200px;">Firma y aclaración</p>
        </div>
    </div>
  </div>

  <div class="flex-row" style="justify-content: flex-end; align-items: center; margin: 1vh;">
    <button class="btn btn-primary" (click)="onExportClick();">Descargar</button>
  </div> -->
</ng-template>
<button *ngIf="!popoverOption" class="flex-row btn btn-primary face-button" type="button" (click)="openModal(name);"
  [disabled]="!isLoaded">

  <div class="face-primary">
    <div class="row">
      <span style="color: white; margin-bottom: 1.8vh;">PDF</span>
    </div>
    <div class="row" style="white-space: nowrap; ">
      <span style="color: white;"><fa-icon style="margin-right: 5px; color: white;"
          [icon]="iconDownload"></fa-icon>Descargar</span>
    </div>

  </div>

</button>

<span *ngIf="popoverOption" [ngClass]="{'disabled': !isLoaded}" (click)="openModal(name);">

  Imprimir recibo de Liquidación

</span>

<!-- <button class="flex-row btn btn-primary" type="button" (click)="openModal(name);">Ver PDF</button> -->