import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAILDataForAdd } from 'src/app/core/models/MAIL';
import { MailService } from 'src/app/services/mail.service';
import { ToastService } from 'src/app/services/toast.service';
import { ConfirmDialogSettings } from 'src/app/shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-mail-form',
  templateUrl: './mail-form.component.html',
  styleUrls: ['./mail-form.component.css'],
})
export class MailFormComponent implements OnInit {
  index: number;
  @Input() edit?: boolean;
  @Input() emailData?: any;
  @Input() first: boolean;

  @Output() closeAction = new EventEmitter();
  @Output() addEventEmmiter = new EventEmitter();
  @Output() selectElementEvent = new EventEmitter();

  mailFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', Validators.required),
  });

  mailToSubmit: string;

  constructor(
    public mailService: MailService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    if (this.edit) {
      this.mailFormGroup.patchValue({
        email: this.emailData.defaultEmail,
      });
    }
  }

  onSubmit() {
    this.mailToSubmit = this.mailFormGroup.value.email
      ? this.mailFormGroup.value.email
      : null;

    if (this.mailFormGroup.invalid) {
      this.toastService.showToast(
        'warning',
        'Hay campos obligatorios sin completar',
      );
    } else {
      this.editMail(this.mailToSubmit);
    }
  }

  editMail(emailToEdit) {
    this.mailService
      .editMail(this.emailData.id, emailToEdit)
      .subscribe((response) => {
        this.toastService.showToast('success', 'Email modificado');
        this.closeAction.emit();
      });
  }
}
