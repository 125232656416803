<div *ngIf="!popoverOption" class="extra border-15" (click)="openModal(confirm)"
  [ngbTooltip]="'EXPENSA EXTRAORDINARIA'">

  <fa-icon class="dollarSign" [icon]="dollarSign" size="2x" style="opacity: 0.7;">
  </fa-icon>

  <div class="plus-circle">
    <fa-icon class="plus" [icon]="plus" size="1x">
    </fa-icon>
  </div>
  <span class="badge text-bg-primary req-style-text " style="font-size: 60%;">
    EXPENSA EXTRAORDINARIA
  </span>
</div>

<div *ngIf="popoverOption" (click)="openModal(confirm)">
  <span>Agregar Expensa Extraordinaria</span>

</div>


<ng-template #confirm>
  <form class="form" [formGroup]="extraFormGroup" (ngSubmit)="addExtra()">
    <h2>Expensa Extraordinaria</h2>
    <hr>
    <label>Monto</label>
    <input type="number" class="form-control" formControlName="amount" [ngClass]="{'borderRed': extraFormGroup.invalid && (extraFormGroup.dirty || extraFormGroup.touched) 
      && !extraFormGroup.value.amount}">

    <div class="addBtn">
      <button class="btn btn-primary">Agregar</button>
    </div>
  </form>
</ng-template>