import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  faCalendar,
  faSearch,
  faPlus,
  faEye,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { ConstantService } from 'src/app/services/constant.service';
import { OwnerService } from 'src/app/services/owner.service';
import { DateService } from 'src/app/services/date.service';
import { Router } from '@angular/router';
import { DocumentTypes } from 'src/app/core/models/DocumentTypes.enum';
import { PhoneNumber } from 'src/app/core/models/PhoneNumber';
import { ToastService } from 'src/app/services/toast.service';
import { OwnerForEdit } from 'src/app/core/models/OwnerForAdd';
import { OwnerType } from 'src/app/core/models/OwnerType.enum';

@Component({
  selector: 'app-owner-form',
  templateUrl: './owner-form.component.html',
  styleUrls: ['./owner-form.component.css'],
})
export class OwnerFormComponent implements OnInit {
  iconCalendar = faCalendar;
  iconSearch = faSearch;
  iconPlus = faPlus;
  iconSee = faEye;
  iconTrash = faTrash;
  display: boolean = true;
  ownerTypeCheck: boolean = true;
  ownerTypeToggle: OwnerType = OwnerType.Person;
  ownerType = OwnerType;
  ownerIndex: number;
  phoneContacts: PhoneNumber[] = [];
  documentTypes: any[] = [];
  submitted: boolean = false;
  ivaCondition: string;

  ownerToSubmit: OwnerForEdit;

  @Input() edit?: boolean;
  @Input() viewObject?: boolean = true;
  @Input() editData?: any;
  @Input() createInMemory?: any;
  @Input() createInMemoryArray?: any;
  @Input() editContractMode?: boolean = false;

  @Output() closeAction = new EventEmitter();
  @Output() cancelAction = new EventEmitter();


  ownerDataFormGroup = new UntypedFormGroup({
    dateOfBirth: new UntypedFormControl(''),
    firstName: new UntypedFormControl('', Validators.required),
    lastName: new UntypedFormControl('', Validators.required),
    taxRole: new UntypedFormControl(null),
    documentId: new UntypedFormControl('', [Validators.minLength(7), Validators.maxLength(15)]),
    documentType: new UntypedFormControl(''),
    streetAddress: new UntypedFormControl(''),
    city: new UntypedFormControl(''),
    state: new UntypedFormControl(''),
    zipCode: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    socialReason: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl(''),
  });

  constructor(
    public ownerService: OwnerService,
    public constantService: ConstantService,
    public dateService: DateService,
    private router: Router,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    for (let item in DocumentTypes) {
      if (isNaN(Number(item))) {
        this.documentTypes.push({ text: item, value: DocumentTypes[item] });
      }
    }
    if (this.edit) {
      this.ownerIndex = this.editData.id;
      this.ivaCondition = 
      
     
      this.phoneContacts =
        this.editData.user?.person?.phones || this.editData.phones;
      var date =
        this.editData.user?.person?.dateOfBirth || this.editData.dateOfBirth;
      var finalDate = date
        ? this.dateService.transformToDateHash(new Date(date))
        : null;
      var ownerType = this.editData.user?.person || this.editData;
      this.setOwnerTypeCheck(ownerType);
      this.ownerDataFormGroup.patchValue({
        dateOfBirth: finalDate,
        firstName:
          this.editData.user?.person?.firstName || this.editData.firstName,
        lastName:
          this.editData.user?.person?.lastName || this.editData.lastName,
        documentId:
          this.editData.user?.person?.documentId || this.editData.documentId,
        documentType:
          this.editData.user?.person?.documentType ||
          this.editData.documentType,
        streetAddress:
          this.editData.user?.person?.address?.streetAddress ||
          this.editData.streetAddress,
        city: this.editData.user?.person?.address?.city || this.editData.city,
        state:
          this.editData.user?.person?.address?.state || this.editData.state,
        zipCode:
          this.editData.user?.person?.address?.zipCode || this.editData.zipCode,
        email: this.editData.user?.email || this.editData.email,
        socialReason:
          this.editData.user?.person?.businessName ||
          this.editData.businessName,
        description:
          this.editData.user?.person?.description || this.editData.description,
        taxRole: 
        this.editData.user?.person?.taxRole || this.editData.taxRole
      });
      let isPerson =
        this.editData.user?.person?.isPerson || this.editData.isPerson;
      if (isPerson) {
        this.selectOwnerType(1);
      } else {
        this.selectOwnerType(2);
      }
    } else {
      this.selectOwnerType();
      this.ownerDataFormGroup.reset();
    }
  }

  onSubmit() {
    let date = this.ownerDataFormGroup.value.dateOfBirth
      ? this.ownerDataFormGroup.value.dateOfBirth
      : null;

    this.ownerToSubmit = {
      id: this.editData?.id || 0,
      userName: this.ownerDataFormGroup.value.email
        ? this.ownerDataFormGroup.value.email
        : null,
      email: this.ownerDataFormGroup.value.email
        ? this.ownerDataFormGroup.value.email
        : null,
      userImageUrl: this.ownerDataFormGroup.value.userImageUrl
        ? this.ownerDataFormGroup.value.userImageUrl
        : null,
      isAdmin: false,
      documentId: this.ownerDataFormGroup.value.documentId
        ? this.idParser(this.ownerDataFormGroup.value.documentId)
        : null,
      documentType: this.ownerDataFormGroup.value.documentType
        ? this.ownerDataFormGroup.value.documentType
        : null,
      dateOfBirth: date ? this.dateService.transformToDateObject(
        date?.day,
        date?.month,
        date?.year,
      ) : null,
      firstName: this.ownerDataFormGroup.value.firstName
        ? this.ownerDataFormGroup.value.firstName
        : '',
      lastName: this.ownerDataFormGroup.value.lastName
        ? this.ownerDataFormGroup.value.lastName
        : '',
      streetAddress: this.ownerDataFormGroup.value.streetAddress
        ? this.ownerDataFormGroup.value.streetAddress
        : null,
      city: this.ownerDataFormGroup.value.city
        ? this.ownerDataFormGroup.value.city
        : null,
      state: this.ownerDataFormGroup.value.state
        ? this.ownerDataFormGroup.value.state
        : null,
      zipCode: this.ownerDataFormGroup.value.zipCode
        ? this.ownerDataFormGroup.value.zipCode
        : null,
      taxRole: this.ownerDataFormGroup.value.taxRole
      ? this.ownerDataFormGroup.value.taxRole
        : null,
      phones: this.phoneContacts,
      
      isPerson: this.ownerTypeToggle == 1 ? true : false,
      businessName: this.ownerDataFormGroup.value.socialReason
        ? this.ownerDataFormGroup.value.socialReason
        : '',
      description: this.ownerDataFormGroup.value.description,
    };

    if (this.ownerDataFormGroup.get('dateOfBirth').hasError('ngbDate')) {
      this.toastService.showToast(
        'warning',
        'Corrija el formato de la fecha a AAAA-MM-DD',
      );
      return;
    }

    if (this.ownerDataFormGroup.invalid) {
      this.ownerDataFormGroup.markAllAsTouched();
      this.toastService.showToast(
        'warning',
        'Hay campos obligatorios sin completar',
      );
      return;
    } else {
      if (this.edit) {
        if (this.createInMemory) {
          this.createInMemoryArray[this.ownerIndex] = this.ownerToSubmit;
          this.closeAction.emit(this.ownerToSubmit);
        } else {
          this.editOwner(this.ownerToSubmit);
        }
      } else {
        this.submitted = true;
        if (this.createInMemory) {
          this.createInMemoryArray.push(this.ownerToSubmit);
          this.closeAction.emit(this.ownerToSubmit);
        } else {
          this.addOwner(this.ownerToSubmit);
        }
      }
    }
  }

  addOwner(ownerToSubmit) {
    this.ownerService
      .createOwner(ownerToSubmit)
      .subscribe((response: number) => {
        this.toastService.showToast('success', 'Propietario creado');
        if (this.editContractMode) {
          this.ownerService.getOwner(response).subscribe((result) => {
            this.createInMemoryArray.push(result);
            this.closeAction.emit(result);
          });
        } else {
          this.closeAction.emit();
          this.router.navigateByUrl(`/owners/${response}`);
        }
      });
  }

  editOwner(ownerForEdit: OwnerForEdit) {
    ownerForEdit.id = this.editData.id;
    this.ownerService
      .updateOwner(this.editData.id, ownerForEdit)
      .subscribe((response) => {
        this.toastService.showToast('success', 'Propietario actualizado');
        if (this.editContractMode) {
          this.ownerService.getOwner(this.editData.id).subscribe((result) => {
            this.createInMemoryArray[this.ownerIndex] = result;
            this.closeAction.emit(result);
          });
        } else {
          this.closeAction.emit(this.editData.id);
        }
      });
  }

  selectOwnerType(value = 1) {
    if (value == 1) {
      this.ownerDataFormGroup.controls['socialReason'].clearValidators();
      this.ownerDataFormGroup.controls['socialReason'].patchValue(null);
      this.ownerDataFormGroup.controls['firstName'].addValidators(
        Validators.required,
      );
      this.ownerDataFormGroup.controls['lastName'].addValidators(
        Validators.required,
      );
    } else {
      this.ownerDataFormGroup.controls['firstName'].clearValidators();
      this.ownerDataFormGroup.controls['lastName'].clearValidators();
      this.ownerDataFormGroup.controls['firstName'].patchValue(null);
      this.ownerDataFormGroup.controls['lastName'].patchValue(null);
      this.ownerDataFormGroup.controls['dateOfBirth'].patchValue(null);
      this.ownerDataFormGroup.controls['documentType'].patchValue("3");
      this.ownerDataFormGroup.controls['socialReason'].addValidators(
        Validators.required,
      );
    }
    this.ownerTypeToggle = value;
  }

  setOwnerTypeCheck(data) {
    this.ownerTypeCheck = data.isPerson;
    data.isPerson ? (this.ownerTypeToggle = 1) : (this.ownerTypeToggle = 2);
  }

  validate(field: string) {
    return (
      this.ownerDataFormGroup.controls[field].errors &&
      this.ownerDataFormGroup.controls[field].touched
    );
  }

  idParser(id) {
    id = id.toString();
    let stringId: string = '';
    for (let i = 0; i < id.length; i++) {
      let char: string = id.charAt(i);
      if (parseInt(char) + 10) {
        stringId += char;
      }
    }
    if (stringId == '') {
      return null;
    }
    return Number(stringId);
  }

  setTaxRole(taxRole){    
    if(taxRole > 0){
      this.ownerDataFormGroup.controls['documentType'].addValidators(
        Validators.required,
      );
      this.ownerDataFormGroup.controls['documentId'].addValidators(
        Validators.required,
      );
    }
    else{
      this.ownerDataFormGroup.controls['documentType'].clearValidators();
      this.ownerDataFormGroup.controls['documentId'].clearValidators();
    }
    this.ownerDataFormGroup.controls["documentType"].updateValueAndValidity();
    this.ownerDataFormGroup.controls["documentId"].updateValueAndValidity();
  }

  cancel(){
    this.cancelAction.emit();
  }
}
