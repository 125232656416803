import { Injectable } from '@angular/core';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { Observable } from 'rxjs';
import { IPaginatedServiceParamters } from '../core/models/ITable';
import { logBookEntry, logBookEntryToSubmit } from '../core/models/logBook';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class logBookService {
  constructor(private apiService: ApiService) {}

  getAllPaginated(
    paginatedArguments: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<logBookEntry[]>> {
    return this.apiService.getPaginated(
      `/api/ContractBinnacle/Contract/${paginatedArguments.id}`,
      paginatedArguments,
    );
  }

  deleteEntry(id) {
    return this.apiService.delete(`/api/ContractBinnacle/${id}`);
  }

  addEntry(newEntry): Observable<logBookEntryToSubmit> {
    return this.apiService.post('/api/ContractBinnacle', newEntry);
  }

  editEntry(id, binnacleToEdit): Observable<logBookEntryToSubmit> {
    return this.apiService.put(`/api/ContractBinnacle/${id}`, binnacleToEdit);
  }

  notifyEntry(id) {
    return this.apiService.put(`/api/ContractBinnacle/Notify/${id}`);
  }

  deleteAllNotify(contractId) {
    return this.apiService.put(
      `/api/ContractBinnacle/DeleteAllNotify/${contractId}`,
    );
  }
}
