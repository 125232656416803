import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { EnvServiceProvider } from './services/env.service.provider';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthModule } from '@auth0/auth0-angular';
import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
import { ProcesCsvModule } from './modules/process-csv/process-csv.module';
import { environment } from '../environments/environment';

registerLocaleData(localeEsAr, 'es-AR');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // AuthConfigModule,
    AuthModule.forRoot({
      domain: environment.auth0_domain,
      clientId: environment.auth0_clientId,
      audience: environment.auth0_audience,
      useRefreshTokens: environment.useRefreshTokens,
    }),
    AppRoutingModule,
    NgbModule,
    ProcesCsvModule,
  ],
  providers: [
    EnvServiceProvider,
    NgbActiveModal,
    { provide: LOCALE_ID, useValue: 'es-AR' },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthHttpInterceptor,
    //   multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
