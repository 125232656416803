import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import {
  faPlus,
  faEye,
  faTrash,
  faCalendar,
} from '@fortawesome/free-solid-svg-icons';
import {
  ConfirmDialogComponent,
  ConfirmDialogSettings,
} from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CashService } from 'src/app/services/cash.service';
import { ReturnsService } from 'src/app/services/returns.service';
import { ToastService } from 'src/app/services/toast.service';
import { ReturnAdditionalForAdd } from 'src/app/core/models/ReturnAdditional';

@Component({
  selector: 'app-returns-form',
  templateUrl: './returns-form.component.html',
  styleUrls: ['./returns-form.component.css'],
})
export class ReturnsFormComponent implements OnInit {
  iconPlus = faPlus;
  iconSee = faEye;
  iconTrash = faTrash;
  iconCalendar = faCalendar;

  confirmDeleteBoxTitles: ConfirmDialogSettings;

  returnAdditionals: ReturnAdditionalForAdd[] = [];
  displayForm: boolean = false;
  submitted: boolean = false;

  @Input() edit?: boolean = false;
  @Input() editData?: any;
  @Input() createInMemory?: any;
  @Input() createInMemoryArray?: any;
  @Input() returnData?: any;

  @Output() cancelAction = new EventEmitter();
  @Output() closeAction = new EventEmitter();
  @Output() loadingScreen = new EventEmitter();

  @ViewChildren(ConfirmDialogComponent)
  confirmBox: QueryList<ConfirmDialogComponent>;
  @ViewChild('noCashForm') noCashForm: ElementRef;

  returnsFormGroup = new UntypedFormGroup({
    withdrawer: new UntypedFormControl('', Validators.required),
    isTransfer: new UntypedFormControl(false, Validators.required),
    description: new UntypedFormControl(''),
  });

  inFavor = false;
  returnDate;

  additionalFormGroup = new FormGroup({
    returnAdditionalSign: new FormControl<boolean>(false, Validators.required),
    returnAdditionalAmount: new FormControl<number>(null, [
      Validators.pattern('^[0-9]*$'),
      Validators.min(0),
      Validators.max(2147483647),
      Validators.required,
    ]),
    returnAdditionalDescription: new FormControl<string>(null, [
      Validators.required,
      Validators.minLength(1),
    ]),
  });

  constructor(
    private returnService: ReturnsService,
    public modalService: NgbModal,
    private toastService: ToastService,
  ) {
    this.confirmDeleteBoxTitles = {
      title: '',
      subtitle: ``,
    };
  }

  ngOnInit() {
    if (this.returnData.ownerAmountInFavor) {
      this.inFavor = true;
    }
  }

  submitReturn() {
    const amountString = (
      this.returnData.amount +
      (this.inFavor ? this.returnData.ownerAmountInFavor : 0)
    ).toLocaleString('es-AR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    let returnToSubmit = {
      detail: this.returnsFormGroup.value.description,
      contractId: this.returnData.contractId,
      isTransfer: this.returnsFormGroup.value.isTransfer,
      withdrawer: this.returnsFormGroup.value.withdrawer.id,
      returnAdditionals: this.returnAdditionals,
      inFavor: this.inFavor,
      installmentId:
        this.returnData.installmentId > 0 ? this.returnData.installmentId : 0,
      returnDate: this.returnDate
        ? new Date(
            this.returnDate.year,
            this.returnDate.month - 1,
            this.returnDate.day,
          )
        : null,
    };

    this.confirmDeleteBoxTitles = {
      title: 'Liquidaciones',
      subtitle: `Desea crear esta Liquidación por $${amountString}?`,
    };

    if (this.returnsFormGroup.invalid) {
      this.returnsFormGroup.markAllAsTouched();
      return;
    } else {
      if (!returnToSubmit.isTransfer) {
        // this.cashService.getAllCashRegisters().subscribe((response: any) => {
        this.confirmBox
          .toArray()[0]
          .openModal()
          .result.then(
            (result) => {
              this.loadingScreen.emit(true);
              if (result) {
                this.submitted = true;
                this.returnService.createReturn(returnToSubmit).subscribe(
                  (response) => {
                    this.toastService.showToast(
                      'success',
                      'Liquidación creada',
                    );
                    this.closeAction.emit(response);
                  },
                  (error) => {
                    this.loadingScreen.emit(false);
                    this.toastService.showToast('error', 'Hubo un problema');
                  },
                );
              } else {
                this.loadingScreen.emit(false);
              }
            },
            (reason) => {
              this.loadingScreen.emit(true);
            },
          );
      } else {
        this.confirmBox
          .toArray()[0]
          .openModal()
          .result.then(
            (result) => {
              this.loadingScreen.emit(true);
              if (result) {
                this.returnService
                  .createReturn(returnToSubmit)
                  .subscribe((response) => {
                    this.toastService.showToast(
                      'success',
                      'Liquidación creada',
                    );
                    this.closeAction.emit(response);
                  });
              } else {
                this.loadingScreen.emit(false);
              }
            },
            (reason) => {
              this.loadingScreen.emit(true);
              //
            },
          );
      }
    }
  }

  validate(field: string) {
    return (
      this.returnsFormGroup.controls[field].errors &&
      this.returnsFormGroup.controls[field].touched
    );
  }

  ok() {
    this.modalService.dismissAll();
  }

  cancel() {
    this.cancelAction.emit();
  }

  createAdditional() {
    if (this.additionalFormGroup.invalid) {
      this.additionalFormGroup.markAllAsTouched();
      return;
    } else {
      let additional: ReturnAdditionalForAdd = {
        sign: this.additionalFormGroup.value.returnAdditionalSign,
        amount: this.additionalFormGroup.value.returnAdditionalAmount,
        detail: this.additionalFormGroup.value.returnAdditionalDescription,
      };
      this.returnAdditionals.push(additional);

      this.additionalFormGroup.markAsUntouched();
      this.additionalFormGroup.patchValue({
        returnAdditionalAmount: null,
        returnAdditionalDescription: null,
      });
    }
  }

  deleteAdditional(index) {
    this.returnAdditionals.splice(index, 1);
  }
}
