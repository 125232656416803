import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Installment } from 'src/app/core/models/Installment';
import { InstallmentService } from 'src/app/services/installment.service';

@Component({
  selector: 'app-installment-form',
  templateUrl: './installment-form.component.html',
  styleUrls: ['./installment-form.component.css'],
})
export class InstallmentFormComponent implements OnInit {
  @Input() installmentToEdit?: Installment;

  @Output() closeAction = new EventEmitter();

  installmentFormGroup = new UntypedFormGroup({
    amount: new UntypedFormControl('', [
      Validators.required,
      Validators.min(0),
      Validators.max(2147483647),
    ]),
  });

  constructor(private installmentService: InstallmentService) {}

  ngOnInit() {
    console.log(this.installmentToEdit);
    this.installmentFormGroup.patchValue({
      amount: this.installmentToEdit.amount,
    });
  }

  onSubmit() {
    let newAmount: number;
    newAmount = this.installmentFormGroup.value.amount;
    this.installmentToEdit.amount = newAmount;
    if (this.installmentFormGroup.invalid) {
    } else {
      this.installmentService
        .editInstallment(this.installmentToEdit, this.installmentToEdit.id)
        .subscribe((response) => {
          this.closeAction.emit();
        });
    }
  }
}
