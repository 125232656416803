import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  faCalendar,
  faSearch,
  faPlus,
  faEye,
  faTrash,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import { PhoneNumber } from 'src/app/core/models/PhoneNumber';
import { ToastService } from 'src/app/services/toast.service';

const suggestions: string[] = ['Casa', 'Oficina', 'Empresa', 'Celular'];

@Component({
  selector: 'app-phonebook-container',
  templateUrl: './phonebook-container.component.html',
  styleUrls: ['./phonebook-container.component.css'],
})
export class PhonebookContainerComponent implements OnInit {
  edit: boolean = false;
  index: number;
  iconCalendar = faCalendar;
  iconSearch = faSearch;
  iconPlus = faPlus;
  iconSee = faEye;
  iconTrash = faTrash;
  iconEdit = faPen;

  @Input() phoneCards: PhoneNumber[];

  phoneBookDataFormGroup = new UntypedFormGroup({
    area: new UntypedFormControl('', Validators.required),
    number: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl(''),
  });

  constructor(private toastService: ToastService) {}

  ngOnInit() {
    /*     this.phoneCards.push(
      {
        area: "+911",
        number: "123123",
        description: "Juan"
      }
    ) */
  }

  addPhoneCard() {
    if (this.phoneBookDataFormGroup.valid) {
      let desc = this.phoneBookDataFormGroup.value.description;
      if (this.edit) {
        this.phoneCards[this.index] = {
          area: this.phoneBookDataFormGroup.value.area,
          number: this.phoneBookDataFormGroup.value.number,
          description: desc ? desc : 'Teléfono',
        };
        this.edit = false;
        this.toastService.showToast('success', 'Teléfono editado');
        this.phoneBookDataFormGroup.reset();
      } else {
        this.phoneCards.push({
          area: this.phoneBookDataFormGroup.value.area,
          number: this.phoneBookDataFormGroup.value.number,
          description: desc ? desc : 'Teléfono',
        });
        this.toastService.showToast('success', 'Teléfono agregado');
        this.phoneBookDataFormGroup.reset();
      }
    } else {
      this.phoneBookDataFormGroup.markAllAsTouched();
      this.toastService.showToast('warning', 'Hay datos sin completar');
    }
  }

  editPhoneContact(value, index) {
    this.edit = true;
    this.index = index;

    this.phoneBookDataFormGroup.patchValue({
      area: value.area,
      number: value.number,
      description: value.description,
    });
  }

  deletePhoneContact(index) {
    this.phoneCards.splice(index, 1);
    this.toastService.showToast('success', 'Teléfono eliminado');
  }
}
