import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { EnvService } from './env.service';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { IPaginatedServiceParamters } from '../core/models/ITable';
import { ProcessCSV } from '../core/models/ProcessCSV';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  token = null;
  headers = new HttpHeaders();
  url: string = '';
  csvUrl: string = '';

  constructor(private env: EnvService, private http: HttpClient) {
    this.headers = this.headers.set('Content-Type', 'application/json');
    this.headers = this.headers.set('Access-Control-Allow-Origin', '*');
    var token = localStorage.getItem('token');
    this.headers = this.headers.set('Authorization', 'Bearer ' + token);
    this.url = env.apiUrl;
    this.csvUrl = env.csvProcessorUrl;
  }

  get<T>(ctrllerUri: string, query?: string): Observable<T> {
    let response: Observable<T>;
    if (query) {
      response = this.http.get<T>(this.url + ctrllerUri + '?' + query, {
        headers: this.headers,
      });
    } else {
      response = this.http.get<T>(this.url + ctrllerUri, {
        headers: this.headers,
      });
    }
    response.pipe(
      catchError((err) => {
        return throwError(err);
      }),
    );
    return response;
  }

  put<T>(ctrllerUri: string, data?: any): Observable<T> {
    const body = JSON.stringify(data);
    const response = this.http.put<T>(this.url + ctrllerUri, body, {
      headers: this.headers,
    });
    response.pipe(
      catchError((err) => {
        return throwError(err);
      }),
    );
    return response;
  }

  patch<T>(ctrllerUri: string, data?: any): Observable<T> {
    const body = JSON.stringify(data);
    const response = this.http.patch<T>(this.url + ctrllerUri, body, {
      headers: this.headers,
    });
    response.pipe(
      catchError((err) => {
        return throwError(err);
      }),
    );
    return response;
  }

  delete<T>(ctrllerUri: string): Observable<T> {
    return this.http.delete<T>(this.url + ctrllerUri, {
      headers: this.headers,
    });
  }

  deleteMany<T>(ctrllerUri: string, data?: any): Observable<T> {
    const body = JSON.stringify(data);
    const response = this.http.delete<T>(this.url + ctrllerUri, {
      headers: this.headers,
      body: body,
    });
    response.pipe(
      catchError((err) => {
        return throwError(err);
      }),
    );
    return response;
  }

  post<T>(ctrllerUri: string, data?: any): Observable<T> {
    const body = JSON.stringify(data);
    const response = this.http.post<T>(this.url + ctrllerUri, body, {
      headers: this.headers,
    });
    response.pipe(
      catchError((err) => {
        return throwError(err);
      }),
    );
    return response;
  }

  getPaginated<T>(
    ctrllerUri: string,
    paginatedArguments?: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<T[]>> {
    let observable: Observable<any>;
    const paginatedResult: PaginatedResult<T[]> = new PaginatedResult<T[]>();
    let params = new HttpParams();

    var objectKeys = Object.keys(paginatedArguments);

    this.eraseFromQueryList(objectKeys, ['query', 'id']);

    objectKeys.forEach((key) => {
      if (paginatedArguments[key] != null) {
        params = params.append(key, paginatedArguments[key]);
      }
    });

    if (paginatedArguments.query) {
      observable = this.http.get<PaginatedResult<T[]>>(
        this.url + ctrllerUri + '?' + paginatedArguments.query,
        { headers: this.headers, params, observe: 'response' },
      );
    } else {
      observable = this.http.get<PaginatedResult<T[]>>(this.url + ctrllerUri, {
        headers: this.headers,
        params,
        observe: 'response',
      });
    }

    return observable.pipe(
      map((response) => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(
            response.headers.get('Pagination'),
          );
        }
        return paginatedResult;
      }),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  fileUpload(fd: FormData, contract?: ProcessCSV[]) {
    var header2 = new HttpHeaders();
    var token = localStorage.getItem('token');
    header2 = header2.set('Access-Control-Allow-Origin', '*');
    header2 = header2.set('Authorization', 'Bearer ' + token);
    return this.http.post(this.csvUrl + '/api/CreateContract', fd, {
      headers: header2,
    });
  }

  fileUploadContractState(fd: FormData) {
    var headers = new HttpHeaders();
    var token = localStorage.getItem('token');
    headers = headers.set('Access-Control-Allow-Origin', '*');
    headers = headers.set('Authorization', 'Bearer ' + token);

    return this.http.post(this.csvUrl + '/api/AccountState', fd, {
      headers: headers,
    });
  }

  formDataArrayUpload<T>(
    ctrllerUri: string,
    fd: FormData,
  ): Observable<HttpEvent<File[]>> {
    var headers = new HttpHeaders();
    var token = localStorage.getItem('token');
    headers = headers.set('Access-Control-Allow-Origin', '*');
    headers = headers.set('Authorization', 'Bearer ' + token);

    return this.http
      .post<File[]>(this.url + ctrllerUri, fd, {
        headers: headers,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        tap((event) => {
          if (event.type === HttpEventType.UploadProgress) {
            return event;
          } else if (event.type == HttpEventType.Response) {
            return event.body;
          }
        }),
        catchError((err) => {
          return throwError(err);
        }),
      );
  }

  saveContract(contracts: ProcessCSV[]) {
    var headers = new HttpHeaders();
    var token = localStorage.getItem('token');
    headers = headers.set('Access-Control-Allow-Origin', '*');
    headers = headers.set('Authorization', 'Bearer ' + token);
    return this.http.post(this.csvUrl + '/api/SaveCSV', contracts, {
      headers: headers,
    });
  }

  downloadCSV() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization', 'Bearer ' + token);

    // Realiza una solicitud GET con responseType 'blob' para recibir el archivo CSV
    return this.http.get(this.csvUrl + '/api/DownloadCSV', {
      headers: headers,
      responseType: 'blob', // Configura la respuesta como un Blob
    });
  }

  public getHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Access-Control-Allow-Origin', '*');
    return headers;
  }

  private appendAuthHeader(headers: HttpHeaders) {
    const token = '';

    if (token === '') {
      return headers;
    }
    const tokenValue = 'Bearer ' + token;

    return headers.set('Authorization', tokenValue);
  }

  private eraseFromQueryList(objectKeys, eraseParams: string[]) {
    eraseParams.forEach((param) => {
      const index = objectKeys.indexOf(param);

      if (index > -1) {
        objectKeys.splice(index, 1);
      }
    });
  }
}
