import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { AgencyForEdit } from 'src/app/core/models/AgencyForAdd';
import { AgencyService } from 'src/app/services/agency.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-agency-form',
  templateUrl: './agency-form.component.html',
  styleUrls: ['./agency-form.component.css'],
})
export class AgencyFormComponent implements OnInit {
  agencyForSubmit: AgencyForEdit;

  @Input() edit?: boolean = false;
  @Input() viewObject?: boolean = true;
  @Input() editData?: any;
  @Input() createInMemory?: any;
  @Input() createInMemoryArray?: any;
  @Input() editContractMode?: boolean = false;

  @Output() closeAction = new EventEmitter();
  @Output() cancelAction = new EventEmitter();

  //MOCKS
  agencyDataFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    streetAddress: new UntypedFormControl(''),
    city: new UntypedFormControl(''),
    state: new UntypedFormControl(''),
    zipCode: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    defaultEmail: new UntypedFormControl('', Validators.required),
  });

  constructor(
    private toastService: ToastService,
    private agencyService: AgencyService,
  ) {}

  ngOnInit() {
    if (this.edit) {
      this.agencyDataFormGroup.patchValue({
        name: this.editData.name,
        streetAddress: this.editData.address.streetAddress,
        city: this.editData.address.city,
        state: this.editData.address.state,
        zipCode: this.editData.address.zipCode,
        email: this.editData.email,
        defaultEmail: this.editData.defaultEmail,
      });
    } else {
      this.agencyDataFormGroup.reset();
    }
  }

  onSubmit() {
    this.agencyForSubmit = {
      id: this.editData?.id,
      
      name: this.agencyDataFormGroup.value.name
        ? this.agencyDataFormGroup.value.name
        : '',
      streetAddress: this.agencyDataFormGroup.value.streetAddress
        ? this.agencyDataFormGroup.value.streetAddress
        : null,
      city: this.agencyDataFormGroup.value.city
        ? this.agencyDataFormGroup.value.city
        : null,
      state: this.agencyDataFormGroup.value.state
        ? this.agencyDataFormGroup.value.state
        : null,
      zipCode: this.agencyDataFormGroup.value.zipCode
        ? this.agencyDataFormGroup.value.zipCode
        : null,
      email: this.agencyDataFormGroup.value.email
        ? this.agencyDataFormGroup.value.email
        : null,
      defaultEmail: this.agencyDataFormGroup.value.defaultEmail
        ? this.agencyDataFormGroup.value.defaultEmail
        : null,
    };

    if (this.agencyDataFormGroup.invalid) {
      this.agencyDataFormGroup.markAllAsTouched();
      this.toastService.showToast(
        'warning',
        'Hay campos obligatorios sin completar',
      );
      return;
    } else {
      if (this.edit) {
        this.agencyService.updateAgency(this.agencyForSubmit).subscribe((response) => {
          this.toastService.showToast('success', 'Agencia actualizada');
          this.closeAction.emit(true);
        },
        (error) =>{
          this.toastService.showToast('error', 'Ha ocurrido un error');
        });
      } else {
        this.closeAction.emit();
      }
    }
  }

  validate(field: string) {
    return (
      this.agencyDataFormGroup.controls[field].errors &&
      this.agencyDataFormGroup.controls[field].touched
    );
  }

  cancel(){
    this.cancelAction.emit();
  }
}
