<section>
  <div style="margin: 20px">
    <div style="  display: flex; align-items: center;">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
          (click)="setPeriod('1')" checked />
        <label class="form-check-label" for="flexRadioDefault3"> Anual </label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
          (click)="setPeriod('2')" />
        <label class="form-check-label" for="flexRadioDefault2">
          Mensual
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
          (click)="setPeriod('3')" />
        <label class="form-check-label" for="flexRadioDefault1">
          Semanal
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
          (click)="setPeriod('4')" />
        <label class="form-check-label" for="flexRadioDefault4"> Diario </label>
      </div>
      <div *ngIf="contractMode" class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
          (click)="setPeriod('5')" />
        <label class="form-check-label" for="flexRadioDefault4"> Todos </label>
      </div>
    </div>

    <div *ngIf="!contractMode" style="margin-left: auto;">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="flexRadioCurrency" id="flexRadioCurrencyPesos"
          (click)="setCurrency(1)" checked />
        <label class="form-check-label" for="flexRadioCurrencyPesos"> Pesos </label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="flexRadioCurrency" id="flexRadioCurrencyDolares"
          (click)="setCurrency(2)" />
        <label class="form-check-label" for="flexRadioCurrencyDolares"> Dolares </label>
      </div>
    </div>


  </div>

  <ng-container *ngIf="!emptyTable; else Norows">
    <table class="table">
      <thead>
        <tr>
          <!-- <th scope="col">#</th> -->
          <th scope="col">{{ "Movimientos" }}</th>
          <th scope="col">{{ "header.accountPartial" | translate }}</th>
          <th scope="col">{{ "header.description" | translate }}</th>
          <!-- <th scope="col">{{'header.referenceId' | translate}}</th> -->
          <th scope="col">{{ "Fecha movimientos" | translate }}</th>
          <th scope="col">{{ "header.movementType" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="fila-hover" *ngFor="let accountMovement of accountMovements; index as i" (click)="
            redirect(
              accountMovement.referenceId,
              movementTypes(accountMovement.movementType)
            )
          " [ngbTooltip]="'Ver más'" style="cursor: pointer">
          <!-- <td>{{ i + 1 }}</td> -->
          <td class="money" [ngClass]="{
              rojo: accountMovement.amount < 0,
              verde: accountMovement.amount > 0
            }">
            {{ accountMovement.amount | currency:
            (currencyVal === 2 ? 'USD' : 'ARS') : (currencyVal === 2 ?
            'symbol'
            :
            'symbol-narrow') : '1.0-0' }}
          </td>
          <td class="money" [ngClass]="{
              rojo: accountMovement.partialAccount < 0,
              verde: accountMovement.partialAccount > 0
            }">
            {{ accountMovement.partialAccount | currency:
            (currencyVal === 2 ? 'USD' : 'ARS') : (currencyVal === 2 ?
            'symbol'
            :
            'symbol-narrow') : '1.0-0' }}
          </td>
          <td>{{ accountMovement.description || "-" }}</td>
          <td>
            {{
            dateService.transformToDateStringFromString(accountMovement.date)
            }}
          </td>
          <td>{{ movementTypes(accountMovement.movementType) | translate }}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</section>
<!-- Paginator  -->
<div *ngIf="!emptyTable" class="paginator-component">
  <ngb-pagination (pageChange)="pageChanged($event)" [(page)]="page" [pageSize]="itemsPerPage"
    [collectionSize]="totalItems" [boundaryLinks]="true" [maxSize]="5">
    <ng-template ngbPaginationPrevious>Prev</ng-template>
    <ng-template ngbPaginationNext>Next</ng-template>
  </ngb-pagination>
</div>
<!-- /Paginator  -->
<ng-template #Norows>
  <div class="norows">
    <fa-icon style="margin-bottom: 5px" [icon]="iconQuestion" size="2x"></fa-icon>
    <span>{{ "gridComponent.tableEmpty" | translate }}</span>
  </div>
</ng-template>