
<div class="grid-container">

  <div class="header">
    <div class="user">
<!--         <img 
        src="../../../../assets/img/user-1.png" 
        alt="user image" 
        class="img rounded-circle"> -->
        <div class="name flex-column">
          <div class="flex-row avatar">
            <div class="numberCircle">{{ avatarInitial | uppercase }}</div>
            <div class="flex-column" style="margin-left: 5px; justify-content: center;">
              <span style="font-size: 2.5rem; font-weight: 500; color: white; margin-bottom: 10px;">{{this.name}}</span>
              <span style="font-size: 1.1rem; font-weight: 450; color: white;" *ngIf="type == 'guarantor'">Garante</span>
              <span style="font-size: 1.1rem; font-weight: 450; color: white;" *ngIf="type == 'owner'"  >Propietario</span>
              <span style="font-size: 1.1rem; font-weight: 450; color: white;" *ngIf="type == 'tenant'" >Inquilino</span>
            </div>
          </div>
        </div>
      </div>
  </div>

  <div class="main-info">
    <div class="flex-row" style="justify-content: left; width: 100%;">
      <div class="shadow-sm border-5" style="padding: 10px 15px; background-color: white;">
        <button class="button btn btn-primary" (click)= "edit()"><fa-icon style="margin-right: 5px;"[icon]="iconPen"></fa-icon>Editar</button>
      </div>
    </div>
    <div class="contact shadow-sm border-5">
      <span style="font-size: 1.2rem; font-weight: 500;">Contacto</span>
      <div class="line-div" style="margin-top: 6px; opacity: 30%;"></div>
      <div class="info">
        <div class="flex-row centered icon-styles">
        <fa-icon class="icon fa-lg" [icon]="iconEmail" style="color: #593b92;"></fa-icon> 
        </div>
        <div class="flex-column">
          <span style="font-size: 0.8rem; font-weight: 450;">E-mail:</span>
          <span style="font-size: 0.9rem; font-weight: 525;">
            {{this.email}}
          </span>
        </div>
      </div>
      <div class="info">
            <div class="flex-row centered icon-styles-2" 
                  style="width: 30px; height: 30px; margin: 10px;">
                <fa-icon class="icon fa-lg" [icon]="iconPhones" style="color: #12257a;"></fa-icon> 
            </div>
        <div class="flex-column">
          <span style="font-size: 0.8rem; font-weight: 450;">Telefonos:</span>
            <div class="flex-column">
              <span style="margin-bottom: 0.5px" *ngFor="let item of this.phones">
              <app-whatsapp-chat [phoneNumber] = "item"></app-whatsapp-chat><i> - {{item.description }}</i></span>
              <span style="font-size: 0.9rem; font-weight: 525;" *ngIf="this.phones.length == 0">No hay información de contacto</span>
            </div>
        </div>
      </div>
    </div>

    <div class="about shadow-sm border-5">
    <span style="font-size: 1.2rem; font-weight: 500;">Acerca de</span>
    <div class="line-div" style="margin-top: 6px; opacity: 30%;"></div>
    <div class="about-content">
      <div class="flex-column">
        <div class="info"  *ngIf="this.userData?.person.isPerson && this.age !== ''">
          <div class="flex-row centered icon-styles-3" 
                style="width: 30px; height: 30px; margin: 10px;">
              <fa-icon class="icon fa-lg" [icon]="iconBday" style="color: #6b0c6b;"></fa-icon> 
          </div>
          <div class="flex-column">
            <span style="font-size: 0.8rem; font-weight: 450;">Fecha de nacimiento:</span>
            <span style="font-size: 0.9rem; font-weight: 525;">
              {{this.age}}
            </span>
          </div>
        </div>
        <div class="info">
          <div class="flex-row centered icon-styles-4" 
                style="width: 30px; height: 30px; margin: 10px;">
              <fa-icon class="icon fa-lg" [icon]="iconDoc" style="color: #6b0c1c;"></fa-icon> 
          </div>
          <div class="flex-column">
            <span style="font-size: 0.8rem; font-weight: 450;">DNI:</span>
            <span style="font-size: 0.9rem; font-weight: 525;">
              {{this.nationalId}} 
            </span>
          </div>
        </div>
        
      </div>

      <div class="flex-column">
        <div class="info">
          <div class="flex-row centered icon-styles-5" 
                style="width: 30px; height: 30px; margin: 10px;">
              <fa-icon class="icon fa-lg" [icon]="iconAddress" style="color: #276b0c;"></fa-icon> 
          </div>
          <div class="flex-column">
            <span style="font-size: 0.8rem; font-weight: 450;">Dirección:</span>
            <span style="font-size: 0.9rem; font-weight: 525;">
              {{this.address}}
            </span>
          </div>
        </div>
      </div>

      <div class="flex-column" *ngIf="userData?.person.taxRole != null">
        <div class="info">
          <div class="flex-row centered icon-styles" 
                style="width: 30px; height: 30px; margin: 10px;">
              <fa-icon class="icon fa-lg" [icon]="iconAgent" style="color: #611e06;"></fa-icon> 
          </div>
          <div class="flex-column">
            <span style="font-size: 0.8rem; font-weight: 450;">Carácter frente a AFIP:</span>
            <span style="font-size: 0.9rem; font-weight: 525;">
              {{ userData?.person.roleName }}
            </span>
          </div>
        </div>
      </div>

      <div class="info" *ngIf="userData?.person.description != null">
        <div class="flex-row centered icon-styles-4" 
              style="width: 30px; height: 30px; margin: 10px;">
            <fa-icon class="icon fa-lg" [icon]="iconDoc" style="color: #6b0c1c;"></fa-icon> 
        </div>
        <div class="flex-column">
          <span style="font-size: 0.8rem; font-weight: 450;">Detalle</span>
          <span style="font-size: 0.9rem; font-weight: 525;">
            {{userData?.person.description}} 
          </span>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="type != 'guarantor'" class="contracts">
    <app-grid-lite [settings]= "contracts"></app-grid-lite>
  </div> 

  <div *ngIf="type != 'guarantor'" class="contracts">
    <app-grid-lite [settings]= "historicContracts"></app-grid-lite>
  </div>

</div>

  <div class="tables">
    <div *ngIf="type == 'owner'" class="balance shadow-sm border-5">
      <h1>{{'ownerBalanceAccount.title' | translate}}</h1>
      <div>
        <app-balance-account-grid [tableService]="service" [id]="id"></app-balance-account-grid>
      </div>
    </div>

    <div class="lite"> 
      <div *ngFor="let item of tableSettings">
        <app-grid-lite [settings]= "item"></app-grid-lite>
      </div>
    </div>
    
  </div>

  <div *ngIf="type == 'owner'" class="properties shadow-sm border-5">
    <span style="font-size: 1.2rem; font-weight: 500;">Propiedades</span>
    <div class="line-div" style="margin-top: 6px; opacity: 30%;"></div>
    <div>
      <div *ngFor="let property of properties" class="summary card shadow-sm border-5">
        <span style="font-size: 1.2rem; font-weight: 500;"><a (click)="propertyUrl(property.id)" class = "card-title hoverblue margin-right-10px">{{ property.address.streetAddress | uppercase}}</a></span>
        <div class="line-div" style="margin-top: 6px; opacity: 30%;"></div>
        <div class="info">
          <div class="flex-column">
            <span style="font-size: 0.8rem; font-weight: 450;">Dirección:</span>
            <span style="font-size: 0.9rem; font-weight: 525;">
              {{property.address.streetAddress}}
            </span>
          </div>
        </div>
        <div class="info">
          <div class="flex-column">
            <span style="font-size: 0.8rem; font-weight: 450;">Piso:</span>
            <span style="font-size: 0.9rem; font-weight: 525;">
              {{property.level}}
            </span>
          </div>
        </div>
        <div class="info">
          <div class="flex-column">
            <span style="font-size: 0.8rem; font-weight: 450;">Area:</span>
            <span style="font-size: 0.9rem; font-weight: 525;">
              {{property.area}}
            </span>
          </div>
        </div>
        <div class="info">
          <div class="flex-column">
            <span style="font-size: 0.8rem; font-weight: 450;">Codigo postal:</span>
            <span style="font-size: 0.9rem; font-weight: 525;">
              {{property.address.zipCode}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>