import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConsortiumHolderService {
  conId: number;
  private consortiumActiveSoubject$ = new ReplaySubject<any>(1);
  consortiumActiveChange$ = this.consortiumActiveSoubject$.asObservable();

  setConsortiumId(id: number) {
    this.consortiumActiveSoubject$.next(id);
  }
}

// Path : src\app\modules\main\shared\consortium\consoritum.service.ts

// Esto va en el ts del sidebar => idConsrotium = 1;

// url: './consortium/' + this.idConsrotium ,  => en el padre quedaria asi

// Asi quedaria el hijo --------------------------------------- title:
// 'Pagos',            url: './consortium/' + this.idConsrotium + '/payment' ,           icon:'',           active:false
