import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { faCalendar, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InstallmentRequirement } from 'src/app/core/models/InstallmentRequirement';
import { RetentionForAdd } from 'src/app/core/models/Retention';
import { DateService } from 'src/app/services/date.service';
import { LiabilitiesService } from 'src/app/services/liabilities.service';
import { RetentionService } from 'src/app/services/retention.service';
import { RetentionFormComponent } from '../forms/retention-form/retention-form.component';

@Component({
  selector: 'app-instreq-servpay-form-for-accumulator',
  templateUrl: './instreq-servpay-form-for-accumulator.component.html',
  styleUrls: ['./instreq-servpay-form-for-accumulator.component.css'],
})
export class InstreqServpayFormForAccumulatorComponent implements OnInit {
  dollarSign = faDollarSign;
  iconCalendar = faCalendar;
  selectedInstallmentsIds: number[] = [];
  installmentRequirements = [];
  selectedOption: any;
  installmentIdList: any[] = [];
  showServiceForm: boolean = false;

  @Input() installments?: any[];
  @Input() edit?: boolean;
  @Input() editData?: RetentionForAdd;
  @Input() contractRequirementId?: number;
  @Input() reqName?: string;

  @Output() closeAction = new EventEmitter();
  @Output() editRetentionEvent = new EventEmitter();

  installmentListForEdit: number[] = [];

  retentionFormGroup = new UntypedFormGroup({
    // amount: new FormControl('', Validators.required),
    // detail: new FormControl('', Validators.required),
    amount: new UntypedFormControl('', [Validators.min(0), Validators.max(2147483647)]),
    detail: new UntypedFormControl(''),
  });

  constructor(
    public modalService: NgbModal,
    private liabilityService: LiabilitiesService,
    private dateService: DateService,
  ) {}

  ngOnInit() {
    if (this.editData) {
      this.installments = [];
      this.showServiceForm = true;
      this.installmentRequirements = this.editData.installmentRequirements;
      this.retentionFormGroup.patchValue({
        amount: this.editData.amount,
        detail: this.editData.detail,
      });
      this.editData.installmentRequirements.forEach((instReq) => {
        let instreq = instReq as unknown as InstallmentRequirement;
        this.installments.push(instreq.installment);
      });
    } else {
      this.installments.forEach((installment) => {
        installment.selected = false;
      });
    }
  }

  selectedInstallment(installments) {
    this.installmentRequirements = [];
    installments.forEach((installment) => {
      let instReq = {
        contractId: installment.contractId,
        contractRequirementId: this.contractRequirementId,
        installmentId: installment.id,
        amount: 0,
        installmentStatusId: 1,
        installment: installment,
        details: '',
        date: '',
      };
      this.installmentRequirements.push(instReq);
      this.selectedInstallmentsIds.push(installment.id);
    });
  }

  changePaid(value, installmentReq) {
    value
      ? (installmentReq.installmentStatusId = 3)
      : (installmentReq.installmentStatusId = 1);
  }

  addInstallment(installment) {
    if (!installment.selected) {
      installment.selected = true;
      let instReq = {
        contractId: installment.contractId,
        contractRequirementId: this.contractRequirementId,
        installmentId: installment.id,
        amount: 0,
        installmentStatusId: 1,
        installment: installment,
        details: '',
        date: '',
        dateTemp: '',
      };
      this.installmentRequirements.push(instReq);
      this.selectedInstallmentsIds.push(installment.id);
    } else {
      installment.selected = false;
      let index = null;
      this.installmentRequirements.forEach((instReq, ind) => {
        instReq.installment.id == installment.id ? (index = ind) : false;
      });
      this.installmentRequirements.splice(index, 1);
      let ind = this.selectedInstallmentsIds.indexOf(installment.id);
      this.selectedInstallmentsIds.splice(ind, 1);
    }
  }

  pay() {
    let installmentReq = {
      installmentRequirementForAddDTOs: this.installmentRequirements,
    };
    this.liabilityService
      .createInstallmentRequirementArray(installmentReq)
      .subscribe((response) => {
        this.closeAction.emit(this.selectedInstallmentsIds);
      });
  }

  changeFilterData(data) {
    data.paymentDate = this.dateService.transformToDateObject(
      data.dateTemp.day,
      data.dateTemp.month,
      data.dateTemp.year,
    );
  }

  openretentionForm() {
    this.showServiceForm
      ? (this.showServiceForm = false)
      : (this.showServiceForm = true);
  }

  payRetention() {
    let retentionToSubmit: RetentionForAdd;

    this.installmentRequirements.forEach((instReq) => {
      instReq.installmentStatusId = 2;
    });

    retentionToSubmit = {
      amount: this.retentionFormGroup.value.amount
        ? this.retentionFormGroup.value.amount
        : null,
      detail: this.retentionFormGroup.value.detail
        ? this.retentionFormGroup.value.detail
        : null,
      approvedState: false,
      installmentRequirements: this.installmentRequirements,
      paymentId: 0,
    };

    if (this.retentionFormGroup.invalid) {
    } else {
      if (this.editData) {
        this.editRetentionEvent.emit(retentionToSubmit);
      }
    }
  }

  close() {
    this.closeAction.emit();
  }
}
