<div class="moratorium-body border shadow-sm">
    <div class="moratorium-line"></div>
    <div class="mora-body" style="grid-area: body;">
        <span class="font-120 opacity-8" style="font-weight: 450; margin: 0.5vh 0;">PUNITIVOS</span>
        <div class="checkbox-container">
            <label class="checkbox-label">
                <input type="checkbox" (click)="toggleElement($event)" #checkbox [(checked)]="toggleMoratorium">
                <span class="checkbox-custom rectangular"></span>
            </label>
        </div>
    </div>
</div>

<app-confirm-dialog style="display: none;" [modalTitles]="confirmBoxTitles"></app-confirm-dialog>
