import { Injectable } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { ICONLIST, iconMap } from '../core/models/Icons';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  constructor() {}

  listIcons() {
    return ICONLIST;
  }

  getIcon(iconName): IconDefinition {
    return iconMap[iconName];
  }
}
