<div class="title">
  <h1 *ngIf="!edit">Añadir Garante</h1>
  <h1 *ngIf="edit">Editar Garante</h1>
</div>
<form [formGroup]="guarantorPersonFormGroup" (ngSubmit)="submitGuarantor()">
  <div class="form-building form-group">
    <div class="container">
      <div class="row">
        <hr>
        <h3>Tipo de Garante</h3>
      </div>
      <div class="row">
        <div class="col-sm">
          <input type="radio" (click)="selectGuarantorType(guarantorType.Person)" name="schemeType"
            [checked]="guarantorTypeToggle == guarantorType.Person" [value]="1">
          <span class="font-120" style="margin-right: 1vw;"> Persona</span>
        </div>
        <div class="col-sm">
          <input type="radio" (click)="selectGuarantorType(guarantorType.Bussiness)" name="schemeType"
            [checked]="guarantorTypeToggle == guarantorType.Bussiness" [value]="2">
          <span class="font-120" style="margin-right: 1vw;"> Institucion</span>
        </div>
      </div>
      <div class="row">
        <hr>
        <h3 *ngIf="guarantorTypeToggle == guarantorType.Person">Datos Personales</h3>
        <h3 *ngIf="guarantorTypeToggle == guarantorType.Bussiness">Datos Empresa</h3>
      </div>
      <div class="row" *ngIf="guarantorTypeToggle == guarantorType.Bussiness">
        <div class="col-sm">
          <label>Razon Social</label>
          <input placeholder="EmpresaSA" type="text" formControlName="socialReason" class="form-control"
            [ngClass]="{'borderRed': validate('socialReason')}">
          <span *ngIf="validate('socialReason')" style="color: var(--border-red); font-size: 90%;">
            Dato Faltante
          </span>
        </div>
        <div class="col-sm">
          <label>CUIT</label>
          <input placeholder="27-12345678-8" type="text" formControlName="documentId" class="form-control"
            [ngClass]="{'borderRed': validate('documentId')}">
          <span *ngIf="validate('documentId')" style="color: var(--border-red); font-size: 90%;">
            Dato Inválido
          </span>
        </div>
      </div>
      <div class="row" *ngIf="guarantorTypeToggle == guarantorType.Person">
        <div class="col-sm">
          <label>Nombre</label>
          <input placeholder="Juan" type="text" formControlName="firstName" class="form-control"
            [ngClass]="{'borderRed': validate('firstName')}">
          <span *ngIf="validate('firstName')" style="color: var(--border-red); font-size: 90%;">
            Dato Faltante
          </span>
        </div>
        <div class="col-sm">
          <label>Apellido</label>
          <input placeholder="Pérez" type="text" formControlName="lastName" class="form-control"
            [ngClass]="{'borderRed': validate('lastName')}">
          <span *ngIf="validate('lastName')" style="color: var(--border-red); font-size: 90%;">
            Dato Faltante
          </span>
        </div>
      </div>
      <div class="row" *ngIf="guarantorTypeToggle == guarantorType.Person">
        <div class="col-sm">
          <label>Fecha Nacimiento</label>
          <div class="input-group" style="margin-bottom: 1vh;">
            <input class="form-control" placeholder="yyyy-mm-dd" formControlName="dateOfBirth" name="dp" ngbDatepicker
              #d="ngbDatepicker" [minDate]="{year: 1000, month: 1, day: 1}">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                <fa-icon [icon]="iconCalendar" size="1x"> </fa-icon>
              </button>
            </div>
          </div>
          <span class="flex-column text-danger"
            *ngIf="guarantorPersonFormGroup.get('dateOfBirth').hasError('ngbDate')">Error de formato</span>
        </div>
        <div class="col-sm">
          <div style="margin-right: 1vw;">
            <label>Tipo</label>
            <div class="flex-row" style="margin-bottom: 1vh;">
              <select class="form-control form-select" id="documentType" formControlName="documentType">
                <option *ngFor="let documentType of documentTypes" [value]="documentType.value">{{documentType.text}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div>
            <label>Numero Documento</label>
            <input placeholder="12345678" type="text" formControlName="documentId" class="form-control"
            [ngClass]="{'borderRed': validate('documentId')}">
          <span *ngIf="validate('documentId')" style="color: var(--border-red); font-size: 90%;">
            Dato Inválido
          </span>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <h3> Vivienda</h3>
      </div>
      <div class="row">
        <div class="col-sm">
          <label>Calle y altura</label>
          <input placeholder="Avenida Santa Fe 1234" type="text" formControlName="streetAddress" class="form-control">
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <label>Provincia</label>
          <input placeholder="CABA" type="text" formControlName="state" class="form-control">
        </div>
        <div class="col-sm">
          <label>Ciudad</label>
          <input placeholder="CABA" type="text" formControlName="city" class="form-control">
        </div>
        <div class="col-sm">
          <label>Codigo Postal</label>
          <input placeholder="1234" type="text" formControlName="zipCode" class="form-control">
        </div>
      </div>
      <hr>
      <div class="row">
        <h3> Información de Contacto</h3>
      </div>
      <div class="row">
        <div class="col-sm">
          <label>Email</label>
          <input placeholder="juanperez@gmail.com" type="email" formControlName="email" class="form-control">
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <label>Telefono</label>
          <app-phonebook-container [phoneCards]="phoneContacts"></app-phonebook-container>
        </div>
      </div>
      <hr>
      <div class="row">
        <h3> Detalle </h3>
      </div>
      <div class="row">
        <textarea class="desc form-control" cols="98" rows="5" type="text" formControlName="description"></textarea>
      </div>
    </div>
  </div>
  <div class="buttons-submitting" style="display: flex; justify-content: space-between;">
    <button type="button" class="btn btn-danger" [disabled]="submitted" (click)="cancel()">Cancelar</button>
    <button class="btn btn-primary" [disabled]="submitted" *ngIf="!edit">Crear</button>
    <button class="btn btn-primary" *ngIf="edit">Editar</button>
  </div>
</form>