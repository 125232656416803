<form [formGroup]="contractAmountFormGroup">
  <app-confirm-dialog style="display: none;" id="confirmDeleteBox" [modalTitles]="confirmDeleteBoxTitles">
  </app-confirm-dialog>
  <div class="flex-column contract-amounts" style="height: fit-content;">
    <div class="select">
      <div style="grid-area: title;">
        <h2>Tipo de Esquema </h2>
        <span>Seleccione el modelo de contrato</span>
      </div>
      <div class="flex-row scheme-selector">
        <div class="input-group mb-3" style="width: 250px;">
          <span class="input-group-text">Modelo</span>
          <select class="form-control form-select" (change)="setElements()" formControlName="schemeType">
            <option [value]="schemeTypeEnum.Comercial">Comercial</option>
            <optgroup label="Vivienda">
              <option [value]="schemeTypeEnum.Vivienda2019">Vivienda 2019</option>
              <option [value]="schemeTypeEnum.Vivienda2023">Vivienda 2023</option>
              <option [value]="schemeTypeEnum.Vivienda2024">Vivienda 2024</option>
            </optgroup>
          </select>
        </div>
      </div>
      <div class="flex-row" style="margin-top: 2vh;">
        <div class="input-group mb-3">
          <span class="input-group-text">Moneda</span>
          <select class="form-control form-select" style="width: 100px;" formControlName="contractCurrency"
            (change)="setElements()" [ngClass]="{'borderRed': validate('contractCurrency')}">
            <option disabled>...</option>
            <option value="1">Pesos</option>
            <option value="2">Dolares</option>
          </select>
        </div>
      </div>
      <div *ngIf="isComercialOrVivienda2024()" class="flex-row" style="margin-top: 2vh;">
        <div class="input-group mb-3" style="width: 250px;" (change)="setElements()">
          <span class="input-group-text">Aumento</span>
          <input list="increaseOptions" class="form-control form-select" formControlName="increaseType">
          <datalist id="increaseOptions">
            <option value="ICL">
            <option value="IPC">
          </datalist>
        </div>
      </div>
      <hr>
    </div>

    <div class="admin with-margin flex-column">
      <h2>Detalles</h2>
      <label>Deposito de Garantía</label>
      <div class="flex-row" style="margin-bottom: 1vh; margin-right: 1vw;">
        <select class="form-control form-select mb-3" style="width: 140px;"
          formControlName="currencyTypeGuaranteeDeposit" (change)="setElements()"
          [ngClass]="{'borderRed': validate('currencyTypeGuaranteeDeposit')}">
          <option disabled>...</option>
          <option value="1">Pesos</option>
          <option value="2">Dolares</option>
        </select>
        <div class="input-group mb-3" style="margin-left: 1vw; width: 140px;">
          <span class="input-group-text">$</span>
          <input class="form-control" type="text" (change)="setElements()"
            formControlName="guaranteeDepositCurrencyValue"
            [ngClass]="{'borderRed': validate('guaranteeDepositCurrencyValue')}">
        </div>
      </div>
      <label>Montos Administrativos / Honorarios </label>
      <div class="flex-row" style="margin-bottom: 1vh;">
        <select class="form-control form-select mb-3" style="width: 140px;" formControlName="adminAmountCurrencyType"
          (change)="setElements()" [ngClass]="{'borderRed': validate('adminAmountCurrencyType')}">
          <option disabled>...</option>
          <option value="0">Dinámico</option>
          <option value="1">Fijo</option>
        </select>
        <div class="input-group mb-3" style="margin-left: 1vw; width: 140px;"
          *ngIf="contractAmountFormGroup.value.adminAmountCurrencyType == 1">
          <span class="input-group-text">$</span>
          <input class="form-control" type="text" formControlName="adminAmountCurrencyValue" (change)="setElements()"
            [ngClass]="{'borderRed': validate('adminAmountCurrencyValue')}">
        </div>
        <div class="input-group mb-3" style="margin-left: 1vw; width: 140px;"
          *ngIf="contractAmountFormGroup.value.adminAmountCurrencyType == 0">
          <span class="input-group-text">%</span>
          <input class="form-control" type="text" formControlName="adminAmountPercentageValue" (change)="setElements()"
            [ngClass]="{'borderRed': validate('adminAmountPercentageValue')}">
        </div>
      </div>
      <div class="flex-row">
        <div style="width: 140px;">
          <label>Dia de Vencimiento</label>
          <input type="number" class="form-control" style="width: 140px;" formControlName="paymentExpiryDay"
            (change)="setElements()" [ngClass]="{'borderRed': validate('paymentExpiryDay')}">
        </div>
        <div class="punitives" style="margin-left: 1vw; width: 140px;">
          <label>Punitivos </label>
          <div class="input-group">
            <span class="input-group-text">%</span>
            <input class="form-control" type="number" (change)="setElements()" formControlName="punitives"
              [ngClass]="{'borderRed': validate('punitives')}">
          </div>
        </div>
      </div>
    </div>

    <div class="flex-column" style="grid-area: period; margin-top: 1px;"
      [ngClass]="{'border border-danger': invalidSchema}">
      <div class="period with-margin"
        *ngIf="contractAmountFormGroup.value.schemeType == schemeTypeEnum.Vivienda2019 || contractAmountFormGroup.value.schemeType == schemeTypeEnum.Vivienda2023">
        <div class="flex-column">
          <span style="font-size: 140%; margin-bottom: 1vh; font-weight: bold;">Montos por Periodo</span>
          <div class="flex-row">
            <div class="input-group">
              <span class="input-group-text">Valor Inicial</span>
              <input type="number" formControlName="schemaAmount" class="form-control" (change)="setElements()"
                [ngClass]="{'borderRed': validate('schemaAmount')}" appLimitDirective>
            </div>
            <div class="input-group" style="margin-left: 3vw;">
              <span class="input-group-text">Meses por período </span>
              <select class="form-control form-select" style="text-align-last: right;"
                [value]="contractInput.installmentGenerationTypeId" disabled>
                <option disabled>...</option>
                <option *ngIf="contractAmountFormGroup.value.schemeType == schemeTypeEnum.Vivienda2019" value="1"
                  selected>12
                </option>
                <option *ngIf="contractAmountFormGroup.value.schemeType == schemeTypeEnum.Vivienda2023" value="1"
                  selected>6</option>
                <option value="3">4</option>
                <option value="4">3</option>
                <option value="6">2</option>
                <option value="12">1</option>
              </select>
            </div>
          </div>
          <div class="flex-row" style="margin-top: 1vh; margin-right: 3vw;" *ngIf="amountOfNewPeriods > 0">
            <div class="input-group" style="width: 50%;">
              <span class="input-group-text">Valor del segundo período</span>
              <input type="number" formControlName="secondSchemaAmount" class="form-control" (change)="setElements()"
                [ngClass]="{'borderRed': validate('secondSchemaAmount')}">
            </div>
          </div>
          <div class="flex-row" style="margin-top: 1vh; margin-right: 3vw;" *ngIf="amountOfNewPeriods > 1">
            <div class="input-group" style="width: 50%;">
              <span class="input-group-text">Valor del tercer período</span>
              <input type="number" formControlName="thirdSchemaAmount" class="form-control" (change)="setElements()"
                [ngClass]="{'borderRed': validate('thirdSchemaAmount')}">
            </div>
          </div>
          <div class="flex-row" style="margin-top: 1vh; margin-right: 3vw;" *ngIf="amountOfNewPeriods > 2">
            <div class="input-group" style="width: 50%;">
              <span class="input-group-text">Valor del cuarto período</span>
              <input type="number" formControlName="fourthSchemaAmount" class="form-control" (change)="setElements()"
                [ngClass]="{'borderRed': validate('fourthSchemaAmount')}">
            </div>
          </div>
          <div class="flex-row" style="margin-top: 1vh; margin-right: 3vw;" *ngIf="amountOfNewPeriods > 3">
            <div class="input-group" style="width: 50%;">
              <span class="input-group-text">Valor del quinto período</span>
              <input type="number" formControlName="fifthSchemaAmount" class="form-control" (change)="setElements()"
                [ngClass]="{'borderRed': validate('fifthSchemaAmount')}">
            </div>
          </div>
          <div class="flex-row" style="margin-top: 1vh; margin-right: 3vw;" *ngIf="amountOfNewPeriods > 4">
            <div class="input-group" style="width: 50%;">
              <span class="input-group-text">Valor del sexto período</span>
              <input type="number" formControlName="sixthSchemaAmount" class="form-control" (change)="setElements()"
                [ngClass]="{'borderRed': validate('sixthSchemaAmount')}">
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="contractAmountFormGroup.value.schemeType == schemeTypeEnum.Comercial || contractAmountFormGroup.value.schemeType == schemeTypeEnum.Vivienda2024"
        class="period with-margin">
        <div
          style="grid-area: title; display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-bottom: 2vh;">
          <span style="font-size: 140%; margin-bottom: 1vh; font-weight: bold;">Montos por Periodo</span>
          <button style="height: inherit;" class="btn btn-primary"
            (click)="addNewContractAmount(fixedContractForm)">Añadir Monto Fijo
          </button>
        </div>

        <table class="table" style="grid-area: table;">
          <thead>
            <tr>
              <th scope="col">Desde</th>
              <th scope="col">Hasta</th>
              <th scope="col">Monto </th>
              <th scope="col">Cantidad de Cuotas</th>
              <th scope="col">N° de Cuota</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let installments of contractInput.installmentSchemas; let ind = index">
              <td>{{dateService.transformToDateStringFromString(installments.fromDate, "/")}}</td>
              <td>{{dateService.transformToDateStringFromString(installments.toDate, "/")}}</td>
              <td>{{installments.amount}}</td>
              <td>{{installments.quantity}}</td>
              <td>{{installments.order}}</td>
              <td>
                <button class="btn btn-primary" (click)="editElement(fixedContractForm, installments, ind)"
                  style="margin-right: 1vw;">
                  <fa-icon [icon]="iconEdit" size="1x"></fa-icon>
                </button>
                <button class="btn btn-danger" (click)="deleteFrom(ind)">
                  <fa-icon [icon]="iconTrash" size="1x"></fa-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</form>
<br>
<br>


<ng-template #fixedContractForm>
  <app-fixed-contract-form [contractInput]="contractInput" [edit]="edit" [editData]="editData"
    (closeOrder)="closeModals()"></app-fixed-contract-form>
</ng-template>