<div *ngIf="noReq == false && contractId != null" class="req-card-line border-5">
  <div class="req-card" *ngFor="let instReqName of reqAccKeys" [ngbTooltip]="instReqName"
          (click)="open(instReqName)"
          placement="top"
          autoClose="outside"
          [ngClass]="{'def-cursor': !interarctive}"> 
    <div class="position-relative">
      <span class="position-absolute top-0 start-10 badge rounded-pill text-bg-danger" style="color: white; z-index: 100;">
        {{reqAccumulator[instReqName].length}}
      </span>
      <ng-template #servicePayForm>
        <div style="margin: 1vh 1vw 1vh 1vw;">
          <app-instreq-servpay-form-for-accumulator [installments]="reqAccumulator[selectedRequirement].installments" 
                                                    [contractRequirementId]="reqAccumulator[selectedRequirement][0]?.instReqState.contractRequirementId" 
                                                    (closeAction)="refresh($event)"
                                                    [reqName] = "selectedRequirement">
          </app-instreq-servpay-form-for-accumulator>
        </div>
      </ng-template>
      <fa-icon 
        [icon]="iconService.getIcon(reqAccumulator[instReqName][0]?.instReqState.requirementIcon)" 
        size="2x"
        style="opacity: 0.7;">
      </fa-icon>
    </div>
    <span class="badge text-bg-primary req-style-text">
        {{instReqName}}
    </span>
  </div>
</div>


<div *ngIf="noReq == true || contractId == null" class="border-5 table-empty">
  <fa-icon style="margin-bottom: 5px;"[icon]="iconQuestion" size="4x"></fa-icon>
  <span style="margin-bottom: 5px; text-align: center;">No hay servicios vencidos</span>
</div>