import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { PipesModule } from '../core/pipes/pipes.module';
import { CardsComponent } from './cards/cards.component';
import { MessageCardComponent } from './message-card/message-card.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ModalConfirmContainerComponent } from './confirm-dialog/modal-confirm-container/modal-confirm-container.component';
import {
  NgbPaginationModule,
  NgbAlertModule,
  NgbTooltipModule,
  NgbCollapseModule,
  NgbPopoverModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { GridComponent } from './grid/grid.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SwitchComponent } from './switch/switch.component';
import { DetailMessagesCardComponent } from './detail-messages-card/detail-messages-card.component';
import { ModalContainerComponent } from './modal-container/modal-container.component';
import { ListPickerComponent } from './list-picker/list-picker.component';
import { MonthPickerComponent } from './month-picker/month-picker.component';
import { PhonebookContainerComponent } from './phonebook-container/phonebook-container.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WhatsappChatComponent } from './whatsapp-chat/whatsapp-chat.component';
import { PersonViewerComponent } from './person-viewer/person-viewer.component';
import { LoadingScreenComponent } from './LoadingScreen/LoadingScreen.component';
import { RouterModule } from '@angular/router';
import { IconSelectorComponent } from './icon-selector/icon-selector.component';
import { SearchComponent } from './search/search.component';
import { DndModule } from 'ngx-drag-drop';
import { LogBookComponent } from './log-book/log-book.component';
import { AdditionalChargesComponent } from './additional-charges/additional-charges.component';
import { AdditionalChargesCardComponent } from './additional-charges/additional-charges-card/additional-charges-card.component';
import { GridLiteComponent } from './grid-lite/grid-lite.component';
import { BalanceAccountGridComponent } from './balance-account-grid/balance-account-grid.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { RentsPieChartComponent } from './rents-pie-chart/rents-pie-chart.component';
import { ServiceHeatmapComponent } from './service-heatmap/service-heatmap.component';
import { FilterV2Component } from './filter-v2/filter-v2.component';
import { FilterV2InputsComponent } from './filter-v2/filter-v2-inputs/filter-v2-inputs.component';
import { ToastComponent } from './toast/toast.component';
import { TwoDigitDecimaNumberDirective } from './directives/limitDirective';
import { ToggleGridComponent } from './grid/grid-tooggle/grid-toggle.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { AfipReceiptComponent } from './afip-receipt/afip-receipt.component';
import { AuthButtonComponent } from './auth-button/auth-button.component';
import { BuildingFormComponent } from './forms/building-form/building-form.component';
import { ContractCardV2Component } from './contract-minor-components/contract-card-v2/contract-card-v2.component';
import { ContractCardComponent } from './contract-minor-components/contract-card/contract-card.component';
import { ContractCreationInfoComponent } from './contract-minor-components/contract-creation-info/contract-creation-info.component';
import { ContractInstallmentSetupComponent } from './contract-minor-components/contract-installment-setup/contract-installment-setup.component';
import { ContractRequirementsHandlerComponent } from './contract-minor-components/contract-requirements-handler/contract-requirements-handler.component';
import { ContractTemplatesHandlerComponent } from './contract-minor-components/contract-templates-handler/contract-templates-handler.component';
import { EntitiesForContractSearchComponent } from './contract-minor-components/entities-for-contract-search/entities-for-contract-search.component';
import { EntityLookupComponent } from './contract-minor-components/entity-lookup/entity-lookup.component';
import { FixedContractFormComponent } from './contract-minor-components/fixed-contract-form/fixed-contract-form.component';
import { CuitFormComponent } from './forms/cuit-form/cuit-form.component';
import { ExtraordinaryExpensesComponent } from './extraordinary-expense/extraordinary-expenses.component';
import { FeeFormComponent } from './forms/fee-form/fee-form.component';
import { GuarantorFormComponent } from './forms/guarantor-form/guarantor-form.component';
import { InstallmentCardsComponent } from './installment-cards/installment-cards.component';
import { InstallmentFormComponent } from './installment-form/installment-form.component';
import { InstallmentGridComponent } from './installment-grid/installment-grid.component';
import { InstallmentRequirementFormComponent } from './installment-requirement-form/installment-requirement-form.component';
import { InstreqServpayFormForAccumulatorComponent } from './instreq-servpay-form-for-accumulator/instreq-servpay-form-for-accumulator.component';
import { MailFormComponent } from './forms/mail-form/mail-form.component';
import { MoratoriumComponent } from './moratorium/moratorium.component';
import { OwnerFormComponent } from './forms/owner-form/owner-form.component';
import { ReceiptFormComponent } from './forms/receipt-form/receipt-form.component';
import { RequirementFormComponent } from './forms/requirement-form/requirement-form.component';
import { RequirementListAccumulatorComponent } from './requirement-list-accumulator/requirement-list-accumulator.component';
import { RequirementCardComponent } from './requirement-list/requirement-card/requirement-card.component';
import { RequirementListComponent } from './requirement-list/requirement-list.component';
import { RetentionFormComponent } from './forms/retention-form/retention-form.component';
import { ReturnsFormComponent } from './forms/returns-form/returns-form.component';
import { SafePipe } from './safe/safe.pipe';
import { SimpleContractListComponent } from './simple-contract-list/simple-contract-list.component';
import { TenantFormComponent } from './forms/tenant-form/tenant-form.component';
import { UserDetailViewerComponent } from './user-detail-viewer/user-detail-viewer.component';
import { UserFormComponent } from './forms/user-form/user-form.component';
import { AgencyFormComponent } from './forms/agency-form/agency-form.component';
import { IncreaseEditFormComponent } from './forms/increase-edit-form/increase-edit-form.component';
import { FacturaFormComponent } from './forms/factura-form/factura-form.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { LiabilitySummaryComponent } from './liability-summary/liability-summary/liability-summary.component';
import { PaymentStepperComponent } from './payment-stepper/payment-stepper.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CustomStepperComponent } from './custom-stepper/custom-stepper.component';
import { PaymentToPDFComponent } from './payment-to-PDF/payment-to-PDF.component';
import { ReturnToPDFComponent } from './return-to-PDF/return-to-PDF.component';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    RoundProgressModule,
    NgbPaginationModule,
    NgbAlertModule,
    NgbTooltipModule,
    FontAwesomeModule,
    NgbCollapseModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgbPopoverModule,
    DndModule,
    NgApexchartsModule,
    NgbTooltipModule,
    NgxFileDropModule,
    CdkStepperModule,
  ],
  declarations: [
    MessageCardComponent,
    CardsComponent,
    ConfirmDialogComponent,
    ModalConfirmContainerComponent,
    ModalContainerComponent,
    GridComponent,
    SwitchComponent,
    DetailMessagesCardComponent,
    ListPickerComponent,
    MonthPickerComponent,
    PhonebookContainerComponent,
    WhatsappChatComponent,
    PersonViewerComponent,
    LoadingScreenComponent,
    IconSelectorComponent,
    LogBookComponent,
    SearchComponent,
    AdditionalChargesComponent,
    AdditionalChargesCardComponent,
    GridLiteComponent,
    BalanceAccountGridComponent,
    RentsPieChartComponent,
    ServiceHeatmapComponent,
    FilterV2Component,
    FilterV2InputsComponent,
    ToastComponent,
    TwoDigitDecimaNumberDirective,
    ToggleGridComponent,
    SidebarComponent,
    HeaderComponent,
    BuildingFormComponent,
    GuarantorFormComponent,
    OwnerFormComponent,
    TenantFormComponent,
    AgencyFormComponent,
    RequirementFormComponent,
    UserDetailViewerComponent,
    SimpleContractListComponent,
    ReturnsFormComponent,
    RequirementListComponent,
    RequirementCardComponent,
    MoratoriumComponent,
    CuitFormComponent,
    InstallmentCardsComponent,
    ExtraordinaryExpensesComponent,
    RetentionFormComponent,
    RequirementListAccumulatorComponent,
    InstallmentRequirementFormComponent,
    InstallmentGridComponent,
    InstreqServpayFormForAccumulatorComponent,
    SafePipe,
    AuthButtonComponent,
    UserFormComponent,
    AfipReceiptComponent,
    ReceiptFormComponent,
    EntityLookupComponent,
    EntitiesForContractSearchComponent,
    ContractTemplatesHandlerComponent,
    ContractCardV2Component,
    ContractCardComponent,
    ContractCreationInfoComponent,
    ContractInstallmentSetupComponent,
    FixedContractFormComponent,
    ContractRequirementsHandlerComponent,
    MailFormComponent,
    InstallmentFormComponent,
    FeeFormComponent,
    IncreaseEditFormComponent,
    FacturaFormComponent,
    ImageViewerComponent,
    PaymentStepperComponent,
    LiabilitySummaryComponent,
    CustomStepperComponent,
    PaymentToPDFComponent,
    ReturnToPDFComponent,
  ],
  exports: [
    MessageCardComponent,
    CardsComponent,
    ConfirmDialogComponent,
    PipesModule,
    RoundProgressModule,
    ModalContainerComponent,
    GridComponent,
    SwitchComponent,
    DetailMessagesCardComponent,
    ListPickerComponent,
    MonthPickerComponent,
    PhonebookContainerComponent,
    WhatsappChatComponent,
    PersonViewerComponent,
    LoadingScreenComponent,
    IconSelectorComponent,
    LogBookComponent,
    SearchComponent,
    AdditionalChargesComponent,
    AdditionalChargesCardComponent,
    GridLiteComponent,
    BalanceAccountGridComponent,
    RentsPieChartComponent,
    ServiceHeatmapComponent,
    FilterV2Component,
    ToastComponent,
    TwoDigitDecimaNumberDirective,
    ToggleGridComponent,
    SidebarComponent,
    HeaderComponent,
    BuildingFormComponent,
    GuarantorFormComponent,
    OwnerFormComponent,
    TenantFormComponent,
    AgencyFormComponent,
    RequirementFormComponent,
    UserDetailViewerComponent,
    SimpleContractListComponent,
    ReturnsFormComponent,
    RequirementListComponent,
    RequirementCardComponent,
    MoratoriumComponent,
    CuitFormComponent,
    InstallmentCardsComponent,
    ExtraordinaryExpensesComponent,
    RetentionFormComponent,
    RequirementListAccumulatorComponent,
    InstallmentRequirementFormComponent,
    InstallmentGridComponent,
    InstreqServpayFormForAccumulatorComponent,
    AuthButtonComponent,
    UserFormComponent,
    AfipReceiptComponent,
    ReceiptFormComponent,
    EntityLookupComponent,
    EntitiesForContractSearchComponent,
    ContractTemplatesHandlerComponent,
    ContractCardV2Component,
    ContractCardComponent,
    ContractCreationInfoComponent,
    ContractInstallmentSetupComponent,
    FixedContractFormComponent,
    ContractRequirementsHandlerComponent,
    MailFormComponent,
    InstallmentFormComponent,
    FeeFormComponent,
    IncreaseEditFormComponent,
    FacturaFormComponent,
    NgbTooltipModule,
    ImageViewerComponent,
    PaymentStepperComponent,
    LiabilitySummaryComponent,
    PaymentToPDFComponent,
    ReturnToPDFComponent,
  ],
})
export class SharedModule {}
