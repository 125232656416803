import { Injectable } from '@angular/core';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { Observable } from 'rxjs';
import { IPaginatedServiceParamters } from '../core/models/ITable';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class RequirementService {
  constructor(private apiService: ApiService) {}

  getAllPaginated(
    paginatedArguments: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<any>> {
    return this.apiService.getPaginated('/api/Requirement', paginatedArguments);
  }

  getRequirement(id) {
    return this.apiService.get(`/api/Requirement/${id}/`);
  }

  deleteRequirement(id) {
    return this.apiService.delete(`/api/Requirement/${id}`);
  }

  updateRequirement(id, updateRequirement) {
    return this.apiService.put(`/api/Requirement/${id}`, updateRequirement);
  }

  createRequirement(newRequirement) {
    return this.apiService.post(`/api/Requirement`, newRequirement);
  }

  getAllRequirements() {
    return this.apiService.get(`/api/Requirement/GetAll`);
  }

  getInstallmentRequirementPerYear() {
    return this.apiService.get(`/api/InstallmentRequirement/ByYear`);
  }
}
