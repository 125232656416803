import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvService {
  // The values that are defined here are the default values that can
  // be overridden by env.js

  // API url
  public apiUrl = '';

  // CLAIM API url
  public claimApiUrl = '';

  public csvProcessorUrl= '';

  // AUTH0
  public AUTH0_CLIENTID = '';
  public AUTH0_AUDIENCE = '';
  public AUTH0_DOMAIN = '';

  // Whether or not to enable debug mode
  public enableDebug = true;

  constructor() {}
}
