<div *ngIf="toggleSamePage" class="btn-group" role="group" aria-label="Basic radio toggle button group">
  <input type="radio" class="btn-check" id="btnradio1" [value]="toggleSamePage.mainUrl" [checked]="toggleStatus"
    (change)="toggleGrid($event)">
  <label class="left" [ngClass]="{'checked': toggleStatus, 'not-checked': !toggleStatus}" for="btnradio1">{{
    toggleSamePage.mainTitle }}</label>

  <input type="radio" class="btn-check" id="btnradio2" [value]="toggleSamePage.secondaryUrl" [checked]="!toggleStatus"
    (change)="toggleGrid($event)">
  <label class="right" [ngClass]="{'checked': !toggleStatus, 'not-checked': toggleStatus}" for="btnradio2">{{
    toggleSamePage.secondaryTitle }}</label>
</div>

<!-- Versión Nueva -->
<div *ngIf="toggle" class="btn-group" role="group" aria-label="Basic radio toggle button group">
  <ng-container *ngFor="let titleAndUrl of toggle.titlesAndUrls; let i = index">
    <input type="radio" class="btn-check" [id]="'btnradio' + i" [value]="titleAndUrl.url" [checked]="i === 0"
      (change)="toggleGrid($event)">
    <label class="{{i === 0 ? 'left' : 'right'}}" [ngClass]="{'checked': i === 0, 'not-checked': i !== 0}"
      [for]="'btnradio' + i">{{ titleAndUrl.title }}</label>
  </ng-container>
</div>