export enum FilterInputTypes {
  STRING = 1,
  TYPES = 2,
  NUMBER = 3,
  MONEY = 4,
  DATE = 5,
  BOOLEAN = 6,
  CONTRACT = 7,
  CUSTOM = 8,
  OPTION = 9,
  CHECKBOX = 10,
}

export interface IFilterConfiguration {
  name: string;
  filterName: string;
  type: FilterInputTypes;
  group?: string;
  selectorOptions?: string[];
  requirement?: string;
}

export interface OrderOptions {
  name: string;
  filterName: string;
}

export interface SelectedFilterTypeAndValue {
  selectedFilter: IFilterConfiguration;
  value: any;
}

// export interface IFilterConfiguration {
//     columns: FilterColumn[];
//     values?: FilterValue[];
// }

// export interface FilterColumn {
//     columnName: string;
//     columnDisplay: string;
//     inputType: FilterInputTypes;
//     operatorOptions: OperationOption[];
//     columnValueTypes?: FilterColumValue[];
//     customFilter?: CustomFilter;
// }

// export interface FilterColumValue {
//     key: any;
//     value: string;
// }

// export interface CustomFilter {
//     service: any;
//     columnFunction: (val)=> {};
//     filterName: string;
//     isGather: boolean;
// }

// export interface FilterValue {
//     column: string;
//     operator: string;
//     value: string;
//     connector: string;
//     expression: string;
//     expressionDisplay?: string;
//     customFilter?: CustomFilter;
// }

// export interface FilterOperationOptions {
//     ALL: OperationOption[];
//     TEXT: OperationOption[];
//     NUMBER: OperationOption[];
//     DATE: OperationOption[];
//     BOOLEAN: OperationOption[];
//     CUSTOM: OperationOption[];
// }

// export interface OperationOption {
//     operator: string;
//     description: string;
// }

// export enum FILTERO_OPERATOR_EXPRESSION {
//     eq = 'Igual a',
//     ne = 'Distinto a',
//     ge = 'Mayor o igual a',
//     gt = 'Mayor a',
//     le = 'Menor o igual a',
//     lt = 'Menor a',
//     cn = 'Contiene a',
// }

// export const FILTER_OPERATION_OPTIONS: FilterOperationOptions = {
//     ALL: [
//         {
//             operator:'eq',
//             description: 'Igual a'
//         },
//         {
//             operator:'ne',
//             description: 'Distinto a'
//         },
//         {
//             operator:'ge',
//             description: 'Mayor o igual a'
//         },
//         {
//             operator:'gt',
//             description: 'Mayor a'
//         },
//         {
//             operator:'le',
//             description: 'Menor o igual a'
//         },
//         {
//             operator:'lt',
//             description: 'Menor a'
//         },
//         {
//             operator:'cn',
//             description: 'Contiene a'
//         }
//     ],
//     TEXT: [
//         {
//             operator:'eq',
//             description: 'Igual a'
//         },
//         {
//             operator:'ne',
//             description: 'Distinto a'
//         },
//         {
//             operator:'sw',
//             description: 'Comienza con'
//         },
//         {
//             operator:'ew',
//             description: 'Termina con'
//         },
//         {
//             operator:'cn',
//             description: 'Contiene a'
//         }
//     ],
//     NUMBER: [
//         {
//             operator:'eq',
//             description: 'Igual a'
//         },
//         {
//             operator:'ne',
//             description: 'Distinto a'
//         },
//         {
//             operator:'ge',
//             description: 'Mayor o igual a'
//         },
//         {
//             operator:'gt',
//             description: 'Mayor a'
//         },
//         {
//             operator:'le',
//             description: 'Menor o igual a'
//         },
//         {
//             operator:'lt',
//             description: 'Menor a'
//         },
//         {
//             operator:'cn',
//             description: 'Contiene a'
//         }
//     ],
//     DATE: [
//         {
//             operator:'eq',
//             description: 'Igual a'
//         },
//         {
//             operator:'ne',
//             description: 'Distinto a'
//         },
//         {
//             operator:'ge',
//             description: 'Mayor o igual a'
//         },
//         {
//             operator:'gt',
//             description: 'Mayor a'
//         },
//         {
//             operator:'le',
//             description: 'Menor o igual a'
//         },
//         {
//             operator:'lt',
//             description: 'Menor a'
//         },
//         {
//             operator:'cn',
//             description: 'Contiene a'
//         }
//     ],
//     BOOLEAN: [
//         {
//             operator:'eq',
//             description: 'Igual a'
//         },
//         {
//             operator:'ne',
//             description: 'Distinto a'
//         }
//     ],
//     CUSTOM: [
//         {
//             operator:'eq',
//             description: 'Igual a'
//         },
//         {
//             operator:'ne',
//             description: 'Distinto a'
//         }
//     ],
// }
