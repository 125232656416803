import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faCalendar, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { catchError, debounceTime, distinctUntilChanged, Observable, of, OperatorFunction, switchMap, tap } from 'rxjs';
import { Address } from 'src/app/core/models/Address';
import { CUITData } from 'src/app/core/models/CUIT';
import { DocumentTypeEnum } from 'src/app/core/models/DocumentTypeEnum';
import { DocumentoFacturaEnum } from 'src/app/core/models/DocumentoFacturaEnum';
import { Factura } from 'src/app/core/models/Factura';
import { FacturaForAdd } from 'src/app/core/models/FacturaForAdd';
import { FacturaTypeEnum, NotaTypeEnum, TaxRole } from 'src/app/core/models/FacturaTypeEnum';
import { Person } from 'src/app/core/models/Person';
import { CuitService } from 'src/app/services/cuit.service';
import { DateService } from 'src/app/services/date.service';
import { FacturasElectronicasService } from 'src/app/services/facturas-electronicas.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-factura-form',
  templateUrl: './factura-form.component.html',
  styleUrls: ['./factura-form.component.css']
})
export class FacturaFormComponent implements OnInit {
  @Output() closeAction = new EventEmitter();
  @Output() cancelAction = new EventEmitter();
  @Input() facturaAsociada: Factura = null;
  @Input() consumidores: Person[] = [];
  @Input() terceros: Person[] = null;
  @Input() amount: number;
  @Input() contractId: number = null;
  @Input() feeId: number = null;
  @Input() paymentId: number = null;

  iconCalendar = faCalendar;
  iconSearch = faSearch;
  iconClear = faTimesCircle;
  submitted: boolean = false;
  facturaToSubmit: FacturaForAdd;
  facturaType: any[] = [];
  docType: any[] = [];
  emisores: CUITData[] = [];
  minDate: Date;
  maxDate: Date;
  searchString: string = '';
  searching: boolean;

  //Nota de crédito
  notaTipoId: number;
  notaTipoTexto: string;
  notaFacturaTexto: string;

  //Terceros
  facturaTipoTexto: string;
  taxRole: number;

  facturaDataFormGroup = new UntypedFormGroup({
    concepto: new UntypedFormControl(2),
    consumidorDocumentoNumero: new UntypedFormControl(''),
    consumidorDocumentoTipo: new UntypedFormControl(''),
    consumidorDomicilio: new UntypedFormControl(''),
    consumidorNombre: new UntypedFormControl('', Validators.required),
    cuit: new UntypedFormControl(''),
    emisorDomicilio: new UntypedFormControl('',),
    emisorNombre: new UntypedFormControl('', Validators.required),
    fechaEmision: new UntypedFormControl('', Validators.required),
    fechaServicioDesde: new UntypedFormControl(''),
    fechaServicioHasta: new UntypedFormControl(''),
    fechaVencimientoPago: new UntypedFormControl('', Validators.required),
    importeExento: new UntypedFormControl(0, [Validators.min(0), Validators.max(2147483647)]),
    importeGravado: new UntypedFormControl(0, [Validators.min(0), Validators.max(2147483647)]),
    importeIva: new UntypedFormControl(0, [Validators.min(0), Validators.max(2147483647)]),
    importeTotal: new UntypedFormControl('', [Validators.required, Validators.min(0), Validators.max(2147483647)]),
    inicioActividades: new UntypedFormControl(''),
    iVATipoId: new UntypedFormControl(3),
    numeroFacturaAsociado: new UntypedFormControl(''),
    pdvAsociado: new UntypedFormControl(''),
    puntoDeVenta: new UntypedFormControl('', [Validators.min(0), Validators.max(2147483647)]),
    tipoDeFactura: new UntypedFormControl('', Validators.required),
    tipoFacturaAsociado: new UntypedFormControl(''),
  });

  constructor(private router: Router,
    private toastService: ToastService,
    private dateService: DateService,
    private cuitService: CuitService,
    private facturasElectronicasService: FacturasElectronicasService) {
    for (let item in FacturaTypeEnum) {
      if (isNaN(Number(item))) {
        this.facturaType.push({ text: item, value: FacturaTypeEnum[item] });
      }
    }
    this.minDate = new Date()
    this.minDate.setDate(this.minDate.getDate() - 5);
    this.maxDate = new Date()
    this.maxDate.setDate(this.maxDate.getDate() + 5);
  }

  ngOnInit(): void {
    this.facturaDataFormGroup.controls['fechaEmision'].patchValue(this.dateService.transformToDateHash(new Date()));
    if (this.consumidores.length > 0 && this.consumidores[0].fullName != 'Consumidor Final') {
      this.consumidores.unshift({
        address: {
          city: '',
          state: '',
          streetAddress: 'No figura',
          zipCode: '',
        },
        businessName: null,
        dateOfBirth: null,
        documentId: 0,
        documentType: 99,
        firstName: null,
        isPerson: null,
        lastName: null,
        personId: null,
        phones: null,
        fullName: 'Consumidor Final'
      })
    }
    if (this.consumidores.length == 2) {
      this.updateConsumidor(1);
    }

    if (this.terceros == null){
      this.cuitService.getAll().subscribe((result) => {
        this.emisores = result;
        if (result.length == 1) {
          this.updateEmisor(0);
        }
      });
    } else {
      if (this.terceros.length == 1) {
        this.updateEmisor(0);
        this.updateFactura();
      }
    }
    if (this.amount) {
      this.facturaDataFormGroup.controls['importeTotal'].patchValue(this.amount);
    }
    if (this.facturaAsociada) {
      this.updateFactura();
      this.notaFacturaTexto = FacturaTypeEnum[this.facturaAsociada.tipoFactura]

      this.facturaDataFormGroup.patchValue({
        tipoFacturaAsociado: this.facturaAsociada.tipoFactura,
        pdvAsociado: this.facturaAsociada.puntoDeVenta,
        numeroFacturaAsociado: this.facturaAsociada.numeroComprobante,
        consumidorDocumentoNumero: this.facturaAsociada.consumidorDocumentoNumero,
        consumidorDocumentoTipo: DocumentTypeEnum[this.facturaAsociada.consumidorDocumentoTipo],
        consumidorDomicilio: this.facturaAsociada.consumidorDomicilio,
        consumidorNombre: this.facturaAsociada.consumidorNombre,
        cuit: this.facturaAsociada.emisorCUIT,
        emisorDomicilio: this.facturaAsociada.emisorDomicilio,
        emisorNombre: this.facturaAsociada.emisorNombre,
        fechaServicioDesde: this.facturaAsociada.fechaServicioDesde,
        fechaServicioHasta: this.facturaAsociada.fechaServicioHasta,
        importeExento: this.facturaAsociada.importeOpEx,
        importeGravado: this.facturaAsociada.importeTributos,
        importeIva: this.facturaAsociada.importeIVA,
        importeTotal: this.facturaAsociada.precioTotal,
        inicioActividades: this.dateService.transformToDateHash(new Date(this.facturaAsociada.inicioActividades)),
        puntoDeVenta: this.facturaAsociada.puntoDeVenta,
        tipoDeFactura: this.notaTipoId,
      });
    }
  }

  onSubmit() {
    this.facturaDataFormGroup.markAllAsTouched();
    if (this.facturaDataFormGroup.get('fechaEmision')?.hasError('ngbDate') ||
      this.facturaDataFormGroup.get('fechaVencimiento')?.hasError('ngbDate')) {
      if (this.facturaDataFormGroup.get('fechaEmision').errors?.ngbDate.maxDate || this.facturaDataFormGroup.get('fechaEmision').errors?.ngbDate.minDate) {
        this.toastService.showToast(
          'warning',
          'No se pueden generar facturas fuera de un rango de 5 días con respecto al dia de la fecha',
        );
      }
      else {
        this.toastService.showToast(
          'warning',
          'Corrija el formato de la fecha a AAAA-MM-DD',
        );
      }
      return;
    }

    if (this.facturaDataFormGroup.invalid) {
      this.toastService.showToast(
        'warning',
        'Hay campos obligatorios sin completar',
      );
      return;
    } else {
      this.submitted = true;
      let inicioActividadesDate = this.facturaDataFormGroup.value.inicioActividades
        ? this.dateService.transformToDateObject(
          this.facturaDataFormGroup.value.inicioActividades?.day,
          this.facturaDataFormGroup.value.inicioActividades?.month,
          this.facturaDataFormGroup.value.inicioActividades?.year
        )
        : null;
      let fechaEmisionDate = this.facturaDataFormGroup.value.fechaEmision
        ? this.dateService.transformToDateObject(
          this.facturaDataFormGroup.value.fechaEmision?.day,
          this.facturaDataFormGroup.value.fechaEmision?.month,
          this.facturaDataFormGroup.value.fechaEmision?.year,
        ) : null;
      let vencimientoDate = this.facturaDataFormGroup.value.fechaVencimientoPago
        ? this.dateService.transformToDateObject(
          this.facturaDataFormGroup.value.fechaVencimientoPago?.day,
          this.facturaDataFormGroup.value.fechaVencimientoPago?.month,
          this.facturaDataFormGroup.value.fechaVencimientoPago?.year,
        ) : null;

      let docTipo: number = +DocumentoFacturaEnum[this.facturaDataFormGroup.value.consumidorDocumentoTipo];
      if (docTipo == 99) {
        this.facturaDataFormGroup.value.consumidorDocumentoNumero = 0
      }
      let fechaServicioDesde = this.dateService.getNumberFromDateYYYYMMDD(this.dateService.getFirstDay(fechaEmisionDate));
      let fechaServicioHasta = this.dateService.getNumberFromDateYYYYMMDD(this.dateService.getLastDay(fechaEmisionDate));
      let fechaVto = this.dateService.getNumberFromDateYYYYMMDD(vencimientoDate);

      this.facturaToSubmit = {
        Concepto: this.facturaDataFormGroup.value.concepto
          ? this.facturaDataFormGroup.value.concepto
          : null,
        ConsumidorDocumentoNumero: docTipo == 99 ? 0 : this.facturaDataFormGroup.value.consumidorDocumentoNumero,
        ConsumidorDocumentoTipo: docTipo,
        ConsumidorDomicilio: this.facturaDataFormGroup.value.consumidorDomicilio
          ? this.facturaDataFormGroup.value.consumidorDomicilio
          : null,
        ConsumidorNombre: this.facturaDataFormGroup.value.consumidorNombre
          ? this.facturaDataFormGroup.value.consumidorNombre
          : null,
        CUIT: this.facturaDataFormGroup.value.cuit
          ? this.facturaDataFormGroup.value.cuit
          : null,
        EmisorDomicilio: this.facturaDataFormGroup.value.emisorDomicilio
          ? this.facturaDataFormGroup.value.emisorDomicilio
          : null,
        EmisorNombre: this.facturaDataFormGroup.value.emisorNombre
          ? this.facturaDataFormGroup.value.emisorNombre
          : null,
        FechaEmision: fechaEmisionDate,
        FechaServicioDesde: fechaServicioDesde
          ? fechaServicioDesde
          : null,
        FechaServicioHasta: fechaServicioHasta
          ? fechaServicioHasta
          : null,
        FechaVencimientoPago: fechaVto ? fechaVto : null,
        HonorariosFacturados: this.facturaDataFormGroup.value.honorariosFacturados
          ? this.facturaDataFormGroup.value.honorariosFacturados
          : null,
        ImporteExento: this.facturaDataFormGroup.value.importeExento,
        ImporteGravado: this.facturaDataFormGroup.value.importeGravado,
        ImporteIva: this.facturaDataFormGroup.value.importeIva,
        ImporteTotal: this.facturaDataFormGroup.value.importeTotal
          ? this.facturaDataFormGroup.value.importeTotal
          : null,
        InicioActividades: inicioActividadesDate,
        IVATipoId: this.facturaDataFormGroup.value.iVATipoId
          ? this.facturaDataFormGroup.value.iVATipoId
          : null,
        NumeroFacturaAsociado: this.facturaDataFormGroup.value.numeroFacturaAsociado
          ? this.facturaDataFormGroup.value.numeroFacturaAsociado
          : null,
        PdvAsociado: this.facturaDataFormGroup.value.pdvAsociado
          ? this.facturaDataFormGroup.value.pdvAsociado
          : null,
        PuntoDeVenta: this.facturaDataFormGroup.value.puntoDeVenta
          ? this.facturaDataFormGroup.value.puntoDeVenta
          : null,
        TipoDeFactura: this.facturaDataFormGroup.value.tipoDeFactura
          ? this.facturaDataFormGroup.value.tipoDeFactura
          : null,
        TipoFacturaAsociado: this.facturaDataFormGroup.value.tipoFacturaAsociado
          ? this.facturaDataFormGroup.value.tipoFacturaAsociado
          : null,
        ContractId: this.contractId,
        FeeId: this.feeId,
        PaymentId: this.paymentId
      };
      this.addFactura(this.facturaToSubmit);
    }
  }

  updateFactura(){
    if (this.facturaAsociada) {
      switch (this.facturaAsociada.tipoFactura) {
        case FacturaTypeEnum.A:
          this.notaTipoId = NotaTypeEnum.A;
          this.notaTipoTexto = 'Nota de Crédito ' + NotaTypeEnum[this.notaTipoId];
          break;
        case FacturaTypeEnum.B:
          this.notaTipoId = NotaTypeEnum.B;
          this.notaTipoTexto = 'Nota de Crédito ' + NotaTypeEnum[this.notaTipoId];
          break;
        case FacturaTypeEnum.C:
          this.notaTipoId = NotaTypeEnum.C;
          this.notaTipoTexto = 'Nota de Crédito ' + NotaTypeEnum[this.notaTipoId];
          break;
      }
    } else if (this.terceros) {
      switch (this.taxRole) {
        case TaxRole.ResponsableInscripto:
          this.facturaDataFormGroup.controls['iVATipoId'].patchValue(5);

          let name = this.facturaDataFormGroup.value.consumidorNombre;
          let id = this.facturaDataFormGroup.value.consumidorDocumentoNumero;
          let selectedConsumidor = this.terceros.find(t => t.fullName == name && t.documentId == id);

          if (selectedConsumidor?.taxRole) {
            this.facturaTipoTexto = 'A';
            this.facturaDataFormGroup.controls['tipoDeFactura'].patchValue(FacturaTypeEnum.A);
          } else {
            this.facturaTipoTexto = 'B';
            this.facturaDataFormGroup.controls['tipoDeFactura'].patchValue(FacturaTypeEnum.B);
          }
          this.facturaDataFormGroup.controls['importeExento'].addValidators(
            Validators.required,
          );
          this.facturaDataFormGroup.controls['importeGravado'].addValidators(
            Validators.required,
          );
          this.facturaDataFormGroup.controls['importeIva'].addValidators(
            Validators.required,
          );
          this.facturaDataFormGroup.controls['iVATipoId'].addValidators(
            Validators.required,
          );   
          break;
        case TaxRole.ResponsableMonotributo:
          this.facturaTipoTexto = 'C';
          this.facturaDataFormGroup.controls['tipoDeFactura'].patchValue(FacturaTypeEnum.C);
          this.facturaDataFormGroup.controls['importeExento'].clearValidators();   
          this.facturaDataFormGroup.controls['importeGravado'].clearValidators();   
          this.facturaDataFormGroup.controls['importeIva'].clearValidators();   
          this.facturaDataFormGroup.controls['iVATipoId'].clearValidators();   
          break;
      }
      this.facturaDataFormGroup.controls["importeExento"].updateValueAndValidity();
      this.facturaDataFormGroup.controls["importeGravado"].updateValueAndValidity();
      this.facturaDataFormGroup.controls["importeIva"].updateValueAndValidity();
      this.facturaDataFormGroup.controls["iVATipoId"].updateValueAndValidity();
    }
  }

  addFactura(facturaToSubmit) {
    this.facturasElectronicasService
      .createFactura(facturaToSubmit)
      .subscribe((response: number) => {
        this.toastService.showToast('success', 'Factura creada');
        this.router.navigateByUrl(`/facturas/${response}`);
        this.closeAction.emit();
      },
        (err) => {
          let msg = 'Ha ocurrido un error';
          if (err.error == 'AFIP') {
            msg = 'No se pudo crear la factura por un error interno con AFIP';
          }          
          this.toastService.showToast('error', msg);
          this.submitted = false;
        });
  }

  validate(field: string) {
    return (
      this.facturaDataFormGroup.controls[field].errors &&
      this.facturaDataFormGroup.controls[field].touched
    );
  }

  updateEmisor(i: number) {    
    if (this.terceros == null){
      let emisor: CUITData = this.emisores[i];
      this.facturaDataFormGroup.patchValue({
        emisorNombre: emisor.socialReason,
        cuit: emisor.cuitNumber,
        emisorDomicilio: emisor.address,
        puntoDeVenta: emisor.sellPoints,
        inicioActividades: this.dateService.transformToDateHash(
          new Date(emisor.activityStartDate)),
      });
    }
    else{
      let tercero: Person = this.terceros[i];
      this.taxRole = tercero.taxRole;
      let address = this.getAddressString(tercero.address);      
      this.facturaDataFormGroup.patchValue({
        emisorNombre: tercero.fullName,
        cuit: tercero.documentId,
        emisorDomicilio: address,
      });
      this.updateFactura();
    }
  }

  updateConsumidor(i: number) {
    let consumidor: Person = this.consumidores[i];
    this.patchConsumidor(consumidor)
  }

  patchConsumidor(p: Person) {
    let doc;
    switch (+p.documentType) {
      case 1:
        doc = DocumentoFacturaEnum['96'];
        break;
      case 2:
        doc = DocumentoFacturaEnum['86'];
        break;
      case 3:
      case 0:
        doc = DocumentoFacturaEnum['80'];
        break;
      case 99:
        doc = DocumentoFacturaEnum['99'];
        break;
    }

    if (this.terceros != null && this.taxRole == TaxRole.ResponsableInscripto) {
      if (p.taxRole) {
        this.facturaTipoTexto = 'A';
        this.facturaDataFormGroup.controls['tipoDeFactura'].patchValue(FacturaTypeEnum.A);
      } else {
        this.facturaTipoTexto = 'B';
        this.facturaDataFormGroup.controls['tipoDeFactura'].patchValue(FacturaTypeEnum.B);
      }        
    }
    
    let address = this.getAddressString(p.address);

    this.facturaDataFormGroup.patchValue({
      consumidorNombre: p.fullName,
      consumidorDocumentoNumero: p.documentId,
      consumidorDomicilio: address,
      consumidorDocumentoTipo: doc,
    });
  }

  getAddressString(address: Address){
    let result = address.streetAddress +
      (address.city ? ', ' + address.city : '') +
      (address.state ? ', ' + address.state : '');
    return result == 'null' ? "No figura" : result;
  }

  cancel() {
    this.cancelAction.emit();
  }

  search: OperatorFunction<any, any> = (text$: Observable<any>) =>
    text$.pipe(
      tap(() => (this.searching = true)),
      debounceTime(750), // DELAY DEL BUSCADOR EN PAGOS Y PANTALLA PRINCIPAL
      distinctUntilChanged(),
      switchMap((queryString) => {
        if (queryString.length > 2) {
          return this.cuitService
            .getAllRecipients(
              isNaN(Number(queryString)) ? 'PersonName=' + queryString : 'PersonIdentification=' + queryString,
            )
            .pipe(
              catchError((error) => {
                return of([]);
              }),
            )
        }
        else {
          return of([]);
        }
      }),
      tap(() => (this.searching = false))
    );

  setResult(event) {
    this.patchConsumidor(event.item);
  }

  clearSearch() {
    this.searchString = '';
    this.facturaDataFormGroup.patchValue({
      consumidorNombre: '',
      consumidorDocumentoNumero: '',
      consumidorDomicilio: '',
    });
  }

  formatResult(consumidor: Person, selected = true) {
    if (selected) {
      return consumidor ? consumidor.fullName : '';
    }
    return consumidor ? `${consumidor.fullName} (${consumidor.documentId})` : '';
  }
}
