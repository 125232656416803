import { ElementRef } from '@angular/core';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ConfirmDialogComponent,
  ConfirmDialogSettings,
} from 'src/app/shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-moratorium',
  templateUrl: './moratorium.component.html',
  styleUrls: ['./moratorium.component.css'],
})
export class MoratoriumComponent implements OnInit {
  @ViewChild(ConfirmDialogComponent) confirmBox: ConfirmDialogComponent;

  @Output() moratoriumStatus = new EventEmitter();

  toggleMoratorium: boolean = false;

  confirmBoxTitles: ConfirmDialogSettings = {
    title: 'Confirma que Desea Perdonar las Moratorias?',
    subtitle: '',
  };

  constructor() {}

  ngOnInit() {}

  toggleElement(e: any) {
    e.preventDefault();
    if (this.toggleMoratorium) {
      this.confirmBoxTitles.title =
        'Confirme que desea desactivar el Cobro de Moratorias.';
    } else {
      this.confirmBoxTitles.title =
        'Confirme que desea activar el Cobro de Moratorias.';
    }
    this.confirmBox.openModal().result.then(
      (result) => {
        if (result) {
          this.toggleMoratorium
            ? (this.toggleMoratorium = false)
            : (this.toggleMoratorium = true);
          this.moratoriumStatus.emit(this.toggleMoratorium);
        }
      },
      (reason) => {
        e.preventDefault();
      },
    );
  }
}
