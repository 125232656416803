<app-confirm-dialog style="display:none;" [modalTitles]="confirmBoxTitles"></app-confirm-dialog>
<div class="grid-container">
  <div class="header">
    <div class="title">
      <div  class="title-name flex-column">
        <span style="font-size: 2.5rem; font-weight: 500; color: white; margin-bottom: 10px;">Procesar CSV</span>
      </div>
    </div>
  </div>
  <div class="flex-row shadow-sm border-5" style="grid-area: homePage; margin-left: 20px; height: 55px; padding: 10px; background-color: white; width: fit-content;">
    <a class="flex-row btn btn-primary" style="min-width: 1.3rem; margin-right: 10px;" type="button" href="/dashboard">
      Home
    </a> 
    <a class="flex-row btn btn" style="min-width: 1.3rem; background-color: rgb(253, 107, 8); color: white ;" type="button" href="contracts"><fa-icon style="margin-right: 5px;"[icon]="iconSee"></fa-icon>
      Contratos 
    </a>
  </div>
  <div style="grid-area:table;" *ngIf="save == false">
    <loading-spinner [evaluation]="save" [msg]="msgLoading" [liteMode]="true"></loading-spinner>
  </div>
  <div class="main-info">
    <span style="font-size: 1.2rem; font-weight: bold;  margin-top: 30px;">Carga tu CSV</span> 
      <div class="info">
        <div class="flex-row">
          <div class="buttons" style="margin-bottom: 60px;">
            <input style="display:none;" type="file" (change)="onFileSelect($event.target)" name="myfile" #fileInput>
            <button class="flex-row btn btn-primary"  type="button" (click)="saveContract(this.newData)" *ngIf="selectedFile && save != false">Guardar Contratos</button>
            <button class="flex-row btn btn-primary" type="button"  [disabled]="selectedFile"  (click)="fileInput.click()" 
              [ngClass]="{'btn-primary': !selectedFile, 'btn-secondary': selectedFile}" *ngIf="!selectedFile">Selec. Archivo</button>
            <button class="flex-row btn btn-danger" color="warn" type="button" (click)="clearCSV()">Borrar</button>            
          </div>
          <div *ngIf="selectedFile != null" style="margin-left: 30px ;">
            <p>Nombre: {{fileName}}</p>
            <p>Tipo: {{fileType}}</p>
            <p>Tamaño: {{fileSize}} bytes </p>
          </div>
        </div>
      </div> 
    </div>
  <div *ngIf="selectedFile != null && save" style="grid-area:table;">
    <table class="table table-md shadow-sm border rounded" *ngFor="let vl of newData; let j = index" style="margin-top: 20px"> 
      <thead>
        <tr class="grid-headers">
          <th scope="col" *ngFor="let h of header; let i = index">
          <div class="flex-row" style="white-space: nowrap;">
            {{h}}
          </div>
          </th>    
        </tr>
      </thead>
      <tbody> 
        <tr>
          <td>
            <button class="flex-row btn btn-danger" color="warn" type="button" (click)="removeContract(j)">
              <fa-icon [icon]="iconRemove"></fa-icon></button>
          </td>
          <td>
            {{vl.internalId || '-'}}
          </td>
          <td>
            {{vl.property.address.streetAddress || '-'}} {{vl.property.identification || ''}}
          </td>
          <td>
            {{vl.tenant.user.userName || '-'}}
          </td>
          <td>
            {{vl.owner.user.userName || '-'}}
          </td>
          <td style="text-align:center ;">
            {{vl.percentageFee || '0' | number: '1.0'}}%
          </td>
          <td style="text-align:center ;">
            {{vl.installmentQuantity || '-'}}
          </td>
          <td style="text-align:center ;">
            {{vl.fromDate || '-' | date: "shortDate"}}
          </td>
          <td style="text-align:center ;">
            {{vl.toDate || '-' | date: "shortDate"}}
          </td>
          <td>
            {{vl.guarantor.person.firstName || '-'}}
          </td> 
            <td style="text-align:center ;">
            <input type="text" style="width: 40px; text-align:center" [(ngModel)]="expirationIncrease[j]">%
          </td>
          <td style="text-align:center;">
            <div class="checkbox-container">
              <label class="checkbox-label">
                  <input  type="checkbox" [(ngModel)]="installmentCheck[j]" #checkbox>
                  <span class="checkbox-custom rectangular"></span>
              </label>
            </div>
          </td>
          <td style="text-align:center;">
            <div class="checkbox-container">
              <label class="checkbox-label">
                  <input  type="checkbox" [(ngModel)]="fixedOrDinamic[j]" #checkbox>
                  <span class="checkbox-custom rectangular"></span>
              </label>
            </div>
          </td>
          <td>
            <div class="buttons" style="margin-bottom: 60px;">
              <input style="display:none;" type="file" (change)="statusAccountFileSelect($event.target, j)" name="myfile" #fileInput>
              <button class="flex-row btn btn-primary" type="button"  [disabled]="this.newData[j].state"  (click)="fileInput.click()" 
              [ngClass]="{'btn-primary': !this.newData[j].state, 'btn-secondary': this.newData[j].state}">Archivo</button>
              <button class="flex-row btn btn-danger" color="warn" type="button" (click)="clearStatusAccount(j)" *ngIf="this.newData[j].state">Cancelar</button>
            </div>
          </td>       
        </tr>
      </tbody>
      <tbody>
        <td *ngIf="this.newData[j].state">
          <p>Cantidad de cuotas: {{InstallmentQuantity(j)}}</p>
        </td>
        <td *ngIf="this.newData[j].state">
          <p>Cantidad de servicios: {{InstallmentRequirementQuantity(j)}}</p>
        </td>
      </tbody>
    </table> 
  </div>
</div>


