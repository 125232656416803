<loading-spinner [evaluation]="isLoaded" [liteMode]="true">

  <apx-chart class="chart"
  [series]="chartOptions?.series"
  [chart]="chartOptions?.chart"
  [labels]="chartOptions?.labels"
  [title]="chartOptions?.title"
  [responsive]="chartOptions?.responsive"
  [colors]="chartOptions?.colors"
  [dataLabels]="chartOptions?.dataLabels"
  [plotOptions]="chartOptions?.plotOptions"
  [legend]="chartOptions?.legend"
  [tooltip]="chartOptions?.tooltip"
></apx-chart>

<div *ngIf="isEmpty" class="chart-empty" >
  <fa-icon style="margin-bottom: 5px;"[icon]="iconQuestion" size="4x"></fa-icon>
  <span style="margin-bottom: 5px;">{{'chart.isEmpty' | translate}}</span>
</div>

</loading-spinner>
