import { Injectable } from '@angular/core';
import { AgencyForEdit } from '../core/models/AgencyForAdd';
import { REConfig } from '../core/models/RealEstateAgency';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AgencyService {
  constructor(private apiService: ApiService) {}

  getAgency() {
    return this.apiService.get(`/api/realEstateAgency/`);
  }

  updateAgency(agencyForUpdate: AgencyForEdit) {
    return this.apiService.patch(`/api/realEstateAgency/${agencyForUpdate.id}`, agencyForUpdate);
  }

  updateConfig(agencyId: number, updatedConfig: REConfig) {
    return this.apiService.patch(`/api/realEstateAgency/Config/${agencyId}`, updatedConfig);
  }
}
