<form [formGroup]="cuitDataFormGroup" (ngSubmit)="submitCuit()">
  <div class="title">
    <h1 *ngIf="!edit">Añadir CUIT</h1>
    <h1 *ngIf="edit">Editar CUIT</h1>
  </div>

  <div class="form-building form-group">
    <div class="container">
      <div class="row">
        <hr>
        <h3>Datos Principales</h3>
      </div>
      <div class="row">
        <div class="col-sm">
          <label>Numero de Cuit</label>
          <input placeholder="27-12345678-9" type="text" formControlName="cuitNumber" class="form-control"
            [ngClass]="{'borderRed': validate('cuitNumber')}">
          <span *ngIf="validate('cuitNumber') && !cuitDataFormGroup.value.cuitNumber"
            style="color: var(--border-red); font-size: 90%;">Dato Faltante</span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <label>Condicion IVA</label>
          <div class="flex-row" style="margin-bottom: 1vh;">
            <select class="form-control form-select" id="ivaAccountableType" formControlName="ivaAccountableType">
              <option [value]="''" disabled>Seleccione una condicion</option>
              <option *ngFor="let iva of ivaTypes" [value]="iva.text">{{iva.text}}</option>
            </select>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <h3> Datos Complementarios</h3>
      </div>
      <div class="row">
        <div class="col-sm">
          <label>Razon Social</label>
          <input class="form-control" formControlName="socialReason" type="text"
            [ngClass]="{'borderRed': validate('socialReason')}">
          <span *ngIf="validate('socialReason') && !cuitDataFormGroup.value.socialReason"
            style="color: var(--border-red); font-size: 90%;">Dato Faltante</span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <label>Domicilio</label>
          <input class="form-control" placeholder="Av. Rivadavia 1090 5° A" formControlName="address" type="text"
            [ngClass]="{'borderRed': validate('address')}">
          <span *ngIf="validate('address') && !cuitDataFormGroup.value.address"
            style="color: var(--border-red); font-size: 90%;">Dato Faltante</span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <label>Punto de Venta</label>
          <input class="form-control" placeholder="1" formControlName="sellPoints" type="number"
            [ngClass]="{'borderRed': validate('sellPoints')}">
          <span *ngIf="validate('sellPoints')" style="color: var(--border-red); font-size: 90%;">
            Dato Inválido
          </span>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-sm">
          <label>Ingresos Brutos</label>
          <div class="flex-row" style="margin-bottom: 1vh;">
            <input placeholder="Exento" type="text" formControlName="ibbAccountableType" class="form-control"
              [ngClass]="{'borderRed': validate('ibbAccountableType')}">
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col-sm">
          <label>Inicio de Actividades</label>
          <div class="input-group" style="margin-bottom: 1vh;">
            <input class="form-control" placeholder="AAAA-MM-DD" formControlName="activityStartDate" name="dp"
              ngbDatepicker #c="ngbDatepicker"
              [minDate]="{year: minDate.getFullYear(), month: minDate.getMonth()+1, day: minDate.getDate()}">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="c.toggle()" type="button">
                <fa-icon [icon]="iconCalendar" size="1x"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="buttons-submitting" style="display: flex; justify-content: space-between;">
    <button type="button" class="btn btn-danger" [disabled]="submitted" (click)="cancel()">Cancelar</button>
    <button class="btn btn-primary" [disabled]="submitted" *ngIf="!edit">Crear</button>
    <button class="btn btn-primary" *ngIf="edit">Editar</button>
  </div>
</form>