import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  private languages: any = { keys: { en: '', es: '' } };
  private languagesNames = [
    { Name: 'English', Key: 'en' },
    { Name: 'Español', Key: 'es' },
  ];
  private language: string;

  constructor(private http: HttpClient) {
    this.http.get('assets/i18n/languages.json').subscribe((res) => {
      this.languages = res;
      // res.json
    });
    let lang = window.localStorage.getItem('lang');
    if (lang == null) {
      lang = navigator.language.substring(0, 2);
      if (this.languages.keys.hasOwnProperty(lang)) {
        this.set(lang);
      } else {
        this.set('es');
      }
    } else {
      this.set(lang);
    }
    this.set('es'); //Hardcodeado a español
  }

  set(language: string): void {
    this.language = language;
  }

  translate(key: string): string {
    if (
      typeof this.languages.keys !== 'undefined' &&
      this.languages.keys.hasOwnProperty(key)
    ) {
      return this.languages.keys[key][this.language];
    } else {
      return key;
    }
  }

  public getLanguagesNames() {
    return this.languagesNames;
  }
}
