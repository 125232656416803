import { Component, Input, OnInit } from '@angular/core';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { PhoneNumber } from 'src/app/core/models/PhoneNumber';

@Component({
  selector: 'app-whatsapp-chat',
  templateUrl: './whatsapp-chat.component.html',
  styleUrls: ['./whatsapp-chat.component.css'],
})
export class WhatsappChatComponent implements OnInit {
  @Input() phoneNumber: PhoneNumber;

  /*@ViewChild('mssChatForm') mssChatForm!: NgForm;*/

  public apiUrl: string = 'https://api.whatsapp.com/send?';

  iconWapp = faWhatsapp;

  constructor() {
    this.phoneNumber = {
      area: '',
      number: '',
      description: '',
    };
  }

  ngOnInit() {
    let linkPhone: string = this.validateWhatsapp(
      this.phoneNumber.area + this.phoneNumber.number,
    );

    this.apiUrl =
      linkPhone.length == 10 ? `${this.apiUrl}phone=549${linkPhone}` : '';
  }

  send() {
    window.open(this.apiUrl);
  }

  validateWhatsapp(number) {
    let phoneString: string = '';
    for (let i = 0; i < number.length; i++) {
      if (i == 0 && number.charAt(i) == '0') {
        continue;
      }
      let char: string = number.charAt(i);
      if (parseInt(char) + 10) {
        phoneString += char;
      }
    }
    if (
      phoneString.length == 10 &&
      (phoneString.substring(0, 2) == '15' ||
        phoneString.substring(0, 2) == '11')
    ) {
      return '11' + phoneString.substring(2);
    }
    return phoneString;
  }
}
