import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { InstallmentWithRequirement } from 'src/app/core/models/InstallmentWithRequirement';
import { ContractService } from 'src/app/services/contract.service';
import { IconService } from 'src/app/services/icon.service';

@Component({
  selector: 'app-liability-summary',
  templateUrl: './liability-summary.component.html',
  styleUrls: ['./liability-summary.component.css']
})
export class LiabilitySummaryComponent implements OnInit, OnChanges {
  @Input() data: InstallmentWithRequirement[];
  @Input() contractId: number;

  oldData: InstallmentWithRequirement[];
  requirements: string[];
  metadata: { requirementId: number, metadata: string; }[];
  reqAccumulator: any;
  reqAccKeys: string[];
  noReq: boolean;
  loaded: boolean = false;

  constructor(
    public iconService: IconService,
    public contractService: ContractService,
    ) {}

  ngOnInit(): void {
    this.oldData = this.data;
  }

  ngOnChanges(){
    if (this.oldData != this.data) {   
      this.contractService.getContractMetadata(this.contractId).subscribe((result: any[]) => {
        this.metadata = result.map(CReq => {
          return {
            requirementId: CReq.requirementId,
            metadata: CReq.metadata
          };
        });
        this.formatData();
        this.oldData = this.data;
      }, () => this.loaded = true);   
    }
  }

  formatData() {
    this.loaded = false;
    this.reqAccumulator = {};
    this.reqAccKeys = [];
    this.data[0].installmentRequirementStates.forEach((req) => {
      let mdata;
      try {
        mdata = JSON.parse(this.metadata.filter(m => m.requirementId == req.requirementId)[0].metadata);
      } catch (error) {
        mdata = "";
      }
      this.reqAccumulator[req.requirementName] = {
        'installments': [],
        'icon': req.requirementIcon,
        'metadata': mdata
      };
      this.reqAccKeys.push(req.requirementName);
    })

    this.data.forEach((element) => {      
      let elementDate = new Date(element.installment.fromDate);
      let todayInstallment = new Date();
      
      if (elementDate.getTime() <= todayInstallment.getTime()) {
        element.installmentRequirementStates.forEach((elementState) => {    
          if (this.reqAccKeys.indexOf(elementState.requirementName) === -1) {
            this.reqAccKeys.push(elementState.requirementName);
          }      
          if (
            !elementState.installmentRequirement &&
            elementState.installmentRequirement?.installmentStatusId != 1
          ) {
            this.reqAccumulator[elementState.requirementName][
              'installments'
            ].push(element.installment.description);
          }
        });
      }
    });

    if (this.reqAccKeys.length == 0) {
      this.noReq = true;
    } else {
      this.noReq = false;
    }
    this.loaded = true;
  }
}
