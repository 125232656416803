import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { DateService } from 'src/app/services/date.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogSettings,
} from 'src/app/shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-requirement-list',
  templateUrl: './requirement-list.component.html',
  styleUrls: ['./requirement-list.component.css'],
})
export class RequirementListComponent implements OnInit {
  isCollapsed: boolean = false;
  selectedCollapsableId: number = 0;
  paid: boolean = true;
  firstUnpaidInstallmentId: number;

  @Input() data: any;

  @Output() updateInstallments = new EventEmitter();

  @ViewChild(ConfirmDialogComponent) confirmBox: ConfirmDialogComponent;

  confirmBoxTitles: ConfirmDialogSettings = {
    title: 'Confirmar Borrado de Requerimiento',
    subtitle: '',
  };

  iconUp = faCaretUp;
  iconDown = faCaretDown;

  constructor(public dateService: DateService) {}

  ngOnInit() {
    this.getLastUnpaidInstallment();
  }

  changeCollapsable(id) {
    this.selectedCollapsableId = id === this.selectedCollapsableId ? 0 : id;
  }

  refreshEmit() {
    this.updateInstallments.emit();
  }

  getLastUnpaidInstallment() {
    this.data.keys.forEach((year) => {
      this.data.dataset[year].forEach((month) => {
        if (month.installment.amountPaid < month.installment.amount) {
          this.firstUnpaidInstallmentId =
            this.firstUnpaidInstallmentId == undefined
              ? month.installment.id
              : this.firstUnpaidInstallmentId;
        }
      });
    });
  }

  print(e) {
    console.log(e);
  }
}
