<loading-spinner [evaluation]="loaded" [liteMode]="true">
  <div class="flex-row shadow-sm border-5" style="background-color: white; width: fit-content; padding: 1rem 0.5rem;" *ngIf="reqAccKeys?.length > 0">
    <div class="card shadow-sm border-5 card-style" *ngFor="let instReqName of reqAccKeys">
      <div class="flex-row nowrap" style="justify-content: space-between; padding: 1rem">
        <div class="flex-row">
          <fa-icon [icon]="iconService.getIcon(reqAccumulator[instReqName].icon)" 
            size="xl" style="opacity: 0.7; margin-right: 8px;"></fa-icon>
          <span style="font-size: 150%; font-weight: 550;">
            {{instReqName}}
          </span>
        </div>
        <span class="numberCircle" *ngIf="reqAccumulator[instReqName].installments.length > 0"
          [ngbTooltip]="reqAccumulator[instReqName].installments.length > 0 ? tooltip: null" placement="right-top">
          {{reqAccumulator[instReqName].installments.length}}
        </span>
      </div>
      <div class="flex-row" style="margin-left: -0.8vw;">
        <ul style="list-style-type:none">
          <li *ngFor="let data of reqAccumulator[instReqName].metadata | keyvalue"><span style="font-weight: bold;">{{ data.key }}:</span> {{ data.value }}</li>
       </ul>
      </div>

      <ng-template #tooltip>
        <b>Meses pendientes</b>
        <ul *ngFor="let month of reqAccumulator[instReqName].installments">
          <li>{{ month | titlecase }}</li>
        </ul>
      </ng-template>
    </div>
  </div>
</loading-spinner>




