import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { ContractForList } from 'src/app/core/models/ContractForList';

@Component({
  selector: 'app-simple-contract-list',
  templateUrl: './simple-contract-list.component.html',
  styleUrls: ['./simple-contract-list.component.scss'],
})
export class SimpleContractListComponent implements OnInit {
  @Input() contractForLists: ContractForList[];

  iconQuestion = faQuestionCircle;

  constructor(private router: Router) {}

  ngOnInit() {}

  public goToContract(contractId: number) {
    this.router.navigateByUrl(`/contracts/${contractId}/detail`);
  }
}
