import { Component, Input, OnInit } from '@angular/core';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { DateService } from 'src/app/services/date.service';
import * as html2pdf from 'html2pdf.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Return } from 'src/app/core/models/Return';
import { ReturnsService } from 'src/app/services/returns.service';

@Component({
  selector: 'app-return-to-PDF',
  templateUrl: './return-to-PDF.component.html',
  styleUrls: ['./return-to-PDF.component.scss'],
})
export class ReturnToPDFComponent implements OnInit {
  @Input() returnId?: number[] = [];
  @Input() popoverOption: boolean = false;

  return: Return;
  date: string;
  newWindowHtml: string;
  iconDownload = faFileArrowDown;
  isLoaded: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private returnService: ReturnsService,
    private dateService: DateService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {}

  parseDate(value): string {
    if (value) {
      let date = new Date(value);
      let dateString = this.dateService.transformToDateString(date);
      return dateString;
    }
    return '-';
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  openModal(template) {
    this.isLoaded = false;
    this.returnService.getReturnReceiptView(this.returnId).subscribe(
      (result: any) => {},
      (error) => {
        this.newWindowHtml = error.error.text;
        this.isLoaded = true;
      },
    );
    this.returnService
      .getReturnReceipt(this.returnId)
      .subscribe((result: any) => {
        this.return = result;
        var newWind = open();
        newWind.document.write(this.newWindowHtml);
      });
  }
}
