import {
  Component,
  Input,
  Output,
  OnInit,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import {
  IPaginatedService,
  IPaginatedServiceParamters,
} from 'src/app/core/models/ITable';
import { ApiService } from 'src/app/services/api.service';
import { IconService } from 'src/app/services/icon.service';
import { TranslateService } from 'src/app/services/translate.service';
import { GridLiteColumn, GridLiteSettings } from './GridLiteSettings';

@Component({
  selector: 'app-grid-lite',
  templateUrl: './grid-lite.component.html',
  styleUrls: ['./grid-lite.component.css'],
})
export class GridLiteComponent<T> implements OnInit {
  // Inputs
  @Input() settings: GridLiteSettings;
  @Input() query: string = 'CreatedOrNot=true';
  // Output
  @Output() returnEmpty = new EventEmitter();
  // Properties
  headers: string[];
  tableData: any[];
  renderData: any[] = [];
  rawData: any[] = [];
  isLoaded = false;
  totalPages: number;
  totalItems: number;
  page: number = 1;
  itemsPerPage: number = 5;
  tableWidth: string = '400';
  quantity: number;
  headerData: any[];
  id: number = 0;

  iconQuestion = faQuestionCircle;

  configUrl = 'assets/config.json';

  constructor(
    public translateService: TranslateService,
    public apiService: ApiService,
    public iconService: IconService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.headers = [];
    this.tableData = [];
    this.renderData = [];
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.settings.hardcodedFilter = this.settings.hardcodedFilter ?? "";
    this.tableWidth = this.settings.tableWidth + 'px';
    if (!this.settings.dataSet) {
      this.getElements();
    } else {
      this.isLoaded = true;
      this.setElements();
    }
  }

  getElements() {
    var paginatedArguments: IPaginatedServiceParamters = {
      pageNumber: this.page,
      query:
        this.query + this.settings.hardcodedFilter
          ? this.settings.hardcodedFilter
          : '',
      pageSize: this.itemsPerPage,
      id: this.settings.id,
    };
    this.settings.tableService.getAllPaginated(paginatedArguments).subscribe(
      (data: PaginatedResult<T[]>) => {
        this.rawData = JSON.parse(JSON.stringify(data.result));
        this.parseDataToRender(data);
        this.quantity = data.pagination.totalItems;
        this.isLoaded = true;
      },
      (err) => {
        this.isLoaded = true;
        if (err.error.status == 404) {
          this.rawData = [];
          this.headers = [];
          this.renderData = [];
          this.quantity = 0;
        }
        this.returnEmpty.emit();
      },
    );
  }

  setElements() {
    this.headers = [];
    this.renderData = [];
    let i = 0;
    this.settings.dataSet.forEach((row: T) => {
      let j = 0;
      const newRow = {};
      this.settings.columns.forEach((column: GridLiteColumn) => {
        newRow[j] = column.function
          ? column.function(row[column.key])
          : row[column.key];
        j++;
      });
      this.renderData.push(newRow);
      i++;
    });
    this.settings.columns.forEach((column: GridLiteColumn) => {
      // Add headers
      if (column.title) {
        this.headers.push(column.title);
      } else {
        this.headers.push(column.key);
      }
    });
    this.tableData = this.settings.dataSet;
  }

  private parseDataToRender(data: PaginatedResult<T[]>) {
    this.headers = [];
    this.renderData = [];
    let i = 0;
    data.result.forEach((row: T) => {
      let j = 0;
      const newRow = {};
      this.settings.columns.forEach((column: GridLiteColumn) => {
        newRow[j] = column.function
          ? column.function(row[column.key])
          : row[column.key];
        j++;
      });
      this.renderData.push(newRow);
      i++;
    });
    this.settings.columns.forEach((column: GridLiteColumn) => {
      // Add headers
      if (column.title) {
        this.headers.push(column.title);
      } else {
        this.headers.push(column.key);
      }
    });
    this.tableData = data.result;
  }

  translate(text: string): string {
    return this.translateService.translate(text);
  }

  clearSearch() {
    this.getElements();
  }

  setResults(data: any) {
    var dataM: PaginatedResult<T[]> = {
      pagination: {
        currentPage: 0,
        itemsPerPage: 0,
        totalItems: 0,
        totalPages: 0,
      },
      result: data,
    };
    this.rawData = JSON.parse(JSON.stringify(dataM.result));
    this.parseDataToRender(dataM);
  }

  viewMore() {
    let url = '';
    this.settings.urlForRedirect
      ? (url =
          this.settings.urlForRedirect + '?' + this.settings.hardcodedFilter)
      : (url = this.settings.urlForRedirect);
    this.router.navigateByUrl(url);
  }

  checkArray(row): boolean {
    return Array.isArray(row);
  }

  detailRedirect(obj, url, end){    
    let id = !!obj.id ? obj.id : obj.contractId;
    let endURL = !!end ? end : "";

    if (!!url) {
      this.router.navigateByUrl(url + id + endURL);
    }
  }
}
