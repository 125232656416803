import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConstantService {
  private documentTypes = new Subject<documentTypesEnum[]>();
  //private documentTypes: documentTypesEnum[];

  constructor() {}

  getDocumentTypes(): Observable<documentTypesEnum[]> {
    {
      return this.documentTypes.asObservable();
    }
  }
}

enum documentTypesEnum {
  DNI = 1,
  CUIL = 2,
  CUIT = 3,
}
