import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-afip-receipt',
  templateUrl: './afip-receipt.component.html',
  styleUrls: ['./afip-receipt.component.css'],
})
export class AfipReceiptComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
