
<div class="title">
  <h1 *ngIf="edit">Editar Inmueble</h1>
  <h1 *ngIf="!edit">Añadir Inmueble</h1>
  <div *ngIf="contractMode">
    <button class="btn btn-outline-secondary buttons-controls" style="grid-area: search;  margin-right: 5px;" (click)="deleteFormBuilding()">
      <fa-icon [icon]="iconTrash" size="1x"> </fa-icon>
    </button>
    <button class="btn btn-outline-secondary buttons-controls" style="grid-area: search;" (click)="openSearch(search)">
      <fa-icon [icon]="iconSearch" size="1x"> </fa-icon>
    </button>
  </div>
</div>

<form [formGroup]="buildingFormGroup" (ngSubmit)="onSubmit()">
  <div class="form-building form-group">
    <div class="flex-column">      
      <div class="flex-column">
        <label>Calle y altura</label>
        <input placeholder="Avenida Santa Fe 1234" type="text" formControlName="streetAddress" class="form-control"
        [ngClass]="{'borderRed': validate('streetAddress')}"
        (change)="contractModeChange()">
        <span *ngIf="validate('streetAddress')"
              style="color: var(--border-red); font-size: 90%;">Dato Faltante
        </span>
      </div>
    </div>

    <div class="flex-row">
      <div class="flex-column">
        <label>Unidad</label>
        <input placeholder="4°B&emsp;•&emsp;Décimo 12" type="text" formControlName="identification" class="form-control" [ngClass]="{'borderRed': validate('identification')}"
        (change)="contractModeChange()">
        <span *ngIf="validate('identification')"
          style="color: var(--border-red); font-size: 90%;">Dato Faltante
        </span>
      </div>
      <div style="margin-left: 10px;" class="flex-column">
        <label>Tipo de ambiente</label>
        <select class="form-control form-select"  id="propertyTypeId" formControlName="propertyTypeId">
          <option  *ngFor="let propertyType of propertyTypes" [value]="propertyType.value">
            {{propertyType.text}}
          </option>
        </select>
      </div>
    </div>

    <div class="flex-row">
      <div class="flex-column">
        <label>Ambientes</label>
        <input placeholder="4" type="number" formControlName="rooms" class="form-control"
        (change)="contractModeChange()" [ngClass]="{'borderRed': validate('rooms')}">
        <span *ngIf="validate('rooms')"
              style="color: var(--border-red); font-size: 90%;">Dato Inválido
        </span>
      </div>
      <div style="margin-left: 10px;" class="flex-column">
        <label>Metros Cuadrados</label>
        <input placeholder="82m2" type="text" formControlName="surface" class="form-control"
        (change)="contractModeChange()">
      </div>
    </div>

   

    <div class="flex-row">
      <div style="margin-right: 10px;" class="flex-column">
        <label>Ciudad</label>
        <input placeholder="CABA" type="text" formControlName="city" class="form-control"
        (change)="contractModeChange()">
      </div>
      <div class="flex-column">
        <label>Estado</label>
        <input placeholder="CABA" type="text" formControlName="state" class="form-control"
        (change)="contractModeChange()">
      </div>
      <div style="margin-left: 10px;" class="flex-column">
        <label>Codigo postal</label>
        <input placeholder="1234" type="text" formControlName="zipCode" class="form-control"
        (change)="contractModeChange()">
      </div>
    </div>

    <div class="flex-row">
      <div class="flex-column">
        <label>Descripcion</label> 
        <textarea type="text" cols="50" formControlName="description" class="form-control"
            (change)="contractModeChange()"></textarea>
        <!-- <input placeholder="" type="text" formControlName="description" class="form-control"
        (change)="contractModeChange()"> -->
      </div>
      <!-- <div style="margin-left: 10px;" class="flex-column">
        <label>Area</label>
        <input placeholder="B" type="text" formControlName="area" class="form-control"
        (change)="contractModeChange()">
      </div>-->
    </div>
  </div>
  <div *ngIf="!contractMode" class="buttons-submitting" style="display: flex; justify-content: space-between;">
    <button type="button" class="btn btn-danger" [disabled]="submitted" (click)="cancel()">Cancelar</button>
    <button class="btn btn-primary" [disabled]="submitted" *ngIf="!edit">Crear</button>
    <button class="btn btn-primary" *ngIf="edit">Editar</button>
  </div>
</form>

<ng-template #search>
  <app-entities-for-contract-search [searchType]="'building'" (selectEntity)="selectedSearchedElement($event)" >
  </app-entities-for-contract-search>
</ng-template>