import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  faCalendar,
  faSearch,
  faPlus,
  faEye,
  faTrash,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contract-card',
  templateUrl: './contract-card.component.html',
  styleUrls: ['./contract-card.component.css'],
})
export class ContractCardComponent implements OnInit {
  iconTrash = faTrash;
  iconEdit = faPen;
  display: boolean = true;
  edit: boolean = false;

  @Input() cardData;
  @Input() cardTitle: string;
  @Output() openEditForm = new EventEmitter();
  @Output() delEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  formOpen() {
    this.openEditForm.emit(this.cardData);
  }

  showDelete(value) {
    this.display = value;
  }

  deleteEvent() {
    this.delEvent.emit();
  }
}
