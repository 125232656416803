import { Component, OnInit, ViewChild } from '@angular/core';
import { InstallmentService } from '../../services/installment.service';

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ChartComponent,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexPlotOptions,
  ApexLegend,
  ApexTooltip,
} from 'ng-apexcharts';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { ContractService } from '../../services/contract.service';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  title: ApexTitleSubtitle;
  responsive: ApexResponsive[];
  dataLabels: ApexDataLabels;
  labels: any;
  colors: any;
  plotOptions: ApexPlotOptions;
  legend: ApexLegend;
  tooltip: ApexTooltip;
};

@Component({
  selector: 'app-rents-pie-chart',
  templateUrl: './rents-pie-chart.component.html',
  styleUrls: ['./rents-pie-chart.component.css'],
})
export class RentsPieChartComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  isLoaded: boolean = false;
  isEmpty: boolean = false;

  iconQuestion = faQuestionCircle;

  constructor(private installmentService: InstallmentService) {
    this.installmentService.getInstallmentsQuantity().subscribe(
      (result: any) => {
        let string =
          result.owedInstallments != 0
            ? `(Pagos Parciales) ${result.owedInstallments}`
            : '';
        this.chartOptions = {
          title: {
            align: 'left',
            margin: 20,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: '2rem',
              fontWeight: 'bold',
            },
          },
          series: [
            result.paidInstallments,
            result.unpaidInstallments + result.owedInstallments,
          ],
          colors: ['#33b5e5', '#Fd6b08'],
          chart: {
            offsetX: -10,
            width: 500,
            height: 400,
            type: 'donut',
          },
          labels: [
            `Cuotas Cobradas: ${result.paidInstallments}`,
            `Cuotas Adeudadas: ${
              result.unpaidInstallments + result.owedInstallments
            }  ${string}`,
          ],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
          plotOptions: {
            pie: {
              expandOnClick: false,
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    showAlways: true,
                    fontSize: '2.5rem',
                    fontWeight: 'bold',
                  },
                  value: {
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                  },
                },
              },
            },
          },
          legend: {
            formatter: function (label, opts) {
              return label;
            },
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '15px',
          },
          tooltip: {
            y: {
              formatter: function(val) {
                return ''
              },
              title: {
                formatter: function (seriesName) {
                  return seriesName
                }
              }
            }
          }
        };
        this.isLoaded = true;
      },
      (error) => {
        if (error.status == 404) {
          this.isLoaded = true;
          this.isEmpty = true;
        }
      },
    );
  }

  ngOnInit() {}
}
