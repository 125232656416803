<div class="month-picker">
	<div class="card">
		<div class="card-header">
			<div class="d-flex align-items-center">
				
				<button *ngIf="!isShowYears" class="btn btn-light mr-auto" (click)="decrementMonth()" placement="bottom" ngbTooltip="{{'monthPicker.decrementMonths' | translate}}">					
					<fa-icon [icon]="ChevronLeft" size="1x"></fa-icon>
				</button>
				<button *ngIf="isShowYears" class="btn btn-light mr-auto" (click)="decrementYear()" placement="bottom" ngbTooltip="{{'monthPicker.decrementYears' | translate}}">					
					<fa-icon [icon]="ChevronLeft" size="1x"></fa-icon>
				</button>
									
				<button class="btn btn-light" style="margin: 0 6px 0 6px" (click)="toggleShowMonths('month');" placement="bottom" ngbTooltip="{{'monthPicker.changeMonth' | translate}}">
					{{model.selectedMonthText}} 
				</button>
				-
				<button class="btn btn-light" style="margin: 0 6px 0 6px" (click)="toggleShowYears('year');" placement="bottom" ngbTooltip="{{'monthPicker.changeYear' | translate}}">
					{{model.selectedYearText}} 
				</button>
				
				<button *ngIf="!isShowYears" class="btn btn-light ml-auto" (click)="incrementMonth()"placement="bottom" ngbTooltip="{{'monthPicker.incrementMonths' | translate}}">					
					<fa-icon [icon]="ChevronRight" size="1x"></fa-icon>
				</button>
				<button *ngIf="isShowYears" class="btn btn-light ml-auto" (click)="incrementYear()"placement="bottom" ngbTooltip="{{'monthPicker.incrementYears' | translate}}">					
					<fa-icon [icon]="ChevronRight" size="1x"></fa-icon>
				</button>
				
			</div> 
		</div>

		<div [ngbCollapse]="isCollapsed" class="card-body">
			<div class="month-picker-body">

				<div class="row" *ngIf="isShowMonths">
					<div class="col col-4" *ngFor="let month of model.months; let i = index">
						<div class="month-picker-cell">
							<button class="btn btn-block" 
								[ngClass]="{'btn-light': isSelectedMonth(month)}"
								[disabled]="isDisabled(month)"
								(click)="selectMonth(month);collapse()">
								<small>{{month}}</small>
							</button>
						</div>
					</div>
				</div>

				<div class="row" *ngIf="isShowYears">
					<div class="col col-4" *ngFor="let year of years; let i = index">
						<div class="month-picker-cell">
							<button class="btn btn-block" 
								[ngClass]="{'btn-light': isSelectedYear(year)}"
								[disabled]="isDisabledYear(year)"
								(click)="selectYear(year);collapse()">
								<small>{{year}}</small>
							</button>
						</div>
					</div>
				</div>

			</div>
		</div>

	</div>
</div>