<div class="phonebook-container">
    <div class="flex-row">
        <form [formGroup]="phoneBookDataFormGroup" class="flex-row">
            <input formControlName="area" name="area" class="form-control area-code" placeholder="011">   
            <input formControlName="number" name="phoneNumber" class="form-control phone-number" placeholder="0000-0000">
            <input formControlName="description" name="description" type="text"  class="form-control description" placeholder="Casa/Trabajo">
        </form>
        <button (click)="addPhoneCard()" type="button" class="btn btn-primary"> + </button>   
    </div>
    <table *ngIf="phoneCards?.length > 0" class="table table-sm table-bordered table-hover phone-table">
        <thead class="thead-light">
            <tr>
                <th> # </th>
                <th> Descripción </th>
                <th> Numero </th>
                <th> Acciones </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let phone of phoneCards; let ind = index">
                <td> {{ind + 1}} </td>
                <td>{{ phone.description }}</td>
                <td><app-whatsapp-chat [phoneNumber] = "phone"></app-whatsapp-chat></td>
                <td>
                <fa-icon class="hoverblue icons-phone" (click)="editPhoneContact(phone, ind)" [icon]="iconEdit" size="1x" ></fa-icon>
                <fa-icon class="hoverred icons-phone" (click)="deletePhoneContact(ind)" [icon]="iconTrash" size="1x" ></fa-icon>
                </td>
            </tr>
        </tbody>
    </table>

    </div>
    
    