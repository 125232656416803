<app-custom-stepper [linear]="true" (selectionChange)="changeStep($event)" [stepTitles]="stepNames">
    <cdk-step [completed]="selectedInstallments().length > 0" (interacted)="validateInstallments($event)">
        <ng-container *ngTemplateOutlet="installmentsStep"></ng-container>
    </cdk-step>
    <cdk-step [completed]="!detailFormGroup.invalid" (interacted)="validateDetails($event)">
        <ng-container *ngTemplateOutlet="detailsStep"></ng-container>
    </cdk-step>
    <cdk-step [optional]="true">
        <ng-container *ngTemplateOutlet="additionalsStep"></ng-container>
    </cdk-step>
    <cdk-step [optional]="true">
        <ng-container *ngTemplateOutlet="requirementsStep"></ng-container>
    </cdk-step>
    <cdk-step [optional]="true">
        <ng-container *ngTemplateOutlet="retentionStep"></ng-container>
    </cdk-step>
</app-custom-stepper>

<ng-template #installmentsStep>
    <div class="flex-row" style="height: 73vh;">
        <div class="flex-column centered" style="width: 70%; padding: 4vh 0;">
            <div class="flex-column" style="max-height: 71vh; overflow-y: scroll; align-items: center; width: 100%;">
                <ng-container *ngFor="let insCheckbox of installments(); let ind = index">
                    <div class="flex-row"
                        style="justify-content: space-between; width: 40vw; padding: 0.1rem 0.1rem 0.1rem 2vw;"
                        *ngIf="insCheckbox.installment.installmentStatusId != 1">
                        <span style="width: 33%;">
                            <input type="checkbox" [checked]="installments()[ind].isSelected"
                                (change)="updateInstallments(ind)">
                            {{ insCheckbox.installment.description | titlecase }}
                        </span>
                        <div class="flex-row" style="width: 33%; justify-content: center;">
                            <span>
                                {{ insCheckbox.installment.finalAmountToPay | currency:
                                (contract.contractCurrency === 2 ? 'USD' : 'ARS') : (contract.contractCurrency === 1 ?
                                'symbol-narrow'
                                :
                                'symbol') : '1.0-0' }}
                            </span>
                        </div>
                        <div class="flex-row" style="width: 33%; justify-content: flex-end; padding-right: 1vw;">
                            <span class="badge text-bg-secondary"
                                *ngIf="insCheckbox.installment.installmentStatusId == 4 && today <= insCheckbox.installment.expiryDate">
                                Pendiente
                            </span>
                            <span class="badge text-bg-warning"
                                *ngIf="insCheckbox.installment.installmentStatusId == 3">
                                Parcial
                            </span>
                            <span class="badge text-bg-danger"
                                *ngIf="insCheckbox.installment.installmentStatusId == 2 || (insCheckbox.installment.installmentStatusId == 4 && today > insCheckbox.installment.expiryDate)">
                                Vencido
                            </span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="flex-column" style="width: 2px; background-color: #e0e0e0;"></div>
        <div class="flex-column" style="width: 30%; padding: 1rem; justify-content: center;">
            <ng-container *ngTemplateOutlet="summary"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #detailsStep>
    <div class="flex-row" style="height: 73vh;">
        <div class="flex-column centered" style="width: 70%;">
            <form class="flex-column" style="row-gap: 2rem; height: 35.24vh;" [formGroup]="detailFormGroup">
                <h5 class="flex-row">Forma de pago:</h5>
                <div class="flex-row" style="column-gap: 2rem;">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="paymentType" id="cash" [value]="2"
                            (change)="changePaymentType(2)" formControlName="paymentType">
                        <label class="form-check-label" for="cash">
                            Pago en inmobiliaria
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="paymentType" id="transfer" [value]="1"
                            (change)="changePaymentType(1)" formControlName="paymentType">
                        <label class="form-check-label" for="transfer">
                            Pago a cuenta de terceros
                        </label>
                    </div>
                </div>
                <div class="flex-row" style="flex-wrap: nowrap; align-items: baseline; justify-content: space-between;">
                    <span style="white-space: nowrap;">Fecha de pago (opcional):</span>
                    <div class="input-group" style="margin-bottom: 1vh; width: fit-content;">
                        <input class="form-control not-allowed" placeholder="AAAA-MM-DD"
                            (ngModelChange)="formatDate($event)" (click)="formatDate()" name="dp"
                            formControlName="paymentDate" ngbDatepicker #c="ngbDatepicker"
                            [minDate]="{year: 1999, month: 11, day: 31}" readonly>
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" (click)="c.toggle()" type="button">
                                <fa-icon [icon]="iconCalendar" size="1x"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="flex-row" style="flex-wrap: nowrap; align-items: baseline; column-gap: 1rem;">
                    <span style="white-space: nowrap;">Abonado por:</span>
                    <select class="form-control form-select" style="min-width: 13vw;" formControlName="tenant">
                        <option [ngValue]="undefined" disabled>Seleccione un inquilino</option>
                        <option [ngValue]="tenant" *ngFor="let tenant of contract.tenants">
                            {{tenant.user.person.fullName}}</option>
                    </select>
                </div>
                <div class="flex-row" style="flex-wrap: nowrap; align-items: baseline; column-gap: 1rem;"
                    *ngIf="detailFormGroup.value.paymentType == 1">
                    <span style="white-space: nowrap;">Recibido por:</span>
                    <select class="form-control form-select" style="min-width: 13vw;" formControlName="owner">
                        <option [ngValue]="undefined" disabled>Seleccione un propietario</option>
                        <option [ngValue]="owner" *ngFor="let owner of contract.owners"> {{owner.user.person.fullName}}
                        </option>
                    </select>
                </div>
            </form>
        </div>
        <div class="flex-column" style="width: 2px; background-color: #e0e0e0;"></div>
        <div class="flex-column" style="width: 30%; padding: 1rem; justify-content: center;">
            <ng-container *ngTemplateOutlet="summary"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #additionalsStep>
    <div class="flex-row" style="height: 73vh;">
        <div class="flex-column centered" style="width: 70%;">
            <loading-spinner [liteMode]="true" [evaluation]="!isLoading">
                <form class="flex-column"
                    style="max-height: 71vh; overflow-y: scroll; justify-content: center; align-items: flex-start; row-gap: 2rem; height: 90%;"
                    [formGroup]="additionalChargesFormGroup">
                    <div class="flex-row"
                        style="justify-content: space-between; width: 100%; height: fit-content; padding: 2px; align-items: baseline;">
                        <h5>¿Computar punitivos?</h5>
                        <div class="btn-group toggle-btn" style="height: fit-content; margin-left: 1vw; height: 100%;">
                            <label class="left" (click)="payPunitives.set(true)"
                                [ngClass]="{'checked': payPunitives(), 'not-checked': !payPunitives()}">Sí</label>
                            <label class="right" (click)="payPunitives.set(false)"
                                [ngClass]="{'checked': !payPunitives(), 'not-checked': payPunitives()}">No</label>
                        </div>
                    </div>
                    <h5 style="text-decoration: underline; margin-top: 1vh;">Cargos adicionales del mes de {{
                        selectedInstallments().length != 1 ? '' : selectedInstallments()[0].description | titlecase}}
                        <select class="month-select" formControlName="monthId"
                            *ngIf="selectedInstallments().length != 1"
                            (ngModelChange)="installmentIdForAdditionalCharges.set($event)">
                            <option *ngFor="let ins of selectedInstallments();" [ngValue]="ins.id">{{ins.description |
                                uppercase}}</option>
                        </select>
                    </h5>
                    <div class="flex-column"
                        style="width: 100%; min-height: 15vh; max-height: 20vh; overflow-y: scroll; margin-top: -3vh;">
                        <ng-container *ngFor="let ac of installmentForAdditionalCharges()?.additionalCharges">
                            <div class="flex-row" style="justify-content: space-between;"
                                *ngIf="!ac.punitive || payPunitives()">
                                <span [ngStyle]=" ac.sign ? {'color': 'green'} : {'color': 'red'}">
                                    {{ ac.sign ? '+' : '-'}} {{ ac.amount | currency:
                                    (contract.contractCurrency === 2 ? 'USD' : 'ARS') : (contract.contractCurrency === 1
                                    ? 'symbol-narrow'
                                    :
                                    'symbol') : '1.0-0' }}
                                </span>
                                <span>
                                    {{ ac.detail }}
                                    <fa-icon class="trash" [icon]="iconTrash" (click)="deleteAC(ac)"
                                        *ngIf="!ac.punitive"></fa-icon>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                    <div class="flex-row" style="column-gap: 1rem; width: 100%;">
                        <select class="month-select" formControlName="sign">
                            <option [ngValue]="true">RECARGO</option>
                            <option [ngValue]="false">DEDUCCION</option>
                        </select>
                        <input class="form-control" type="number" formControlName="amount" placeholder="$">
                    </div>
                    <textarea class="form-control" cols="10" rows="2" type="text" formControlName="detail"
                        placeholder="Escriba la razón del cargo"></textarea>
                    <div class="flex-row" style="justify-content: space-between; width: 100%;">
                        <button class="btn btn-outline-danger" type="button" (click)="resetCharges()">Cancelar</button>
                        <button class="btn btn-primary" type="button" (click)="createCharges()">Agregar</button>
                    </div>
                </form>
            </loading-spinner>
        </div>
        <div class="flex-column" style="width: 2px; background-color: #e0e0e0;"></div>
        <div class="flex-column" style="width: 30%; padding: 1rem; justify-content: center;">
            <ng-container *ngTemplateOutlet="summary"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #requirementsStep>
    <div class="flex-row" style="height: 73vh;">
        <div class="flex-column centered" style="width: 70%; padding: 4vh 0;">
            <loading-spinner [liteMode]="true" [evaluation]="!isLoading">
                <div class="flex-column"
                    style="max-height: 71vh; overflow-y: scroll; align-items: center; width: 100%;">
                    <div class="flex-column" style="min-width: 40vw; max-width: 55vw; width: 100%; gap: 0.5rem;">
                        <h5 style="text-decoration: underline;">Obligaciones pendientes</h5>
                        <ng-container *ngFor="let row of requirements">
                            <div class="flex-row" style="width: 100%; justify-content: space-between;"
                                *ngIf="row.hasUnpaidRequirements">
                                <span style="white-space: nowrap; min-width: 4vw;">{{ row.month | titlecase }}</span>
                                <div class="flex-row" style="gap: 0.5rem;">
                                    <span class="badge hover-badge" *ngFor="let req of row.requirements"
                                        (click)="req.status == 'retenido' ? null : selectedRequirement.set({ data: row, req: req });"
                                        [ngClass]="{'text-bg-success': req.status == 'pago', 'text-bg-warning': req.status == 'parcial', 'text-bg-info': req.status == 'retenido',
                                        'text-bg-danger': req.status == 'vencido', 'text-bg-secondary': req.status == 'pendiente'}">
                                        <fa-icon style="margin-right: 5px;"
                                            [icon]="iconService.getIcon(req.icon)"></fa-icon>
                                        {{ req.name }}
                                    </span>
                                </div>
                            </div>
                        </ng-container>
                        <form class="flex-column footer" [formGroup]="requirementFormGroup"
                            *ngIf="selectedRequirement() !== null">
                            <div class="flex-row" style="justify-content: center;">
                                <h5 style="text-decoration: underline;">{{ selectedRequirement().data.month | titlecase
                                    }} - {{ selectedRequirement().req.name }}</h5>
                            </div>
                            <div class="flex-row" style="justify-content: space-between; width: 100%;">
                                <select class="month-select" formControlName="installmentStatusId">
                                    <option [value]="1">Pago completo</option>
                                    <option [value]="3">Pago parcial</option>
                                </select>
                                <input class="form-control" style="width: 7.2rem;" type="number"
                                    formControlName="amount" placeholder="$ Monto">
                                <div class="flex-row"
                                    style="flex-wrap: nowrap; align-items: baseline; column-gap: 1rem; width: fit-content;">
                                    <span style="white-space: nowrap;">Fecha:</span>
                                    <div class="input-group" style="flex-wrap: nowrap;">
                                        <input class="form-control not-allowed" placeholder="AAAA-MM-DD"
                                            formControlName="paymentDate" name="dp" ngbDatepicker #d="ngbDatepicker"
                                            [minDate]="{year: 1999, month: 11, day: 31}" readonly>
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" (click)="d.toggle()"
                                                type="button">
                                                <fa-icon [icon]="iconCalendar" size="1x"></fa-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-row"
                                style="justify-content: space-between; width: 100%; flex-wrap: nowrap;">
                                <input class="form-control" style="width: 65%;" type="text" formControlName="details"
                                    placeholder="Detalle de operacion">
                                <span>
                                    ¿Se retiene?
                                    <div class="btn-group toggle-btn"
                                        style="height: fit-content; margin-left: 1vw; height: 100%;">
                                        <label class="left" style="height: 2rem;" (click)="isRetention.set(true)"
                                            [ngClass]="{'checked': isRetention(), 'not-checked': !isRetention()}">Sí</label>
                                        <label class="right" style="height: 2rem;" (click)="isRetention.set(false)"
                                            [ngClass]="{'checked': !isRetention(), 'not-checked': isRetention()}">No</label>
                                    </div>
                                </span>
                            </div>
                            <div class="flex-row" style="justify-content: space-between; width: 100%;">
                                <button class="btn btn-outline-danger" type="button"
                                    (click)="selectedRequirement.set(null)">Cancelar</button>
                                <button
                                    *ngIf="selectedRequirement().req.status == 'pago' || selectedRequirement().req.status == 'parcial'"
                                    class="btn btn-danger" type="button" (click)="deleteRequirementForPayment()">
                                    Eliminar
                                </button>
                                <button class="btn" type="button"
                                    (click)="isRetention() ? addRetention() : addRequirementToPayment()"
                                    [ngClass]="{'btn-primary': !isRetention(), 'btn-info': isRetention()}">
                                    {{ isRetention() ? 'Retener obligación' : 'Saldar obligación' }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </loading-spinner>
        </div>
        <div class="flex-column" style="width: 2px; background-color: #e0e0e0;"></div>
        <div class="flex-column" style="width: 30%; padding: 1rem; justify-content: center;">
            <ng-container *ngTemplateOutlet="summary"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #retentionStep>
    <div class="flex-row" style="height: 73vh;">
        <div class="flex-column centered" style="width: 70%; padding: 4vh 0;">
            <div class="flex-column"
                style="min-height: 66vh; overflow-y: scroll; align-items: center; width: 100%; row-gap: 2rem;">
                <table class="table table-striped rounded" style="background-color: white;"
                    *ngIf="retainedRequirements().length > 0 || newRetentions().length > 0">
                    <thead>
                        <tr>
                            <th>Mes</th>
                            <th>Servicio</th>
                            <th>Monto</th>
                            <th>Fecha</th>
                            <th>Detalle</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let req of retainedRequirements()">
                            <td>{{ req.ins.month | titlecase }}</td>
                            <td>{{ req.req.name }}</td>
                            <td>{{ req.insReq.amount ? '$' : '' }}{{ req.insReq.amount || '-' }}</td>
                            <td>{{ req.insReq.paymentDate ? '' : '-' }}{{ req.insReq.paymentDate | date:'shortDate' }}
                            </td>
                            <td>{{ req.insReq.details || '-' }}</td>
                            <td>
                                <fa-icon class="trash" [icon]="iconTrash"
                                    (click)="removeRetainedRequirement(req)"></fa-icon>
                            </td>
                        </tr>
                        <tr *ngFor="let ret of newRetentions()">
                            <td>-</td>
                            <td>Extra</td>
                            <td>{{ ret.amount | currency:
                                (contract.contractCurrency === 2 ? 'USD' : 'ARS') : (contract.contractCurrency === 1 ?
                                'symbol-narrow'
                                :
                                'symbol') : '1.0-0' }}</td>
                            <td>-</td>
                            <td>{{ ret.detail }}</td>
                            <td>
                                <fa-icon class="trash" [icon]="iconTrash" (click)="deleteRetention(ret)"></fa-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <form class="flex-row" style="width: 100%; gap: 1rem;" [formGroup]="retentionFormGroup">
                    <div class="flex-column" style="width: 20%;">
                        <input class="form-control" type="number" formControlName="amount" placeholder="$">
                    </div>
                    <div class="flex-column" style="width: 60%;">
                        <input class="form-control" type="text" formControlName="detail" placeholder="Detalle">
                    </div>
                    <div class="flex-column" style="width: 20%; justify-content: center;">
                        <button class="btn btn-primary" type="button" (click)="createRetentions()">Agregar</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="flex-column" style="width: 2px; background-color: #e0e0e0;"></div>
        <div class="flex-column" style="width: 30%; padding: 1rem; justify-content: center;">
            <ng-container *ngTemplateOutlet="summary"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #summary>
    <h5 style="text-decoration: underline;">Pago {{ detailFormGroup.value.paymentType == 1? 'a cuenta de terceros' : 'en
        inmobiliaria' }}:</h5>
    <div class="flex-row" style="justify-content: space-between;" *ngIf="formattedPaymentDate">
        <span>Fecha de pago: </span>
        <span>{{formattedPaymentDate | date: 'shortDate'}}</span>
    </div>
    <div class="flex-row" style="justify-content: space-between;">
        <span>Abonante: </span>
        <span>{{detailFormGroup.value.tenant?.user.person.fullName || '-' }}</span>
    </div>
    <div class="flex-row" style="justify-content: space-between;" *ngIf="detailFormGroup.value.paymentType == 1">
        <span>Receptor: </span>
        <span>{{detailFormGroup.value.owner?.user.person.fullName || '-' }}</span>
    </div>
    <div class="flex-row" style="justify-content: space-between;">
        <span>Saldo a Favor:</span>
        <span>{{contract?.tenantAmountInFavor || 0 | currency:
            (contract.contractCurrency === 2 ? 'USD' : 'ARS') : (contract.contractCurrency === 1 ? 'symbol-narrow'
            :
            'symbol') : '1.0-0'}}</span>
    </div>
    <div class="flex-row" style="justify-content: space-between;">
        <span>Cuotas: </span>
        <span>{{installmentAmount() || 0 | currency:
            (contract.contractCurrency === 2 ? 'USD' : 'ARS') : (contract.contractCurrency === 1 ? 'symbol-narrow'
            :
            'symbol') : '1.0-0'}}</span>
    </div>
    <div class="flex-row" style="justify-content: space-between;">
        <span>Adicionales: </span>
        <span>{{additionalChargesAmount() | currency:
            (contract.contractCurrency === 2 ? 'USD' : 'ARS') : (contract.contractCurrency === 1 ? 'symbol-narrow'
            :
            'symbol') : '1.0-0'}}</span>
    </div>
    <div class="flex-row" style="justify-content: space-between;">
        <span>Punitivos: </span>
        <span>{{punitiveAmount() | currency:
            (contract.contractCurrency === 2 ? 'USD' : 'ARS') : (contract.contractCurrency === 1 ? 'symbol-narrow'
            :
            'symbol') : '1.0-0'}}</span>
    </div>
    <div class="flex-row" style="justify-content: space-between;">
        <span>Retenido: </span>
        <span>{{retentionAmount() | currency:
            (contract.contractCurrency === 2 ? 'USD' : 'ARS') : (contract.contractCurrency === 1 ? 'symbol-narrow'
            :
            'symbol') : '1.0-0'}}</span>
    </div>
    <div class="flex-row" style="justify-content: space-between;">
        <span>Total a Pagar: </span>
        <span>{{totalAmount() || 0 | currency:
            (contract.contractCurrency === 2 ? 'USD' : 'ARS') : (contract.contractCurrency === 1 ? 'symbol-narrow'
            :
            'symbol') : '1.0-0'}}</span>
    </div>
    <div class="flex-row" style="justify-content: space-between; margin-top: 1vh;">
        <input class="form-control" type="number" placeholder="$" style="max-width: 10vw; margin-right: 1vw;"
            [ngModel]="paymentAmount()" (ngModelChange)="validatePayment($event)" appLimitDirective>
        <button class="btn btn-success" type="button" (click)="pay()"
            [disabled]="!(selectedInstallments().length > 0)|| isLoading">
            <fa-icon style="margin-right: 5px;" [icon]="iconSign"></fa-icon>Pago
        </button>
    </div>
</ng-template>