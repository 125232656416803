import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-list-picker',
  templateUrl: './list-picker.component.html',
  styleUrls: ['./list-picker.component.css'],
})
export class ListPickerComponent implements OnInit {
  page: number;
  public selectedElement: any;

  @Input() width?: string;
  @Input() height?: string;
  @Input() listPropertyData?: any[];
  @Input() itemsPerPage?: number;
  @Input() totalItems?: number;
  @Input() condominiumId?: number;
  @Input() sizeOfTriangle?: string;
  @Input() sizeOfTrianglePoint?: string;
  @Input() service?: any;

  @Output() nextPage = new EventEmitter();
  @Output() elementWasSelected = new EventEmitter();

  solidTransparent: string;
  solidTransparent2: string;
  listProperties: any[];
  listPropertyDataMock: any[];

  constructor() {
    this.page = 1;
    this.itemsPerPage = 5;
    this.listPropertyDataMock = [
      { id: '1', description: 'mock1' },
      { id: '2', description: 'mock2' },
      { id: '3', description: 'mock3' },
      { id: '4', description: 'mock4' },
      { id: '5', description: 'mock5' },
      { id: '6', description: 'mock6' },
      { id: '7', description: 'mock7' },
      { id: '8', description: 'mock8' },
      { id: '9', description: 'mock9' },
      { id: '10', description: 'mock10' },
    ];
    // this.totalItems = this.listPropertyData.length;
    this.totalItems = this.listPropertyDataMock.length;
    this.width = '350px';
    this.height = '30px';
    this.sizeOfTriangle = '5px';
    this.sizeOfTrianglePoint = '10px';

    this.solidTransparent = `${this.sizeOfTriangle} solid transparent`;
    this.solidTransparent2 = `${this.sizeOfTrianglePoint} solid #ffa162`;
    this.condominiumId = 1;
  }

  ngOnInit(): void {
    // if (this.service) {
    //   this.service.getPropertyPaginated(this.condominiumId, this.page, this.itemsPerPage).subscribe(response => {
    //     if (response){
    //       this.listPropertyData = response.result;
    //       this.listProperties = this.listPropertyData;
    //       this.totalItems = this.listPropertyData.length;
    //     }
    //   });
    // } // UNCOMMENT FOR MOCK
    this.listPropertyData = this.listPropertyDataMock;
    const maxItemIndex = this.itemsPerPage * this.page;
    this.listProperties = this.listPropertyDataMock.slice(
      maxItemIndex - this.itemsPerPage,
      maxItemIndex,
    );
    this.totalItems = this.listPropertyData.length;
  }

  pageChanged(event) {
    this.page = event;
    const maxItemIndex = this.itemsPerPage * this.page;
    this.listProperties = this.listPropertyData.slice(
      maxItemIndex - this.itemsPerPage,
      maxItemIndex,
    );
  }

  selectRow(index) {
    this.selectedElement = this.listPropertyData.filter(
      (property) => property.id === this.listProperties[index].id,
    )[0];
    this.elementWasSelected.emit();
  }

  selected(itemId) {
    if (this.selectedElement) {
      return this.selectedElement.id === itemId;
    } else {
      return false;
    }
  }
}
