<ng-container *ngIf="evaluation else loading">
  <div class="inner">
    <ng-content></ng-content>
  </div>
  <div *ngIf="msg != null">

  </div>
</ng-container>

<ng-template #loading>
  <div *ngIf="!liteMode" class="spinner-container">
    <div [className]="isblue ? 'spinner-grow baup-blue' : 'spinner-grow baup-orange'" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <span class="msg" *ngIf="msg">{{msg}}</span>
  </div>

  <div *ngIf="liteMode" class="lite-mode">
    <div [className]="isblue ? 'spinner-grow baup-blue' : 'spinner-grow baup-orange'" role="status">
    </div>
    <span class="msg" *ngIf="msg">{{msg}}</span>
  </div>
  
</ng-template>