<div class="title">
    <h1 *ngIf="!edit">Añadir Agencia</h1>
    <h1 *ngIf="edit">Editar Agencia</h1>
</div>
<form [formGroup]="agencyDataFormGroup" (ngSubmit)="onSubmit()">
    <div class="form-building form-group">
        <div class="container">
            <div class="row">
                <hr>
                <h3>Datos de la Agencia</h3>
            </div>
            <div class="row">
                <div class="col-sm">
                    <label>Nombre</label>
                    <input placeholder="Inmobiliaria" type="text" formControlName="name" class="form-control"
                        [ngClass]="{'borderRed': validate('name')}">
                    <span *ngIf="validate('name') && !agencyDataFormGroup.value.name"
                        style="color: var(--border-red); font-size: 90%;">
                        Dato Faltante
                    </span>
                </div>
            </div>
            <hr>
            <div class="row">
                <h3> Vivienda</h3>
            </div>
            <div class="row">
                <div class="col-sm">
                    <label>Calle y altura</label>
                    <input placeholder="Avenida Santa Fe 1234" type="text" formControlName="streetAddress"
                        class="form-control">
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <label>Provincia</label>
                    <input placeholder="CABA" type="text" formControlName="state" class="form-control">
                </div>
                <div class="col-sm">
                    <label>Ciudad</label>
                    <input placeholder="CABA" type="text" formControlName="city" class="form-control">
                </div>
                <div class="col-sm">
                    <label>Codigo Postal</label>
                    <input placeholder="1234" type="text" formControlName="zipCode" class="form-control">
                </div>
            </div>
            <hr>
            <div class="row">
                <h3> Casillas de mail</h3>
            </div>
            <div class="row">
                <div class="col-sm">
                    <label>Email de contacto</label>
                    <input placeholder="info@agency.com" type="email" formControlName="email" class="form-control">
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-sm">
                    <label>Email interno</label>
                    <input placeholder="no-reply@agency.com" type="email" formControlName="defaultEmail" class="form-control"
                    [ngClass]="{'borderRed': validate('defaultEmail')}">
                    <span *ngIf="validate('defaultEmail') && !agencyDataFormGroup.value.defaultEmail" 
                        style="color: var(--border-red); font-size: 90%;">
                        Dato Faltante
                    </span>
                </div>
            </div> -->
        </div>
    </div>
    <div class="buttons-submitting" style="display: flex; justify-content: space-between;">
        <button type="button" class="btn btn-danger" (click)="cancel()">Cancelar</button>
        <button class="btn btn-primary" *ngIf="!edit">Crear</button>
        <button class="btn btn-primary" *ngIf="edit">Editar</button>
    </div>
</form>