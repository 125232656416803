<form [formGroup]="installmentFormGroup" (ngSubmit)="onSubmit()">
  <div class="title">
    <h1>Editar Cuota</h1>
  </div>
  <hr>
  <div class="form-building form-group">
    <div class="form-data">
      <div class="main-data">
        <label>Monto</label>
        <input type="number" formControlName="amount" class="form-control"
        [ngClass]="{'borderRed': installmentFormGroup.invalid && (installmentFormGroup.dirty || installmentFormGroup.touched) 
                    && !installmentFormGroup.value.amount}">                                    
      </div>    
    </div>
  </div>  

  <div class="buttons-submitting" style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; margin-top: 15px;">
    <button class="btn btn-primary">Editar</button>
  </div>

</form>   