import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.css'],
})
export class ModalContainerComponent implements OnInit {
  @Input() modalContainer: TemplateRef<any>;
  @Input() cancelButton: boolean = false;
  @Input() size: number = 1;
  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  openModal(modal?) {
    let sizeString = 'md';
    switch (this.size) {
      case 2:
        sizeString = 'lg';
        break;
      case 3:
        sizeString = 'xl';
        break;
      default:
        break;
    }

    if(this.cancelButton){
      this.modalService.open(
        modal ? modal : this.modalContainer || ModalContainerComponent, {backdrop: 'static', keyboard: false, size: sizeString}
      );
    }else{
      this.modalService.open(
        modal ? modal : this.modalContainer || ModalContainerComponent, {size: sizeString}
      );
    }

  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
