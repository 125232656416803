import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GuarantorService } from 'src/app/services/guarantor.service';
import { OwnerService } from 'src/app/services/owner.service';
import { PropertyService } from 'src/app/services/property.service';
import { TenantService } from 'src/app/services/tenant.service';
import {
  of,
  fromEvent,
  Subscription,
  combineLatest,
  BehaviorSubject,
  Observable,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  tap,
  map,
  mergeMap,
} from 'rxjs/operators';

@Component({
  selector: 'app-entities-for-contract-search',
  templateUrl: './entities-for-contract-search.component.html',
  styleUrls: ['./entities-for-contract-search.component.css'],
})
export class EntitiesForContractSearchComponent implements OnInit {
  @Input() searchType: string;
  @Output() selectEntity = new EventEmitter();
  @ViewChild('searchBox', { static: true }) searchBox: ElementRef;

  searchTypes = {
    building: {
      id: 1,
      name: 'Inmueble',
    },
    owner: {
      id: 2,
      name: 'Propietario',
    },
    tennant: {
      id: 3,
      name: 'Inquilino',
    },
    guarantor: {
      id: 4,
      name: 'Garante',
    },
  };

  buildingHeader = ['header.address', 'header.unit'];
  personHeader = ['Nombre', 'Email', 'DNI'];

  searchString: any;
  searchResults: any[] = [];
  searchItem: number;
  searchTitle: string;
  totalItems: number;
  searching: boolean = false;
  page: number = 1;
  currentPage$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  search: Function;
  results$: Observable<any>;

  constructor(
    private ownerREService: OwnerService,
    private propertyREService: PropertyService,
    private tenantREService: TenantService,
    private guarantorREService: GuarantorService,
  ) {}

  ngOnInit() {
    this.initSearch(this.searchType);
    let inputBox$ = fromEvent(
      <HTMLInputElement>this.searchBox.nativeElement,
      'input',
    );
    this.results$ = combineLatest([inputBox$, this.currentPage$]).pipe(
      tap(() => (this.searching = true)),
      debounceTime(750),
      distinctUntilChanged(),
      map(([input, page]) => [
        this.loadQuery((input.target as HTMLInputElement).value),
        page,
      ]),
      mergeMap(([queryString, page]) =>
        this.search(queryString, page, 5).pipe(
          catchError((error) => {
            return of([]);
          }),
          tap(() => {
            this.searching = false;
          }),
        ),
      ),
    );
  }

  public initSearch(searchType: string) {
    this.searchResults = [];
    this.searchItem = this.searchTypes[searchType].id;
    this.searchTitle = this.searchTypes[searchType].name;
    switch (this.searchItem) {
      case 1:
        this.search = function (x, y, z) {
          return this.propertyREService.searchProperties(x, y, z);
        };
        break;
      case 2:
        this.search = function (x, y, z) {
          return this.ownerREService.searchOwners(x, y, z);
        };
        break;
      case 3:
        this.search = function (x, y, z) {
          return this.tenantREService.searchTenants(x, y, z);
        };
        break;
      case 4:
        this.search = function (x, y, z) {
          return this.guarantorREService.searchGuarantors(x, y, z);
        };
        break;
      default:
        break;
    }
  }

  searchPageChanged(event) {
    this.page = event;
    this.currentPage$.next(event);
  }

  selectElement(searchElelemnt) {
    this.selectEntity.emit(searchElelemnt);
  }

  loadQuery(qString) {
    if (this.searchItem == 1) {
      return `Address=${qString}`;
    } else {
      return `Name=${qString}`;
    }
  }
}
