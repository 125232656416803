import { Component, TemplateRef } from '@angular/core';
import {
  faTimes,
  faCheck,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css'],
})
export class ToastComponent {
  constructor(public toastService: ToastService) {}
  iconError = faTimes;
  iconSuccess = faCheck;
  iconWarning = faExclamationTriangle;
  iconInfo = faInfoCircle;

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
