<ng-template #name class="flex-row centered">
</ng-template>

<button *ngIf="!popoverOption" class="flex-row btn btn-primary face-button" type="button" (click)="openModal(name);"
  [disabled]="!isLoaded">

  <div class="face-primary">
    <div class="row">
      <span style="color: white; margin-bottom: 1.6vh;">PDF</span>
    </div>
    <div class="row" style="white-space: nowrap; ">
      <span style="color: white;"><fa-icon style="margin-right: 5px; color: white;"
          [icon]="iconDownload"></fa-icon>Descargar</span>
    </div>

  </div>

</button>


<span *ngIf="popoverOption" [ngClass]="{'disabled': !isLoaded}" (click)="openModal(name);">

  Imprimir Recibo de Pago

</span>