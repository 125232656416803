import { Injectable } from '@angular/core';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { Observable, of } from 'rxjs';
import { IPaginatedServiceParamters } from '../core/models/ITable';
import { Guarantor } from '../core/models/Guarantor';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class GuarantorService {
  constructor(private apiService: ApiService) {}

  getAllPaginated(
    paginatedArguments: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<Guarantor[]>> {
    return this.apiService.getPaginated(
      '/api/Guarantor/GetAllGuarantors',
      paginatedArguments,
    );
  }

  searchGuarantors(
    query?,
    page?,
    itemsPerPage?,
  ): Observable<PaginatedResult<Guarantor[]>> {
    var paginatedArguments: IPaginatedServiceParamters = {
      pageNumber: page,
      pageSize: itemsPerPage,
      query: query,
    };
    return query.substring(query.indexOf('=')+1).length > 2 ? this.apiService.getPaginated('/api/guarantor', paginatedArguments) : of(null);
  }

  getGuarantor(id) {
    return this.apiService.get(`/api/guarantor/${id}`);
  }

  deleteGuarantor(id) {
    return this.apiService.delete(`/api/guarantor/${id}`);
  }

  updateGuarantor(id, updateGuarantor) {
    return this.apiService.put(`/api/guarantor/${id}`, updateGuarantor);
  }

  createGuarantor(newGuarantor) {
    return this.apiService.post(`/api/guarantor`, newGuarantor);
  }
}
