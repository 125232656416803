import { InstallmentRequirementForAdd } from './InstallmentRequirementForAdd';

export interface InstallmentRequirementForPayment
  extends InstallmentRequirementForAdd {
  id?: number;
  actionTypeId?: ActionTypeEnum;
}

export enum ActionTypeEnum {
  Add = 1,
  Update = 2,
  Delete = 3,
}
