import { Component } from '@angular/core';
import { Rents } from './core/models/rents';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'baupfront';
  dataType: Rents;

  links = [
    { title: 'Pagina Principal', fragment: '/' },
    { title: 'Consorcios', fragment: '/consorcio' },
    { title: 'Gastos Recurrentes', fragment: '/expenses' },
    { title: 'Propiedades', fragment: '/properties' },
    { title: 'Condominios', fragment: '/condominium' },
    { title: 'Lista de Pagos', fragment: '/payments_list/1' },
  ];

  constructor(public route: ActivatedRoute, public auth: AuthService) {
    this.dataType = new Rents();
  }
}
