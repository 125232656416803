<div #filterForm class="card card-body">
  <div *ngIf="orderByTypes" style="width: 50%;">
    <div class="flex-row">
      <h3 style="font-size: 150%;">Ordenar</h3>
    </div>
    <div class="input-group mb-4">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Ordenar Por</span>
      </div>
      <select class="form-control form-select" name="OrderBy" #OrderBy (change)="ddOnChange($event)">
        <option value=""></option>
        <option [value]="input.name" *ngFor="let input of orderByTypes; let ind=index"> {{input.filterName}} </option>
      </select>
      <select class="form-control form-select" name="OrderByDescending" #OrderByDescending
        (change)="changeOrder($event)">
        <option [value]='false'> Ascendente </option>
        <option [value]='true'> Descendente </option>
      </select>
    </div>
  </div>


  <div *ngFor="let group of filtersByGroup | keyvalue: returnZero">
    <div class="flex-row">
      <h3 style="font-size: 150%;">{{ group.key }}</h3>
    </div>
    <div [ngClass]="getClass(group.value.length)">
      <div class="flex-sm-column" style="padding-left: 5px; padding-right: 5px;" *ngFor="let filter of group.value">

        <div class="input-group mb-3" name="filter.filterName"
          *ngIf="filter.type == filterInputTypes.STRING && (requirements[filter.requirement] !== '' || filter.requirement == undefined)">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">{{ filter.filterName }}</span>
          </div>
          <input class="form-control" name="{{filter.name}}" type="text" aria-describedby="basic-addon1">
        </div>

        <div class="input-group mb-3"
          *ngIf="filter.type == filterInputTypes.NUMBER && (requirements[filter.requirement] !== '' || filter.requirement == undefined)">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">{{ filter.filterName }}</span>
          </div>
          <input class="form-control" name="{{filter.name}}" type="number" aria-describedby="basic-addon1">
        </div>

        <div class="input-group mb-3"
          *ngIf="filter.type == filterInputTypes.DATE && (requirements[filter.requirement] !== '' || filter.requirement == undefined)">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">{{ filter.filterName }}</span>
          </div>
          <input class="form-control" placeholder="yyyy-mm-dd" name="{{filter.name}}" ngbDatepicker #a="ngbDatepicker"
            aria-describedby="basic-addon1">
          <div class="input-group-append filter">
            <button class="btn btn-outline-secondary" (click)="a.toggle()" type="button">
              <fa-icon [icon]="iconCalendar" size="1x"> </fa-icon>
            </button>
          </div>
        </div>

        <div class="input-group mb-4"
          *ngIf="filter.type == filterInputTypes.OPTION && (requirements[filter.requirement] !== '' || filter.requirement == undefined)">
          <select class="form-control form-select" name="{{filter.name}}" #{{filter.name}}
            (change)="ddOnChange($event)">
            <option value="">{{ filter.filterName }}</option>
            <option [value]="ind + 1" *ngFor="let input of filter.selectorOptions; let ind = index">{{input}}</option>
          </select>
        </div>

        <div class="input-group mb-3"
          *ngIf="filter.type == filterInputTypes.MONEY && (requirements[filter.requirement] !== '' || filter.requirement == undefined)">
          <select class="form-control form-select" name="{{filter.name}}" (change)="ddOnChange($event)">
            <option value="">{{ filter.filterName }}</option>
            <option value="1">Pesos</option>
            <option value="2">Dólares</option>
          </select>
        </div>

        <div class="input-group mb-3"
          *ngIf="filter.type == filterInputTypes.BOOLEAN && (requirements[filter.requirement] !== '' || filter.requirement == undefined)">
          <select class="form-control form-select" name="{{filter.name}}" (change)="ddOnChange($event)">
            <option value="">{{ filter.filterName }}</option>
            <option value=true>Si</option>
            <option value=false>No</option>
          </select>
        </div>

        <div class="input-group mb-3"
          *ngIf="filter.type == filterInputTypes.CHECKBOX && (requirements[filter.requirement] !== '' || filter.requirement == undefined)">
          <div class="form-check" style="margin-top: 0.83vh;">
            <input class="form-control form-check-input" name="{{filter.name}}" type="checkbox" value=""
              id="flexCheckDefault" style="height: 20px; margin-top: -1px;">
            <label class="form-check-label" for="flexCheckDefault" style="font-size: 120%; margin-left: 5px;">
              {{ filter.filterName }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-row" style="justify-content: center;">
    <button class="btn btn-success filter-button" (click)="saveFilters()">
      <fa-icon [icon]="iconSearch" style="margin-right: 20px;"></fa-icon>
      Filtrar</button>
    <button class="btn btn-danger filter-button" (click)="deleteFilters()">
      <fa-icon [icon]="iconX" style="margin-right: 20px;"></fa-icon>
      Remover Filtros</button>
  </div>
</div>