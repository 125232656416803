import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  transformToDateObject(day, month, year) {
    return new Date(year, month - 1, day, 0, 0, 0, 0);
  }

  transformToDateString(date: Date, separator?: string, time?: boolean) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
  
    let separatorHour = separator || ':';
  
    let formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    let formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  
    if (time) {
      return `${day}${separator || '/'}${month}${separator || '/'}${year} - ${formattedHours}${separatorHour}${formattedMinutes} hs`;
    } else {
      return `${day}${separator || '/'}${month}${separator || '/'}${year}`;
    }
  }

  transformToDateStringFromString(dateString: string, separator?: string) {
    if (dateString != undefined) {
      let date = new Date(dateString);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      if (separator) {
        return day + separator + month + separator + year;
      } else {
        return day + '/' + month + '/' + year;
      }
    }
  }

  transformToDateHash(date: Date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return {
      year: year,
      month: month,
      day: day,
    };
  }

  getToday() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    let todayDate = dd + '/' + mm + '/' + yyyy;
    return todayDate;
  }

  getTodayEng() {
    var today = new Date();
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear().toString();

    let month = mm;

    switch (month) {
      case '1':
        month = 'enero';
        break;
      case '2':
        month = 'febrero';
        break;
      case '3':
        month = 'marzo';
        break;
      case '4':
        month = 'abril';
        break;
      case '5':
        month = 'mayo';
        break;
      case '6':
        month = 'junio';
        break;
      case '7':
        month = 'julio';
        break;
      case '8':
        month = 'agosto';
        break;
      case '9':
        month = 'septiembre';
        break;
      case '10':
        month = 'octubre';
        break;
      case '11':
        month = 'noviembre';
        break;
      case '12':
        month = 'diciembre';
        break;

      default:
        break;
    }

    let todayDate = month + ' ' + yyyy;

    return todayDate;
  }

  getNewDate() {
    var moment = require('moment');

    // obtener el nombre del mes, día del mes, año, hora
    var now = moment().format('YYYY/MM/DD HH:mm:ss');
    return now;
  }

  getStartOfMonth(date, separator?: string) {
    let day = 1;
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (separator) {
      return year + separator + month + separator + day;
    } else {
      return year + '-' + month + '-' + day;
    }
  }

  getEndOfMonth(date, separator?: string) {
    let day = 1;
    let month = date.getMonth() + 2;
    let year = date.getFullYear();
    if (separator) {
      return year + separator + month + separator + day;
    } else {
      return year + '-' + month + '-' + day;
    }
  }

  monthDiff(date: Date, dateToSubstract: Date) {
    let months;
    months = (date.getFullYear() - dateToSubstract.getFullYear()) * 12;
    months -= dateToSubstract.getMonth();
    months += date.getMonth();
    return months;
  }

  getFirstDay(date: Date){
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month, 1);
  }

  getLastDay(date: Date){
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month + 1, 0);
  }

  getNumberFromDateYYYYMMDD(date: Date){
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return parseInt(`${year}${month}${day}`);
  }
}
