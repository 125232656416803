<form [formGroup]="additionalChargesFormGroup" (ngSubmit)="onSubmit()" style="height: 100%;">
  <div class="line-stuff">
    <!-- <div class="border-top border-bottom line-div"></div> -->
    <div class="payment-div shadow-sm border">
      <span style="font-weight: 550; opacity: 0.8;grid-area: title;font-size: 105%;">
        CARGOS ADICIONALES DE {{ installments.length != 1 ? '' : installments[0].description | uppercase}}
        <select [(ngModel)]="installment" [ngModelOptions]="{standalone: true}" *ngIf="installments.length != 1"
          style="border-radius: 5px; border-color: rgba(0, 0, 0, 0.3); margin-left: 3px;">
          <option *ngFor="let ins of installments; let first" [ngValue]="ins" [selected]="first">{{ins.description |
            uppercase}}</option>
        </select>
      </span>
      <div class="additional-charges">
        <div class="add-charge-line" style="width: 100%;" *ngFor="let charge of installment?.additionalCharges">

          <span style="grid-area: value;" [ngClass]="{'red': !charge.sign, 'green': charge.sign}"
            *ngIf="showPunitives || !charge.punitive">
            <span [ngClass]="{'red': !charge.sign, 'green': charge.sign}">{{charge.sign ? "+" : "-"}}</span>
            {{charge.amount}}
          </span>
          <span style="grid-area: description;" *ngIf="showPunitives || !charge.punitive">
            {{charge.detail}}
          </span>
          <fa-icon style="grid-area: edit;" class="hoverlightblue" [icon]="iconPen" size="1x"
            (click)="editCharge(charge)" style="margin-right: 10px;" *ngIf="!charge.punitive"></fa-icon>
          <fa-icon style="grid-area: delete;" class="hoverlightred" [icon]="iconDelete" size="1x"
            (click)="deleteCharge(charge)" *ngIf="!charge.punitive"></fa-icon>
        </div>
      </div>
      <div class="flex-column left-aligned" style="width: 100%; grid-area: input; margin-bottom: 5px;">
        <div class="flex-row left-aligned" style="width: 100%; margin-bottom: 5px;">
          <span style="font-size: 85%; font-weight: 450; opacity: 0.8;">MONTO DEL PAGO</span>
        </div>
        <input class="form-control" placeholder="$" style="grid-area: input;" formControlName="amount" min="0"
          type="number" [ngClass]="{'borderRed': (additionalChargesFormGroup.invalid &&
                (additionalChargesFormGroup.dirty || additionalChargesFormGroup.touched) && !additionalChargesFormGroup.value.amount)
                || invalidAmount}" appLimitDirective>
        <div class="flex-row left-aligned" style="width: 100%;">
          <span style="color: var(--border-red); font-size: 90%;" *ngIf="invalidAmount">
            Ingresar solo números enteros
          </span>
        </div>
      </div>
      <div class="flex-column left-aligned" style="width: 100%; grid-area: type;">
        <div class="flex-row left-aligned" style="width: 100%; margin-bottom: 5px;">
          <span style="font-size: 85%; font-weight: 450; opacity: 0.8;">RECARGA / DESCUENTO</span>
        </div>
        <select class="form-control form-select" formControlName="sign"
          [ngClass]="{'borderRed': additionalChargesFormGroup.invalid &&
        (additionalChargesFormGroup.dirty || additionalChargesFormGroup.touched) && !additionalChargesFormGroup.value.sign}">
          <option [ngValue]="undefined" disabled>...</option>
          <option [ngValue]="true">Recarga</option>
          <option [ngValue]="false">Descuento</option>
        </select>
      </div>
      <div class="flex-column left-aligned" style="width: 100%; grid-area: details;">
        <div class="flex-row left-aligned" style="width: 100%; margin-bottom: 5px;">
          <span style="font-size: 85%; font-weight: 450; opacity: 0.8;">DETALLES</span>
        </div>
        <textarea class="form-control custom-box" cols="70" rows="3" formControlName="detail"
          [ngClass]="{'borderRed': additionalChargesFormGroup.invalid &&
                  (additionalChargesFormGroup.dirty || additionalChargesFormGroup.touched) && !additionalChargesFormGroup.value.detail}">
        </textarea>
      </div>
      <button class="btn btn-primary" style="grid-area: payment;">Agregar</button>
      <button class="btn btn-outline-danger" style="grid-area: cancel;" type="button"
        (click)="resetForm()">Cancelar</button>
    </div>
  </div>
</form>