import { EventEmitter, Input, Output } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  faCalendar,
  faSearch,
  faPlus,
  faEye,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PropertyForEdit } from 'src/app/core/models/PropertyForAdd';
import { PropertyTypeEnum } from 'src/app/core/models/PropertyType.enum';
import { OwnerService } from 'src/app/services/owner.service';
import { PropertyService } from 'src/app/services/property.service';
import { ToastService } from 'src/app/services/toast.service';
import { GridComponent } from 'src/app/shared/grid/grid.component';

@Component({
  selector: 'app-building-form',
  templateUrl: './building-form.component.html',
  styleUrls: ['./building-form.component.css'],
})
export class BuildingFormComponent implements OnInit {
  @ViewChild(GridComponent) gridComponent: GridComponent<any>;

  iconCalendar = faCalendar;
  iconSearch = faSearch;
  iconPlus = faPlus;
  iconSee = faEye;
  iconTrash = faTrash;
  display: boolean = true;
  showSearchOwner: boolean = false;
  showAddOwner: boolean = false;
  searchString: string;
  searchResults: any[] = [];
  page: number = 1;
  buildingIndex: number;
  submitted: boolean = false;
  propertyTypes: any[] = [];


  @Input() edit?: boolean = false;
  @Input() editData?: any;
  @Input() contractMode: boolean = false;
  @Input() createInMemory?: any = false;
  @Input() createInMemoryArray?: any;
  @Input() editContractMode?: boolean = false;
  /*   @Input() propertyList?: boolean = false; */
  /*   @Input() showOwnerForms: boolean = true;  */

  @Output() closeAction = new EventEmitter();
  @Output() cancelAction = new EventEmitter();

  addTooltip = 'Añadir';
  //MOCKS

  guarantorType = [{ id: 1, type: 'Garante' }];

  buildingFormGroup = new UntypedFormGroup({
    identification: new UntypedFormControl('', Validators.required),
    streetAddress: new UntypedFormControl('', Validators.required),
    propertyTypeId : new UntypedFormControl(''),
    rooms : new UntypedFormControl('', [Validators.min(0), Validators.max(2147483647)]),
    surface : new UntypedFormControl(''),
    city: new UntypedFormControl(''),
    state: new UntypedFormControl(''),
    zipCode: new UntypedFormControl(''),
    level: new UntypedFormControl(''),
    description: new UntypedFormControl(''),
    area: new UntypedFormControl(''),
  });

  constructor(
    public propertyService: PropertyService,
    private ownerREService: OwnerService,
    private router: Router,
    private modalService: NgbModal,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    for (let item in PropertyTypeEnum) {
      if (isNaN(Number(item))) {
        this.propertyTypes.push({ text: item, value: PropertyTypeEnum[item] });
      }
    }
    if (this.edit) {
      this.buildingIndex = this.editData.id;
      this.buildingFormGroup.patchValue({
        streetAddress:this.editData?.address?.streetAddress || this.editData?.streetAddress,
        city: this.editData?.address?.city || this.editData?.city,
        state: this.editData?.address?.state || this.editData?.state,
        zipCode: this.editData?.address?.zipCode || this.editData?.zipCode,
        identification:this.editData?.identification,
        level: this.editData?.level,
        description: this.editData?.description,
        area: this.editData?.area,
        propertyTypeId: this.editData?.propertyTypeId,
        rooms: this.editData?.rooms,
        surface: this.editData?.surface,
      });
    }
  }

  onSubmit() {
    let buildingToSubmit: PropertyForEdit = {
      id: this.editData?.id || 0,
      identification: this.buildingFormGroup.value.identification,
      streetAddress: this.buildingFormGroup.value.streetAddress,
      city: this.buildingFormGroup.value.city,
      state: this.buildingFormGroup.value.state,
      zipCode: this.buildingFormGroup.value.zipCode,
      level: this.buildingFormGroup.value.level,
      description: this.buildingFormGroup.value.description,
      area: this.buildingFormGroup.value.area,
      propertyTypeId: this.buildingFormGroup.value.propertyTypeId,
      rooms: this.buildingFormGroup.value.rooms,
      surface: this.buildingFormGroup.value.surface
    };

    if (this.buildingFormGroup.invalid) {
      this.buildingFormGroup.markAllAsTouched();
      return;
    } else {
      if (this.edit) {
        if (this.createInMemory) {
          this.createInMemoryArray.property = buildingToSubmit;
          this.closeAction.emit(buildingToSubmit);
        } else {
          this.editProperty(buildingToSubmit);
        }
      } else {
        if (this.createInMemory) {       
          this.submitted = true;
          this.createInMemoryArray.property = buildingToSubmit;
          this.closeAction.emit(buildingToSubmit);
        } else {
          this.submitted = true;
          this.addProperty(buildingToSubmit);
        }
      }
    }
  }

  addProperty(propertyForSubmit) {
    this.propertyService
      .createProperty(propertyForSubmit)
      .subscribe((response) => {
        if (this.editContractMode) {
          this.propertyService.getProperty(response).subscribe((result) => {
            this.createInMemoryArray.push(result);
            this.closeAction.emit(result);
          });
        } else {
          this.closeAction.emit();
          this.router.navigateByUrl(`/properties/${response}`);
        }
      });
  }

  editProperty(propertyForEdit: PropertyForEdit) {
    this.propertyService
      .updateProperty(this.editData.id, propertyForEdit)
      .subscribe((response) => {
        this.toastService.showToast('success', 'Propiedad editada');
        if (this.editContractMode) {
          this.propertyService
            .getProperty(this.editData.id)
            .subscribe((result) => {
              this.createInMemoryArray = result;
              this.closeAction.emit(result);
            });
        } else {
          this.closeAction.emit(this.editData.id);
        }
      });
  }

  validate(field: string) {
    return (
      this.buildingFormGroup.controls[field]?.errors &&
      this.buildingFormGroup.controls[field]?.touched
    );
  }

  openSearch(searchForm) {
    this.modalService.open(searchForm, { size: 'lg' });
  }

  selectedSearchedElement(entity) {
    this.buildingFormGroup.patchValue({
      identification: entity.identification || entity.identification,
      streetAddress: entity.address?.streetAddress || entity.streetAddress,
      propertyTypeId: entity.propertyTypeId || entity.propertyTypeId,
      enviroment: entity.rooms || entity.rooms,
      surface: entity.surface || entity.surface,
      city: entity.address?.city || entity.city,
      state: entity.address?.state || entity.state,
      zipCode: entity.address?.zipCode || entity.zipCode,
      level: entity.level || entity.level,
      description: entity.description || entity.description,
      area: entity.area || entity.area,
    });
    this.modalService.dismissAll();
  }

  contractModeChange() {
    if (this.contractMode) {
      this.editData.address.city = this.buildingFormGroup.value.city;
      this.editData.address.state = this.buildingFormGroup.value.state;
      this.editData.address.streetAddress =
        this.buildingFormGroup.value.streetAddress;
      this.editData.propertyTypeId = this.buildingFormGroup.value.propertyTypeId;
      this.editData.rooms = this.buildingFormGroup.value.rooms;
      this.editData.surface = this.buildingFormGroup.value.surface;
      this.editData.address.zipCode = this.buildingFormGroup.value.zipCode;
      this.editData.identification =
        this.buildingFormGroup.value.identification;
      this.editData.level = this.buildingFormGroup.value.level;
      this.editData.description = this.buildingFormGroup.value.description;
      this.editData.area = this.buildingFormGroup.value.area;
    }
  }

  deleteFormBuilding() {
    this.buildingFormGroup.reset();
    this.editData.id = 0;
  }

  cancel(){
    this.cancelAction.emit();
  }
}
