<section class="app-container">
    <div class="flex-row" style="margin-bottom: auto;">
        <div class="tabs" *ngFor="let step of steps; let i = index; let f = first;"
          [ngClass]="{'active': selectedIndex === i}" [ngStyle]="{'margin-left': !f ? '1rem' : '0'}" (click)="onClick(i)">
          {{ stepTitles[i] }}
        </div>
    </div>
    <div class="flex-row" [style.display]="selected ? 'block' : 'none'">
        <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
    </div>
    <div class="flex-row" style="margin-top: auto; justify-content: space-between;">
        <button class="btn btn-primary" cdkStepperPrevious>&larr;</button>
        <button class="btn btn-primary" cdkStepperNext>&rarr;</button>
    </div>
</section>