<div class="contracts with-margin">
  <form [formGroup]="contractDataFormGroup">
    <label>Carpeta / Codigo Interno </label>
    <div style="margin-bottom: 1vh; display: flex; flex-direction: row; justify-content: flex-start; align-items: center;">
      <input class="form-control" 
              type="text" 
              style="margin-right: 1vw; width: 10vw;" 
              formControlName="folder"
              (change)="folderInnerCode()" 
              [ngClass]="{'borderRed': contractDataFormGroup.invalid && 
              (contractDataFormGroup.dirty || contractDataFormGroup.touched) && !contractDataFormGroup.value.folder}">
      <input class="form-control" 
              type="text" 
              style="width: 10vw;" 
              formControlName="innerCode"
              (change)="folderInnerCode()"
              [ngClass]="{'borderRed': contractDataFormGroup.invalid && 
                (contractDataFormGroup.dirty || contractDataFormGroup.touched) && !contractDataFormGroup.value.innerCode}">
    </div>
    <label>Fecha Comienzo</label>
    <div class="input-group" style="margin-bottom: 1vh;width: 200px;">
      <input class="form-control" 
              placeholder="yyyy-mm-dd" 
              formControlName="startDate"
              name="dp"  
              ngbDatepicker #a="ngbDatepicker" 
              (dateSelect)="changeStartDate()" 
              (change)="changeStartDate()"
              [ngClass]="{'borderRed': contractDataFormGroup.invalid && 
              (contractDataFormGroup.dirty || contractDataFormGroup.touched) && !contractDataFormGroup.value.startDate}">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="a.toggle()" type="button">
          <fa-icon [icon]="iconCalendar" size="1x"> </fa-icon>
        </button>
      </div>
    </div>
    <label>Tipo de Cuota / Cantidad de Cuotas </label>
    <div style="margin-bottom: 1vh; display: flex; flex-direction: row; justify-content: flex-start; align-items: center;">
      <select class="form-control form-select"   
              style="margin-right: 1vw; width: 12vw;"
              formControlName="installmentGenerationTypeId"  
              (change)="chooseTypeOfInstallment()"
              [ngClass]="{'borderRed': contractDataFormGroup.invalid && 
              (contractDataFormGroup.dirty || contractDataFormGroup.touched) && !contractDataFormGroup.value.installmentGenerationTypeId}">
        <option disabled>...</option>
        <option value="1">Mensual</option>
        <!-- <option value="2">Bimestral</option>
        <option value="3">Trimestral</option>
        <option value="4">Cuatrimestral</option>
        <option value="6">Semestral</option>
        <option value="12">Anual</option> -->
      </select>
      <input  class="form-control" 
              *ngIf="chosenTypeInstCheck"
              type="text" 
              style="margin-right: 1vw; width: 10vw;" 
              formControlName="installmentQuantity" 
              (change)="changeStartDate()"
              placeholder="36"
              [ngClass]="{'borderRed': contractDataFormGroup.invalid && 
                        (contractDataFormGroup.dirty || contractDataFormGroup.touched) 
                        && !contractDataFormGroup.value.installmentQuantity}">
    </div>
    <label>Fecha Vencimiento</label>
    <div class="input-group"  style="margin-bottom: 1vh;width: 200px;">
      <input class="form-control" 
              placeholder="yyyy-mm-dd" 
              formControlName="expiryDate"
              name="dp"  
              ngbDatepicker #b="ngbDatepicker"
              (dateSelect)="loadExpiryDate()" 
              (change)="loadExpiryDate()"
              [ngClass]="{'borderRed': contractDataFormGroup.invalid && 
              (contractDataFormGroup.dirty || contractDataFormGroup.touched) && !contractDataFormGroup.value.expiryDate}">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="b.toggle()" type="button">
          <fa-icon [icon]="iconCalendar" size="1x"> </fa-icon>
        </button>
      </div>
    </div>  

    <div style="margin-bottom: 1vh; display: flex; flex-direction: row; align-items: center">
      <div style="display: flex; flex-direction: column;">
        <label>Comprobante honorarios</label>
        <select class="form-control form-select"   
                style="margin-right: 1vw; width: 12vw;"
                formControlName="receiptTypeId"  
                (change)="loadCuit()"
                [ngClass]="{'borderRed': contractDataFormGroup.invalid && 
                (contractDataFormGroup.dirty || contractDataFormGroup.touched) && !contractDataFormGroup.value.receiptTypeId}">
          <option disabled>...</option>
          <option value="1">Factura</option>
          <option value="2">Recibo</option>
        </select>
      </div>
      <div style="display: flex; flex-direction: column;">
        <label>Facturacion del alquiler</label>
        <select class="form-control form-select" style="margin-right: 1vw; width: 12vw;"
          (change)="changeInvoice()" formControlName="generatesOwnerBill">
          <option [ngValue]="false">No</option>
          <option [ngValue]="true">Sí</option>
        </select>
      </div>
      
      <!-- <div *ngIf="cuits?.length > 0"
        style="display: flex; flex-direction: row; margin-top: 5px;">
        <div class="flex-column">
          <label>CUIT</label>
            <select class="form-control form-select"   
            style="margin-right: 1vw; width: 12vw;"
            formControlName="cuit"
            (change)="loadSellPoints()">
              <option disabled>...</option>
              <option *ngFor="let cuit of cuits" [ngValue]="cuit"> {{cuit.socialReason}} </option>
            </select>
        </div>

        <div *ngIf="sellPoints?.length > 0" class="flex-column">
          <label>Puntos de venta</label>
            <select class="form-control form-select"   
            style="margin-right: 1vw; width: 12vw;"
            (change)="setPoint()"
            formControlName="sellPoint">
              <option *ngFor="let point of sellPoints" [ngValue]="point"> {{point.fantasyName}} </option>
            </select>
        </div>
      </div> -->

    </div>
    
    <div *ngIf="cuits?.length > 0 && sellPoints?.length > 0"
        style="display: flex; flex-direction: column; margin-top: 5px;">
        <label>Tipo de factura</label>
          <select class="form-control form-select"   
          style="margin-right: 1vw; width: 12vw;"
          formControlName="billTypeId"
          (change)="setBilltype()">
            <option value="1"> Factura A </option>
            <option value="6"> Factura B </option>
            <option value="11"> Factura C </option>
            <option value="51"> Factura M </option>
          </select>
    </div>
  </form>
</div>