import { Injectable } from '@angular/core';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { Observable } from 'rxjs';
import { IPaginatedServiceParamters } from '../core/models/ITable';
import { PendingFee } from '../core/models/PendingFee';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PendingFeeService {
  constructor(private apiService: ApiService) {}

  getAllPaginated(
    paginatedArguments: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<PendingFee[]>> {
    return this.apiService.getPaginated('/api/fee/pending', paginatedArguments);
  }

  getPendingFee(contractId) {
    return this.apiService.get(`/api/Fee/Pending/${contractId}`);
  }

  addPendingFee(pendingFee) {
    return this.apiService.post(`/api/Fee/CollectPendingFee`, pendingFee);
  }

  addPendingFees(pendingFees) {
    return this.apiService.post(`/api/Fee/CollectPendingFees`, pendingFees);
  }
}
