import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { ContractService } from '../../services/contract.service';
import { faBars, faCircleChevronLeft, faCircleXmark, faGear, faSignOut, faUserTie } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('avatar') avatar: ElementRef;
  @ViewChild('navbar') navbar: ElementRef;
  @ViewChild('submenu', { static: false }) submenu: ElementRef;

  toolTip: string;
  user: User;
  personType: string = 'IsAddress';
  personTypes = { Propiedad: 'IsAddress', Inquilino: 'IsTenant', Propietario: 'IsOwner' };
  selectedContract: any;
  barsIcon = faBars;
  avatarInitial: string;
  @Input() expanded: boolean;
  userTie = faUserTie;
  gear = faGear;
  exit = faSignOut;
  close = faCircleXmark
  arrowL = faCircleChevronLeft
  bars = faBars;
  sidebarIcon: typeof faBars | typeof faCircleXmark;
  submenuVisible = false;

  @Output() toggleSideNav = new EventEmitter();

  constructor(
    public auth: AuthService,
    public router: Router,
    public contractService: ContractService,
    private changeDetectorRef: ChangeDetectorRef
    ) 
  {
    if (auth.user$) {
      auth.user$.subscribe((user) => {
        this.user = user;        
      
        let initials = user.name.split(" ");
        this.avatarInitial = initials.map(word => word.charAt(0)).join("");
      });
    }
  }
  
  toggleMenu = true;
  
  ngOnInit(): void {
    this.toolTip = this.expanded ? 'Colapsar menu' : 'Expandir menu';
    this.sidebarIcon = this.expanded ? faCircleXmark : faBars;
  }

  getSearchResult(result) {
    this.selectedContract = result;
  }

  returnZero() {
    return 0;
  }

  goToContract() {
    this.router.navigateByUrl(`/contracts/${this.selectedContract.id}/detail`);
    
  }

  redirect() {
    this.router.navigateByUrl('/home');
  }

  redirectToAgent() {
    this.router.navigateByUrl(`/users/${this.user["https://baup.com.ar/userId"]}`);
  }

  redirectToConfig() {
    this.router.navigateByUrl('/configuration');
  }

  toggleSidebar() {
    this.toggleSideNav.emit();
    this.toolTip = !this.expanded ? 'Colapsar menu' : 'Expandir menu';
    this.sidebarIcon = !this.expanded ? faCircleXmark : faBars;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (this.submenuVisible && !this.avatar.nativeElement.contains(event.target)) {
      this.submenuVisible = false;
    }
  }
  
  toggleSubmenu(){
    this.submenuVisible = !this.submenuVisible;
    this.changeDetectorRef.detectChanges();
    const navbarHeight = this.navbar.nativeElement.offsetHeight;
    this.submenu.nativeElement.style.top = `${navbarHeight}px`;
  }
}
