import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loading-spinner',
  templateUrl: './LoadingScreen.component.html',
  styleUrls: ['./LoadingScreen.component.css'],
})
export class LoadingScreenComponent implements OnInit {
  @Input() evaluation: boolean;
  @Input() liteMode: boolean = false;
  @Input() msg: string = null;
  isblue = false;

  constructor() {
    this.isblue = Math.random() < 0.5;
  }

  ngOnInit() {}
}
