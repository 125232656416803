import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.css'],
})
export class SwitchComponent implements OnInit {
  @Output() toggleEventFire = new EventEmitter();
  @Input() checked: boolean;

  constructor() {
    this.checked = false;
  }

  ngOnInit(): void {}

  toggleEvent() {
    this.toggleEventFire.emit();
  }
}
