import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { ApiService } from './api.service';
import { IPaginatedServiceParamters } from '../core/models/ITable';
import { PendingReturn } from '../core/models/PendingReturn';
import { Return } from '../core/models/Return';

@Injectable({
  providedIn: 'root',
})
export class ReturnsService {
  constructor(private apiService: ApiService) {}

  getAllPaginated(
    paginatedArguments: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<Return[]>> {
    return this.apiService.getPaginated('/api/Return', paginatedArguments);
  }

  getReturn(id) {
    return this.apiService.get(`/api/Return/${id}`);
  }

  getPendingReturns(
    query?,
    page?,
    itemsPerPage?,
  ): Observable<PaginatedResult<PendingReturn[]>> {
    var paginatedArguments: IPaginatedServiceParamters = {
      pageNumber: page,
      pageSize: itemsPerPage,
      query: query,
    };
    return this.apiService.getPaginated(
      '/api/Return/pending',
      paginatedArguments,
    );
  }

  createReturn(newReturn) {
    console.log(newReturn);
    return this.apiService.post(`/api/Return`, newReturn);
  }

  getReturnReceipt(id) {
    return this.apiService.get(`/api/Return/ReturnReceipt/${id}`);
  }

  getReturnReceiptView(id) {
    return this.apiService.get(`/api/Return/ReceiptView/${id}`);
  }

  getFakeReturn() {
    return fakeReturns;
  }

  getReturnQuantity() {
    return this.apiService.get(`/api/Return/Quantity`);
  }
}

var fakeReturns = [
  {
    contractId: 2,
    amount: 31500,
    paymentsQuantity: 1,
    owners: [
      {
        id: 3,
        user: {
          person: {
            personId: 13,
            documentId: 773278,
            documentType: 1,
            dateOfBirth: '2021-04-13T12:37:14.041995',
            age: 0,
            firstName: 'Jorge',
            lastName: 'Garcia',
            fullName: 'Jorge Garcia',
            address: {
              id: 14,
              streetAddress: 'Fake 3481',
              city: 'CABA',
              state: 'CABA',
              zipCode: '1431',
            },
            phones: [
              {
                id: 20,
                area: '54911',
                number: '50590561',
                description: 'celular',
              },
              {
                id: 21,
                area: '011',
                number: '45722389',
                description: 'celular',
              },
            ],
            businessName: null,
            isPerson: true,
          },
          userId: 9,
          username: 'OwnerTest',
          email: 'OwnerTest@email.com',
          userImageUrl: 'string',
          isAdmin: true,
        },
        properties: [],
        agencyId: 1,
        contracts: [],
      },
    ],
    address: {
      id: 14,
      streetAddress: 'Fake 3481',
      city: 'CABA',
      state: 'CABA',
      zipCode: '1431',
    },
  },
];
