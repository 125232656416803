import { Injectable } from '@angular/core';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { Observable } from 'rxjs';
import { IPaginatedServiceParamters } from '../core/models/ITable';
import { ExpenditurePropertyToPay } from '../core/models/ExpenditurePropertyToPay';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class FacturasElectronicasService {
  constructor(private apiService: ApiService) {}

  getAllPaginated(
    paginatedArguments: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<any[]>> {
    return this.apiService.getPaginated(
      `/api/FacturaElectronica/FacturasElectronicas`,
      paginatedArguments,
    );
  }

  getFactura(id) {
    return this.apiService.get(`/api/FacturaElectronica/${id}`);
  }

  createFactura(newFactura) {
    return this.apiService.post(`/api/FacturaElectronica`, newFactura);
  }

  deleteFactura(id) {
    return this.apiService.delete(`/api/FacturaElectronica/${id}`);
  }

  getFacturaPDF(id) {
    return this.apiService.get(`/api/FacturaElectronica/FacturaPDFView/${id}`);
  }

}
