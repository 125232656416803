<div class="grid">
  <section *ngIf="contractForLists.length > 0">
    <table style="width: 100%;" class="table-sm table-striped">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">{{'header.fromDate' | translate}}</th>
          <th scope="col">{{'header.toDate' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let contractForList of contractForLists;" (click)="goToContract(contractForList.id)">
          <td>{{contractForList.address}}</td>      
          <td>{{contractForList.fromDate | date: 'shortDate'}}</td>
          <td>{{contractForList.toDate | date: 'shortDate'}}</td>
        </tr>
      </tbody>
    </table>
  </section>
  <div *ngIf="contractForLists.length == 0" style="height: 100%;">
    <div class="table-empty" >
      <fa-icon style="margin-bottom: 5px;"[icon]="iconQuestion" size="4x"></fa-icon>
      <span style="margin-bottom: 5px;">{{'gridComponent.tableEmpty' | translate}}</span>
    </div>
  </div>
</div>