<div class="card-div" (mouseenter)="mouseEnter(true)" (mouseleave)="mouseEnter(false)">
    <div  class="card-content" *ngIf="!openCard"> 
        <span class="number"> {{messageQuantity}} </span>
        <div class="vertical-line"></div>
        <div class="class-messages">
            <span class="text-message" >{{'cardMessages' + '.' + title | translate}} </span> <br>
            <span class="text-message" style="padding-top: 5px;">{{'cardNoRead'| translate}}</span>
        </div>
    </div>
    <div  class="card-open-content" *ngIf="openCard"> 
        <div *ngIf="!noMessages">
            <div class="message" *ngFor="let element of messages">
                <span>{{element.message}}</span>
            </div>
        </div>
        <div *ngIf="noMessages">
            <div class="no-message">
                <span>{{'noMessages'| translate}}</span>
            </div>
        </div>
    </div>
</div>