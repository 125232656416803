<ngb-toast *ngFor="let toast of toastService.toasts" [class]="toast.classname + ' bottom-0'" [autohide]="true"
  [delay]="toast.delay || 10000" (click)="toastService.remove(toast)"
  style="width: 17vw; min-height: 15vh; height: fit-content; display: flex; flex-direction: column-reverse; align-items: center; justify-content: center;">

  <ng-template [ngIf]=" toast.template == 'error'">
    <div class="toast-body">
      <fa-icon class="icon" [icon]="iconError"></fa-icon>
      <span style="color: white;">{{ toast.message }}</span>
    </div>
  </ng-template>

  <ng-template [ngIf]=" toast.template == 'success'">
    <div class="toast-body">
      <fa-icon class="icon" [icon]="iconSuccess"></fa-icon>
      <span style="color: white;">{{ toast.message }}</span>
    </div>
  </ng-template>

  <ng-template [ngIf]=" toast.template == 'warning'">
    <div class="toast-body">
      <fa-icon class="icon" [icon]="iconWarning"></fa-icon>
      <span style="color: white;">{{ toast.message }}</span>
    </div>
  </ng-template>

  <ng-template [ngIf]=" toast.template == 'info'">
    <div class="toast-body">
      <fa-icon class="icon" [icon]="iconInfo"></fa-icon>
      <span style="color: white;">{{ toast.message }}</span>
    </div>
  </ng-template>
</ngb-toast>