<div class="flex-row" *ngIf="inputElement?.selectedFilter?.type ==  filterInputTypes.NUMBER">
  <input class="form-control filter" type="number" style="width: 10vw;" [(ngModel)]="attribute">
  <div>
    <button class="btn btn-primary" (click)="setFilterValue()">Filtrar</button>
  </div>
</div>

<div class="flex-row" *ngIf="inputElement?.selectedFilter?.type ==  filterInputTypes.STRING">
  <input class="form-control filter" type="text" style="width: 10vw;" [(ngModel)]="attribute">
  <div>
    <button class="btn btn-primary" (click)="setFilterValue()">Filtrar</button>
  </div>
</div>

<div class="flex-row" *ngIf="inputElement?.selectedFilter?.type ==  filterInputTypes.DATE">
  <input class="form-control filter"
          placeholder="yyyy-mm-dd"
          name="dp"
          [(ngModel)]="attribute"
          ngbDatepicker #a="ngbDatepicker">
  <div class="input-group-append filter">
    <button class="btn btn-outline-secondary" (click)="a.toggle()" type="button">
      <fa-icon [icon]="iconCalendar" size="1x"> </fa-icon>
    </button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="setFilterValueDate()">Filtrar</button>
  </div>
</div>

<div class="flex-row" *ngIf="inputElement?.selectedFilter?.type ==  filterInputTypes.OPTION">
  <select class="form-control form-select filter"
          style=" width: 10vw;"
          [(ngModel)]="attribute">
          <option [ngValue]="input" *ngFor="let input of inputElement.selectedFilter.selectorOptions">{{input}}</option>
  </select>
  <div>
    <button class="btn btn-primary" (click)="setFilterValue()">Filtrar</button>
  </div>
</div>

<div class="flex-row" *ngIf="inputElement?.selectedFilter?.type ==  filterInputTypes.MONEY">
  <select class="form-control form-select filter" 
          style=" width: 10vw;"
          [(ngModel)]="attribute">
          <option [ngValue]="1">Pesos</option>
          <option [ngValue]="2">Dólares</option>
  </select>
  <div>
    <button class="btn btn-primary" (click)="setFilterValue()">Filtrar</button>
  </div>
</div>

<div class="flex-row" *ngIf="inputElement?.selectedFilter?.type ==  filterInputTypes.BOOLEAN">
  <select class="form-control form-select filter" 
          style=" width: 10vw;"
          [(ngModel)]="attribute">
          <option [ngValue]="true">Si</option>
          <option [ngValue]="false">No</option>
  </select>
  <div>
    <button class="btn btn-primary" (click)="setFilterValue()">Filtrar</button>
  </div>
</div>