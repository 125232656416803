<form [formGroup]="fixedContractFormGroup" (ngSubmit)="onSubmitFixedForm()">
  <div class="title">
    <h1>Añadir Monto Fijo</h1>
  </div>
  <div class="form-building form-group">
    <label>Numero de Cuotas</label>
    <input type="number" formControlName="quantity" class="form-control" style="margin-top: -5px;"   
    [ngClass]="{'borderRed': !fixedContractFormGroup.controls.quantity.pristine  && 
    (fixedContractFormGroup.dirty || fixedContractFormGroup.touched) && fixedContractFormGroup.controls.quantity.status == 'INVALID'}">
    <span style="color: var(--border-red); font-size: 90%; margin-top: -10px; margin-bottom: 10px;" *ngIf="invalidQuantity">
      Verifique el campo
    </span>
    <label>Valor</label>
    <input type="number" formControlName="amount" class="form-control" style="margin-top: -5px;"
    [ngClass]="{'borderRed': !fixedContractFormGroup.controls.amount.pristine && 
    (fixedContractFormGroup.dirty || fixedContractFormGroup.touched) && fixedContractFormGroup.controls.amount.status == 'INVALID'}">
    <span style="color: var(--border-red); font-size: 90%; margin-top: -10px; margin-bottom: 10px;" *ngIf="invalidAmount">
      Verifique el campo
    </span>
<!--     <label>Fecha Inicio</label>
    <div class="input-group" style="margin-bottom: 1vh;">
      <input class="form-control" placeholder="yyyy-mm-dd" formControlName="fromdate"
            name="dp"  ngbDatepicker #a="ngbDatepicker"
            [ngClass]="{'borderRed': fixedContractFormGroup.invalid && 
            (fixedContractFormGroup.dirty || fixedContractFormGroup.touched) && !fixedContractFormGroup.value.fromdate}">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="a.toggle()" type="button">
          <fa-icon [icon]="iconCalendar" size="1x"> </fa-icon>
        </button>
      </div>
    </div>
    <label>Fecha Final</label>
    <div class="input-group" style="margin-bottom: 1vh;">
      <input class="form-control" placeholder="yyyy-mm-dd" formControlName="todate"
            name="dp"  ngbDatepicker #b="ngbDatepicker"
            [ngClass]="{'borderRed': fixedContractFormGroup.invalid && 
            (fixedContractFormGroup.dirty || fixedContractFormGroup.touched) && !fixedContractFormGroup.value.todate}">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="b.toggle()" type="button">
          <fa-icon [icon]="iconCalendar" size="1x"> </fa-icon>
        </button>
      </div>
    </div> -->
  </div>
  <div class="buttons-submitting">
    <button class="btn btn-primary">Crear</button>
  </div>
</form>