import { Injectable } from '@angular/core';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { IPaginatedServiceParamters } from '../core/models/ITable';
import { Contract } from '../core/models/Contract';
import { ContractForAdd } from '../core/models/ContractForAdd';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  isContractAptForRenewal(contract: Contract): boolean {
    throw new Error('Method not implemented.');
  }
  isContractAptToFinish(contract: Contract): boolean {
    throw new Error('Method not implemented.');
  }
  query: string = '';
  personType: string = 'IsTenant';
  pageNumber: number = 1;
  itemsPerPage: number = 50;

  public searchTypes = [
    { value: 'IsTenant', description: 'Inquilino' },
    { value: 'IsOwner', description: 'Propietario' },
  ];

  constructor(private apiService: ApiService) {}

  getAllPaginated(
    paginatedArguments: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<Contract[]>> {
    return this.apiService.getPaginated(
      '/api/Contract/GetAllContracts',
      paginatedArguments,
    );
  }

  getContract(id): Observable<Contract> {
    return this.apiService.get(`/api/Contract/${id}/Detail`);
  }

  editContract(data): Observable<ContractForAdd> {
    return this.apiService.put(`/api/Contract`, data);
  }

  addTenantToContract(contractId, data): Observable<Contract> {
    return this.apiService.put(
      `/api/Contract/${contractId}/EditContractTenant`,
      data,
    );
  }

  getName(id): Observable<any> {
    return this.apiService.get(`/api/Contract/${id}/name`);
  }

  getContractDetail(id): Observable<Contract> {
    return this.apiService.get(`/api/Contract/${id}/detail`);
  }

  addContract(newContract): Observable<any> {
    return this.apiService.post('/api/Contract', newContract);
  }

  getAmounts(id): Observable<any> {
    return this.apiService.get(`/api/Contract/${id}/Amounts`);
  }

  getContractClauses(contractId): Observable<any> {
    return this.apiService.get(`/api/Contract/${contractId}/Clauses`);
  }

  addClause(newClause) {
    return this.apiService.post('/api/contract/AddClause', newClause);
  }

  addExtension(contractId, contractExtension) {
    return this.apiService.post(
      `/api/contract/${contractId}/Extension`,
      contractExtension,
    );
  }

  deleteExtension(extensionId) {
    return this.apiService.delete(`/api/contract/Extension/${extensionId}`);
  }

  cancelContract(contractId) {
    return this.apiService.put(`/api/contract/${contractId}/CancelContract`);
  }

  finishContract(contractId) {
    return this.apiService.put(`/api/contract/${contractId}/EndContract`);
  }

  deleteContract(id) {
    return this.apiService.delete(`/api/contract/${id}`);
  }

  /* SEARCH */
  searchTypeAhead(search: string) {
    if (search === '') {
      return of([]);
    }
    return this.search(search);
  }

  search(query) {
    return this.apiService.get(`/api/Contract/search`, query);
  }

  createQuery(queryString, personType): string {
    let query = '';
    if (personType != 'IsAddress') {
      isNaN(Number(queryString))
        ? (query = 'Name=' + queryString)
        : (query = 'DocumentId=' + queryString);
      query = query + `&${personType}=true&`;
      query =
        query +
        `PageNumber=${this.pageNumber}&CreatedOrNot=true&PageSize=${this.itemsPerPage}`;
    } else {
      query = 'Name=' + queryString + `&${personType}=true&`;
      query =
        query +
        `PageNumber=${this.pageNumber}&CreatedOrNot=true&PageSize=${this.itemsPerPage}`;
    }
    return query;
  }

  getAllInventoryItems(propertyId) {
    return this.apiService.get(
      `/api/Contract/${propertyId}/GetAllInventoryItems`,
    );
  }

  getContractMetadata(contractId) {
    return this.apiService.get(
      `/api/contract/${contractId}/RequirementsMetadata`,
    );
  }
  editAmountInFavor(contractId, amount) {
    return this.apiService.put(
      `/api/Contract/EditAmountInFavor/${contractId}`,
      amount,
    );
  }

  getContractFiles(contractId) {
    return this.apiService.get(`/api/Contract/${contractId}/Files/`);
  }

  getContractInstallmentIncreases(contractId) {
    return this.apiService.get(
      `/api/Contract/${contractId}/InstallmentIncreses/`,
    );
  }

  getContractInstallmentSchemas(contractId) {
    return this.apiService.get(
      `/api/Contract/${contractId}/InstallmentSchemas/`,
    );
  }
}
