<div class="title">
  <h1 *ngIf="!edit">Añadir Requerimiento</h1>
  <h1 *ngIf="edit">Editar Requerimiento</h1>
</div>
<div class="flex-column" style="margin: 1vh 1vw 1vh 1vw; width: 15vw;">
</div>

<form [formGroup]="requirementFormGroup" (ngSubmit)="submitRequirement()">
  <div class="form-building form-group">
    <div>
      <label>Nombre</label>
      <div>
        <input type="text" formControlName="name" class="form-control" [ngClass]="{'borderRed': validate('name')}">
        <span *ngIf="validate('name') && !requirementFormGroup.value.name"
          style="color: var(--border-red); font-size: 90%;">
          Dato Faltante
        </span>
      </div>

      <label>Tipo de generacion</label>
      <div class="flex-row" style="margin-bottom: 1vh;">
        <select class="form-control form-select" id="generationType" formControlName="generationType"
          [(ngModel)]="generationTypeValue" [ngClass]="{'borderRed': validate('generationType')}">
          <option disabled>...</option>
          <option value="1">Mensual</option>
          <option value="2">Bimestral</option>
          <!--<option *ngFor="let generationType of generationTypes" [value]="generationType.value">{{generationType.text}}</option>-->
        </select>
      </div>

      <div style="margin-bottom: 1vh;">
        <label>Icono</label>
        <app-icon-selector (selectIcon)="selectIcon($event)"></app-icon-selector>
        <label *ngIf="newIcon">Icono seleccionado: <fa-icon [icon]='newIcon'></fa-icon> </label>
        <span *ngIf="validate('icon') && !requirementFormGroup.value.icon"
          style="color: var(--border-red); font-size: 90%;">
          Dato Faltante
        </span>
      </div>


      <label>Parametros extras del requerimiento</label>
      <div class="flex-row">
        <input type="text" formControlName="extraRequirements" class="form-control" style="grid-area: inputReq"
          #extraRequirement>
        <button
          style="height: 35px; width: 40px; grid-area: button; place-self: center center; float: right ; margin-left: 5px;"
          class="btn btn-primary button-circle" (click)="addNewExtraRequirement(extraRequirement.value)" type="button"
          [ngbTooltip]="addUnitToolTip">
          <fa-icon [icon]="iconPlus" size="1x"></fa-icon>
        </button>
      </div>
      <br>
      <div style="grid-area: list; place-self: start start;">
        <div class="border-15 combo-pill">
          <ul class="list-group">
            <li class="list-group-item" *ngFor="let extraReq of extraRequirementList; let i = index">
              <fa-icon class="detele-icon hoverred" [icon]="iconTrash" size="1x" [ngbTooltip]="deleteTooltip"
                style="margin-right: 1vw;" (click)="deleteFromExtraRequirementList(i)">
              </fa-icon>
              {{extraReq}}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="buttons-submitting" style="display: flex; justify-content: space-between;">
    <button type="button" class="btn btn-danger" (click)="cancel() ">Cancelar</button>
    <button class="btn btn-primary" [disabled]="submitted" *ngIf="!edit">Crear</button>
    <button class="btn btn-primary" *ngIf="edit">Editar</button>
  </div>
</form>