import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contract-templates-handler',
  templateUrl: './contract-templates-handler.component.html',
  styleUrls: ['./contract-templates-handler.component.css'],
})
export class ContractTemplatesHandlerComponent implements OnInit {
  @ViewChild('BuildingForm') buildingForm: ElementRef;
  @ViewChild('OwnerForm') ownerForm: ElementRef;
  @ViewChild('TennantForm') tennantForm: ElementRef;
  @ViewChild('GuarantorForm') guarantorForm: ElementRef;

  @Output() closeFormEvent = new EventEmitter();
  @Input() dataForEdit: any;
  @Input() templateType: string;

  propertyList: false;
  contractInput: any = [];
  createInMemoryProperty: boolean = true;
  edit: boolean = false;

  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  openForm(dataForEdit) {
    this.dataForEdit = dataForEdit;
    if (this.dataForEdit) {
      this.edit = true;
      // if (this.dataForEdit.id) {
      //   this.createInMemoryProperty = false;
      // } else {
      //   this.createInMemoryProperty = true;
      // }
    } else {
      this.edit = false;
    }
    if (this.templateType == 'building') {
      this.modalService.open(this.buildingForm);
    }
    if (this.templateType == 'owner') {
      this.modalService.open(this.ownerForm);
    }
    if (this.templateType == 'tennant') {
      this.modalService.open(this.tennantForm);
    }
    if (this.templateType == 'guarantor') {
      this.modalService.open(this.guarantorForm);
    }
  }

  closeForm(event) {
    this.closeFormEvent.emit(event);

    this.modalService.dismissAll();
  }

  cancelForm() {
    this.modalService.dismissAll();
  }

  showDelete(value) {}

  deleteEvent(index) {}

  updateProperty(event) {}
}
