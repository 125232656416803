<div class="pdfBody" id='html-pdf'>
    <div class="header">

        <div class="main-info">
            <div class="logo">
                <img src="assets/img/baup-01.svg">
            </div>
            <div class="info">
                <span>BAUP S.A</span>
                <span>Avenida Siempreviva 742</span>
                <span>Teléfono: 4777-7420</span>
                <span>Mail: Bauponline&#64;gmail.com</span>
                <span>IVA RESPONDABLE INSCRIPTO</span>
            </div>
        </div>

        <div class="type">
            <h1>B</h1>
            <span>Cod.:07</span>
        </div>

        <div class="receipt-info">
            <div class="receipt-num">
                <span style="font-weight: 550;">
                    FACTURA N°
                </span>
                <span>
                    123123123
                </span>
            </div>
            <div class="dates">
                <div class="date">
                    <span>Fecha:</span>
                    <span>06/07/2020</span>
                    <span>08:26:45</span>
                </div>
            </div>
            <span>C.U.I.T: 30598702056</span>
            <span>Ingresos Brutos: C.M.901-1621231-2</span>
            <span>Inicio de Actividades: 01/04/2010</span>
        </div>
    </div>

    <div class="client">
        <div class="client-info">
            <span>OMAR CHADI</span>
            <span>DNI</span>
            <span>37-142772</span>
        </div>
        <div class="client-info">
            <span>CAPITAL FEDERAL</span>
            <span>A CONSUMIDOR FINAL</span>
        </div>
        
    </div>


    <div class="vendor">

    </div>

    <table class="table prod">
        <thead>
            <tr>
                <th>CANTIDAD</th>
                <th>DESCRIPCION</th>
                <th>PRECIO UNITARIO</th>
                <th>ALICUOTA IVA</th>
                <th>% IVA</th>
                <th>IMPORTE</th>
            </tr>
        </thead>
        
        <tbody>
            <tr>
                <td>1</td>
                <td>CTL4100</td>
                <td>100000</td>
                <td>0</td>
                <td>10.50</td>
                <td>10499</td>
            </tr>
        </tbody>

    </table>

    <div class="total">
        <span>TOTAL $</span>
        <span>10499</span>
    </div>

    <table class="table payment">
        <thead>
            <tr>
                <th>TARJETA</th>
                <th>CUPON</th>
                <th>CUOTAS</th>
                <th>IMPORTE</th>
            </tr>
        </thead>
        
        <tbody>
            <tr>
                <td>MERCADO PAGO</td>
                <td>44265100</td>
                <td>1</td>
                <td>10499</td>
            </tr>
        </tbody>
    </table>

    <hr>

    <div class="footer">
        <span>147 TELÉFONO GRATUITO CABA, AREA DE DEFENSA Y PROTECCION AL CONSUMIDOR</span>
        <div class="bar-toDate">
            <div style="background-color: black; width: 100px; height: 50px;">
            </div>
            <div class="flex-column">
                <span>CAE: 1234967</span>
                <span>Vencimiento: 26-07-78 </span>
            </div>
        </div>
    </div>

</div>