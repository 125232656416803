<div class="title">
  <h1>Crear Factura</h1>
</div>
<form [formGroup]="facturaDataFormGroup" (ngSubmit)="onSubmit()">
  <div class="form-building form-group">
    <div class="container">
      <div class="row">
        <hr>
        <h3>Datos del emisor</h3>
      </div>
      <div class="row">
        <div class="col-sm">
          <label>Nombre del Emisor</label>
          <select class="form-control form-select" (change)="updateEmisor($event.target.value)" 
            id="emisorNombre" [ngModel]="emisores.length == 1 ? 0 : ''"
            [ngModelOptions]="{standalone: true}" [ngClass]="{'borderRed': validate('emisorNombre')}"
            *ngIf="!facturaAsociada && terceros == null">
            <option [ngValue]="''" disabled>Seleccione un individuo</option>
            <option *ngFor="let emisor of emisores; let ind = index" [value]="ind">
              {{emisor.socialReason}}
            </option>
          </select>
          <select class="form-control form-select" (change)="updateEmisor($event.target.value)" 
            id="emisorNombre" [ngModel]="terceros.length == 1 ? 0 : ''"
            [ngModelOptions]="{standalone: true}" [ngClass]="{'borderRed': validate('emisorNombre')}"
            *ngIf="!facturaAsociada && terceros != null">
            <option [ngValue]="''" disabled>Seleccione un individuo</option>
            <option *ngFor="let tercero of terceros; let ind = index" [value]="ind">
              {{tercero.fullName}}
            </option>
          </select>
          <span class="row" style="font-size: 1rem; padding: 10px; margin-left: 5px;" *ngIf="facturaAsociada">{{facturaDataFormGroup.value.emisorNombre}}</span>
        </div>
        <div class="col-sm">
          <label>CUIT del Emisor</label>
          <span class="row" style="font-size: 1rem; padding: 10px; margin-left: 5px;">{{facturaDataFormGroup.value.cuit}}</span>
        </div>
        <div class="col-sm" *ngIf="terceros == null">
          <label>Inicio de Actividades</label>
          <div class="input-group" style="margin-bottom: 1vh;"hidden>
            <input class="form-control" placeholder="AAAA-MM-DD" formControlName="inicioActividades" name="dp"
              ngbDatepicker [readonly]="true" [minDate]="{year: 1800, month: 1, day: 1}" #inicioActividadesInput>
            <div class="input-group-append">
            </div>
          </div>
          <span class="row"  style="font-size: 1rem; padding: 10px; margin-left: 5px;">{{inicioActividadesInput.value | date:'yyyy-MM-dd'}}</span>
        </div>
        <div class="col-sm" *ngIf="!facturaAsociada && terceros != null">
          <label>Inicio de Actividades</label>
          <div class="input-group" style="margin-bottom: 1vh;">
            <input class="form-control" placeholder="AAAA-MM-DD" formControlName="inicioActividades" name="dp"
              ngbDatepicker #z="ngbDatepicker" [ngClass]="{'borderRed': validate('inicioActividades')}">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="z.toggle()" type="button">
                <fa-icon [icon]="iconCalendar" size="1x"> </fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <label>Domicilio del Emisor</label>
          <span class="row" style="font-size: 1rem; padding: 10px; margin-left: 5px;">{{facturaDataFormGroup.value.emisorDomicilio}}</span>
        </div>
        <div class="col-sm">
          <label>Punto De Venta</label>
          <span class="row" style="font-size: 1rem; padding: 10px; margin-left: 5px;"
            *ngIf="terceros == null">
            {{facturaDataFormGroup.value.puntoDeVenta}}
          </span>
          <input placeholder="0002" type="number" formControlName="puntoDeVenta" class="form-control"
            [ngClass]="{'borderRed': validate('puntoDeVenta')}" *ngIf="!facturaAsociada && terceros != null">
        </div>
        <div class="col-sm">
          <label>Tipo de Factura</label>
          <div class="flex-row" style="margin-bottom: 1vh;">
            <select class="form-control form-select" id="tipoDeFactura" formControlName="tipoDeFactura"
              [ngClass]="{'borderRed': validate('tipoDeFactura')}" *ngIf="!facturaAsociada && terceros == null">
              <option [value]="''" disabled>Seleccione un tipo de factura</option>
              <option *ngFor="let factura of facturaType" [value]="factura.value">{{factura.text}}</option>
            </select>
          <span class="row" style="font-size: 1rem; padding: 10px; margin-left: 5px;" *ngIf="facturaAsociada || terceros != null">{{facturaTipoTexto ?? notaTipoTexto}}</span>
          </div>
          <span *ngIf="validate('tipoDeFactura') && !facturaAsociada
            && !facturaDataFormGroup.value.tipoDeFactura" style="color: var(--border-red); font-size: 90%;">
            Dato Faltante
          </span>
        </div>
      </div>
      <hr>
      <div class="row">
        <h3> Datos del Consumidor</h3>
      </div>
      <div class="row">
        <div class="col-sm" *ngIf="!facturaAsociada">
          <label>Nombre del Consumidor</label>
          <div class="flex-row search-div" style="width: 100%;" *ngIf="consumidores.length == 0"
            [ngClass]="{'borderRed highlight': validate('consumidorNombre')}">
            <input class="search-input" type="text" style="width: 100%;" 
            [(ngModel)]="searchString" 
            [ngModelOptions]="{standalone: true}" 
            (selectItem)="setResult($event)"
            [ngbTypeahead]="search" 
			      [resultTemplate]="rt"
            placement="bottom-left"
            [inputFormatter]="formatResult"
            placeholder="Escriba el nombre del individuo o parte del mismo.">
            <span *ngIf="searching && searchString != ''">Buscando...</span>
            <fa-icon class="shadowframe cursorpointer button-circle" 
            [icon]="iconClear" size="lg" style="color:red; margin: 0 10px;" 
            (click)="clearSearch()" [ngbTooltip]="'Borrar Búsqueda'" *ngIf="searchString != ''"></fa-icon>
          </div>

          <select class="form-control form-select" (change)="updateConsumidor($event.target.value)" 
            id="consumidorNombre" [ngModel]="consumidores.length == 2 ? 1 : ''"
            [ngModelOptions]="{standalone: true}" [ngClass]="{'borderRed': validate('consumidorNombre')}" 
            *ngIf="consumidores.length != 0">
            <option [ngValue]="''" disabled>Seleccione un individuo</option>
            <option *ngFor="let consumidor of consumidores; let ind = index" [value]="ind">
              {{consumidor.fullName}}
            </option>
          </select>
        </div>
        <div class="col-sm" *ngIf="facturaAsociada">
          <label>Nombre del Consumidor</label>
          <span class="row" style="font-size: 1rem; padding: 10px; margin-left: 5px;">{{facturaDataFormGroup.value.consumidorNombre}}</span>
        </div>
        <div class="col-sm">
          <label>Domicilio del Consumidor</label>
          <span class="row" style="font-size: 1rem; padding: 10px; margin-left: 5px;">{{facturaDataFormGroup.value.consumidorDomicilio}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <label>Tipo de Documento</label>
          <span class="row" style="font-size: 1rem; padding: 10px; margin-left: 5px;">{{facturaDataFormGroup.value.consumidorDocumentoTipo}}</span>
        </div>
        <div class="col-sm">
          <label>Numero de Documento</label>
          <span class="row" style="font-size: 1rem; padding: 10px; margin-left: 5px;">{{facturaDataFormGroup.value.consumidorDocumentoNumero}}</span>
        </div>
      </div>
      <hr>
      <div class="row">
        <h3> Datos de la factura</h3>
      </div>
      <div class="row">
        <div class="col-sm">
          <label>Monto Total</label>
          <input placeholder="2500" type="number" formControlName="importeTotal" class="form-control"
            [ngClass]="{'borderRed': validate('importeTotal')}" *ngIf="!facturaAsociada">
          <span class="row" style="font-size: 1rem; padding: 10px; margin-left: 5px;" *ngIf="facturaAsociada">{{facturaDataFormGroup.value.importeTotal}}</span>
          <span *ngIf="validate('importeTotal') && !facturaAsociada
                    && !facturaDataFormGroup.value.importeTotal" style="color: var(--border-red); font-size: 90%;">
            Dato Faltante
          </span>
        </div>
        <div class="col-sm">
          <label>Fecha de Emision</label>
          <div class="input-group" style="margin-bottom: 1vh;">
            <input class="form-control" placeholder="AAAA-MM-DD" formControlName="fechaEmision" name="dp" ngbDatepicker
              #c="ngbDatepicker" [minDate]="{year: minDate.getFullYear(), month: minDate.getMonth()+1, day: minDate.getDate()}" 
              [maxDate]="{year: maxDate.getFullYear(), month: maxDate.getMonth()+1, day: maxDate.getDate()}"
              [ngClass]="{'borderRed': validate('fechaEmision')}">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="c.toggle()" type="button">
                <fa-icon [icon]="iconCalendar" size="1x"> </fa-icon>
              </button>
            </div>
          </div>
          <span *ngIf="validate('fechaEmision') 
                    && !facturaDataFormGroup.value.importeTotal" style="color: var(--border-red); font-size: 90%;">
            Dato Faltante
          </span>
        </div>
        <div class="col-sm">
          <label>Fecha de Vencimiento</label>
          <div class="input-group" style="margin-bottom: 1vh;">
            <input class="form-control" placeholder="AAAA-MM-DD" formControlName="fechaVencimientoPago" name="dp"
              ngbDatepicker #e="ngbDatepicker" [minDate]="{year: minDate.getFullYear(), month: minDate.getMonth()+1, day: minDate.getDate()}"
              [ngClass]="{'borderRed': validate('fechaVencimientoPago')}">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="e.toggle()" type="button">
                <fa-icon [icon]="iconCalendar" size="1x"> </fa-icon>
              </button>
            </div>
          </div>
          <span *ngIf="validate('fechaVencimientoPago') 
                    && !facturaDataFormGroup.value.importeTotal" style="color: var(--border-red); font-size: 90%;">
            Dato Faltante
          </span>
        </div>
      </div>
      <div class="row" *ngIf="taxRole == 2">
        <div class="col-sm">
          <label>Importe Exento</label>
          <input placeholder="2500" type="number" formControlName="importeExento" class="form-control"
            [ngClass]="{'borderRed': validate('importeExento')}">
          <span *ngIf="validate('importeExento') && !facturaAsociada
                    && !facturaDataFormGroup.value.importeExento" style="color: var(--border-red); font-size: 90%;">
            Dato Faltante
          </span>
        </div>
        <div class="col-sm">
          <label>Importe Gravado</label>
          <input placeholder="2500" type="number" formControlName="importeGravado" class="form-control"
            [ngClass]="{'borderRed': validate('importeGravado')}">
          <span *ngIf="validate('importeGravado')
                    && !facturaDataFormGroup.value.importeGravado" style="color: var(--border-red); font-size: 90%;">
            Dato Faltante
          </span>
        </div>
        <div class="col-sm">
          <label>Importe IVA</label>
          <input placeholder="2500" type="number" formControlName="importeIva" class="form-control"
            [ngClass]="{'borderRed': validate('importeIva')}">
          <span *ngIf="validate('importeIva')
                    && !facturaDataFormGroup.value.importeIva" style="color: var(--border-red); font-size: 90%;">
            Dato Faltante
          </span>
        </div>
        <div class="col-sm">
          <label>Tipo de IVA</label>
          <select class="form-control form-select" id="iVATipoId" formControlName="iVATipoId"
            [ngClass]="{'borderRed': validate('iVATipoId')}">
            <option [value]="3">0%</option>
            <option [value]="4">10.5%</option>
            <option [value]="5">21%</option>
            <option [value]="6">27%</option>
            <option [value]="8">5%</option>
            <option [value]="9">2.5%</option>
          </select>
          <span *ngIf="validate('iVATipoId')
                    && !facturaDataFormGroup.value.iVATipoId" style="color: var(--border-red); font-size: 90%;">
            Dato Faltante
          </span>
        </div>
      </div>
      <hr>
      <div class="row" *ngIf="facturaAsociada">
        <h3> Datos de la factura asociada</h3>
      </div>
      <div class="row" *ngIf="facturaAsociada">
        <div class="col-sm">
          <label>Tipo de factura</label>
          <span class="row" style="font-size: 1rem; padding: 10px; margin-left: 5px;">{{notaFacturaTexto}}</span>
        </div>
        <div class="col-sm">
          <label>Punto de venta</label>
          <span class="row" style="font-size: 1rem; padding: 10px; margin-left: 5px;">{{facturaDataFormGroup.value.pdvAsociado}}</span>
        </div>
        <div class="col-sm">
          <label>Numero de comprobante</label>
          <span class="row" style="font-size: 1rem; padding: 10px; margin-left: 5px;">{{facturaDataFormGroup.value.numeroFacturaAsociado}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="buttons-submitting" style="display: flex; justify-content: space-between;">
    <button type="button" class="btn btn-danger" [disabled]="submitted" (click)="cancel()">Cancelar</button>
    <button class="btn btn-primary" [disabled]="submitted">Crear</button>
  </div>
</form>

<ng-template #rt let-r="result" let-t="term">
  <fa-icon [icon]="iconSearch" size="md" style="margin-right: 10px; margin-top: 10px;"></fa-icon>
<ngb-highlight style="text-overflow: ellipsis;" [result]="formatResult(r, false)" [term]="t"></ngb-highlight>
</ng-template>