import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { faCalendar, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LiabilitiesService } from 'src/app/services/liabilities.service';
import { DateService } from 'src/app/services/date.service';
import { IconService } from 'src/app/services/icon.service';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { RetentionService } from 'src/app/services/retention.service';
import { ToastService } from 'src/app/services/toast.service';
import { RetentionForAdd } from 'src/app/core/models/Retention';

@Component({
  selector: 'app-retention-form',
  templateUrl: './retention-form.component.html',
  styleUrls: ['./retention-form.component.scss'],
})
export class RetentionFormComponent implements OnInit {
  iconQuestion = faQuestionCircle;
  iconCalendar = faCalendar;
  selectedInstallmentsIds: number[] = [];
  installmentRequirements = [];
  installmentIdList: any[] = [];
  edit: boolean = false;

  reqAccKeys: string[] = [];
  reqAccumulator = {};
  noReq: boolean = false;
  requirementSelectedName: string = '';
  selectedRequirement: boolean = false;
  submitted: boolean = false;

  @Input() installments?: any[];
  @Input() editData?: any;
  @Input() contractRequirementId?: number;
  @Input() contractId?: number;

  @Output() closeAction = new EventEmitter();
  @Output() createRetentionEvent = new EventEmitter();
  @Output() editRetentionEvent = new EventEmitter();

  retentionFormGroup = new UntypedFormGroup({
    amount: new UntypedFormControl('', [
      Validators.required,
      Validators.min(0),
      Validators.max(2147483647),
    ]),
    detail: new UntypedFormControl('', Validators.required),
  });
  constructor(
    public modalService: NgbModal,
    private liabilityService: LiabilitiesService,
    private dateService: DateService,
    public iconService: IconService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.loadAccCards();

    /*     if (this.editData) {
      console.log(this.editData, this.reqAccumulator)
      this.edit = true; */
    /* this.installments = []; */
    /*       this.editData.installmentRequirements.forEach(instReqEdit => {
        var installment = this.reqAccumulator[instReqEdit.reqName].installments[i]
        this.contractRequirementId = this.reqAccumulator[this.requirementSelectedName][0]?.instReqState.contractRequirementId;
            installment.selected = true;
            let instReq = {
              contractId: installment.contractId,
              contractRequirementId: this.contractRequirementId,
              installmentId: installment.id,
              amount: 0,
              installmentStatusId: 1,
              installment: installment,
              details: '',
              date: '',
              dateTemp: '',
              reqName:  JSON.parse(JSON.stringify(this.requirementSelectedName))
            };
            this.installmentRequirements.push(instReq);
            this.selectedInstallmentsIds.push(installment.id);
      }); */

    /*       this.installmentRequirements = this.editData.installmentRequirements;

      this.retentionFormGroup.patchValue({
        amount: this.editData.amount,
        detail: this.editData.detail,
      }); */
    /*       this.editData.installmentRequirements.forEach(instReq => {
        let instreq = instReq as unknown as InstallmentRequirement
        this.installments.push(instreq.installment);
        this.selectedInstallmentsIds.push(instreq.id)
      }) */
    /*   }  */
  }

  selectReq(reqName) {
    this.requirementSelectedName = reqName;
    this.selectedRequirement = true;
  }

  loadAccCards() {
    this.reqAccKeys = [];
    this.liabilityService
      .getInstallmentRequirementContract(this.contractId)
      .subscribe((result: any[]) => {
        result[0].installmentRequirementStates.forEach((state) => {
          this.reqAccumulator[state.requirementName] = [];
        });
        result.forEach((element) => {
          let elementDate = new Date(element.installment.endDate);
          let todayInstallment = new Date();
          if (elementDate.getTime() <= todayInstallment.getTime()) {
            element.installmentRequirementStates.forEach((elementState) => {
              elementState.installmentRequirement;
              if (
                !elementState.installmentRequirement &&
                elementState.installmentRequirement?.installmentStatusId != 1
              ) {
                this.reqAccumulator[elementState.requirementName].push({
                  instReqState: elementState,
                  installment: element.installment.id,
                });
                if (
                  this.reqAccumulator[elementState.requirementName][
                    'installments'
                  ]
                ) {
                  this.reqAccumulator[elementState.requirementName][
                    'installments'
                  ].push(JSON.parse(JSON.stringify(element.installment)));
                } else {
                  this.reqAccumulator[elementState.requirementName][
                    'installments'
                  ] = [];
                  this.reqAccumulator[elementState.requirementName][
                    'installments'
                  ].push(JSON.parse(JSON.stringify(element.installment)));
                }
              }
            });
          }
        });

        let tempKeys = Object.keys(this.reqAccumulator);
        tempKeys.forEach((key) => {
          this.reqAccumulator[key].length <= 0
            ? delete this.reqAccumulator[key]
            : false;
        });

        this.reqAccKeys = Object.keys(this.reqAccumulator);

        if (this.reqAccKeys.length == 0) {
          this.noReq = true;
        } else {
          this.noReq = false;
        }
      });
  }

  addInstallment(i) {
    var installment =
      this.reqAccumulator[this.requirementSelectedName].installments[i];
    this.contractRequirementId =
      this.reqAccumulator[
        this.requirementSelectedName
      ][0]?.instReqState.contractRequirementId;
    if (!installment.selected) {
      installment.selected = true;
      let instReq = {
        contractId: installment.contractId,
        contractRequirementId: this.contractRequirementId,
        installmentId: installment.id,
        amount: 0,
        installmentStatusId: 1,
        installment: installment,
        details: '',
        date: '',
        dateTemp: '',
        reqName: JSON.parse(JSON.stringify(this.requirementSelectedName)),
      };
      this.installmentRequirements.push(instReq);
      this.selectedInstallmentsIds.push(installment.id);
    } else {
      installment.selected = false;
      let index = null;
      this.installmentRequirements.forEach((instReq, ind) => {
        instReq.installment.id == installment.id ? (index = ind) : false;
      });
      this.installmentRequirements.splice(index, 1);
      let ind = this.selectedInstallmentsIds.indexOf(installment.id);
      this.selectedInstallmentsIds.splice(ind, 1);
    }
  }

  changeFilterData(data) {
    data.paymentDate = this.dateService.transformToDateObject(
      data.dateTemp.day,
      data.dateTemp.month,
      data.dateTemp.year,
    );
  }

  createRetention() {
    let retentionToSubmit: RetentionForAdd;

    this.installmentRequirements.forEach((instReq) => {
      instReq.installmentStatusId = 2;
    });

    retentionToSubmit = {
      amount: this.retentionFormGroup.value.amount
        ? this.retentionFormGroup.value.amount
        : null,
      detail: this.retentionFormGroup.value.detail
        ? this.retentionFormGroup.value.detail
        : null,
      approvedState: false,
      installmentRequirements: this.installmentRequirements,
      paymentId: 0,
    };

    if (this.retentionFormGroup.invalid) {
      this.toastService.showToast(
        'warning',
        'Hay campos obligatorios sin completar',
      );
      this.retentionFormGroup.markAllAsTouched();
      return;
    } else {
      if (this.editData) {
        this.editRetentionEvent.emit(retentionToSubmit);
      } else {
        this.submitted = true;
        this.createRetentionEvent.emit(retentionToSubmit);
      }
    }
  }

  close() {
    this.closeAction.emit();
    this.toastService.showToast('success', 'Retención creada');
    /* this.closeAction.emit(retentionToSubmit); */
  }

  validate(field: string) {
    return (
      this.retentionFormGroup.controls[field]?.errors &&
      this.retentionFormGroup.controls[field]?.touched
    );
  }

  getDate(installment) {
    let stringDate = new Date(installment.fromDate).toLocaleDateString(
      'es-es',
      { year: 'numeric', month: 'short' },
    );
    return stringDate.charAt(0).toUpperCase() + stringDate.slice(1);
  }
}
