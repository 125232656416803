import { Injectable } from '@angular/core';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { Observable, of } from 'rxjs';
import { IPaginatedServiceParamters } from '../core/models/ITable';
import { Owner } from '../core/models/Owner';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class OwnerService {
  constructor(private apiService: ApiService) {}

  getAllPaginated(
    paginatedArguments: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<Owner[]>> {
    return this.apiService.getPaginated(
      '/api/owner/getallowners',
      paginatedArguments,
    );
  }

  searchOwners(
    query?,
    page?,
    itemsPerPage?,
  ): Observable<PaginatedResult<Owner[]>> {
    var paginatedArguments: IPaginatedServiceParamters = {
      pageNumber: page,
      pageSize: itemsPerPage,
      query: query,
    };
    return query.substring(query.indexOf('=')+1).length > 2 ? this.apiService.getPaginated('/api/Owner', paginatedArguments) : of(null);
  }

  getOwner(Id) {
    return this.apiService.get(`/api/Owner/${Id}`);
  }

  deleteOwner(id) {
    return this.apiService.delete(`/api/owner/${id}`);
  }

  updateOwner(id, updateOwner) {
    return this.apiService.put(`/api/owner/${id}`, updateOwner);
  }

  createOwner(newOwner) {
    return this.apiService.post(`/api/owner`, newOwner);
  }
}
