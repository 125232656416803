<nav class="navbar shadow-sm" #navbar>
  <div class="logo">
    <button color="primary" class="toggle-sidebar" (click)="toggleSidebar()" [ngbTooltip]="toolTip">
      <fa-icon size="2x" [icon]="sidebarIcon"></fa-icon>
    </button>
    <img src="assets/img/baup-02.svg" (click)="redirect()">
    <div style="display: flex; flex-direction: row;">
      <span style="align-self: center;" class="badge text-bg-warning">BETA</span>
      <span
        style="color: rgb(160, 160, 160); letter-spacing: 1px; font-size: 0.9rem; font-weight: 450; margin-left: 5px;">
        v0.2.3</span>
    </div>
  </div>
  <div class="contractSearch">
    <select (change)="personType = $event.target.value" class="selector form-select"
      style="border:none; color: #adadad; width: 23%;">
      <option *ngFor="let person of personTypes | keyvalue: returnZero" [value]="person.value">
        {{person.key}}
      </option>
    </select>
    <app-search [gridMode]="false" [dashboardMode]="false" [headerMode]="true" [parentService]="contractService"
      [personType]="personType" (setResultsEvent)="getSearchResult($event)"></app-search>
    <div class="flex-row centered search-div">
    </div>
    <button style="margin-right: 5px;" class="btn btn-primary" (click)="goToContract()"> Ir </button>
  </div>
  <div class="agent">
    <div class="numberCircle" (click)="toggleSubmenu()" #avatar>{{ avatarInitial | uppercase }}</div>
  </div>
  <div class="submenu flex-column" #submenu *ngIf="submenuVisible">
    <div class="flex-row" style="padding: 10px 15px;">
      <span><b *ngIf="auth.user$ | async as user">{{user.name}}</b></span>
    </div>
    <div class="flex-row list-item" (click)="redirectToAgent()">
      <fa-icon style="margin-right: 10px" [icon]="userTie" size="1x"></fa-icon>
      <span>Usuario</span>
    </div>
    <div class="flex-row list-item" (click)="redirectToConfig()">
      <fa-icon style="margin-right: 10px" [icon]="gear" size="1x"></fa-icon>
      <span>Configuración</span>
    </div>
    <div class="flex-row list-item">
      <fa-icon style="margin-right: 10px" [icon]="exit" size="1x"></fa-icon>
      <app-auth-button></app-auth-button>
    </div>
  </div>
</nav>
