import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: '[appLimitDirective]',
})
export class TwoDigitDecimaNumberDirective {
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    '-',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
  ];
  constructor(private el: ElementRef) {}
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    if (
      current.substring(current.indexOf('.')).length > 2 &&
      current.indexOf('.') > 0
    ) {
      event.preventDefault();
    }
  }
}
