
<app-toast class="toast-container bottom-0 end-0 p-3" aria-live="polite" aria-atomic="true"></app-toast>
<div class="root"  [ngClass]="{'hide-sidebar': !sidebarExpanded}">
  <div class="sidebar">
    <app-sidebar (sidebarState)="!sidebarExpanded = $event" *ngIf="sidebarExpanded"></app-sidebar>
  </div>
  <div class="main">
    <div class="header">
      <app-header (toggleSideNav)="toggleSidebar()" [expanded]="sidebarExpanded"></app-header>
    </div>
    <div class="home">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </div>
</div>