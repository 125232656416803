import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  faCalendar,
  faEye,
  faPlus,
  faSearch,
  faTrash,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import { DateService } from 'src/app/services/date.service';
import { ToastService } from 'src/app/services/toast.service';
import { CuitService } from 'src/app/services/cuit.service';
import { CUITDataForAdd } from 'src/app/core/models/CUIT';
import { ivaCondition } from 'src/app/core/models/IvaCondition.enum';

@Component({
  selector: 'app-cuit-form',
  templateUrl: './cuit-form.component.html',
  styleUrls: ['./cuit-form.component.scss'],
})
export class CuitFormComponent implements OnInit {
  @Input() edit?: boolean;
  @Input() viewObject?: boolean = true;
  @Input() editData?: any;
  @Input() first: boolean;
  @Output() cancelAction = new EventEmitter();
  @Output() closeAction = new EventEmitter();


  iconCalendar = faCalendar;
  iconSearch = faSearch;
  iconPlus = faPlus;
  iconSee = faEye;
  iconTrash = faTrash;
  iconEdit = faPen;

  index: number;
  ivaTypes: any[] = [];
  receiptTypes: any[] = [];
  sellPoint: number;
  cuitToSubmit: CUITDataForAdd;
  editSp: boolean;
  submitted: boolean = false;
  minDate: Date;



  cuitDataFormGroup = new UntypedFormGroup({
    cuitNumber: new UntypedFormControl('', Validators.required),
    ivaAccountableType: new UntypedFormControl(''),
    socialReason: new UntypedFormControl('', Validators.required),
    ibbAccountableType: new UntypedFormControl('0'),
    address: new UntypedFormControl('', Validators.required),
    sellPoints: new UntypedFormControl('', [Validators.min(0), Validators.max(2147483647)]),
    activityStartDate: new UntypedFormControl('', Validators.required),
  });


  constructor(
    public cuitService: CuitService,
    public dateService: DateService,
    private toastService: ToastService,
  ) {
    this.minDate = new Date()
    this.minDate.setDate(this.minDate.getDate()-5);
  }

  ngOnInit() {
    for (let item in ivaCondition) {
      if (isNaN(Number(item))) {
        this.ivaTypes.push({ text: item, value: ivaCondition[item] });
      }
    }

    if (this.edit) {
      this.index = this.editData.id;
      this.cuitDataFormGroup.patchValue({
        cuitNumber: this.editData.cuitNumber,
        ivaAccountableType: this.editData.ivaAccountableType,
        socialReason: this.editData.socialReason,
        address: this.editData.address,
        ibbAccountableType: this.editData.ibbAccountableType,
        sellPoints: this.editData.sellPoints,
        activityStartDate: this.dateService.transformToDateHash(
          new Date(this.editData.activityStartDate),
        ),
      });
    }
  }

  submitCuit() {
    let date = this.cuitDataFormGroup.value.activityStartDate
      ? this.cuitDataFormGroup.value.activityStartDate
      : null;

    this.cuitToSubmit = {
      cuitNumber: this.cuitDataFormGroup.value.cuitNumber
        ? this.cuitDataFormGroup.value.cuitNumber
        : null,
      ivaAccountableType: this.cuitDataFormGroup.value.ivaAccountableType
        ? this.cuitDataFormGroup.value.ivaAccountableType
        : 1,
      socialReason: this.cuitDataFormGroup.value.socialReason
        ? this.cuitDataFormGroup.value.socialReason
        : null,
      address: this.cuitDataFormGroup.value.address
        ? this.cuitDataFormGroup.value.address
        : '',
      activityStartDate: this.dateService.transformToDateObject(
        date?.day,
        date?.month,
        date?.year,
      ),
      ibbAccountableType: this.cuitDataFormGroup.value.ibbAccountableType
      ? this.cuitDataFormGroup.value.ibbAccountableType
      : '',
      sellPoints: this.cuitDataFormGroup.value.sellPoints
      ? this.cuitDataFormGroup.value.sellPoints
      : '',
    };

    if (this.cuitDataFormGroup.get('activityStartDate').hasError('ngbDate')) 
    {
      this.toastService.showToast(
        'warning',
        'Corrija el formato de la fecha a AAAA-MM-DD',
      ); 
      return;
    }
    if (this.cuitDataFormGroup.invalid) {
      this.cuitDataFormGroup.markAllAsTouched();
      this.toastService.showToast(
        'warning',
        'Hay campos obligatorios sin completar',
      );
      return;
    } else {
      if (this.edit) {
          this.editCuit(this.cuitToSubmit); 
      } else {
        this.submitted = true;
        this.addCuit(this.cuitToSubmit);
      }
    }
  }

  addCuit(cuitToSubmit) {
    this.cuitService.addCuit(cuitToSubmit).subscribe((response) => {
      this.toastService.showToast('success', 'Cuit agregado');
      this.closeAction.emit();
    },
    (err) => {
      this.toastService.showToast('error', 'Ha ocurrido un error');
      this.submitted = false;
    });
  }

  editCuit(cuitToEdit) {
    this.cuitService
      .editCuit(this.editData.id, cuitToEdit)
      .subscribe((response) => {
        this.toastService.showToast('success', 'Cuit modificado');
        this.closeAction.emit();
        
      });
  }

  validate(field: string) {
    return (
      this.cuitDataFormGroup.controls[field].errors &&
      this.cuitDataFormGroup.controls[field].touched
    );
  }

  cancel() {
    this.cancelAction.emit();
  }
}
