import { Injectable } from '@angular/core';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { Observable } from 'rxjs';
import { IPaginatedServiceParamters } from '../core/models/ITable';
import { ExpenditurePropertyToPay } from '../core/models/ExpenditurePropertyToPay';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AdditionalChargesService {
  constructor(private apiService: ApiService) {}

  getAllPaginated(
    paginatedArguments: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<any[]>> {
    return this.apiService.getPaginated(
      `/api/AdditionalCharge`,
      paginatedArguments,
    );
  }

  getAdditionalCharge(id): Observable<ExpenditurePropertyToPay[]> {
    return this.apiService.get(`/api/AdditionalCharge/${id}`);
  }

  addAdditionalCharge(additionalCharge): Observable<number> {
    return this.apiService.post(`/api/AdditionalCharge`, additionalCharge);
  }

  deleteAdditionalCharge(id): Observable<any[]> {
    return this.apiService.delete(`/api/AdditionalCharge/${id}`);
  }

  editAdditionalCharge(id, additionalCharge): Observable<any[]> {
    return this.apiService.put(`/api/AdditionalCharge/${id}`, additionalCharge);
  }
}
