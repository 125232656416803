import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { CUITData } from '../core/models/CUIT';
import { Observable, of } from 'rxjs';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { IPaginatedServiceParamters } from '../core/models/ITable';
import { Sellpoint } from '../core/models/Sellpoint';
import { Person } from '../core/models/Person';

@Injectable({
  providedIn: 'root',
})
export class CuitService {
  constructor(private apiService: ApiService) {}

  getAllPaginated(
    paginatedArguments: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<CUITData[]>> {
    return this.apiService.getPaginated(
      '/api/CuitData/GetAllCuitData',
      paginatedArguments,
    );
  }

  getAll(): Observable<CUITData[]> {
    return this.apiService.get('/api/CuitData/GetCuitData');
  }

  getAllRecipients(query: string): Observable<Person[]> {
    if (query === '') {
      return of([]);
    }
    return this.apiService.get('/api/CuitData/GetCuitRecipients', query);
  }  

  getCuit(id): Observable<CUITData> {
    return this.apiService.get(`/api/CuitDataOwner/${id}`);
  }

  addCuit(newCuit): Observable<any> {
    return this.apiService.post('/api/CuitData/', newCuit);
  }

  editCuit(id, cuit): Observable<any> {
    return this.apiService.put(`/api/CuitData/${id}`, cuit);
  }

  deleteCuit(id) {
    return this.apiService.delete(`/api/CuitData/${id}`);
  }

  /*   getAllSellPoint(paginatedArguments?: IPaginatedServiceParamters): Observable<PaginatedResult<Sellpoint[]>> {
    return this.apiService.getPaginated("/api/CuitData/SellPoint/GetAllSellPoint");
  } */
}
