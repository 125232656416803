  <div class="iconGrid-container border" >
    <button *ngFor="let item of iconList; let i=index" type="button"
        class="btn btn-primary"
        placement="bottom"
        (click) = "iconSelected(item)"
        [ngClass] = "{'selected': selected == item.iconName }"
        >
          <div>
            <fa-icon [icon] = 'item' ></fa-icon>
          </div>
    </button>
  </div>










