import { Injectable } from '@angular/core';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { Observable, of } from 'rxjs';
import { IPaginatedServiceParamters } from '../core/models/ITable';

import { ApiService } from './api.service';
import { Tenant } from '../core/models/Tenant';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  query: string = '';
  pageNumber: number = 1;
  itemsPerPage: number = 50;

  public searchTypes = [
    { value: 'DocumentId', description: 'DNI' },
    { value: 'Name', description: 'Nombre' },
  ];

  constructor(private apiService: ApiService) {}

  getAllPaginated(
    paginatedArguments: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<Tenant[]>> {
    return this.apiService.getPaginated(
      '/api/tenant/GetAllTenants',
      paginatedArguments,
    );
  }

  searchTenants(
    query?,
    page?,
    itemsPerPage?,
  ): Observable<PaginatedResult<Tenant[]>> {
    var paginatedArguments: IPaginatedServiceParamters = {
      pageNumber: page,
      pageSize: itemsPerPage,
      query: query,
    };
    return query.substring(query.indexOf('=')+1).length > 2 ? this.apiService.getPaginated('/api/tenant', paginatedArguments) : of(null);
  }

  getTenant(id) {
    return this.apiService.get(`/api/tenant/${id}`);
  }

  deleteTenant(id) {
    return this.apiService.delete(`/api/tenant/${id}`);
  }

  updateTenant(id, updateTenant) {
    return this.apiService.put(`/api/tenant/${id}`, updateTenant);
  }

  createTenant(newTenant) {
    return this.apiService.post(`/api/tenant`, newTenant);
  }

  /* SEARCH */
  searchTypeAhead(search: string) {
    if (search === '') {
      return of([]);
    }
    return this.search(search);
  }

  search(query) {
    return this.apiService.get(`/api/Tenant`, query);
  }

  createQuery(queryString, personType): string {
    let query = '';
    isNaN(Number(queryString))
      ? (query = 'Name=' + queryString)
      : (query = 'DocumentId=' + queryString);
    query = query + `&${personType}=true&`;
    query =
      query +
      `PageNumber=${this.pageNumber}&CreatedOrNot=true&PageSize=${this.itemsPerPage}`;
    return query;
  }

  chooseFormatter(personType) {
    // if (personType == "IsTenant")  {
    //   return   (x: ContractForList) => x.tenantNames[0] + "; " + x.address + "; Carpeta: " + x.folderId + "; Codigo Int.: " + x.internalId ;
    // } else {
    //   return   (x: ContractForList) => x.ownerNames[0] + "; " + x.address + "; Carpeta: " + x.folderId + "; Codigo Int.: " + x.internalId ;
    // }
    return (x: Tenant) => x.user.person.fullName + ';';
  }
}
