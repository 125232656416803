<div *ngIf="gridMode && !dashboardMode" class="flex-row parent-div" [ngClass]="{'focused-div': focus}">
    <div class="flex-row search-div" style="margin-right: 10px; width: 100%;">
        <fa-icon [icon]="iconSearch" size="lg" style="margin-right: 10px; margin-top: 10px;"></fa-icon>
        <input class="search-input" type="text" style="width: 100%;" [(ngModel)]="searchString"
            (selectItem)="setResults($event)" [ngbTypeahead]="search" [resultTemplate]="rt" placement="bottom-left"
            [inputFormatter]="formatResult" (focus)="changeFocus(true)" (focusout)="changeFocus(false)"
            placeholder="Buscar Contrato">
        <span style="margin-top: 10px;" *ngIf="searching && searchString != ''">Buscando...</span>
        <fa-icon class="shadowframe cursorpointer button-circle" [icon]="iconClear" size="lg"
            style="color:red; margin-left: 10px; margin-top: 10px;" (click)="clearSearch()" [ngbTooltip]="clearTooltip"
            *ngIf="searchString != ''"></fa-icon>
    </div>
</div>

<div class="col" style="width: 100%;" *ngIf="!gridMode && !dashboardMode && !headerMode">
    <div class="row" style="flex-wrap: nowrap; margin-top: 10px;">
        <input class="form-control" type="text" style="width: 80%;" #inputSearch [(ngModel)]="searchString"
            (selectItem)="setResults($event)" [ngbTypeahead]="search" [resultTemplate]="rt"
            [inputFormatter]="formatResult">
        <fa-icon [icon]="iconClear" size="2x" (click)="clearSearch()"
            style="color:red; margin-top: 4px; cursor: pointer;"></fa-icon>
    </div>
    <div class="row" style="margin-top: 5px;">
        <span *ngIf="!(searching && searchString != '')" style="color: transparent;">|</span>
        <span *ngIf="searching && searchString != ''">Buscando...</span>
    </div>
</div>

<div *ngIf="dashboardMode" class="flex-row" style="justify-content: flex-start; align-items: center; width: 100%;">
    <div class="flex-row centered search-div" [ngClass]="{'focused-div': focus}" style="margin-right: 10px;">
        <fa-icon [icon]="iconSearch" size="lg" style="margin-right: 10px;"></fa-icon>
        <input class="search-input" type="text" #inputSearch placeholder="Busqueda..." [(ngModel)]="searchString"
            (selectItem)="setResults($event)" [ngbTypeahead]="search" placement="bottom-right" [resultTemplate]="rt"
            [inputFormatter]="formatResult" (focus)="changeFocus(true)" (focusout)="changeFocus(false)">
    </div>
    <span style="margin-left: 10px; margin-right: 10px; font-size: 1.02rem;"
        *ngIf="searching && searchString != ''">Buscando...</span>
    <span class="hoverlightred" (click)="clearSearch()"
        style="margin-right: 10px; font-size: 1.02rem; font-weight: 400; color: #c2c2c2;"
        *ngIf="!searching || searchString == ''"> Limpiar Busqueda</span>
    <!-- <fa-icon class="shadowframe cursorpointer button-circle" 
    [icon]="iconClear" size="2x" style="opacity: 0.7; color:red; margin-left: 10px;" 
    (click)="clearSearch()" 
    [ngbTooltip]="clearTooltip"></fa-icon> -->
</div>

<div *ngIf="headerMode" class="flex-row" style="justify-content: flex-start; align-items: center; width: 100%;">
    <div class="flex-row centered search-div" [ngClass]="{'focused-div': focus}" style="margin-right: 10px;">
        <fa-icon [icon]="iconSearch" size="lg" style="margin-right: 10px;"></fa-icon>
        <input style="width: 16vw" class="search-input" type="text" #inputSearch placeholder="Busqueda..." [(ngModel)]="searchString"
            (selectItem)="setResults($event)" [ngbTypeahead]="search" placement="bottom-right" [resultTemplate]="rt"
            [inputFormatter]="formatResult" (focus)="changeFocus(true)" (focusout)="changeFocus(false)">
    </div>
    <span style="margin-left: 10px; margin-right: 10px; font-size: 1.02rem;"
        *ngIf="searching && searchString != ''">Buscando...</span>
    <span class="hoverlightred" (click)="clearSearch()"
        style="margin-right: 10px; font-size: 1.02rem; font-weight: 400; color: #c2c2c2;"
        *ngIf="!searching || searchString == ''"> Limpiar </span>
</div>


<ng-template #rt let-r="result" let-t="term">
    <fa-icon [icon]="iconSearch" size="md" style="margin-right: 10px; margin-top: 10px;"></fa-icon>
    <ngb-highlight style="text-overflow: ellipsis;" [result]="chooseFormatter(r)" [term]="t"></ngb-highlight>
</ng-template>