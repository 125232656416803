<div class="requirement-lists">
  <app-confirm-dialog style="display: none;" [modalTitles]="confirmBoxTitles"></app-confirm-dialog>
  <div class="requirement-card" *ngFor="let key of data.keys" (click)="getLastUnpaidInstallment()" >
    <div class="requirement-installments-title shadowframe" (click)="changeCollapsable(key)">
      <span style="grid-area: title; font-size: 120%; font-weight: 500;">Año: {{key}}</span>
      <fa-icon style="grid-area: arrow; opacity: 0.7;" 
                [icon]="iconUp" 
                size="3x" 
                *ngIf="key != this.selectedCollapsableId"></fa-icon>
      <fa-icon style="grid-area: arrow; opacity: 0.7;" 
                [icon]="iconDown" 
                size="3x" 
                *ngIf="key == this.selectedCollapsableId"></fa-icon>
    </div>
    <div #collapse="ngbCollapse"  
          [(ngbCollapse)]="key != this.selectedCollapsableId" 
          class="border"
          style="height: fit-content; background-color: white; margin-left: 2vw; margin-top: 1vh;"> 
      <div *ngFor="let element of data.dataset[key]" style="padding: 0 1vh 0 1vh;">
        <app-requirement-card  [requirement]="element"  [confirmationDialog]="confirmBox" [lineMode]="true" (refreshCard)="refreshEmit()" (updateExpense)="print($event)" [installmentId]="element.installment.id" *ngIf="element.installment.amount > element.installment.amountPaid && firstUnpaidInstallmentId != undefined"></app-requirement-card>
        <app-requirement-card  [requirement]="element"  [confirmationDialog]="confirmBox" [lineMode]="true" (refreshCard)="refreshEmit()" (updateExpense)="print($event)" [installmentId]="firstUnpaidInstallmentId"  *ngIf="!(element.installment.amount > element.installment.amountPaid) && firstUnpaidInstallmentId != undefined"></app-requirement-card>
      </div>
    </div>
  </div>
</div>
