import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  NgZone,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  faCalendar,
  faSearch,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { KeyValue } from '@angular/common';
import {
  FilterInputTypes,
  SelectedFilterTypeAndValue,
} from 'src/app/core/models/IFilterConfiguration';

@Component({
  selector: 'app-filter-v2',
  templateUrl: './filter-v2.component.html',
  styleUrls: ['./filter-v2.component.css'],
})
export class FilterV2Component implements OnInit {
  @ViewChild('filterForm') filterForm: ElementRef;

  @Input() allowedFilterTypes: any;
  @Input() orderByTypes: any;

  @Output() removeFilters = new EventEmitter();

  private onCompare(
    _left: KeyValue<any, any>,
    _right: KeyValue<any, any>,
  ): number {
    return -1;
  }

  totalFilters: SelectedFilterTypeAndValue[] = [];

  firstTime: boolean = true;
  selectedFilter: any = null;
  orderByOption: string = '';
  orderByFieldOptionSelected: string = '';
  params: any;

  filtersByGroup = {};
  orderByDescending: any = false;

  requirements = {};
  iconCalendar = faCalendar;
  iconSearch = faSearch;
  iconX = faTimesCircle;
  filterInputTypes = FilterInputTypes;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.params = params;
      if (this.firstTime) {
        this.loadFiltersFromURL(params);
      }
    });
    this.totalFilters.push();
    this.firstTime = false;

    this.allowedFilterTypes.forEach((filter) => {
      let group = filter.group;
      if (!this.filtersByGroup.hasOwnProperty(group)) {
        this.filtersByGroup[group] = [];
      }
      this.filtersByGroup[group].push(filter);

      let req = filter.requirement;
      if (req !== undefined && !this.requirements.hasOwnProperty(req)) {
        this.requirements[req] = '';
      }
    });
  }

  setValue(event, totalFilter) {
    totalFilter.value = event;
    var params = this.nullParams();
    this.addFiltersToUrl(params);
  }

  addNewFilter(newFilter?) {
    this.totalFilters.push({
      selectedFilter: null,
      value: null,
    });
  }

  loadFiltersFromURL(params) {
    var filterKeys = Object.keys(params);

    //CHECK FOR ORDERBY
    params['OrderBy'] != null
      ? (this.orderByOption = 'OrderBy')
      : (this.orderByOption = 'OrderByDescending');
    params['OrderBy'] != null
      ? (this.orderByFieldOptionSelected = params['OrderBy'])
      : (this.orderByFieldOptionSelected = params['OrderByDescending']);

    this.allowedFilterTypes.forEach((filterType) => {
      filterKeys.forEach((filterKey) => {
        if (filterKey == filterType.name) {
          var found = false;
          this.totalFilters.forEach((filter) => {
            filter.selectedFilter.name == filterKey ? (found = true) : null;
          });
          found
            ? null
            : this.totalFilters.push({
                selectedFilter: filterType,
                value: params[filterKey],
              });
        }
      });
    });
  }

  addFiltersToUrl(params) {
    this.router.navigate([], {
      queryParams: params,
    });
  }

  setOrderbyFilter() {
    var params = {};
    params['OrderBy'] = null;
    params['OrderByDescending'] = null;
    params[this.orderByOption] = this.orderByFieldOptionSelected;
    this.router.navigate([], {
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }

  nullParams() {
    var params = {};
    var objectKeys = Object.keys(this.params);
    objectKeys.forEach((key) => {
      if (key != 'OrderBy' && key != 'OrderByDescending') {
        params[key] = null;
      }
    });
    return params;
  }

  deleteFilter(ind) {
    this.totalFilters.splice(ind, 1);
    var params = this.nullParams();
    this.addFiltersToUrl(params);
  }

  changeOrder(event) {
    this.orderByDescending = event.target.value === 'true';
  }

  ddOnChange(event) {
    let name = event.target.name;
    let val = event.target.value;
    // let index = val == '' ? 0 : val;
    // let label = event.target[index].label;

    Object.keys(this.requirements).forEach((key) => {
      let keyArr = key.split('.');
      if (this.requirements[keyArr[0]] == '') {
        this.requirements[key] = '';
      }
      if (
        keyArr[keyArr.length - 2] == name ||
        keyArr[keyArr.length - 1] == name
      ) {
        if (
          !isNaN(parseInt(keyArr[keyArr.length - 1])) &&
          val !== keyArr[keyArr.length - 1]
        ) {
          this.requirements[key] = '';
        } else {
          this.requirements[key] = val;
        }
      }
    });
  }

  getInputs() {
    let data = this.filterForm.nativeElement.querySelectorAll('.form-control');
    let dataJson = {};
    console.log(data);

    data.forEach((input) => {
      if (input.name == 'OrderBy' && input.value != '') {
        if (this.orderByDescending === true) {
          dataJson['OrderByDescending'] = input.value;
        } else {
          dataJson['OrderBy'] = input.value;
        }
      } else if (input.name != 'OrderByDescending') {
        dataJson[input.name] =
          input.checked === true ? +input.checked : input.value;
      }
    });

    return [data, dataJson];
  }

  saveFilters() {
    let values = this.getInputs()[1];

    Object.keys(values).forEach((key) => {
      if (values[key] == '') {
        delete values[key];
      }
      if (/\d\d\d\d-\d\d-\d\d/.test(values[key])) {
        values[key] = values[key].replaceAll('-', '/');
      }
    });

    this.addFiltersToUrl(values);
  }

  deleteFilters() {
    let inputs = this.getInputs()[0];
    inputs.forEach((input) => {
      input.value = '';
    });
    Object.keys(this.requirements).forEach((key) => {
      this.requirements[key] = '';
    });
    this.router.navigate([], {});
    this.removeFilters.emit();
  }

  getClass(length) {
    // if (length == 6){
    //   return 'row row-cols-6';
    // }
    if (length % 4 == 1) {
      return 'row row-cols-5';
    }
    return 'row row-cols-4';
  }

  returnZero() {
    return 0;
  }
}
