import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-installment-grid',
  templateUrl: './installment-grid.component.html',
  styleUrls: ['./installment-grid.component.scss'],
})
export class InstallmentGridComponent implements OnInit {
  @Input() installmentList: any[];

  @Output() selectInstallment = new EventEmitter();

  installmentIdList: any[] = [];

  constructor() {}

  ngOnInit() {
    this.installmentList?.forEach((installment) => {
      delete installment.selected;
    });
  }

  addInstallment(installment) {
    if (!installment.selected) {
      installment.selected = true;
      this.installmentIdList.push(installment);
    } else {
      installment.selected = false;
      let ind = this.installmentIdList.indexOf(installment);
      this.installmentIdList.splice(ind, 1);
    }
    this.selectInstallment.emit(this.installmentIdList);
  }

  addAmount() {}
}
