<div class="ad-charges-body">
  <div *ngIf="search" class="search-div" style="grid-area: search;">
    <select [(ngModel)]="personType" class="form-control form-select select-search">
      <option  [ngValue]="item.type" *ngFor="let item of settings.search" >{{item.title}}</option>
    </select>
    <app-search style="width: 130%"
                [gridMode]="true"
                [parentService]="contractService" 
                (clearSearchEvent)="clearSearch()" 
                (setResultsEvent)="setResults($event)"
                [personType]="personType">
    </app-search>
  </div>
  <div class="table-div" style="grid-area: table;" *ngIf="contractId">
    <app-grid [settings]="settings" [tableService]="installmentService" [search]="false" 
        [size]="2" [actions]="true" [buttonForCreation]="false"
        (selectElementEvent)="selectedElements($event)" [_refreshGrid]="_addCharge.asObservable()"
        [id]="contractId" [customEmptyMessage]="emptyMessage">
    </app-grid>
  </div>

  <div style="grid-area: add; margin-top: 10px;">
    <app-additional-charges-card 
      [hidden]="hideChild"
      [installments] = "installment"
      (refreshAddCharges)="refreshGrid($event)"
      *ngIf="installment.length == 1">
    </app-additional-charges-card>
  </div>
</div>