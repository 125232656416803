import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  Signal,
  ViewChildren,
  computed,
  effect,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  faCalendar,
  faSearch,
  faPlus,
  faEye,
  faTrash,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContractForAdd } from 'src/app/core/models/ContractForAdd';
import { InstallmentIncreseForAdd } from 'src/app/core/models/InstallmentIncreseForAdd';
import { DateService } from 'src/app/services/date.service';
import { InstallmentTypeEnum } from 'src/app/core/models/InstallmentTypeEnum.enum';
import {
  ConfirmDialogSettings,
  ConfirmDialogComponent,
} from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { ContractEditService } from 'src/app/modules/contracts/pages/contract-edit/contract-edit.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { Contract } from 'src/app/core/models/Contract';
import { interval } from 'rxjs';

@Component({
  selector: 'app-contract-installment-setup',
  templateUrl: './contract-installment-setup.component.html',
  styleUrls: ['./contract-installment-setup.component.css'],
})
export class ContractInstallmentSetupComponent implements OnInit {
  iconCalendar = faCalendar;
  iconSearch = faSearch;
  iconPlus = faPlus;
  iconSee = faEye;
  iconTrash = faTrash;
  iconEdit = faPen;

  schemeType: number;
  isChecked: boolean = true;
  currencyTypeGuaranteeDeposit: number;
  guaranteeDepositCurrencyValue: number;
  adminAmountCurrencyType: number;
  adminAmountCurrencyValue: number;
  adminAmountPercentageValue: number;
  edit: boolean = false;
  editData: any;
  punitives: number;
  paymentExpiryDay: number;
  contractCurrency: number;
  confirmDeleteBoxTitles: ConfirmDialogSettings;

  invalidSchema: boolean;
  schemeTypeEnum = InstallmentTypeEnum;

  @Input() contractInput: ContractForAdd;
  @Input() createInMemory?: boolean = true;

  contractForEdit = toSignal(
    this.contractEditService.contractForEditObservable,
  );

  amountOfNewPeriods: number = 0;

  @ViewChildren('checkedForErrors') viewChildren!: QueryList<ElementRef>;
  @ViewChildren(ConfirmDialogComponent)
  confirmBox: QueryList<ConfirmDialogComponent>;

  contractAmountFormGroup = new UntypedFormGroup({
    schemeType: new UntypedFormControl('', Validators.required),
    contractCurrency: new UntypedFormControl('', Validators.required),
    increaseType: new UntypedFormControl(''),
    currencyTypeGuaranteeDeposit: new UntypedFormControl(
      '',
      Validators.required,
    ),
    guaranteeDepositCurrencyValue: new UntypedFormControl('', [
      Validators.min(0),
      Validators.max(2147483647),
      Validators.required,
    ]),
    adminAmountCurrencyValue: new UntypedFormControl('', [
      Validators.min(0),
      Validators.max(2147483647),
      Validators.required,
    ]),
    adminAmountPercentageValue: new UntypedFormControl('', [
      Validators.min(0),
      Validators.max(100),
      Validators.required,
    ]),
    adminAmountCurrencyType: new UntypedFormControl('', Validators.required),
    paymentExpiryDay: new UntypedFormControl('', [
      Validators.min(1),
      Validators.max(31),
      Validators.required,
    ]),
    punitives: new UntypedFormControl('', [
      Validators.min(0),
      Validators.max(100),
      Validators.required,
    ]),

    /* Contract schema */
    installmentSchemas: new UntypedFormControl('', Validators.required),
    schemaAmount: new UntypedFormControl('', [
      Validators.min(0),
      Validators.max(2147483647),
      Validators.required,
    ]),
    secondSchemaAmount: new UntypedFormControl('', [
      Validators.min(0),
      Validators.max(2147483647),
      Validators.required,
    ]),
    thirdSchemaAmount: new UntypedFormControl('', [
      Validators.min(0),
      Validators.max(2147483647),
      Validators.required,
    ]),
    fourthSchemaAmount: new UntypedFormControl('', [
      Validators.min(0),
      Validators.max(2147483647),
      Validators.required,
    ]),
    fifthSchemaAmount: new UntypedFormControl('', [
      Validators.min(0),
      Validators.max(2147483647),
      Validators.required,
    ]),
    sixthSchemaAmount: new UntypedFormControl('', [
      Validators.min(0),
      Validators.max(2147483647),
      Validators.required,
    ]),
  });

  constructor(
    private modalService: NgbModal,
    public dateService: DateService,
    private contractEditService: ContractEditService,
  ) {
    this.confirmDeleteBoxTitles = {
      title: 'Desea Cambiar de Esquema?',
      subtitle: 'Los montos cargados seran borrados',
    };
  }

  ngOnInit() {
    var fromDate = new Date(this.contractInput.fromDate);
    let currentInstallment =
      this.dateService.monthDiff(new Date(), fromDate) + 1;
    if (currentInstallment > 12) {
      if (currentInstallment > this.contractInput.installmentQuantity) {
        this.amountOfNewPeriods =
          Math.ceil(this.contractInput.installmentQuantity / 12) - 1;
      } else {
        this.amountOfNewPeriods = Math.ceil(currentInstallment / 12) - 1;
      }
    }

    this.invalidSchema = false;
    this.contractAmountFormGroup.patchValue({
      schemeType: this.contractInput.installmentTypeId || 1,
      contractCurrency: this.contractInput.contractCurrency || 1,
      currencyTypeGuaranteeDeposit:
        this.contractInput.initalDepositCurrency || 1,
      guaranteeDepositCurrencyValue: this.contractInput.initialDeposit || null,
      adminAmountCurrencyValue: this.contractInput.fixedFee || null,
      adminAmountPercentageValue: this.contractInput.percentageFee || null,
      adminAmountCurrencyType: this.contractInput.isFixedFee ? 1 : 0,
      paymentExpiryDay: this.contractInput.expirationPayDay || null,
      punitives: this.contractInput.expirationIncrease || null,
      increaseType: this.contractInput.increaseType || null,
    });

    if (this.contractInput.installmentTypeId == 1) {
      switch (this.amountOfNewPeriods) {
        case 0:
          this.contractAmountFormGroup.controls[
            'secondSchemaAmount'
          ].clearValidators();
          this.contractAmountFormGroup.controls[
            'thirdSchemaAmount'
          ].clearValidators();
          this.contractAmountFormGroup.controls[
            'fourthSchemaAmount'
          ].clearValidators();
          this.contractAmountFormGroup.controls[
            'fifthSchemaAmount'
          ].clearValidators();
          this.contractAmountFormGroup.controls[
            'sixthSchemaAmount'
          ].clearValidators();

          this.contractAmountFormGroup.controls[
            'secondSchemaAmount'
          ].patchValue(null);
          this.contractAmountFormGroup.controls['thirdSchemaAmount'].patchValue(
            null,
          );
          this.contractAmountFormGroup.controls[
            'fourthSchemaAmount'
          ].patchValue(null);
          this.contractAmountFormGroup.controls['fifthSchemaAmount'].patchValue(
            null,
          );
          this.contractAmountFormGroup.controls['sixthSchemaAmount'].patchValue(
            null,
          );
          break;
        case 1:
          this.contractAmountFormGroup.controls[
            'secondSchemaAmount'
          ].addValidators([
            Validators.min(0),
            Validators.max(2147483647),
            Validators.required,
          ]);
          this.contractAmountFormGroup.controls[
            'thirdSchemaAmount'
          ].clearValidators();
          this.contractAmountFormGroup.controls[
            'fourthSchemaAmount'
          ].clearValidators();
          this.contractAmountFormGroup.controls[
            'fifthSchemaAmount'
          ].clearValidators();
          this.contractAmountFormGroup.controls[
            'sixthSchemaAmount'
          ].clearValidators();

          this.contractAmountFormGroup.controls[
            'secondSchemaAmount'
          ].patchValue(this.contractInput.secondSchemaAmount);
          this.contractAmountFormGroup.controls['thirdSchemaAmount'].patchValue(
            null,
          );
          this.contractAmountFormGroup.controls[
            'fourthSchemaAmount'
          ].patchValue(null);
          this.contractAmountFormGroup.controls['fifthSchemaAmount'].patchValue(
            null,
          );
          this.contractAmountFormGroup.controls['sixthSchemaAmount'].patchValue(
            null,
          );
          break;
        case 2:
          this.contractAmountFormGroup.controls[
            'secondSchemaAmount'
          ].addValidators([
            Validators.min(0),
            Validators.max(2147483647),
            Validators.required,
          ]);
          this.contractAmountFormGroup.controls[
            'thirdSchemaAmount'
          ].addValidators([
            Validators.min(0),
            Validators.max(2147483647),
            Validators.required,
          ]);
          this.contractAmountFormGroup.controls[
            'fourthSchemaAmount'
          ].clearValidators();
          this.contractAmountFormGroup.controls[
            'fifthSchemaAmount'
          ].clearValidators();
          this.contractAmountFormGroup.controls[
            'sixthSchemaAmount'
          ].clearValidators();

          this.contractAmountFormGroup.controls[
            'secondSchemaAmount'
          ].patchValue(this.contractInput.secondSchemaAmount);
          this.contractAmountFormGroup.controls['thirdSchemaAmount'].patchValue(
            this.contractInput.thirdSchemaAmount,
          );
          this.contractAmountFormGroup.controls[
            'fourthSchemaAmount'
          ].patchValue(null);
          this.contractAmountFormGroup.controls['fifthSchemaAmount'].patchValue(
            null,
          );
          this.contractAmountFormGroup.controls['sixthSchemaAmount'].patchValue(
            null,
          );
          break;
      }
      this.contractAmountFormGroup.controls[
        'installmentSchemas'
      ].clearValidators();
      this.contractAmountFormGroup.controls['installmentSchemas'].patchValue(
        null,
      );
      this.contractInput.installmentSchemas = [];
      this.contractAmountFormGroup.controls['schemaAmount'].patchValue(
        this.contractInput.installmentIncreses?.initialAmount || null,
      );
    } else if (this.contractInput.installmentTypeId == 2) {
      this.contractAmountFormGroup.controls['schemaAmount'].clearValidators();
      this.contractAmountFormGroup.controls[
        'secondSchemaAmount'
      ].clearValidators();
      this.contractAmountFormGroup.controls[
        'thirdSchemaAmount'
      ].clearValidators();
      this.contractAmountFormGroup.controls['schemaAmount'].patchValue(null);
      this.contractAmountFormGroup.controls['secondSchemaAmount'].patchValue(
        null,
      );
      this.contractAmountFormGroup.controls['thirdSchemaAmount'].patchValue(
        null,
      );
      this.contractInput.installmentIncreses = undefined;
      this.contractAmountFormGroup.controls['installmentSchemas'].patchValue(
        this.contractInput.installmentSchemas || null,
      );
    } else if (this.contractInput.installmentTypeId == 3) {
      if (currentInstallment > 6) {
        if (currentInstallment > this.contractInput.installmentQuantity) {
          this.amountOfNewPeriods =
            Math.ceil(this.contractInput.installmentQuantity / 6) - 1;
        } else {
          this.amountOfNewPeriods = Math.ceil(currentInstallment / 6) - 1;
        }
      }
      switch (this.amountOfNewPeriods) {
        case 0:
          this.contractAmountFormGroup.controls[
            'secondSchemaAmount'
          ].clearValidators();
          this.contractAmountFormGroup.controls[
            'thirdSchemaAmount'
          ].clearValidators();
          this.contractAmountFormGroup.controls[
            'fourthSchemaAmount'
          ].clearValidators();
          this.contractAmountFormGroup.controls[
            'fifthSchemaAmount'
          ].clearValidators();
          this.contractAmountFormGroup.controls[
            'sixthSchemaAmount'
          ].clearValidators();

          this.contractAmountFormGroup.controls[
            'secondSchemaAmount'
          ].patchValue(null);
          this.contractAmountFormGroup.controls['thirdSchemaAmount'].patchValue(
            null,
          );
          this.contractAmountFormGroup.controls[
            'fourthSchemaAmount'
          ].patchValue(null);
          this.contractAmountFormGroup.controls['fifthSchemaAmount'].patchValue(
            null,
          );
          this.contractAmountFormGroup.controls['sixthSchemaAmount'].patchValue(
            null,
          );
          break;
        case 1:
          this.contractAmountFormGroup.controls[
            'secondSchemaAmount'
          ].addValidators([
            Validators.min(0),
            Validators.max(2147483647),
            Validators.required,
          ]);
          this.contractAmountFormGroup.controls[
            'thirdSchemaAmount'
          ].clearValidators();
          this.contractAmountFormGroup.controls[
            'fourthSchemaAmount'
          ].clearValidators();
          this.contractAmountFormGroup.controls[
            'fifthSchemaAmount'
          ].clearValidators();
          this.contractAmountFormGroup.controls[
            'sixthSchemaAmount'
          ].clearValidators();

          this.contractAmountFormGroup.controls[
            'secondSchemaAmount'
          ].patchValue(this.contractInput.secondSchemaAmount);
          this.contractAmountFormGroup.controls['thirdSchemaAmount'].patchValue(
            null,
          );
          this.contractAmountFormGroup.controls[
            'fourthSchemaAmount'
          ].patchValue(null);
          this.contractAmountFormGroup.controls['fifthSchemaAmount'].patchValue(
            null,
          );
          this.contractAmountFormGroup.controls['sixthSchemaAmount'].patchValue(
            null,
          );
          break;
        case 2:
          this.contractAmountFormGroup.controls[
            'secondSchemaAmount'
          ].addValidators([
            Validators.min(0),
            Validators.max(2147483647),
            Validators.required,
          ]);
          this.contractAmountFormGroup.controls[
            'thirdSchemaAmount'
          ].addValidators([
            Validators.min(0),
            Validators.max(2147483647),
            Validators.required,
          ]);
          this.contractAmountFormGroup.controls[
            'fourthSchemaAmount'
          ].clearValidators();
          this.contractAmountFormGroup.controls[
            'fifthSchemaAmount'
          ].clearValidators();
          this.contractAmountFormGroup.controls[
            'sixthSchemaAmount'
          ].clearValidators();

          this.contractAmountFormGroup.controls[
            'secondSchemaAmount'
          ].patchValue(this.contractInput.secondSchemaAmount);
          this.contractAmountFormGroup.controls['thirdSchemaAmount'].patchValue(
            this.contractInput.thirdSchemaAmount,
          );
          this.contractAmountFormGroup.controls[
            'fourthSchemaAmount'
          ].patchValue(null);
          this.contractAmountFormGroup.controls['fifthSchemaAmount'].patchValue(
            null,
          );
          this.contractAmountFormGroup.controls['sixthSchemaAmount'].patchValue(
            null,
          );
          break;
        case 3:
          this.contractAmountFormGroup.controls[
            'secondSchemaAmount'
          ].addValidators([
            Validators.min(0),
            Validators.max(2147483647),
            Validators.required,
          ]);
          this.contractAmountFormGroup.controls[
            'thirdSchemaAmount'
          ].addValidators([
            Validators.min(0),
            Validators.max(2147483647),
            Validators.required,
          ]);
          this.contractAmountFormGroup.controls[
            'fourthSchemaAmount'
          ].addValidators([
            Validators.min(0),
            Validators.max(2147483647),
            Validators.required,
          ]);
          this.contractAmountFormGroup.controls[
            'fifthSchemaAmount'
          ].clearValidators();
          this.contractAmountFormGroup.controls[
            'sixthSchemaAmount'
          ].clearValidators();

          this.contractAmountFormGroup.controls[
            'secondSchemaAmount'
          ].patchValue(this.contractInput.secondSchemaAmount);
          this.contractAmountFormGroup.controls['thirdSchemaAmount'].patchValue(
            this.contractInput.thirdSchemaAmount,
          );
          this.contractAmountFormGroup.controls[
            'fourthSchemaAmount'
          ].patchValue(this.contractInput.fourthSchemaAmount);
          this.contractAmountFormGroup.controls['fifthSchemaAmount'].patchValue(
            null,
          );
          this.contractAmountFormGroup.controls['sixthSchemaAmount'].patchValue(
            null,
          );
          break;
        case 4:
          this.contractAmountFormGroup.controls[
            'secondSchemaAmount'
          ].addValidators([
            Validators.min(0),
            Validators.max(2147483647),
            Validators.required,
          ]);
          this.contractAmountFormGroup.controls[
            'thirdSchemaAmount'
          ].addValidators([
            Validators.min(0),
            Validators.max(2147483647),
            Validators.required,
          ]);
          this.contractAmountFormGroup.controls[
            'fourthSchemaAmount'
          ].addValidators([
            Validators.min(0),
            Validators.max(2147483647),
            Validators.required,
          ]);
          this.contractAmountFormGroup.controls[
            'fifthSchemaAmount'
          ].addValidators([
            Validators.min(0),
            Validators.max(2147483647),
            Validators.required,
          ]);
          this.contractAmountFormGroup.controls[
            'sixthSchemaAmount'
          ].clearValidators();

          this.contractAmountFormGroup.controls[
            'secondSchemaAmount'
          ].patchValue(this.contractInput.secondSchemaAmount);
          this.contractAmountFormGroup.controls['thirdSchemaAmount'].patchValue(
            this.contractInput.thirdSchemaAmount,
          );
          this.contractAmountFormGroup.controls[
            'fourthSchemaAmount'
          ].patchValue(this.contractInput.fourthSchemaAmount);
          this.contractAmountFormGroup.controls['fifthSchemaAmount'].patchValue(
            this.contractInput.fifthSchemaAmount,
          );
          this.contractAmountFormGroup.controls['sixthSchemaAmount'].patchValue(
            null,
          );
          break;
        case 5:
          this.contractAmountFormGroup.controls[
            'secondSchemaAmount'
          ].addValidators([
            Validators.min(0),
            Validators.max(2147483647),
            Validators.required,
          ]);
          this.contractAmountFormGroup.controls[
            'thirdSchemaAmount'
          ].addValidators([
            Validators.min(0),
            Validators.max(2147483647),
            Validators.required,
          ]);
          this.contractAmountFormGroup.controls[
            'fourthSchemaAmount'
          ].addValidators([
            Validators.min(0),
            Validators.max(2147483647),
            Validators.required,
          ]);
          this.contractAmountFormGroup.controls[
            'fifthSchemaAmount'
          ].addValidators([
            Validators.min(0),
            Validators.max(2147483647),
            Validators.required,
          ]);
          this.contractAmountFormGroup.controls[
            'sixthSchemaAmount'
          ].addValidators([
            Validators.min(0),
            Validators.max(2147483647),
            Validators.required,
          ]);

          this.contractAmountFormGroup.controls[
            'secondSchemaAmount'
          ].patchValue(this.contractInput.secondSchemaAmount);
          this.contractAmountFormGroup.controls['thirdSchemaAmount'].patchValue(
            this.contractInput.thirdSchemaAmount,
          );
          this.contractAmountFormGroup.controls[
            'fourthSchemaAmount'
          ].patchValue(this.contractInput.fourthSchemaAmount);
          this.contractAmountFormGroup.controls['fifthSchemaAmount'].patchValue(
            this.contractInput.fifthSchemaAmount,
          );
          this.contractAmountFormGroup.controls['sixthSchemaAmount'].patchValue(
            this.contractInput.sixthSchemaAmount,
          );
          break;
      }

      this.contractAmountFormGroup.controls[
        'installmentSchemas'
      ].clearValidators();
      this.contractAmountFormGroup.controls['installmentSchemas'].patchValue(
        null,
      );
      this.contractInput.installmentSchemas = [];
      this.contractAmountFormGroup.controls['schemaAmount'].patchValue(
        this.contractInput.installmentIncreses?.initialAmount || null,
      );
    }
  }

  ngAfterViewInit() {}

  addNewContractAmount(form) {
    this.edit = false;
    this.editData = null;
    this.modalService.open(form);
  }

  closeModals() {
    this.modalService.dismissAll();
  }

  editElement(form, data, index) {
    this.edit = true;
    this.editData = data;
    this.editData.index = index;
    this.modalService.open(form);
  }

  deleteFrom(ind) {
    this.contractInput.installmentSchemas.splice(ind, 1);
    if (this.contractInput.installmentSchemas.length == 0)
      this.contractInput.installmentSchemas = undefined;
  }

  setElements() {
    var contract = this.contractInput;
    var fromDate = new Date(contract.fromDate);
    let currentInstallment =
      this.dateService.monthDiff(new Date(), fromDate) + 1;
    if (
      this.contractAmountFormGroup.value.schemeType ==
        InstallmentTypeEnum.Comercial ||
      this.contractAmountFormGroup.value.schemeType ==
        InstallmentTypeEnum.Vivienda2024
    ) {
      this.contractAmountFormGroup.controls['schemaAmount'].clearValidators();
      this.contractAmountFormGroup.controls[
        'secondSchemaAmount'
      ].clearValidators();
      this.contractAmountFormGroup.controls[
        'thirdSchemaAmount'
      ].clearValidators();
      this.contractAmountFormGroup.controls[
        'fourthSchemaAmount'
      ].clearValidators();
      this.contractAmountFormGroup.controls[
        'fifthSchemaAmount'
      ].clearValidators();
      this.contractAmountFormGroup.controls[
        'sixthSchemaAmount'
      ].clearValidators();

      this.contractAmountFormGroup.controls['schemaAmount'].patchValue(null);
      this.contractAmountFormGroup.controls['secondSchemaAmount'].patchValue(
        null,
      );
      this.contractAmountFormGroup.controls['thirdSchemaAmount'].patchValue(
        null,
      );
      this.contractAmountFormGroup.controls['fourthSchemaAmount'].patchValue(
        null,
      );
      this.contractAmountFormGroup.controls['fifthSchemaAmount'].patchValue(
        null,
      );
      this.contractAmountFormGroup.controls['sixthSchemaAmount'].patchValue(
        null,
      );

      contract.installmentIncreses = undefined;

      this.contractAmountFormGroup.controls['installmentSchemas'].setValidators(
        [Validators.required],
      );
      this.contractAmountFormGroup.controls[
        'installmentSchemas'
      ].updateValueAndValidity();

      this.contractAmountFormGroup.controls['installmentSchemas'].patchValue(
        contract.installmentSchemas,
      );
    } else {
      if (
        this.contractAmountFormGroup.value.schemeType ==
        InstallmentTypeEnum.Vivienda2019
      ) {
        if (currentInstallment > 12) {
          if (currentInstallment > contract.installmentQuantity) {
            this.amountOfNewPeriods =
              Math.ceil(contract.installmentQuantity / 12) - 1;
          } else {
            this.amountOfNewPeriods = Math.ceil(currentInstallment / 12) - 1;
          }
        }
        switch (this.amountOfNewPeriods) {
          case 0:
            this.clearSecondSchemaAmount();
            this.clearThirdSchemaAmount();
            this.clearFourthSchemaAmount();
            this.clearFifthSchemaAmount();
            this.clearSixthSchemaAmount();
            break;
          case 1:
            this.setSecondSchemaAmountValidator();
            this.clearThirdSchemaAmount();
            this.clearFourthSchemaAmount();
            this.clearFifthSchemaAmount();
            this.clearSixthSchemaAmount();
            break;
          case 2:
            this.setSecondSchemaAmountValidator();
            this.setThirdSchemaAmountValidator();
            this.clearFourthSchemaAmount();
            this.clearFifthSchemaAmount();
            this.clearSixthSchemaAmount();
            break;
        }
      } else if (
        this.contractAmountFormGroup.value.schemeType ==
        InstallmentTypeEnum.Vivienda2023
      ) {
        if (currentInstallment > 6) {
          if (currentInstallment > contract.installmentQuantity) {
            this.amountOfNewPeriods =
              Math.ceil(contract.installmentQuantity / 6) - 1;
          } else {
            this.amountOfNewPeriods = Math.ceil(currentInstallment / 6) - 1;
          }
        }
        switch (this.amountOfNewPeriods) {
          case 0:
            this.clearSecondSchemaAmount();
            this.clearThirdSchemaAmount();
            this.clearFourthSchemaAmount();
            this.clearFifthSchemaAmount();
            this.clearSixthSchemaAmount();
            break;
          case 1:
            this.setSecondSchemaAmountValidator();
            this.clearThirdSchemaAmount();
            this.clearFourthSchemaAmount();
            this.clearFifthSchemaAmount();
            this.clearSixthSchemaAmount();
            break;
          case 2:
            this.setSecondSchemaAmountValidator();
            this.setThirdSchemaAmountValidator();
            this.clearFourthSchemaAmount();
            this.clearFifthSchemaAmount();
            this.clearSixthSchemaAmount();
            break;
          case 3:
            this.setSecondSchemaAmountValidator();
            this.setThirdSchemaAmountValidator();
            this.setFourthSchemaAmountValidator();
            this.clearFifthSchemaAmount();
            this.clearSixthSchemaAmount();
            break;
          case 4:
            this.setSecondSchemaAmountValidator();
            this.setThirdSchemaAmountValidator();
            this.setFourthSchemaAmountValidator();
            this.setFifthSchemaAmountValidator();
            this.clearSixthSchemaAmount();
            break;
          case 5:
            this.setSecondSchemaAmountValidator();
            this.setThirdSchemaAmountValidator();
            this.setFourthSchemaAmountValidator();
            this.setFifthSchemaAmountValidator();
            this.setSixthSchemaAmountValidator();
            break;
        }
      }

      this.invalidSchema = false;
      this.contractAmountFormGroup.controls[
        'installmentSchemas'
      ].clearValidators();
      this.contractAmountFormGroup.controls[
        'installmentSchemas'
      ].updateValueAndValidity();
      this.contractAmountFormGroup.controls['installmentSchemas'].patchValue(
        null,
      );
      contract.installmentSchemas = [];

      this.contractAmountFormGroup.controls['schemaAmount'].setValidators([
        Validators.min(0),
        Validators.max(2147483647),
        Validators.required,
      ]);

      this.contractAmountFormGroup.controls[
        'schemaAmount'
      ].updateValueAndValidity();

      let installmentDinamicToSubmit: InstallmentIncreseForAdd = {
        initialAmount: this.contractAmountFormGroup.value.schemaAmount,
        fromDate: contract.fromDate,
        increaseValue: 0,
        increaseTypeId: 1,
        quantity: contract.installmentQuantity,
        contractId: 0,
      };
      contract.installmentIncreses = installmentDinamicToSubmit;

      // if (this.contractAmountFormGroup.value.schemaAmount) this.clearPreviousInstallmentSchema(this.contractAmountFormGroup.value.schemeType);
    }
    this.clearPreviousAdminAmount(null);

    contract.installmentTypeId = this.contractAmountFormGroup.value.schemeType;
    contract.contractCurrency =
      this.contractAmountFormGroup.value.contractCurrency;
    contract.expirationPayDay =
      this.contractAmountFormGroup.value.paymentExpiryDay;
    contract.expirationIncrease = this.contractAmountFormGroup.value.punitives;
    contract.initalDepositCurrency =
      this.contractAmountFormGroup.value.currencyTypeGuaranteeDeposit;
    contract.initialDeposit =
      this.contractAmountFormGroup.value.guaranteeDepositCurrencyValue;
    contract.fixedFee =
      this.contractAmountFormGroup.value.adminAmountCurrencyValue;
    contract.percentageFee =
      this.contractAmountFormGroup.value.adminAmountPercentageValue;
    contract.isFixedFee =
      this.contractAmountFormGroup.value.adminAmountCurrencyType == 1
        ? true
        : false;
    contract.secondSchemaAmount =
      this.contractAmountFormGroup.value.secondSchemaAmount;
    contract.thirdSchemaAmount =
      this.contractAmountFormGroup.value.thirdSchemaAmount;
    contract.fourthSchemaAmount =
      this.contractAmountFormGroup.value.fourthSchemaAmount;
    contract.fifthSchemaAmount =
      this.contractAmountFormGroup.value.fifthSchemaAmount;
    contract.sixthSchemaAmount =
      this.contractAmountFormGroup.value.sixthSchemaAmount;

    contract.increaseType = this.contractAmountFormGroup.value.increaseType;

    this.contractInput = contract;
    console.log(
      this.contractInput,
      this.contractAmountFormGroup.value.increaseType,
    );

    this.contractEditService.setContract(contract);
  }

  setSecondSchemaAmountValidator() {
    this.contractAmountFormGroup.controls['secondSchemaAmount'].setValidators([
      Validators.min(0),
      Validators.max(2147483647),
      Validators.required,
    ]);
    this.contractAmountFormGroup.controls[
      'secondSchemaAmount'
    ].updateValueAndValidity();
  }

  clearSecondSchemaAmount() {
    this.contractAmountFormGroup.controls[
      'secondSchemaAmount'
    ].clearValidators();
    this.contractAmountFormGroup.controls[
      'secondSchemaAmount'
    ].updateValueAndValidity();
    this.contractAmountFormGroup.controls['secondSchemaAmount'].patchValue(
      null,
    );
  }

  setThirdSchemaAmountValidator() {
    this.contractAmountFormGroup.controls['thirdSchemaAmount'].setValidators([
      Validators.min(0),
      Validators.max(2147483647),
      Validators.required,
    ]);
    this.contractAmountFormGroup.controls[
      'thirdSchemaAmount'
    ].updateValueAndValidity();
  }

  clearThirdSchemaAmount() {
    this.contractAmountFormGroup.controls[
      'thirdSchemaAmount'
    ].clearValidators();
    this.contractAmountFormGroup.controls[
      'thirdSchemaAmount'
    ].updateValueAndValidity();
    this.contractAmountFormGroup.controls['thirdSchemaAmount'].patchValue(null);
  }

  setFourthSchemaAmountValidator() {
    this.contractAmountFormGroup.controls['fourthSchemaAmount'].setValidators([
      Validators.min(0),
      Validators.max(2147483647),
      Validators.required,
    ]);
    this.contractAmountFormGroup.controls[
      'fourthSchemaAmount'
    ].updateValueAndValidity();
  }

  clearFourthSchemaAmount() {
    this.contractAmountFormGroup.controls[
      'fourthSchemaAmount'
    ].clearValidators();
    this.contractAmountFormGroup.controls[
      'fourthSchemaAmount'
    ].updateValueAndValidity();
    this.contractAmountFormGroup.controls['fourthSchemaAmount'].patchValue(
      null,
    );
  }

  setFifthSchemaAmountValidator() {
    this.contractAmountFormGroup.controls['fifthSchemaAmount'].setValidators([
      Validators.min(0),
      Validators.max(2147483647),
      Validators.required,
    ]);
    this.contractAmountFormGroup.controls[
      'fifthSchemaAmount'
    ].updateValueAndValidity();
  }

  clearFifthSchemaAmount() {
    this.contractAmountFormGroup.controls[
      'fifthSchemaAmount'
    ].clearValidators();
    this.contractAmountFormGroup.controls[
      'fifthSchemaAmount'
    ].updateValueAndValidity();
    this.contractAmountFormGroup.controls['fifthSchemaAmount'].patchValue(null);
  }

  setSixthSchemaAmountValidator() {
    this.contractAmountFormGroup.controls['sixthSchemaAmount'].setValidators([
      Validators.min(0),
      Validators.max(2147483647),
      Validators.required,
    ]);
    this.contractAmountFormGroup.controls[
      'sixthSchemaAmount'
    ].updateValueAndValidity();
  }

  clearSixthSchemaAmount() {
    this.contractAmountFormGroup.controls[
      'sixthSchemaAmount'
    ].clearValidators();
    this.contractAmountFormGroup.controls[
      'sixthSchemaAmount'
    ].updateValueAndValidity();
    this.contractAmountFormGroup.controls['sixthSchemaAmount'].patchValue(null);
  }

  clearPreviousInstallmentSchema(schemeType) {
    this.confirmBox
      .toArray()[0]
      .openModal()
      .result.then(
        (result) => {
          if (result) {
            if (schemeType == 1) {
              this.contractInput.installmentSchemas = undefined;
            } else {
              this.contractAmountFormGroup.patchValue({
                schemaAmount: null,
              });
              this.contractInput.installmentIncreses = undefined;
            }
          } else {
          }
        },
        (reason) => {
          //
        },
      );
  }

  clearPreviousAdminAmount(value) {
    if (this.contractAmountFormGroup.value.adminAmountCurrencyType == 1) {
      this.contractAmountFormGroup.patchValue(
        {
          adminAmountPercentageValue: value,
        },
        { onlySelf: true },
      );
    } else {
      this.contractAmountFormGroup.patchValue(
        {
          adminAmountCurrencyValue: value,
        },
        { onlySelf: true },
      );
    }
  }

  showDate(installment) {
    return this.dateService.transformToDateString(new Date(installment), '/');
  }

  validate(field: string) {
    return (
      this.contractAmountFormGroup.controls[field].errors &&
      this.contractAmountFormGroup.controls[field].touched
    );
  }

  isComercialOrVivienda2024(): boolean {
    return (
      this.contractAmountFormGroup.value.schemeType ==
        this.schemeTypeEnum.Comercial ||
      this.contractAmountFormGroup.value.schemeType ==
        this.schemeTypeEnum.Vivienda2024
    );
  }
}
