import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { empty } from 'rxjs';
import jwt_decode from 'jwt-decode';

@Injectable({ providedIn: 'root' })
export class TokenResolver  {
  tokenData = null;

  constructor(
    private authservice: AuthService,
    private route: ActivatedRoute,
  ) {}

  private tokenExpired(token: string) {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> {
    var token = localStorage.getItem('token');
    if (token) {
      if (this.tokenExpired(token)) {
        return this.authservice.getAccessTokenSilently();
      } else {
        empty();
      }
    } else {
      return this.authservice.getAccessTokenSilently();
    }
  }
}
