<div class="flex-row inmueble-card border" style="grid-area: inmueble;"  (mouseenter) ="showDelete(false) "  (mouseleave) ="showDelete(true)">
  <div class="flex-col" style="min-width: 20px;">
    <fa-icon class="hoverred" [icon]="iconTrash" size="1x"[ngClass]="{'displayMe': display}" (click)="deleteEvent()"> </fa-icon>
  </div>
  <div class="flex-col">
    <span> {{cardTitle}} </span>    
  </div>
  <div class="flex-col" style="min-width: 20px;">
    <fa-icon class="hoverred" [icon]="iconEdit" size="1x" [ngClass]="{'displayMe': display}" (click)="formOpen()"> </fa-icon>
  </div>
</div>