<form [formGroup]="installmentreqFormGroup">
  <div style="display: flex; flex-direction: row; margin-bottom: -5px; margin-top: -10px; justify-content: flex-end">
  </div>
  <div class="flex-row" style="justify-content: center;">
    <div class="form-reqs form-group" style="display: flex; flex-direction: column;">
      <div style="margin-bottom: 1vh;">
        <label>Monto</label>
        <input type="number" formControlName="amount" class="form-control">
        <label>Detalle</label>
        <input type="text" formControlName="details" class="form-control">
        <label>Fecha</label>
        <div style="display: flex; flex-direction: row;">
          <input formControlName="payDate" class="form-control" placeholder="yyyy-mm-dd" name="pd" ngbDatepicker
            #d="ngbDatepicker">
          <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
            <fa-icon [icon]="iconCalendar" size="1x"> </fa-icon>
          </button>
        </div>
      </div>

      <div style="width: 100%; display: flex; align-items: center; justify-content: space-evenly; margin-top: 2vh;">
        <button class="btn btn-warning" type="button" (click)="createLiability(3)">Parcial</button>
        <button class="btn btn-success" type="button" (click)="createLiability(1)">Completo</button>
        <button *ngIf="requirement.hasInstallmentReq" class="btn btn-danger" type="button"
          (click)="deleteLiability()">Eliminar</button>
      </div>
    </div>
  </div>
  <app-image-viewer [settings]="fileSettings" [fileArray]="insReqFiles ?? []" (emitFileArray)="updateFileArray($event)"
    style="width: 100%;" *ngIf="requirement.hasInstallmentReq"></app-image-viewer>
</form>