import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-confirm-container',
  templateUrl: './modal-confirm-container.component.html',
  styleUrls: ['./modal-confirm-container.component.css'],
})
export class ModalConfirmContainerComponent implements OnInit {
  @Input() title = `Information`;
  @Input() c;
  @Input() d;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
