import { Component, Input, OnInit, effect } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  faCalendar,
  faSearch,
  faPlus,
  faEye,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Sellpoint } from 'src/app/core/models/Sellpoint';
import { CuitService } from 'src/app/services/cuit.service';
import { DateService } from 'src/app/services/date.service';
import { ContractEditService } from '../../../modules/contracts/pages/contract-edit/contract-edit.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-contract-creation-info',
  templateUrl: './contract-creation-info.component.html',
  styleUrls: ['./contract-creation-info.component.css'],
})
export class ContractCreationInfoComponent implements OnInit {
  @Input() contractInput: any;

  contractForEdit = toSignal(
    this.contractEditService.contractForEditObservable,
  );

  iconCalendar = faCalendar;
  iconSearch = faSearch;
  iconPlus = faPlus;
  iconSee = faEye;
  iconTrash = faTrash;
  chosenTypeInstCheck: boolean = false;
  sellPoints: Sellpoint[] = [];
  cuits: any[] = [];

  contractDataFormGroup = new UntypedFormGroup({
    folder: new UntypedFormControl('', [
      Validators.required,
      Validators.min(0),
      Validators.max(2147483647),
    ]),
    innerCode: new UntypedFormControl('', [
      Validators.required,
      Validators.min(0),
      Validators.max(2147483647),
    ]),
    startDate: new UntypedFormControl('', Validators.required),
    expiryDate: new UntypedFormControl('', Validators.required),
    recisionDate: new UntypedFormControl(''),
    installmentQuantity: new UntypedFormControl('', [
      Validators.required,
      Validators.min(1),
      Validators.max(100),
    ]),
    installmentGenerationTypeId: new UntypedFormControl(''),
    receiptTypeId: new UntypedFormControl('', Validators.required),
    cuit: new UntypedFormControl(''),
    sellPoint: new UntypedFormControl(''),
    billTypeId: new UntypedFormControl(''),
    generatesOwnerBill: new UntypedFormControl(''),
  });

  constructor(
    private dateService: DateService,
    private cuitService: CuitService,
    private contractEditService: ContractEditService,
  ) {}

  ngOnInit() {
    if (this.contractInput) {
      this.contractDataFormGroup.patchValue({
        folder: this.contractInput.folderId,
        innerCode: this.contractInput.internalId,
        startDate: this.dateService.transformToDateHash(
          new Date(this.contractInput.fromDate),
        ),
        expiryDate: this.dateService.transformToDateHash(
          new Date(this.contractInput.toDate),
        ),
        installmentQuantity: this.contractInput.installmentQuantity,
        installmentGenerationTypeId:
          this.contractInput.installmentGenerationTypeId,
        receiptTypeId: this.contractInput.receiptTypeId,
        billTypeId: this.contractInput.billTypeId,
        generatesOwnerBill: this.contractInput.generatesOwnerBill,
      });
      this.chosenTypeInstCheck = true;
    }

    if (this.contractInput.receiptTypeId == '1') {
      this.loadCuit();
    }
  }

  changeInvoice() {
    this.contractInput.generatesOwnerBill =
      this.contractDataFormGroup.value.generatesOwnerBill;
  }

  folderInnerCode() {
    this.contractInput.folderId = this.contractDataFormGroup.value.folder;
    this.contractInput.internalId = this.contractDataFormGroup.value.innerCode;
  }

  changeStartDate() {
    // ESTE METODO CAMBIA LA FECHA DE EXPIRACION A PARTIR DE LA FECHA DE
    //COMIENZO AÑADIENDO LOS DIFERENTES PARAMETROS ELEGIDOS POR EL USUARIO
    if (this.contractDataFormGroup.value.startDate) {
      this.contractInput.installmentQuantity = Number(
        this.contractDataFormGroup.value.installmentQuantity,
      );
      this.contractInput.installmentGenerationTypeId = Number(
        this.contractDataFormGroup.value.installmentGenerationTypeId,
      );

      let yearN = this.contractDataFormGroup.value.startDate.year;
      let monthN = this.contractDataFormGroup.value.startDate.month;
      let dayN = this.contractDataFormGroup.value.startDate.day;

      if (this.contractDataFormGroup.value.installmentQuantity != '') {
        let intallmentQuantity = Number(
          this.contractDataFormGroup.value.installmentQuantity,
        );
        let typeOfInstallment = Number(
          this.contractDataFormGroup.value.installmentGenerationTypeId,
        );
        let newDate = new Date(
          yearN,
          monthN + intallmentQuantity * typeOfInstallment,
          dayN,
        );
        this.contractDataFormGroup.patchValue({
          expiryDate: {
            year: newDate.getFullYear(),
            month: newDate.getMonth(),
            day: newDate.getDate(),
          },
        });
      } else {
        let newDate = new Date(yearN, monthN + 36, dayN);
        this.contractDataFormGroup.patchValue({
          expiryDate: {
            year: newDate.getFullYear(),
            month: newDate.getMonth(),
            day: newDate.getDate(),
          },
        });
      }
    }
    this.loadStartDate();
    this.loadExpiryDate();

    this.contractEditService.setContract(this.contractInput);
  }

  loadStartDate() {
    if (this.contractDataFormGroup.value.startDate) {
      let date = this.contractDataFormGroup.value.startDate;
      this.contractInput.fromDate = this.dateService.transformToDateObject(
        date.day,
        date.month,
        date.year,
      );
    }
  }

  loadExpiryDate() {
    if (this.contractDataFormGroup.value.expiryDate) {
      let date = this.contractDataFormGroup.value.expiryDate;
      this.contractInput.toDate = this.dateService.transformToDateObject(
        date.day,
        date.month,
        date.year,
      );
    }
  }

  chooseTypeOfInstallment() {
    this.chosenTypeInstCheck = true;
    this.changeStartDate();
  }

  loadCuit() {
    this.contractInput.receiptTypeId =
      this.contractDataFormGroup.value.receiptTypeId;
    if (this.contractDataFormGroup.value.receiptTypeId == 1) {
      this.cuitService.getAll().subscribe((result) => {
        this.cuits = result;
        if (this.contractInput.receiptTypeId == 1) {
          this.cuits.forEach((CUIT) => {
            if (this.contractInput.cuit?.id == CUIT.id) {
              this.contractDataFormGroup.controls['cuit'].patchValue(CUIT);
            }
          });
          this.loadSellPoints();
        }
      });
    } else {
      this.cuits = [];
      this.sellPoints = [];
      this.contractInput.cuit = null;
      this.contractInput.cuitId = null;
      this.contractInput.sellPoint = null;
      this.contractInput.sellPointId = null;
      this.contractInput.billTypeId = null;
      this.contractDataFormGroup.controls['billTypeId'].patchValue(null);
      this.contractDataFormGroup.controls['cuit'].patchValue(null);
      this.contractDataFormGroup.controls['sellPoint'].patchValue(null);
    }
  }

  loadSellPoints() {
    this.contractInput.cuit = this.contractDataFormGroup.value.cuit;
    this.contractInput.cuitId = this.contractInput.cuit.id;
    this.sellPoints = this.contractInput.cuit.sellPoints;
    if (this.contractInput.receiptTypeId == '1') {
      this.sellPoints?.forEach((point) => {
        if (this.contractInput.sellPointId == point.id) {
          this.contractDataFormGroup.controls['sellPoint'].patchValue(point);
        }
      });
    }
  }

  setPoint() {
    this.contractInput.sellPoint = this.contractDataFormGroup.value.sellPoint;
    this.contractInput.sellPointId = this.contractInput.sellPoint.id;
  }

  setBilltype() {
    this.contractInput.billTypeId = this.contractDataFormGroup.value.billTypeId;
  }
}
