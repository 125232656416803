import { Input } from '@angular/core';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { PaymentsService } from 'src/app/services/payments.service';
import * as html2pdf from 'html2pdf.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-payment-to-PDF',
  templateUrl: './payment-to-PDF.component.html',
  styleUrls: ['./payment-to-PDF.component.scss'],
})
export class PaymentToPDFComponent implements OnInit {
  @Input() paymentId?: any[];
  @Input() popoverOption?: boolean = false;

  payment: any;
  totalRent: number = 0;

  serviceStatus = [];

  frontOrBack = false;
  newWindowHtml: string;
  iconDownload = faFileArrowDown;
  isLoaded: boolean = true;

  constructor(
    public paymentService: PaymentsService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {}

  closeModal() {
    this.modalService.dismissAll();
  }

  openModal(template) {
    this.paymentService.getPaymentReceiptView(this.paymentId).subscribe(
      (payment: any) => {},
      (error) => {
        this.newWindowHtml = error.error.text;
      },
    );
    this.paymentService
      .getPaymentReceipt(this.paymentId)
      .subscribe((payment: any) => {
        var newWind = open();
        newWind.document.write(this.newWindowHtml);
        this.isLoaded = true;
      });
  }
}
