import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { TranslateService } from 'src/app/services/translate.service';
import { ApiService } from 'src/app/services/api.service';
import { GridSettings, GridColumn, Header } from './GridSettings';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { ModalContainerComponent } from '../modal-container/modal-container.component';
import { IconService } from '../../services/icon.service';
import { SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faQuestionCircle,
  faFilter,
  faPlus,
  faFileExcel,
} from '@fortawesome/free-solid-svg-icons';
import { Observable, Subscription } from 'rxjs';
import { ContractService } from 'src/app/services/contract.service';
import {
  IPaginatedService,
  IPaginatedServiceParamters,
} from 'src/app/core/models/ITable';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css'],
})
export class GridComponent<T> implements OnInit {
  //Childs
  @ViewChild(ModalContainerComponent) modalContainer: ModalContainerComponent;
  @ViewChildren('checkbox') checkboxes: QueryList<ElementRef>;

  // Output
  @Output() deleteAction = new EventEmitter();
  @Output() addEventEmmiter = new EventEmitter();
  @Output() selectElementEvent = new EventEmitter();

  // Inputs
  @Input() tableService: IPaginatedService;
  @Input() search: any;
  @Input() actions: any;
  @Input() dataType: T;
  @Input() formTemplate?: any;
  @Input() query: string = 'CreatedOrNot=true';
  @Input() id: number = 0;
  @Input() buttonForCreation?: boolean = true;
  @Input() isParentPage?: boolean = true;
  @Input() size: number = 1; // 1 small, 2 medium, 3 large
  @Input() cancelFormButton: boolean = false; // 1 small, 2 medium, 3 large
  @Input() settings: GridSettings;
  @Input() _refreshGrid: Observable<boolean>;
  @Input() itemsPerPage: number = 10;
  @Input() customEmptyMessage: string; // Mensaje personalizado en caso de tabla vacia

  // Properties
  iconPlus = faPlus;
  totalPages: number;
  totalItems: number;
  headers: Header[] = [];
  tableData: any[];
  renderData: any[] = [];
  rawData: any[] = [];
  isLoaded = false;
  personType: string = 'IsAddress';
  currentSelectedElement: any;
  currentSelectedElementsArray: any[] = [];
  currentSelectedElementsArrayIndexes: string[] = [];
  toggleFilters: boolean = false;
  appliedFilters: boolean = false;
  buttonText: string = 'Mostrar';
  hardFilt: string = '';
  paginatedArguments: IPaginatedServiceParamters = {
    query: this.query,
    pageNumber: 1,
    pageSize: this.itemsPerPage,
    id: this.id,
  };
  paginatedData: {
    firstItem: number;
    lastItem: number;
    currentPage: number;
  };

  keys: string[];
  headerData: any[];
  tableWidth: string;
  columnsWidth: string[] = [];
  urlData: any = [];
  title: string;

  loadingCSV = false;
  iconExcel = faFileExcel;

  iconQuestion = faQuestionCircle;
  iconFilter = faFilter;

  configUrl = 'assets/config.json';
  subscriptions = new Subscription();

  constructor(
    public translateService: TranslateService,
    public apiService: ApiService,
    public iconService: IconService,
    public contractService: ContractService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
  ) {
    this.headers = [];
    this.tableData = [];
    this.renderData = [];
  }

  ngOnInit(): void {
    this.tableWidth = this.settings.tableWidth + 'px';
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        var objectKeys = Object.keys(params);

        if (
          (objectKeys.indexOf('ContractId') < 0 && objectKeys.length > 0) ||
          objectKeys.length > 1
        ) {
          // this.toggleFilters = true;
        }

        this.appliedFilters = objectKeys.length > 0 ? true : false;
        this.toggleFilters = false;
        this.buttonText = 'Mostrar';

        this.loadDefaultPagParameterValues();

        objectKeys.forEach((key) => {
          if (
            params[key] != null &&
            this.paginatedArguments.query?.split('=')[0] != key
          ) {
            this.paginatedArguments[key] = params[key];
          }
        });
        this.getElements();
      }),
    );
    this.subscriptions.add(
      this._refreshGrid.subscribe((result) => {
        this.getElements();
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.id?.currentValue != changes.id?.previousValue) {
      this.refresh();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // Internal functions
  getElements() {
    let urlString = location.href;
    let url = new URL(urlString);
    let params = new URLSearchParams(url.search);
    this.title = params.get('Title');

    this.subscriptions.add(
      this.tableService.getAllPaginated(this.paginatedArguments).subscribe(
        (data: PaginatedResult<T[]>) => {
          this.rawData = JSON.parse(JSON.stringify(data.result));
          this.parseDataToRender(data);
          this.isLoaded = true;
        },
        (error) => {
          if (this.paginatedArguments.pageNumber != 1) {
            this.paginatedArguments.pageNumber = 1;
            this.getElements();
          } else {
            this.rawData = [];
            this.tableData = [];
            this.renderData = [];
            this.isLoaded = true;
          }
        },
      ),
    );
  }

  private parseDataToRender(data: PaginatedResult<T[]>) {
    this.headers = [];
    this.renderData = [];
    this.tableData = [];
    let i = 0;
    let x = 0;
    data.result.forEach((row: T) => {
      let j = 0;
      const newRow = {};
      const newUrlFun = {};
      this.settings.columns.forEach((column: GridColumn) => {
        newRow[j] = column.function
          ? column.function(row[column.key])
          : row[column.key];
        j++;
      });
      this.tableData.push(row);
      this.renderData.push(newRow);
      i++;
    });
    this.settings.columns.forEach((column: GridColumn) => {
      // Add headers
      if (column.columnSize) {
        this.headers[x] = {
          title: column.title,
          width: column.columnSize + '%',
        };
      } else {
        this.headers[x] = {
          title: column.title,
          width: null,
        };
      }
      x++;
    });
    this.totalItems = data.pagination?.totalItems || 0;
    this.totalPages = data.pagination?.totalPages || 0;
    this.paginatedData = {
      firstItem:
        (this.paginatedArguments.pageNumber - 1) *
          this.paginatedArguments.pageSize +
        1,
      lastItem: 0,
      currentPage: this.paginatedArguments.pageNumber,
    };
    this.paginatedData.lastItem =
      this.paginatedData.currentPage == this.totalPages
        ? this.totalItems
        : this.itemsPerPage * this.paginatedData.currentPage;
  }

  translate(text: string): string {
    return this.translateService.translate(text);
  }

  pageChanged(event) {
    this.paginatedArguments.pageNumber = event;
    this.getElements();
  }

  deleteRow(value) {
    this.deleteAction.emit(value);
  }

  refresh() {
    this.modalContainer?.closeModal();
    this.getElements();
  }

  addButton() {
    this.addEventEmmiter.emit();
  }

  addEvent() {
    if (this.formTemplate) {
      this.modalContainer.openModal(this.formTemplate);
    }
  }

  /* SEARCH */

  setResults(data: any) {
    this.paginatedArguments.pageNumber = 1;
    let params = {
      ContractId: data.id,
    };
    this.router.navigate([], {
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }

  clearSearch() {
    this.paginatedArguments.pageNumber = 1;
    let urlString = location.href;
    let url = new URL(urlString);
    let params = new URLSearchParams(url.search);
    params.delete('ContractId');
    let index = urlString.indexOf('/', 10);
    this.router.navigateByUrl(
      `${urlString.split('?')[0].slice(index)}${
        params.toString() ? '?' + params.toString() : ''
      }`,
    );
    this.getElements();
  }

  returnSelectElement(row, isChecked, index) {
    var returnElement = {
      data: this.tableData[index],
      status: isChecked.checked,
      index: index,
      page: this.paginatedArguments.pageNumber,
    };
    this.currentSelectedElement = returnElement;
    this.selectElementEvent.emit(returnElement);
  }

  returnSelectedElements(row, isChecked, index) {
    var returnElement = {
      data: this.tableData[index],
      status: isChecked.checked,
      index: index,
      page: this.paginatedArguments.pageNumber,
    };

    if (isChecked.checked) {
      this.currentSelectedElementsArray.push(returnElement);
      this.currentSelectedElementsArrayIndexes.push(
        `${this.paginatedArguments.pageNumber}${index}`,
      );
    } else {
      var foundElementIndex = undefined;
      this.currentSelectedElementsArray.forEach((element, ind) => {
        if (
          element.page == this.paginatedArguments.pageNumber &&
          element.index == index
        ) {
          foundElementIndex = ind;
        }
      });
      if (this.currentSelectedElementsArray.length == 1) {
        this.currentSelectedElementsArray = [];
      } else {
        foundElementIndex != undefined
          ? this.currentSelectedElementsArray.splice(foundElementIndex, 1)
          : false;
      }
      var indexArrayDeleteIndex =
        this.currentSelectedElementsArrayIndexes.indexOf(
          `${this.paginatedArguments.pageNumber}${index}`,
        );
      this.currentSelectedElementsArrayIndexes.length == 1
        ? (this.currentSelectedElementsArrayIndexes = [])
        : this.currentSelectedElementsArrayIndexes.splice(
            indexArrayDeleteIndex,
            1,
          );
    }
    this.selectElementEvent.emit(this.currentSelectedElementsArray);
  }

  isSelected(index): boolean {
    return this.currentSelectedElementsArrayIndexes.includes(
      `${this.paginatedArguments.pageNumber}${index}`,
    );
  }

  turnOnFilters() {
    this.toggleFilters = !this.toggleFilters;
    if (this.toggleFilters) {
      this.buttonText = 'Ocultar';
    } else {
      this.buttonText = 'Mostrar';
    }
  }

  checkArray(row): boolean {
    return Array.isArray(row);
  }

  loadDefaultPagParameterValues() {
    var number = this.paginatedArguments.pageNumber;
    this.paginatedArguments = {
      query:
        this.query + this.settings.hardcodedFilter
          ? this.settings.hardcodedFilter
          : '',
      pageNumber: 1,
      pageSize: this.itemsPerPage,
      id: this.id,
      period: this.settings.period,
    };
  }

  unCheck() {
    this.currentSelectedElement = undefined;
    this.currentSelectedElementsArray = [];
    this.currentSelectedElementsArrayIndexes = [];
    this.checkboxes.forEach((checkbox) => {
      checkbox.nativeElement.checked = false;
    });
  }

  togglePages(event) {
    this.router.navigateByUrl(event);
  }

  changePageSize(event) {
    this.itemsPerPage = event.target.value;
    this.paginatedArguments.pageSize = event.target.value;
    this.refresh();
  }

  footerMsg(title) {
    title = title ? `${title} del ` : '';
    return `Mostrando ${title}${this.paginatedData.firstItem} al ${this.paginatedData.lastItem} de ${this.totalItems} registros.`;
  }

  downloadCSV() {
    this.loadingCSV = true;
    console.log('Descargando Excel...');
    this.toastService.showToast(
      'info',
      'Descargando CSV, el proceso puede tomar unos momentos...',
    );
    this.apiService.downloadCSV().subscribe(
      (res: any) => {
        const blob = new Blob([res], { type: 'text/csv' });

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'Contratos_' + new Date().toISOString() + '.csv';

        a.click();

        window.URL.revokeObjectURL(url);
        this.loadingCSV = false;

        this.toastService.showToast('success', 'Descarga Exitosa');
      },
      (error) => {
        this.toastService.showToast(
          'error',
          'Error al descargar el archivo CSV',
        );
        this.loadingCSV = false;
        console.error('Error al descargar el archivo CSV', error);
      },
    );
  }
}
