import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-message-card',
  templateUrl: './message-card.component.html',
  styleUrls: ['./message-card.component.css'],
})
export class MessageCardComponent implements OnInit {
  @Input() cardContent: TemplateRef<any>;
  @Input() width: any;
  @Input() height: any;

  constructor() {
    this.width = '200px';
    this.height = '200px';
  }

  ngOnInit(): void {}
}
