import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@auth0/auth0-spa-js';
import {
  faBirthdayCake,
  faBuilding,
  faEnvelope,
  faIdCard,
  faPen,
  faPhone,
  faQuestionCircle,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { IPaginatedService } from 'src/app/core/models/ITable';
import { PhoneNumber } from 'src/app/core/models/PhoneNumber';
import { Property } from 'src/app/core/models/Property';
import { DateService } from 'src/app/services/date.service';
import { TranslateService } from 'src/app/services/translate.service';
import { GridLiteSettings } from 'src/app/shared/grid-lite/GridLiteSettings';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-detail-viewer',
  templateUrl: './user-detail-viewer.component.html',
  styleUrls: ['./user-detail-viewer.component.css'],
})
export class UserDetailViewerComponent implements OnInit {
  iconBday = faBirthdayCake;
  iconEmail = faEnvelope;
  iconDoc = faIdCard;
  iconAddress = faBuilding;
  iconPhones = faPhone;
  iconAgent = faUserTie;
  iconPen = faPen;

  @Input() userData: User;
  @Input() contracts: GridLiteSettings;
  @Input() historicContracts: GridLiteSettings;
  @Input() tableSettings: GridLiteSettings[];
  @Input() service: IPaginatedService;
  @Input() id: number;
  @Input() type: string;
  @Input() properties: Property[];
  @Input() editForm: any;

  userAddress: string;
  avatarInitial: string;
  email: string = '';
  phones: PhoneNumber[] = [];
  age: string = '';
  nationalId: string = '';
  address: string = '';
  name: string = '';
  iconQuestion = faQuestionCircle;

  constructor(
    public dateService: DateService,
    public translateService: TranslateService,
    private router: Router,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.loadData();
  }

  loadData(user = null) {
    if (user != null) {
      this.userData = user;
    }
    let initials = this.userData?.person.fullName.split(" ");
    this.avatarInitial = initials.map(word => word.charAt(0)).join("");
    
    this.name = this.userData?.person.isPerson
      ? this.userData?.person.fullName.trim()
      : this.userData?.person.businessName.trim();
    this.age = this.getAge(this.userData?.person);
    this.address = this.getAddress(this.userData.person.address);
    this.email = this.userData?.email || 'No hay direccion de email';
    this.nationalId =
      this.userData?.person.documentId || 'No hay numero de identificación';
    this.phones = this.userData?.person.phones;
  }

  propertyUrl(id) {
    this.router.navigateByUrl(`/properties/${id}`);
  }

  getAge(person) {
    if (person.dateOfBirth) {
      return `${person.dateOfBirth.substring(0, 10)} (${person.age})`;
    }
    return 'No hay fecha de nacimiento';
  }

  getAddress(addressProperty) {
    let addresses: string =
      addressProperty.streetAddress;
    addresses = addresses?.replace(/null/g, '').trim();
    if (addresses != '') {
      return addresses;
    }
    return 'No hay direccion';
  }

  edit(){    
    this.modalService.open(this.editForm, { size: 'lg' })
  }
}

