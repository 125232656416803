import { EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  faCalendar,
  faSearch,
  faPlus,
  faEye,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { DocumentTypes } from 'src/app/core/models/DocumentTypes.enum';
import { PhoneNumber } from 'src/app/core/models/PhoneNumber';
import { TenantForEdit } from 'src/app/core/models/TenantForAdd';
import { TenantType } from 'src/app/core/models/TenantType.enum';

import { DateService } from 'src/app/services/date.service';
import { TenantService } from 'src/app/services/tenant.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-tenant-form',
  templateUrl: './tenant-form.component.html',
  styleUrls: ['./tenant-form.component.css'],
})
export class TenantFormComponent implements OnInit {
  iconCalendar = faCalendar;
  iconSearch = faSearch;
  iconPlus = faPlus;
  iconSee = faEye;
  iconTrash = faTrash;
  display: boolean = true;
  tenantTypeCheck: boolean = true;
  tenantTypeToggle: TenantType = TenantType.Person;
  tenantType = TenantType;
  tenantIndex: number;
  phoneContacts: PhoneNumber[] = [];
  documentTypes: any[] = [];
  submitted: boolean = false;


  tenantToSubmit: TenantForEdit;

  @Input() edit?: boolean = false;
  @Input() viewObject?: boolean = true;
  @Input() editData?: any;
  @Input() createInMemory?: any;
  @Input() createInMemoryArray?: any;
  @Input() editContractMode?: boolean = false;

  @Output() closeAction = new EventEmitter();
  @Output() cancelAction = new EventEmitter();

  addTooltip = 'Añadir';
  //MOCKS

  guarantorType = [{ id: 1, type: 'Garante' }];

  tenantDataFormGroup = new UntypedFormGroup({
    dateOfBirth: new UntypedFormControl(''),
    firstName: new UntypedFormControl('', Validators.required),
    lastName: new UntypedFormControl('', Validators.required),
    documentId: new UntypedFormControl('', [Validators.minLength(7), Validators.maxLength(15)]),
    documentType: new UntypedFormControl(''),
    streetAddress: new UntypedFormControl(''),
    city: new UntypedFormControl(''),
    state: new UntypedFormControl(''),
    zipCode: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    socialReason: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl(''),
    taxRole: new UntypedFormControl(null),
  });

  constructor(
    public tenantService: TenantService,
    public dateService: DateService,
    private router: Router,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    for (let item in DocumentTypes) {
      if (isNaN(Number(item))) {
        this.documentTypes.push({ text: item, value: DocumentTypes[item] });
      }
    }
    if (this.edit) {
      this.tenantIndex = this.editData.id;

      this.phoneContacts =
        this.editData.user?.person?.phones || this.editData.phones;

      var date =
        this.editData.user?.person?.dateOfBirth || this.editData.dateOfBirth;
      var finalDate = date
        ? this.dateService.transformToDateHash(new Date(date))
        : null;
      var ownerType = this.editData.user?.person || this.editData;
      this.setTenantTypeCheck(ownerType);
      this.tenantDataFormGroup.patchValue({
        dateOfBirth: finalDate,
        firstName:
          this.editData.user?.person?.firstName || this.editData.firstName,
        lastName:
          this.editData.user?.person?.lastName || this.editData.lastName,
        documentId:
          this.editData.user?.person?.documentId || this.editData.documentId,
        documentType:
          this.editData.user?.person?.documentType ||
          this.editData.documentType,
        streetAddress:
          this.editData.user?.person?.address?.streetAddress ||
          this.editData.streetAddress,
        city: this.editData.user?.person?.address?.city || this.editData.city,
        state:
          this.editData.user?.person?.address?.state || this.editData.state,
        zipCode:
          this.editData.user?.person?.address?.zipCode || this.editData.zipCode,
        email: this.editData.user?.email || this.editData.email,
        socialReason:
          this.editData.user?.person?.businessName ||
          this.editData.businessName,
        description:
          this.editData.user?.person?.description || this.editData.description,
        taxRole:           
          this.editData.user?.person?.taxRole || this.editData.taxRole,
      });
      let isPerson =
        this.editData.user?.person?.isPerson || this.editData.isPerson;
      if (isPerson) {
        this.selectTenantType(1);
      } else {
        this.selectTenantType(2);
      }
    } else {
      this.selectTenantType();
      this.tenantDataFormGroup.reset();
    }
  }

  onSubmit() {
    let date = this.tenantDataFormGroup.value.dateOfBirth
      ? this.tenantDataFormGroup.value.dateOfBirth
      : null;

    this.tenantToSubmit = {
      id: this.editData?.id || 0,
      userName: this.tenantDataFormGroup.value.email
        ? this.tenantDataFormGroup.value.email
        : null,
      email: this.tenantDataFormGroup.value.email
        ? this.tenantDataFormGroup.value.email
        : null,
      userImageUrl: this.tenantDataFormGroup.value.userImageUrl
        ? this.tenantDataFormGroup.value.userImageUrl
        : null,
      isAdmin: false,
      documentId: this.tenantDataFormGroup.value.documentId
        ? this.idParser(this.tenantDataFormGroup.value.documentId)
        : null,
      documentType: this.tenantDataFormGroup.value.documentType
        ? this.tenantDataFormGroup.value.documentType
        : null,
      dateOfBirth: date ? this.dateService.transformToDateObject(
        date?.day,
        date?.month,
        date?.year,
      ) : null,
      firstName: this.tenantDataFormGroup.value.firstName
        ? this.tenantDataFormGroup.value.firstName
        : '',
      lastName: this.tenantDataFormGroup.value.lastName
        ? this.tenantDataFormGroup.value.lastName
        : '',
      streetAddress: this.tenantDataFormGroup.value.streetAddress
        ? this.tenantDataFormGroup.value.streetAddress
        : null,
      city: this.tenantDataFormGroup.value.city
        ? this.tenantDataFormGroup.value.city
        : null,
      state: this.tenantDataFormGroup.value.state
        ? this.tenantDataFormGroup.value.state
        : null,
      zipCode: this.tenantDataFormGroup.value.zipCode
        ? this.tenantDataFormGroup.value.zipCode
        : null,
      phones: this.phoneContacts,
      isPerson: this.tenantTypeToggle == 1 ? true : false,
      businessName: this.tenantDataFormGroup.value.socialReason
        ? this.tenantDataFormGroup.value.socialReason
        : '',
      description: this.tenantDataFormGroup.value.description,
      taxRole: this.tenantDataFormGroup.value.taxRole 
        ? this.tenantDataFormGroup.value.taxRole 
        : null,
    };

    if (this.tenantDataFormGroup.get('dateOfBirth').hasError('ngbDate')) {
      this.toastService.showToast(
        'warning',
        'Corrija el formato de la fecha a AAAA-MM-DD',
      );
      return;
    }

    if (this.tenantDataFormGroup.invalid) {
      this.tenantDataFormGroup.markAllAsTouched();
      this.toastService.showToast(
        'warning',
        'Hay campos obligatorios sin completar',
      );
      return;
    } else {
      if (this.edit) {
        if (this.createInMemory) {
          this.createInMemoryArray[this.tenantIndex] = this.tenantToSubmit;
          this.closeAction.emit(this.tenantToSubmit);
        } else {
          this.editTenant(this.tenantToSubmit);
        }
      } else {
        this.submitted = true
        if (this.createInMemory) {
          this.createInMemoryArray.push(this.tenantToSubmit);
          this.closeAction.emit(this.tenantToSubmit);
        } else {
          this.addTenant(this.tenantToSubmit);
        }
      }
    }
  }

  addTenant(tenantToSubmit) {
    this.tenantService
      .createTenant(tenantToSubmit)
      .subscribe((response: number) => {
        this.toastService.showToast('success', 'Inquilino creado');
        if (this.editContractMode) {
          this.tenantService.getTenant(response).subscribe((result) => {
            this.createInMemoryArray.push(result);
            this.closeAction.emit(result);
          });
        } else {
          this.closeAction.emit();
          this.router.navigateByUrl(`/tenants/${response}`);
        }
      });
  }

  editTenant(TenantForEdit: TenantForEdit) {
    TenantForEdit.id = this.editData.id;
    this.tenantService
      .updateTenant(this.editData.id, TenantForEdit)
      .subscribe((response) => {
        this.toastService.showToast('success', 'Inquilino actualizado');
        if (this.editContractMode) {
          this.tenantService.getTenant(this.editData.id).subscribe((result) => {
            this.createInMemoryArray[this.tenantIndex] = result;
            this.closeAction.emit(result);
          });
        } else {
          this.closeAction.emit(this.editData.id);
        }
      });
  }

  selectTenantType(value = 1) {
    if (value == 1) {
      this.tenantDataFormGroup.controls['socialReason'].clearValidators();
      this.tenantDataFormGroup.controls['socialReason'].patchValue(null);
      this.tenantDataFormGroup.controls['firstName'].addValidators(
        Validators.required,
      );
      this.tenantDataFormGroup.controls['lastName'].addValidators(
        Validators.required,
      );
    } else {
      this.tenantDataFormGroup.controls['firstName'].clearValidators();
      this.tenantDataFormGroup.controls['lastName'].clearValidators();
      this.tenantDataFormGroup.controls['firstName'].patchValue(null);
      this.tenantDataFormGroup.controls['lastName'].patchValue(null);
      this.tenantDataFormGroup.controls['dateOfBirth'].patchValue(null);
      this.tenantDataFormGroup.controls['documentType'].patchValue("3");
      this.tenantDataFormGroup.controls['socialReason'].addValidators(
        Validators.required,
      );
    }
    this.tenantTypeToggle = value;
  }

  setTaxRole(taxRole){    
    if(taxRole > 0){
      this.tenantDataFormGroup.controls['documentType'].addValidators(
        Validators.required,
      );
      this.tenantDataFormGroup.controls['documentId'].addValidators(
        Validators.required,
      );
    }
    else{
      this.tenantDataFormGroup.controls['documentType'].clearValidators();
      this.tenantDataFormGroup.controls['documentId'].clearValidators();
    }
    this.tenantDataFormGroup.controls["documentType"].updateValueAndValidity();
    this.tenantDataFormGroup.controls["documentId"].updateValueAndValidity();
  }

  setTenantTypeCheck(data) {
    this.tenantTypeCheck = data.isPerson;
    data.isPerson ? (this.tenantTypeToggle = 1) : (this.tenantTypeToggle = 2);
  }

  validate(field: string) {
    return (
      this.tenantDataFormGroup.controls[field].errors &&
      this.tenantDataFormGroup.controls[field].touched
    );
  }

  idParser(id) {
    id = id.toString();
    let stringId: string = '';
    for (let i = 0; i < id.length; i++) {
      let char: string = id.charAt(i);
      if (parseInt(char) + 10) {
        stringId += char;
      }
    }
    if (stringId == '') {
      return null;
    }
    return Number(stringId);
  }

  cancel(){
    this.cancelAction.emit();
  }
}
