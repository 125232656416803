import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: any[] = [];

  show(options: any = {}) {
    this.toasts.push({ ...options });
  }

  remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }

  showToast(
    type: 'success' | 'error' | 'warning' | 'info' | 'notify',
    message: string = null,
  ) {
    switch (type) {
      case 'success':
        this.show({
          template: 'success',
          classname: 'bg-success text-light',
          delay: 10000,
          message: message ?? 'Operación realizada con EXITO!',
        });
        break;
      case 'warning':
        this.show({
          template: 'warning',
          classname: 'bg-warning text-light',
          delay: 10000,
          message: message ?? 'Verifique que todo este bien',
        });
        break;
      case 'error':
        this.show({
          template: 'error',
          classname: 'bg-danger text-light',
          delay: 10000,
          message: message ?? 'Ups hubo un error',
        });
        break;
      case 'info':
        this.show({
          template: 'info',
          classname: 'bg-primary text-light',
          delay: 10000,
          message: message ?? 'Su solicitud esta siendo procesada',
        });
        break;
      case 'notify':
        this.show({
          template: 'info',
          classname: 'bg-primary text-light',
          delay: 100000,
          message: message ?? 'Notificación',
        });
        break;
      default:
        break;
    }
  }
}

// DOCUMENTACION: Invoke para el toastService en el componente requerido
// showToast() {
//   this.toastService.show({ template: 'dangerTpl', classname: 'bg-danger text-light', delay: 5000, message: 'Hola hola' });
// }
