import { Injectable } from '@angular/core';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { Observable } from 'rxjs';
import { IPaginatedServiceParamters } from '../core/models/ITable';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class InstallmentService {
  constructor(private apiService: ApiService) {}

  getAllPaginated(
    paginatedArguments: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<any>> {
    return this.apiService.getPaginated(
      `/api/Installment/Contract/${paginatedArguments.id}/Paginated`,
      paginatedArguments,
    );
  }

  getAllInstallments(contractId) {
    return this.apiService.get(`/api/Installment/Contract/${contractId}`);
  }

  getAllInstallmentsStatus() {
    return this.apiService.get(`/api/Installment/Status`);
  }

  getAllUnpaidInstallments(contractId) {
    return this.apiService.get(
      `/api/Installment/Contract/${contractId}/Unpaid`,
    );
  }

  getInstallment(id) {
    return this.apiService.get(`/api/Installment/${id}`);
  }

  GetPaginatedInstallmentsWithReturns(
    contractId,
    paginatedArguments: IPaginatedServiceParamters,
    isSelected: boolean
  ): Observable<PaginatedResult<any>> {
    const url = `/api/Installment/Contract/${contractId}/WithReturns`;
    const data = {
      ...paginatedArguments,
      ShowFutureInstallments: isSelected
    };
    return this.apiService.getPaginated(url, data);
  }

  getInstallmentsQuantity() {
    return this.apiService.get('/api/Installment/Quantity');
  }

  editInstallment(updateInstallment, id) {
    return this.apiService.put(`/api/Installment/${id}`, updateInstallment);
  }
}
