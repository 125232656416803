import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Installment } from 'src/app/core/models/Installment';
import { DateService } from 'src/app/services/date.service';
import { faEye, faPen } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-installment-cards',
  templateUrl: './installment-cards.component.html',
  styleUrls: ['./installment-cards.component.css'],
})
export class InstallmentCardsComponent implements OnInit {
  @Input() installment: Installment;
  @Input() maratoriumState: any;

  iconEdit = faPen;
  iconView = faEye;

  check: boolean = false;
  totalCardAmount: number = 0;
  totalAdditionalCharges: number = 0;
  totalPunitives: number = 0;
  withPunitives: boolean = true;
  installmentToEdit: Installment;
  newDescription: string;
  url: string;

  constructor(
    public dateService: DateService,
    public modalService: NgbModal,
    public router: Router,
  ) {}

  ngOnInit() {
    this.maratoriumState.subscribe((result) => {
      this.withPunitives = result;
      this.calculateLiabilities(this.withPunitives);
    });
    this.calculateLiabilities(this.withPunitives);
    this.url = `/contracts/${this.installment.contractId}/installment/${this.installment.id}`;
  }

  calculateLiabilities(moratoriumBool) {
    this.totalCardAmount = 0;
    this.totalPunitives = 0;
    this.installment.additionalCharges.forEach((additionalCharge) => {
      if (
        additionalCharge.punitive &&
        moratoriumBool &&
        this.installment?.installmentStatusId != 1
      ) {
        this.totalPunitives += additionalCharge.sign
          ? additionalCharge.amount
          : -additionalCharge.amount;
      }
    });
    this.totalCardAmount +=
      this.installment.amount +
      this.totalPunitives -
      this.installment.amountPaid;
  }

  editInstallment(installment, form) {
    this.installmentToEdit = installment;
    this.modalService.open(form);
  }

  close() {
    this.modalService.dismissAll();
    this.calculateLiabilities(this.withPunitives);
  }

  transformInstallmentName(inst: Installment) {
    let name = inst.description;
    let [firstName, ...numberYear] = name.split(' ');

    const months = {
      January: 'Enero',
      February: 'Febrero',
      March: 'Marzo',
      April: 'Abril',
      May: 'Mayo',
      June: 'Junio',
      July: 'Julio',
      August: 'Agosto',
      September: 'Septiembre',
      October: 'Octubre',
      November: 'Noviembre',
      December: 'Diciembre',
    };

    if (months[firstName]) {
      // Check if firstName is a key in the months object. If it is, set name to the value of the key plus numberYear. Otherwise, leave name as it is.
      name = `${months[firstName]} ${numberYear}`; // ES6 string interpolation syntax used here.
    }

    return name; // Return the updated or unchanged name.
  }

  redirect(url: string) {
    this.router.navigateByUrl(url);
  }
}
