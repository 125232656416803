import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ConsortiumHolderService } from '../consortium-holder/consortium-holder.service';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css'],
})
export class CardsComponent implements OnInit {
  @Input() smallCard: boolean;
  @Input() cardData: any;

  constructor(
    private router: Router,
    private consortiumHolderService: ConsortiumHolderService,
  ) {
    this.smallCard = true;
  }

  ngOnInit(): void {}

  navigateTo() {
    this.consortiumHolderService.setConsortiumId(this.cardData.id);
    this.router.navigate(['/home/consortium', this.cardData.id, 'detail']);
  }
}
