<form [formGroup]="mailFormGroup" (ngSubmit)="onSubmit()">
    <div class="title">
      <h1>Editar MAIL</h1>
    </div>
    
    <div>
      <span>Recuerde que el mail de contacto debe ser validado en la página de SendGrid. Siga este <a href="https://app.sendgrid.com/settings/sender_auth" target="_blank">link </a> para hacer la validación </span>
    </div>
    <hr>
    <div class="form-building form-group">
      <div class="form-data">
        <div class="main-data"> 
  
          <label>Dirección de Email</label>
          <input type="text" formControlName="email" class="form-control"
          [ngClass]="{'borderRed': mailFormGroup.invalid && (mailFormGroup.dirty || mailFormGroup.touched) 
                      && !mailFormGroup.value.mail}">                                    
        </div>    
       
      </div>
    </div>  

    <div *ngIf="mailFormGroup.invalid && (mailFormGroup.dirty || mailFormGroup.touched) 
    && !mailFormGroup.value.mail" style="color: var(--border-red); font-size: 120%;">
      Datos faltantes
    </div>

    <div class="buttons-submitting" style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
      <button class="btn btn-primary">Editar</button>
    </div>

     
    
</form>
