import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-detail-messages-card',
  templateUrl: './detail-messages-card.component.html',
  styleUrls: ['./detail-messages-card.component.css'],
})
export class DetailMessagesCardComponent implements OnInit {
  @Input() messages: any[];
  @Input() messageQuantity: number;
  @Input() title: string;
  openCard: boolean;
  noMessages: boolean;

  constructor() {
    this.messageQuantity = 0;
  }

  ngOnInit(): void {
    if (this.messages.length > 0) {
      this.messages = this.messages.slice(0, 5);
    } else {
      this.messages = [];
      this.noMessages = true;
    }
  }

  mouseEnter(event) {
    this.openCard = event;
  }
}
