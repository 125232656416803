import {
  faPlug,
  faTrash,
  faFaucet,
  faTv,
  faLaptopHouse,
  faBed,
  faBell,
  faCouch,
  faFan,
  faHouseUser,
  faSnowflake,
  faMoneyCheckAlt,
  faMoneyBill,
  faMoneyCheck,
  faCar,
  faReceipt,
  faParking,
  faBroom,
  faWifi,
  faAmbulance,
  faTint,
  faLightbulb,
  faBurn,
  faPhone,
  faDollarSign,
  faBox,
  faDesktop,
  faUser,
  faHandshake,
  faUsers,
  faUserFriends,
  faUserTie,
  faUserTag,
  faFile,
  faCashRegister,
  faVoteYea,
  faPlus,
  faUserSecret,
  faExchangeAlt,
  faCheckCircle,
  faPercentage,
  faCalendar,
  faListOl,
  faHashtag,
  faExclamationTriangle,
  faCheck,
  faTimes,
  faInfoCircle,
  faSignature,
  faFileSignature,
  faCalculator,
  faFileInvoiceDollar,
  faHandHoldingDollar,
  faBuilding,
  faHouseChimney,
  faCircleExclamation,
  faCircle,
  faArrowRotateLeft,
  faGear
} from '@fortawesome/free-solid-svg-icons';

export const iconMap = {
  'gear': faGear,
  desktop: faDesktop,
  'cash-register': faCashRegister,
  'vote-yea': faVoteYea,
  plus: faPlus,
  user: faUser,
  users: faUsers,
  'users-friend': faUserFriends,
  'user-tie': faUserTie,
  'user-tag': faUserTag,
  'user-secret': faUserSecret,
  file: faFile,
  handshake: faHandshake,
  exchange: faExchangeAlt,
  'circle-check': faCheckCircle,
  plug: faPlug,
  trash: faTrash,
  faucet: faFaucet,
  tv: faTv,
  'house-laptop': faLaptopHouse,
  bed: faBed,
  bell: faBell,
  couch: faCouch,
  fan: faFan,
  'house-user': faHouseUser,
  snowflake: faSnowflake,
  'money-check-dollar': faMoneyCheckAlt,
  'money-bill': faMoneyBill,
  'money-check': faMoneyCheck,
  car: faCar,
  receipt: faReceipt,
  'square-parking': faParking,
  broom: faBroom,
  wifi: faWifi,
  'truck-medical': faAmbulance,
  tint: faTint,
  lightbulb: faLightbulb,
  burn: faBurn,
  phone: faPhone,
  'dollar-sign': faDollarSign,
  box: faBox,
  percentage: faPercentage,
  calendar: faCalendar,
  list: faListOl,
  hashtag: faHashtag,
  'triangle-exclamation': faExclamationTriangle,
  check: faCheck,
  times: faTimes,
  'info-circle': faInfoCircle,
  contract: faFileSignature,
  calculator: faCalculator,
  bill: faFileInvoiceDollar,
  hand: faHandHoldingDollar,
  building: faBuilding,
  userTie: faUserTie,
  house: faHouseChimney,
  'circle-exclamation': faCircleExclamation,
};

export const ICONLIST = [
  faPlug,
  faTrash,
  faFaucet,
  faTv,
  faLaptopHouse,
  faBed,
  faBell,
  faCouch,
  faFan,
  faHouseUser,
  faSnowflake,
  faMoneyCheckAlt,
  faMoneyBill,
  faMoneyCheck,
  faCar,
  faReceipt,
  faParking,
  faBroom,
  faWifi,
  faAmbulance,
  faArrowRotateLeft
];
