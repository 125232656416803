import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-receipt-form',
  templateUrl: './receipt-form.component.html',
  styleUrls: ['./receipt-form.component.css'],
})
export class ReceiptFormComponent implements OnInit {
  @Input() edit?: boolean;
  @Input() viewObject?: boolean = true;
  @Input() editData?: any;
  @Input() createInMemory?: any;
  @Input() createInMemoryArray?: any;

  @Output() closeAction = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
