import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { ProcessCSVComponent } from './pages/process-csv/process-csv.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ProcessCSVComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule,
    FormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: ProcessCSVComponent,
      },
    ]),
  ],
})
export class ProcesCsvModule {}
