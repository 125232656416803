import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Installment } from 'src/app/core/models/Installment';
import { InstallmentRequirementStates } from 'src/app/core/models/InstallmentRequirementStates';
import { InstallmentWithRequirement } from 'src/app/core/models/InstallmentWithRequirement';
import { Installments } from 'src/app/core/models/Installments';
import { DateService } from 'src/app/services/date.service';
import { IconService } from 'src/app/services/icon.service';
import { PaymentsService } from 'src/app/services/payments.service';

@Component({
  selector: 'app-requirement-card',
  templateUrl: './requirement-card.component.html',
  styleUrls: ['./requirement-card.component.css'],
})
export class RequirementCardComponent implements OnInit {
  @Input() requirement: InstallmentWithRequirement;
  @Input() installmentId?: number;
  @Input() installment: Installment;
  @Input() confirmationDialog: any;
  @Input() cardMode: boolean = false;
  @Input() lineMode: boolean = false;
  @Input() paymentMode: boolean = false;
  // @Input() paymentAccumulatorMode: boolean = false;

  @Output() updateExpense = new EventEmitter();
  @Output() refreshCard = new EventEmitter();

  requeriments: Installments;

  newDate = new Date();

  /* @ViewChild('popInstRequirement') popOver: NgbPopover; */

  constructor(
    public iconService: IconService,
    public dateService: DateService,
    public paymentService: PaymentsService,
  ) {}

  ngOnInit() {
    this.requirement.installmentRequirementStates.forEach((req) => {
      req['installmentState'] = this.installmentState(req);
    });
  }

  update() {
    console.log('pass');

    this.updateExpense.emit();
  }

  refresh(pop) {
    this.refreshCard.emit();
    pop.close();
  }

  private installmentState(req: InstallmentRequirementStates) {
    let state = req.status.id;
    let newState: string;
    let compareDate =
      this.installment != undefined
        ? new Date(this.installment?.fromDate)
        : new Date(this.requirement.installment.fromDate);

    if (req.hasInstallmentReq) {
      if (state == 1) {
        newState = 'pagado';
      }
      if (state == 3) {
        newState = 'parcial';
      }
    } else {
      if (compareDate < this.newDate) {
        newState = 'vencido';
      } else {
        newState = 'no vencido';
      }
    }
    return newState;
  }
}
