<div class="log-book-grid" [class.open]="openLog" ng-draggable>
  <div class="log-book-area border-5" [hidden]="!openLog">
    <div class="log-head" style="grid-area: head;">
      <fa-icon class="fa-lg" [icon]="stickyNote" size="2x" style="grid-area: icon; color: white;"></fa-icon>
      <div class="logbook-text-container" style="grid-area: head-text;">
        <span class="logbook-head-text">Notas de {{contractName}}</span>
        <span class="logbook-head-text-small">Entradas de Notas</span>
      </div>
      <button class="btn flex-row centered" style="grid-area: close;">
        <fa-icon class="fa-lg" [icon]="times" (click)="openLogBook()" style="color: white;"></fa-icon>
      </button>
    </div>
    <div class="log-entries" style="margin-bottom: 20px;">
      <div
        style="display: flex; flex-direction: row; justify-content: flex-end; margin-bottom: 10px; margin-right: 10px;">
        <button (click)="deleteAllNotify()" class="btn btn-secondary ">
          Eliminar Recordatorios </button>
      </div>
      <div class="list-group log-stuff" #scroll>
        <table class="table">
          <thead>
            <tr class="grid-headers">
              <th scope="col">
                <div class="flex-row">
                  #
                </div>
              </th>
              <th scope="col">
                <div class="flex-row">
                  Entrada
                </div>
              </th>
              <th scope="col">
                <div class="flex-row">
                  Fecha
                </div>
              </th>
              <th scope="col">
                <div class="flex-row">
                  Agente
                </div>
              </th>
              <th scope="col">
                <div class="flex-row">
                  Acciones
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let entry of entries; let ind = index; let last = last">
              <td>
                <div>
                  <p>{{ind + 1}}</p>
                </div>
              </td>
              <td>
                <div>
                  <p style="max-width: 15vw; text-align: justify;">{{entry.entry}}</p>
                </div>
              </td>
              <td>
                <div>
                  <p>{{ stringDate(entry.date) }}</p>
                </div>
              </td>
              <td>
                <div>
                  <p>{{ entry.user.person.fullName }}</p>
                </div>
              </td>
              <td>
                <div>
                  <button type="button" class="button btn btn-sm"
                    [ngClass]="{'btn-secondary': !entry.notify, 'btn-success': entry.notify}" placement="bottom"
                    style="margin-right: 15px;" (click)="notification(entry.id)" [ngbTooltip]="'Recordatorio'">
                    <fa-icon [icon]="bell"></fa-icon>
                  </button>
                  <button type="button" class="button btn btn-primary btn-sm" placement="bottom"
                    style="margin-right: 15px;" (click)="editEntry(edit, entry)" [ngbTooltip]="'Editar'">
                    <fa-icon [icon]="stickyNote"></fa-icon>
                  </button>
                  <button type="button" class="button btn btn-danger btn-sm" placement="bottom"
                    style="margin-right: 15px;" (click)="deleteEntry(entry.id)" [ngbTooltip]="'Eliminar'">
                    <fa-icon [icon]="trash"></fa-icon>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="new-entry">
      <form class="logbook-form border-top" [formGroup]="entryFormGroup">
        <textarea class="form-control custom-box" formControlName="entry" cols="70" rows="3"
          style="grid-area: text;"></textarea>
        <button class="btn btn-outline-primary rounded" style="grid-area: send;">
          <fa-icon class="fa-lg" [icon]="paperPlane" (click)="sendEntry()"></fa-icon>
        </button>
      </form>
    </div>
  </div>

</div>
<div class="logbook-button">
  <button [ngbTooltip]="'Ver notas'" type="button" class="log-button btn btn-primary rounded-circle"
    (click)="openLogBook()">
    <fa-icon class="fa-lg" [icon]="stickyNote"></fa-icon>
  </button>
</div>

<ng-template #edit>
  <div style="margin: 1vh 1vw 1vh 1vw;">
    <h1>Editar Entrada</h1>
    <hr>
    <form style="display: flex; flex-direction: column;" [formGroup]="entryEditFormGroup" (ngSubmit)="confirmEdit()">
      <div>
        <textarea rows="3" class="form-control" formControlName="entry"></textarea>
      </div>
      <div style="display: flex; flex-direction: row; justify-content: space-evenly; margin-top: 2vh;">
        <button type="button" (click)="cancel()" class="btn btn-danger">Cancelar</button>
        <button type="submit" class="btn btn-primary">Aceptar</button>
      </div>
    </form>
  </div>
</ng-template>