<div class="requirement-card border-10 border shadowframe" *ngIf="lineMode">
    <span style="grid-area: month; font-size: 110%; font-weight: 450;">{{requirement.installment.description}}</span>
    <span style="grid-area: from; font-size: 100%;">{{requirement.installment.fromDate | date: 'shortDate'}}</span>
    <span style="grid-area: to; font-size: 100%;">{{requirement.installment.endDate | date: 'shortDate'}}</span>
    <div style="grid-area: requirements; justify-self: start" class="requirements">
        <app-extraordinary-expenses [id]="installmentId" (updateExpense)="update()"></app-extraordinary-expenses>
        <div class="req-card border-15" *ngFor="let instReq of requirement.installmentRequirementStates"
            [ngbPopover]="popInstRequirement" popoverTitle={{instReq.requirementName}} placement="bottom"
            autoClose="outside" popoverClass="zindex" #p="ngbPopover">
            <fa-icon [icon]="iconService.getIcon(instReq.requirementIcon)" size="2x" style="opacity: 0.7;" [ngClass]="{'red-requirement': (instReq.installmentState == 'vencido'), 
                'green-requirement': (instReq.installmentState == 'pagado'), 
                'yellow-requirement': (instReq.installmentState == 'parcial'),
                'grey-requirement': (instReq.installmentState == 'no vencido')}">
            </fa-icon>
            <span class="badge text-bg-primary req-style-text">
                {{instReq.requirementName}}
            </span>
            <ng-template #popInstRequirement>
                <app-installment-requirement-form (closeAction)="refresh(p)" [installment]="requirement.installment"
                    [requirement]="instReq"></app-installment-requirement-form>
            </ng-template>
        </div>
    </div>
</div>

<div class="requirement-card-line" *ngIf="paymentMode">
    <app-extraordinary-expenses [id]="installmentId" (closeAction)="update()"
        style="margin-bottom: 10px;"></app-extraordinary-expenses>
    <div class="req-card-line border-5" *ngFor="let instReq of requirement.installmentRequirementStates"
        [ngbPopover]="popInstRequirement" popoverTitle={{instReq.requirementName}} placement="bottom"
        autoClose="outside" popoverClass="zindex" #p="ngbPopover">
        <fa-icon [icon]="iconService.getIcon(instReq.requirementIcon)" size="2x"
            style="opacity: 0.7; margin-left: 0.4vw; margin-bottom: 10px;" [ngClass]="{'red-requirement': (instReq.installmentState == 'vencido'), 
                'green-requirement': (instReq.installmentState == 'pagado'), 
                'yellow-requirement': (instReq.installmentState == 'parcial'),
                'grey-requirement': (instReq.installmentState == 'no vencido')}">
        </fa-icon>
        <span class="badge text-bg-primary req-style-text"
            style="margin-left: 0.4vw; width: fit-content; max-width: 6vw;">
            {{instReq.requirementName}}
        </span>
        <ng-template #popInstRequirement>
            <app-installment-requirement-form (closeAction)="refresh(p)" [installment]="requirement.installment"
                [requirement]="instReq"></app-installment-requirement-form>
        </ng-template>
    </div>
</div>


<div class="requirement-card-small" *ngIf="cardMode">
    <div class="description" style="grid-area: desc;">
        <span
            style="grid-area: month; font-size: 110%; font-weight: 450;">{{requirement.installment.description}}</span>
        <span style="grid-area: from; font-size: 100%;">{{requirement.installment.fromDate | date: 'shortDate'}}</span>
        <span style="grid-area: to; font-size: 100%;">{{requirement.installment.endDate | date: 'shortDate'}}</span>
    </div>
    <hr style="grid-area: hr;">
    <div class="icons-list" style="grid-area: icon;">
        <app-extraordinary-expenses [id]="installmentId" (updateExpense)="update()"></app-extraordinary-expenses>
        <div class="req-card" *ngFor="let instReq of requirement.installmentRequirementStates"
            [ngbPopover]="popInstRequirement" popoverTitle={{instReq.requirementName}} placement="bottom"
            autoClose="outside" popoverClass="zindex" #p="ngbPopover">
            <fa-icon [icon]="iconService.getIcon(instReq.requirementIcon)" size="2x" style="opacity: 0.7;" [ngClass]="{'red-requirement': (instReq.installmentState == 'vencido'), 
        'green-requirement': (instReq.installmentState == 'pagado'), 
        'yellow-requirement': (instReq.installmentState == 'parcial'),
        'grey-requirement': (instReq.installmentState == 'no vencido')}">
            </fa-icon>
            <span class="badge text-bg-primary req-style-text">
                {{instReq.requirementName}}
            </span>
            <ng-template #popInstRequirement>
                <app-installment-requirement-form (closeAction)="refresh(p)" [installment]="requirement.installment"
                    [requirement]="instReq"></app-installment-requirement-form>
            </ng-template>
        </div>
    </div>
</div>
<!-- instReq.requirementIcon.toLowerCase() -->


<!-- <div class="requirement-card-line" *ngIf="paymentAccumulatorMode">
    <app-extraordinary-expenses [id] = "installmentId" (updateExpense)="update()" ></app-extraordinary-expenses>
    <app-requirement-list-accumulator [requirementAndInstallment]="requirement"></app-requirement-list-accumulator>
</div> -->