export enum FacturaTypeEnum {
    A = 1,
    B = 6,
    C = 11
  }

  export enum NotaTypeEnum {
    A = 3,
    B = 8,
    C = 13
  }

  export enum TaxRole {
    ResponsableMonotributo = 1,
    ResponsableInscripto = 2
  }

  export enum TiposDeIVAEnum{
    "0%" = 3,
    "10.5%" = 4,
    "21%" = 5,
    "27%" = 6,
    "5%" = 8,
    "2.5%" = 9,
  } 