
<div class="card-container shadow-sm card hoverlightbluebg">
    <div class="line" style="grid-area: line;"></div>
    <div class="unpaid-card"  style="grid-area: cardbody;">
        <div style="grid-area: amount; width: 100%; height: 100%;">
            <div class="amount-styles">
                <div class="flex-row left-aligned" style="width: fit-content; height: fit-content; margin: 3px;">
                    <span class="font-150 t-opacity-8" style="font-weight: 550; color: #1daa1d; margin-right: 10px;" *ngIf="installment?.installmentStatusId == 1">
                        PAGADO
                    </span>
                    <span class="font-150 t-opacity-8" style="font-weight: 550;" *ngIf="totalCardAmount > 0">
                        {{totalCardAmount | currency:'ARS' : 'symbol-narrow' : '1.0-0'}}
                    </span>
                    <span class="font-150 t-opacity-8" style="font-weight: 550; margin-left: 1vw;" *ngIf="totalCardAmount <= 0">
                        {{installment?.amountPaid | currency:'ARS' : 'symbol-narrow' : '1.0-0'}}
                    </span>
                    <div class="flex-row centered">
                        <span class="t-opacity-8" style="font-weight: 450; font-size: 100%; margin: 3px;" *ngIf="totalPunitives > 0 && totalCardAmount != 0">
                            ({{installment?.amount - installment?.amountPaid | currency:'ARS' : 'symbol-narrow' : '1.0-0' }} + {{totalPunitives | currency:'ARS' : 'symbol-narrow' : '1.0-0'}})
                        </span>
                    </div>
                </div>
                <div class="additional-charges-amounts" 
                    *ngFor="let additionalCharge of installment.additionalCharges" 
                    style="width: fit-content; height: fit-content;">
                    <span class="t-opacity-8" style="font-weight: 450; font-size: 90%; margin: 1px;" *ngIf="additionalCharge.punitive && withPunitives && installment?.installmentStatusId != 1">
                        {{additionalCharge.sign ? "+" : "-"}} 
                        {{additionalCharge.amount | currency:'ARS' : 'symbol-narrow' : '1.0-0'}} 
                        {{additionalCharge.detail}} 
                        <span class="badge text-bg-warning">
                            Moroso
                        </span>
                    </span>
                    <span class="t-opacity-8" style="font-weight: 450; font-size: 90%; margin: 1px;" *ngIf="!additionalCharge.punitive">
                        {{additionalCharge.sign ? "+" : "-"}} 
                        {{additionalCharge.amount | currency:'ARS' : 'symbol-narrow' : '1.0-0'}} 
                        {{additionalCharge.detail}} 
                    </span>
                </div>
            </div>
        </div>
        <div class="flex-row centered" style="grid-area: badge;">
            <span class="badge text-bg-danger"  *ngIf="installment?.installmentStatusId == 4">
                Impago
            </span>
            <span class="badge text-bg-warning" *ngIf="installment?.installmentStatusId == 3">
                Pago Parcial
            </span>
        </div>
        <span class="t-opacity-8" style="grid-area: enddate; font-weight: 550; font-size: 110% !important;" >
            {{dateService.transformToDateStringFromString(installment?.fromDate)}}
        </span>
        <div class="flex-row" style="grid-area: description; width: 100%; justify-content: flex-start; align-items: center;">
            <span >{{transformInstallmentName(installment)}}</span>
        </div>
        <div style="grid-area: edit; margin-left: 40px;">
            <button class="btn btn-primary" (click)="editInstallment(installment, editInstallmentForm)" *ngIf="installment?.installmentStatusId == 4">
              <fa-icon [icon]="iconEdit" size="1x"></fa-icon>
            </button>
            <button class="btn" (click)="redirect(url)" style="background-color: #Fd6b08; color: white; margin-left: 10px;" [ngbTooltip]="'Ver más'">
                <fa-icon [icon]="iconView" size="1x"></fa-icon>
            </button>
        </div>
    </div>
</div>

<ng-template #editInstallmentForm>
    <div style="margin: 1vh 1vw 1vh 1vw;">
        <app-installment-form 
        [installmentToEdit]="installmentToEdit" 
        (closeAction)="close()">
        </app-installment-form>
    </div>
</ng-template>

