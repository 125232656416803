<!-- <button class="btn btn-primary" (click)="auth.logout({ returnTo: document.location.origin })">
  {{'Logout' | translate}}
</button> -->

<ng-container *ngIf="auth.isAuthenticated$ | async; else loggedOut">
  <span (click)="logOut()">
    {{'Logout' | translate}}
  </span>
</ng-container> 

<ng-template #loggedOut>
  <a class="btn btn-primary" (click)="auth.loginWithRedirect()">{{'Login' | translate}}</a>
</ng-template>
