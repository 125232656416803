

<body>
  <div class="d-flex align-items-center justify-content-center vh-100">
    <div class="text-center">
      <img src="assets/img/baup-01.svg" alt="" width="250" height="300">
        <p class="fs-3"> <span class="text-danger">Ooops!</span> Usuario no autorizado.</p>
        <p class="lead">
            No tiene acceso al sistema, comuniquese con soporte.
          </p>
        <a (click)="logOut()"  class="btn btn-primary">Cerrar sesión</a>
    </div>
</div>

</body>