<div class="flex-row" style="justify-content: space-evenly; align-items: center; width: 100%;">
  <span class="title" style="font-size: 200%; font-weight: bold; color: #27274e;">Arrastre los servicios deseados
    hacia esta ventana </span>
</div>
<div class="services-taxes" style="height: 80%; width: 95%;">
  <div class="list" style="width: 25%">
    <span style="font-size: 120%; font-weight: bold;">Servicios Disponibles</span>
    <hr>
    <div *ngFor="let item of requirements">
      <div class="requirement-card border-15 shadowframe" (click)="onDrop(item, ParametersSetForm, 1)"
        (dndStart)="onGrabbing($event, true)" (dndEnd)="onGrabbing($event, false)" [dndDraggable]="item"
        *ngIf="activeIds.indexOf(item.id) == -1">
        <span style="min-width: fit-content; white-space:nowrap;">{{item.name}} <fa-icon
            [icon]="iconService.getIcon(item.icon)" size="1x" style="margin-left: 5px; color: #fd6b08;"></fa-icon>
        </span>
      </div>
    </div>
  </div>
  <div class="flex-column" style="width: 75%; height: fit-content; min-height: 100%;" 
    [ngClass]="{'border' : !grabbing,'dropzone-grabbing' : grabbing}" dndDropzone (dndDrop)="onDrop($event, ParametersSetForm)">
    <div class="flex-row" style="width: 100%; gap: 0.5rem; padding: 0.5rem; flex-wrap: wrap;">
      <div class="flex-column req-card" *ngFor="let requirement of dropzone; let ind = index">
        <span style="font-size: 110%; font-weight: bold; text-align: center;">
          <fa-icon [icon]="iconService.getIcon(requirement.icon)" size="2x" style="color: #fd6b08;"></fa-icon>
          {{requirement.requirement || requirement.name}}
        </span>
        <div class="flex-row" style="gap: 5px; align-items: center; justify-content: space-evenly;">
          <button class="btn btn-primary" style="height: fit-content;"
            (click)="editRequirement(ParametersSetForm, requirement)">
            <fa-icon [icon]="iconEdit" size="1x"></fa-icon>
          </button>
          <span style="text-align: center; max-width: 5rem;">Ultimo pago: {{getFromDate(requirement.requirementId ||
            requirement.id)}}</span>
          <button class="btn btn-danger" style="height: fit-content;" (click)="removeItem(ind, requirement)">
            <fa-icon [icon]="iconTrash" size="1x"></fa-icon>
          </button>
        </div>
        <div style="grid-area: extra; text-align: center;">
          <span *ngFor="let extraReq of getReqProperties(requirement)">{{ extraReq }}<br></span>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #ParametersSetForm>
  <div class="flex-column extra-requirements-form">
    <div class="title">
      <h1>Requerimientos</h1>
      <hr>
    </div>
    <label>Fecha</label>
    <div class="date">
      <div class="flex-row centered">
        <button class="btn btn-light" style="margin-right: 1vw;" (click)="prevDate()">
          <fa-icon [icon]="iconLeft" size="1x"></fa-icon>
        </button>
        <span style="margin-right: 1vw; font-size: 105%;">{{currentYear}}</span>
        <button class="btn btn-light" (click)="nextDate()">
          <fa-icon [icon]="iconRight" size="1x"></fa-icon>
        </button>
      </div>
      <div class="flex-row centered">
        <select class="form-control form-select" id="user" [(ngModel)]="selectedMonth">
          <option disabled>...</option>
          <option *ngFor="let month of yearMonths">{{month.month}}</option>
        </select>
      </div>
    </div>
    <div *ngFor="let reqs of req.extraRequirements">
      <label>{{reqs}}</label>
      <input class="form-control" [(ngModel)]="requirementMap[reqs]" #requirements>
    </div>
    <button class="btn btn-primary" style="max-width: 10vw;" (click)="accept()">Aceptar</button>
  </div>
</ng-template>