import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  faPen,
  faPlus,
  faSearch,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Contract } from 'src/app/core/models/Contract';
import { ContractService } from 'src/app/services/contract.service';
import { ContractTemplatesHandlerComponent } from '../contract-templates-handler/contract-templates-handler.component';

@Component({
  selector: 'app-entity-lookup',
  templateUrl: './entity-lookup.component.html',
  styleUrls: ['./entity-lookup.component.css'],
})
export class EntityLookupComponent implements OnInit {
  @Input() formInput: any;
  @Input() entryArray: any[] = [];
  @Input() searchType: string;
  @Input() title: string = 'Test';

  @Output() deleteElementEvent = new EventEmitter();
  @Output() addedEntityEvent = new EventEmitter();

  @ViewChild(ContractTemplatesHandlerComponent)
  entityForm: ContractTemplatesHandlerComponent;

  addTooltip: string = 'Añadir';
  dataForEdits: any;
  editIndex: number;
  contractId: number;
  contract: Contract;

  iconRemove = faTrash;
  iconEdit = faPen;
  iconPlus = faPlus;
  iconSearch = faSearch;

  constructor(
    private modalService: NgbModal,
    private contractService: ContractService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.contractId = params['id'];
    });
  }

  ngOnInit() {}

  openEntityForm() {
    if (this.searchType == 'building' && this.entryArray[0] == null) {
      this.editIndex = null;
      this.dataForEdits = null;
      this.entityForm.openForm(null);
    } else if (this.searchType != 'building') {
      this.editIndex = null;
      this.dataForEdits = null;
      this.entityForm.openForm(null);
    }
  }

  openFormEdit(editData, ind) {
    this.editIndex = ind;
    this.dataForEdits = editData;

    this.entityForm.openForm(editData);
  }

  deleteItem(index) {
    if (this.searchType == 'building') {
      this.entryArray = null;
      this.deleteElementEvent.emit(this.entryArray);
    } else {
      this.entryArray.splice(index, 1);
      this.deleteElementEvent.emit(this.entryArray);
    }
  }

  getData(index) {
    return this.entryArray[index];
  }

  openSearch(searchForm) {
    this.modalService.open(searchForm, { size: 'lg' });
  }

  selectedSearchedElement(entity) {
    if (this.searchType == 'building') {
      this.entryArray = entity;
    } else {
      this.entryArray.push(entity);
    }

    this.modalService.dismissAll();
    this.addedEntityEvent.emit(this.entryArray);
  }

  addEntityFromMemory(entity) {
    if (this.dataForEdits) {
      this.entryArray[this.editIndex] = entity;
    } else {
      this.entryArray.push(entity);
    }
    this.addedEntityEvent.emit(this.entryArray);
  }
}
