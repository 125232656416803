<form [formGroup]="userDataFormGroup" (ngSubmit)="onSubmit()">
  <div class="title">
    <h1 *ngIf="edit">Editar Usuario</h1>
    <h1 *ngIf="!edit">Añadir Usuario</h1>
  </div>
  <div class="form-building form-group">
    <div class="form-userdata">
<!--       <div class="flex-row" style="justify-content: flex-start; align-items: center; grid-area: toggle;">
        <app-switch style="margin-top: 1vh; margin-right: 1vw;" (toggleEventFire)="toggleElement()" [checked]="true"></app-switch>
        <input type="radio" 
                style="margin-right: 1vw; height: 20px; width: 20px;"
                (click)="selectUserType(userType.Person)"
                name="schemeType" 
                [checked]="userTypeCheck"
                [value]="1">
        <span  class="font-120" style="margin-right: 1vw;">Persona</span>
        <input type="radio"
                style="margin-right: 1vw; height: 20px; width: 20px;"
                (click)="selectUserType(userType.Bussiness)"
                name="schemeType"
                [checked]="!userTypeCheck"
                [value]="2">
        <span  class="font-120" style="margin-right: 1vw;">Persona Juridica</span>
      </div> -->
      <div style="grid-area: title;">
        <div class="flex-column" style="justify-content: flex-start;">
          <h3>Datos Personales</h3>
          <!-- <h3  *ngIf="userTypeToggle ==  userType.Bussiness">Datos Empresa</h3> -->
        </div>
        <hr>
      </div>
      <div style="grid-area: name"> 
        <label>Nombre</label>
        <input placeholder="Juan" type="text" formControlName="firstName" class="form-control"
        [ngClass]="{'borderRed': validate('firstName')}">
        <span *ngIf="validate('firstName')" style="color: var(--border-red); font-size: 90%;">
          Dato Faltante
        </span>
      </div>
<!--       <div style="grid-area: name" *ngIf="userTypeToggle ==  userType.Bussiness"> 
        <label>Razon Social</label>
        <input type="text" formControlName="socialReason" class="form-control"
        [ngClass]="{'borderRed': validate('socialReason')}">
        <span *ngIf="validate('socialReason')" style="color: var(--border-red); font-size: 90%;">
          Dato Faltante
        </span>
      </div> -->
      <div style="grid-area: lname"> 
        <label>Apellido</label>
        <input placeholder="Pérez" type="text" formControlName="lastName"  class="form-control">
      </div>
      <div style="grid-area: dob"> 
        <label>Fecha Nacimiento</label>
        <div class="input-group" style="margin-bottom: 1vh;">
          <input class="form-control" placeholder="yyyy-mm-dd" formControlName="dateOfBirth"
                name="dp"  ngbDatepicker #d="ngbDatepicker"
                [minDate]="{year: 1000, month: 1, day: 1}">        
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
              <fa-icon [icon]="iconCalendar" size="1x"> </fa-icon>
            </button>
          </div>
        </div>        
        <span class="flex-column text-danger" *ngIf="userDataFormGroup.get('dateOfBirth').hasError('ngbDate')">Error de formato</span>
      </div>
      <div class="flex-row" style="grid-area: dni;"> 
        <div style="margin-right: 1vw;">
          <label>Tipo</label>
          <div class="flex-row" style="margin-bottom: 1vh;"> 
            <select class="form-control form-select" id="documentType" formControlName="documentType">
                <option *ngFor="let documentType of documentTypes" [value]="documentType.value">{{documentType.text}}</option>
            </select>
          </div>
        </div>
        <div>
          <label>Numero Documento</label>
          <input placeholder="12345678" type="number" formControlName="documentId" class="form-control"
            [ngClass]="{'borderRed': validate('documentId')}">
          <span *ngIf="validate('documentId')" style="color: var(--border-red); font-size: 90%;">
            Dato Inválido
          </span>
        </div>
      </div>
      <div style="grid-area: liv;">
        <h3> Vivienda</h3>
        <hr>
      </div>
      <div style="grid-area: street"> 
        <label>Calle y altura</label>
        <input placeholder="Avenida Santa Fe 1234" type="text" formControlName="streetAddress" class="form-control">
      </div>
      <div style="grid-area: state"> 
        <label>Provincia</label>
        <input placeholder="CABA" type="text" formControlName="state" class="form-control">
      </div>
      <div style="grid-area: city"> 
        <label>Ciudad</label>
        <input placeholder="CABA" type="text" formControlName="city" class="form-control">
      </div>
      <div style="grid-area: zip"> 
        <label>Codigo Postal</label>
        <input placeholder="1234" type="text" formControlName="zipCode" class="form-control">
      </div>
      <div style="grid-area: userd;">
        <h3> Información de Contacto</h3>
        <hr>
      </div>
      <div style="grid-area: email"> 
        <label>Email</label>
        <input placeholder="juanperez@gmail.com" *ngIf="!edit" type="email" formControlName="email" class="form-control"
        [ngClass]="{'borderRed': validate('email')}">
        <input *ngIf="edit" type="email" formControlName="email" class="form-control"
        [ngClass]="{'borderRed': validate('email')}" readonly>
        <span *ngIf="validate('email')" style="color: var(--border-red); font-size: 90%;">
          Dato Faltante
        </span>
      </div>
      <div style="grid-area: phone">
        <label>Telefono</label>
        <app-phonebook-container [phoneCards]="phoneContacts"></app-phonebook-container>
      </div>
    </div>
    <div style="grid-area: sec">
      <h3> Seguridad </h3>
      <hr>
    </div>
    <div style="grid-area: pass; margin-bottom: 20px;"> 
      <label>Nombre de usuario</label>
      <input placeholder="JPerez" style="width: 40%;" type="text" formControlName="userName" class="form-control"
      [ngClass]="{'borderRed': validate('userName')}">
      <span *ngIf="validate('userName')" style="color: var(--border-red); font-size: 90%;">
      Dato Faltante
    </span>
  </div>
<!--     <div style="grid-area: pass; margin-bottom: 20px;"> 
      <label>Contraseña</label>
      <input type="password" formControlName="password" class="form-control"
      [ngClass]="{'borderRed': validate('password')}">
      <span *ngIf="validate('password')" style="color: var(--border-red); font-size: 90%;">
      Dato Faltante
    </span>
  </div> -->
</div>
<div class="buttons-submitting" style="display: flex; justify-content: space-between;">
  <button type="button" class="btn btn-danger" [disabled]="submitted" (click)="cancel()">Cancelar</button>
  <button class="btn btn-primary" [disabled]="submitted" *ngIf="!edit">Crear</button>
  <button class="btn btn-primary" *ngIf="edit">Editar</button>
</div>
</form>