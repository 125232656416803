import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import {
  FilterInputTypes,
  SelectedFilterTypeAndValue,
} from 'src/app/core/models/IFilterConfiguration';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-filter-v2-inputs',
  templateUrl: './filter-v2-inputs.component.html',
  styleUrls: ['./filter-v2-inputs.component.css'],
})
export class FilterV2InputsComponent implements OnInit {
  attribute: any = null;
  iconCalendar = faCalendar;
  filterInputTypes = FilterInputTypes;

  @Input() inputElement: SelectedFilterTypeAndValue = null;

  @Output() setFilterValues = new EventEmitter();

  constructor(private dateService: DateService) {}

  ngOnInit() {
    //
    //
    if (this.inputElement?.selectedFilter != null) {
      if (this.inputElement.selectedFilter.type == FilterInputTypes.DATE) {
        this.attribute = this.dateService.transformToDateHash(
          new Date(this.inputElement.value),
        );
      } else if (
        this.inputElement.selectedFilter.type == FilterInputTypes.MONEY
      ) {
        this.attribute = parseInt(this.inputElement.value);
      } else if (
        this.inputElement.selectedFilter.type == FilterInputTypes.BOOLEAN
      ) {
        this.inputElement.value
          ? (this.attribute = true)
          : (this.attribute = false);
      } else {
        this.attribute = this.inputElement.value;
      }
    }
  }

  setFilterValue() {
    this.setFilterValues.emit(this.attribute);
  }

  setFilterValueDate() {
    this.setFilterValues.emit(
      `${this.attribute.day}/${this.attribute.month}/${this.attribute.year}`,
    );
  }
}
