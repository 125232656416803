import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { faDollarSign, faPlus } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdditionalChargesSubmit } from 'src/app/core/models/AdditionalCharges';
import { AdditionalChargesService } from 'src/app/services/additional-charges.service';
import { ModalContainerComponent } from 'src/app/shared/modal-container/modal-container.component';

@Component({
  selector: 'app-extraordinary-expenses',
  templateUrl: './extraordinary-expenses.component.html',
  styleUrls: ['./extraordinary-expenses.component.css'],
})
export class ExtraordinaryExpensesComponent implements OnInit {
  @Input() id: number;
  @Input() popoverOption: boolean = false;

  @ViewChild(ModalContainerComponent) modalContainer: ModalContainerComponent;

  @Output() closeAction = new EventEmitter();

  dollarSign = faDollarSign;
  plus = faPlus;

  extraFormGroup = new UntypedFormGroup({
    amount: new UntypedFormControl('', [
      Validators.required,
      Validators.min(0),
      Validators.max(2147483647),
    ]),
  });

  extra: AdditionalChargesSubmit = {
    detail: 'Expensa Extraordinaria',
    amount: 0,
    sign: false,
    installmentId: 0,
  };

  constructor(
    private additionalChargeService: AdditionalChargesService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {}

  openModal(modal) {
    this.modalService.open(modal, { size: 'sm' });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  addExtra() {
    if (this.extraFormGroup.invalid) {
    } else {
      this.extra.amount = this.extraFormGroup.value.amount;
      this.extra.installmentId = this.id;

      this.additionalChargeService
        .addAdditionalCharge(this.extra)
        .subscribe((response) => {
          this.closeAction.emit(response);
          this.closeModal();
        });
    }
  }
}
