import { CdkStep, StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  Component,
  Input,
  OnInit,
  Signal,
  WritableSignal,
  computed,
  effect,
  signal,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  faCalendar,
  faDollarSign,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AdditionalCharges,
  AdditionalChargesSubmit,
} from 'src/app/core/models/AdditionalCharges';
import { Contract } from 'src/app/core/models/Contract';
import { Installment } from 'src/app/core/models/Installment';
import { InstallmentRequirementForAdd } from 'src/app/core/models/InstallmentRequirementForAdd';
import {
  ActionTypeEnum,
  InstallmentRequirementForPayment,
} from 'src/app/core/models/InstallmentRequirementForPayment';
import { Owner } from 'src/app/core/models/Owner';
import { PaymentForSubmit } from 'src/app/core/models/PaymentForSubmit';
import { PaymentTypeEnum } from 'src/app/core/models/PaymentTypeEnum.enum';
import { Tenant } from 'src/app/core/models/Tenant';
import { AdditionalChargesService } from 'src/app/services/additional-charges.service';
import { DateService } from 'src/app/services/date.service';
import { IconService } from 'src/app/services/icon.service';
import { LiabilitiesService } from 'src/app/services/liabilities.service';
import { PaymentsService } from 'src/app/services/payments.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-payment-stepper',
  templateUrl: './payment-stepper.component.html',
  styleUrls: ['./payment-stepper.component.css'],
})
export class PaymentStepperComponent implements OnInit {
  @Input() contract: Contract;
  @Input() installmentId: number = 0;

  iconCalendar = faCalendar;
  iconSign = faDollarSign;
  iconTrash = faTrash;

  //Properties
  stepNames: string[] = [
    'Cuotas',
    'Datos generales',
    'Adicionales',
    'Obligaciones',
    'Retención',
  ];
  selectedIndex: number = 0;
  formattedPaymentDate: Date;
  retentionDetail: string;
  today = new Date();
  additionalLoader: boolean = true;
  isLoading: boolean = false;
  requirements: {
    month: string;
    expired: boolean;
    contractId: number;
    installmentId: number;
    hasUnpaidRequirements: boolean;
    requirements: {
      name: string;
      icon: string;
      status: string;
      contractRequirementId: number;
      installmentStatusId: number;
      amount: number;
      details: string;
      id: number;
      paymentDate: {
        year: number;
        month: number;
        day: number;
      };
    }[];
  }[] = [];

  requirementsForPayment = signal([]);
  //Signals
  installments: WritableSignal<
    {
      installment: Installment;
      isSelected: boolean;
    }[]
  > = signal([]);
  selectedInstallments: Signal<Installment[]> = computed(() =>
    this.installments()
      .filter((i) => i.isSelected)
      .map((i) => i.installment),
  );
  installmentAmount: Signal<number> = computed(() =>
    this.selectedInstallments().reduce(
      (n, { finalAmountToPay }) => n + finalAmountToPay,
      0,
    ),
  );
  installmentIdForAdditionalCharges: WritableSignal<number> = signal(null);
  installmentForAdditionalCharges: Signal<Installment> = computed(() => {
    if (
      this.selectedInstallments()?.length > 0 &&
      this.installmentIdForAdditionalCharges()
    )
      return this.selectedInstallments().find(
        (i) => i.id == this.installmentIdForAdditionalCharges(),
      );
    return null;
  });
  additionalChargesAmount: Signal<number> = computed(() => {
    return (
      this.selectedInstallments().reduce((allTotal, installment) => {
        const sum = installment.additionalCharges.reduce((total, charge) => {
          if (charge.punitive) return total;
          return total + (charge.sign ? charge.amount : -charge.amount);
        }, 0);
        return allTotal + sum;
      }, 0) ?? 0
    );
  });
  payPunitives: WritableSignal<boolean> = signal(false);
  punitiveAmount: Signal<number> = computed(() =>
    !this.payPunitives()
      ? 0
      : this.selectedInstallments().reduce((n, { additionalCharges }) => {
          const punitiveCharge = additionalCharges.find((ac) => ac.punitive);

          if (punitiveCharge) {
            return (
              n +
              (punitiveCharge.sign
                ? punitiveCharge.amount
                : punitiveCharge.amount * -1)
            );
          } else {
            return n;
          }
        }, 0),
  );
  selectedRequirement: WritableSignal<any> = signal(null);
  isRetention: WritableSignal<boolean> = signal(false);
  retainedRequirements: WritableSignal<any[]> = signal([]);
  retentionAmount: WritableSignal<number> = signal(0);
  totalAmount: Signal<number> = computed(
    () =>
      this.installmentAmount() +
      this.additionalChargesAmount() +
      this.punitiveAmount() -
      this.contract?.tenantAmountInFavor,
  );
  paymentAmount: WritableSignal<number> = signal(0);
  newRetentions: WritableSignal<{ amount: number; detail: string }[]> = signal(
    [],
  );

  //Forms
  detailFormGroup = new FormGroup({
    paymentType: new FormControl<PaymentTypeEnum>(2, Validators.required),
    owner: new FormControl<Owner>(undefined),
    tenant: new FormControl<Tenant>(undefined, Validators.required),
    paymentDate: new FormControl<{
      year: number;
      month: number;
      day: number;
    }>(undefined),
  });

  additionalChargesFormGroup = new FormGroup({
    monthId: new FormControl<number>(undefined, Validators.required),
    sign: new FormControl<boolean>(true, Validators.required),
    amount: new FormControl<number>(undefined, [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.min(0),
      Validators.max(2147483647),
    ]),
    detail: new FormControl<string>('', Validators.required),
  });

  requirementFormGroup = new FormGroup({
    id: new FormControl<number | null>(null),
    contractId: new FormControl<number | null>(undefined, Validators.required),
    contractRequirementId: new FormControl<number | null>(
      undefined,
      Validators.required,
    ),
    installmentId: new FormControl<number | null>(
      undefined,
      Validators.required,
    ),
    installmentStatusId: new FormControl<number | null>(1, Validators.required),
    amount: new FormControl<number | null>(null, [
      Validators.pattern('^[0-9]*(.[0-9]{1,2})?$'), // Acepta hasta 2 decimales
      Validators.min(0),
      Validators.max(2147483647),
    ]),
    details: new FormControl<string | null>(null),
    paymentDate: new FormControl<{
      year: number | null;
      month: number | null;
      day: number | null;
    }>(null),
  });

  retentionFormGroup = new FormGroup({
    amount: new FormControl<number>(undefined, [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.min(1),
      Validators.max(2147483647),
    ]),
    detail: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(1),
    ]),
  });

  constructor(
    private toastService: ToastService,
    private additionalChargeService: AdditionalChargesService,
    private paymentsService: PaymentsService,
    private liabilityService: LiabilitiesService,
    private router: Router,
    private modalService: NgbModal,
    public iconService: IconService,
    public dateService: DateService,
  ) {
    effect(
      () => {
        if (this.additionalLoader) {
          let id = this.selectedInstallments()[0]?.id ?? null;
          this.additionalChargesFormGroup.controls['monthId'].patchValue(id);
          this.installmentIdForAdditionalCharges.set(id);
        }
        this.additionalLoader = true;
      },
      { allowSignalWrites: true },
    );
    effect(
      () => {
        let res = this.selectedRequirement();
        if (res === null) {
          this.requirementFormGroup.reset();
        } else {
          if (
            res.req.installmentStatusId == 1 ||
            res.req.installmentStatusId == 3
          ) {
            this.requirementFormGroup.patchValue({
              id: res.req?.id,
              contractId: res.data.contractId,
              contractRequirementId: res.req.contractRequirementId,
              installmentId: res.data.installmentId,
              installmentStatusId: res.req.installmentStatusId,
              amount: res.req?.amount,
              details: res.req?.details,
              paymentDate: res.req.paymentDate
                ? this.dateService.transformToDateHash(
                    new Date(res.req.paymentDate),
                  )
                : undefined,
            });
          } else {
            this.requirementFormGroup.patchValue({
              id: res.req?.id,
              contractId: res.data.contractId,
              contractRequirementId: res.req.contractRequirementId,
              installmentId: res.data.installmentId,
              installmentStatusId: 1,
              amount: undefined,
              details: undefined,
              paymentDate: undefined,
            });
          }
        }
        this.isRetention.set(false);
      },
      { allowSignalWrites: true },
    );
    effect(
      () => {
        this.retentionAmount.set(
          this.retainedRequirements().reduce(
            (n, val) => n + (val.insReq.amount ?? 0),
            0,
          ) + this.newRetentions().reduce((n, { amount }) => n + amount, 0),
        );
      },
      { allowSignalWrites: true },
    );
    effect(
      () => {
        this.paymentAmount.set(
          parseFloat(
            (
              this.installmentAmount() +
              this.additionalChargesAmount() +
              this.punitiveAmount() -
              this.contract?.tenantAmountInFavor
            ).toFixed(2),
          ),
        );
      },
      { allowSignalWrites: true },
    );
  }

  ngOnInit(): void {
    let installmentCheckboxes = [];
    this.contract.installments.forEach((ins) => {
      const dateString = ins.expirationDate.toString().split('T')[0];
      const splitValues = dateString.split('-');
      ins['expiryDate'] = new Date(
        +splitValues[0],
        +splitValues[1] - 1,
        +splitValues[2],
      );
      if (ins.installmentStatusId != 1) {
        installmentCheckboxes.push({
          installment: ins,
          isSelected: false,
        });
      }
    });
    this.installments.set(installmentCheckboxes);

    this.detailFormGroup.controls['tenant'].patchValue(
      this.contract.tenants.length == 1 ? this.contract.tenants[0] : undefined,
    );
    this.detailFormGroup.controls['owner'].patchValue(
      this.contract.owners.length == 1 ? this.contract.owners[0] : undefined,
    );

    for (let ind = 0; ind < this.liabilityService.insWithReq.length; ind++) {
      const installment = this.liabilityService.insWithReq[ind].installment;
      const insRequirementList =
        this.liabilityService.insWithReq[ind].installmentRequirementStates;

      const dateString = installment.expirationDate.toString().split('T')[0];
      const splitValues = dateString.split('-');
      let expired =
        new Date(+splitValues[0], +splitValues[1] - 1, +splitValues[2]) <=
        new Date();

      let reqs = [];
      let hasUnpaidRequirements: boolean = false;
      insRequirementList.forEach((req) => {
        let status: string;
        switch (req.status.id) {
          case 1:
            status = 'pago';
            break;
          case 3:
            hasUnpaidRequirements = true;
            status = 'parcial';
            break;
          default:
            hasUnpaidRequirements = true;
            status = expired ? 'vencido' : 'pendiente';
            break;
        }
        let requirement = {
          contractRequirementId: req.contractRequirementId,
          name: req.requirementName,
          icon: req.requirementIcon,
          status: status,
          installmentStatusId: req.status.id,
          details: req.installmentRequirement?.details || '',
          amount: req.installmentRequirement?.amount || null,
          id: req.installmentRequirement?.id || null,
          paymentDate: req.installmentRequirement?.paymentDate || null,
        };
        reqs.push(requirement);
      });

      this.requirements.push({
        contractId: installment.contractId,
        installmentId: installment.id,
        hasUnpaidRequirements: hasUnpaidRequirements,
        expired: expired,
        month:
          installment.description.substring(0, 3) +
          ' ' +
          installment.description.substring(installment.description.length - 2),
        requirements: reqs,
      });
    }

    if (this.installmentId > 0) {
      this.installments().forEach((inst) => {
        if (inst.installment.id == this.installmentId) {
          inst.isSelected = true;
        }
      });
    }
  }

  changeStep(event: StepperSelectionEvent) {
    this.selectedIndex = event.selectedIndex;
    event.previouslySelectedStep.interacted = false;
    event.selectedStep.interacted = false;
  }

  validateDetails(e: CdkStep) {
    if (this.detailFormGroup.invalid && e.interacted) {
      this.detailFormGroup.markAllAsTouched();
      e.interacted = false;
      this.toastService.showToast(
        'warning',
        'Corrobore los datos del formulario.',
      );
      return false;
    }
    return true;
  }

  validateInstallments(e: CdkStep) {
    if (this.selectedInstallments().length < 1 && e.interacted) {
      e.interacted = false;
      this.toastService.showToast('warning', 'Seleccione al menos una cuota.');
      return false;
    }
    return true;
  }

  formatDate(dateObject = null) {
    if (!dateObject) {
      if (this.detailFormGroup.controls['paymentDate'].value != undefined)
        this.detailFormGroup.controls['paymentDate'].patchValue(undefined);
      this.formattedPaymentDate = undefined;
    } else {
      this.formattedPaymentDate = new Date(
        dateObject.year,
        dateObject.month - 1,
        dateObject.day,
      );
    }
  }

  changePaymentType(paymentType) {
    if (paymentType == 1)
      this.detailFormGroup.controls['owner'].addValidators(Validators.required);
    else {
      this.detailFormGroup.controls['owner'].clearValidators();
      this.detailFormGroup.controls['owner'].patchValue(undefined);
    }
  }

  updateInstallments(index: number) {
    this.installments.update((arr) => {
      arr[index].isSelected = !arr[index].isSelected;
      return [...arr];
    });
  }

  validatePayment(value) {
    this.paymentAmount.set(value);
  }

  pay() {
    this.isLoading = true;
    let retentions = [];
    let reqs = this.retainedRequirements();
    let distinctRequirementList = [...new Set(reqs.map((r) => r.req.name))];
    distinctRequirementList.forEach((reqName) => {
      let filteredRequirements = reqs.filter((r) => r.req.name == reqName);
      let retention = {
        amount: filteredRequirements.reduce(
          (n, { insReq }) => n + (insReq.amount ?? 0),
          0,
        ),
        approvedState: false,
        detail: reqName,
        installmentRequirements: filteredRequirements.map((r) => r.insReq),
        paymentId: 0,
      };
      retentions.push(retention);
    });
    this.newRetentions().forEach((ret) => {
      let retention = {
        amount: ret.amount,
        approvedState: false,
        detail: ret.detail,
        installmentRequirements: [],
        paymentId: 0,
      };
      retentions.push(retention);
    });

    let newPayment: PaymentForSubmit = {
      contractId: this.contract.id,
      amount: this.paymentAmount(),
      tenantId: this.detailFormGroup.controls['tenant'].value.id,
      paymentTypeId: this.detailFormGroup.controls['paymentType'].value,
      installmentIds: this.selectedInstallments().map(
        (installment) => installment.id,
      ),
      details: null,
      payPunitive: this.payPunitives(),
      payDay: this.formattedPaymentDate || null,
      ownerId: this.detailFormGroup.controls['owner'].value?.id || null,
      retentions:
        retentions.reduce((n, { amount }) => n + (amount ?? 0), 0) > 0
          ? retentions
          : [],
      requirements: this.requirementsForPayment(),
    };
    this.paymentsService.createNewPayment(newPayment).subscribe(
      (result) => {
        this.isLoading = false;
        this.toastService.showToast('success', 'Pago Realizado');
        this.router.navigateByUrl(`/payments/${result.paymentId}`);
        this.modalService.dismissAll();
      },
      (error) => {
        this.toastService.showToast('error', 'No se pudo crear el pago');
        this.isLoading = false;
      },
    );
  }

  createCharges() {
    this.additionalChargesFormGroup.markAllAsTouched();
    if (this.additionalChargesFormGroup.invalid) {
      this.toastService.showToast('warning', 'Verifique los campos.');
    } else {
      this.isLoading = true;
      let additionalChargeToSubmit: AdditionalChargesSubmit = {
        detail: this.additionalChargesFormGroup.value.detail,
        amount: this.additionalChargesFormGroup.value.amount,
        sign: this.additionalChargesFormGroup.value.sign,
        installmentId: this.additionalChargesFormGroup.value.monthId,
      };
      this.additionalChargeService
        .addAdditionalCharge(additionalChargeToSubmit)
        .subscribe(
          (response) => {
            this.additionalLoader = false;
            let ac: AdditionalCharges = {
              amount: additionalChargeToSubmit.amount,
              detail: additionalChargeToSubmit.detail,
              installmentId: additionalChargeToSubmit.installmentId,
              sign: additionalChargeToSubmit.sign,
              punitive: false,
              id: response,
            };

            let contractInstallment = this.contract.installments.find(
              (i) => i.id == ac.installmentId,
            );
            contractInstallment.additionalCharges.push(ac);
            let signalInstallment = this.installments().findIndex(
              (i) => i.installment.id == ac.installmentId,
            );
            this.installments.update((arr) => {
              arr[signalInstallment].installment.additionalCharges =
                contractInstallment.additionalCharges;
              return [...arr];
            });

            this.toastService.showToast(
              'success',
              'Se ha generado el cargo adicional',
            );
            this.resetCharges();
            this.isLoading = false;
          },
          (err) => {
            this.toastService.showToast(
              'error',
              'No se ha podido generado el cargo adicional',
            );
            this.isLoading = false;
          },
        );
    }
  }

  resetCharges() {
    this.additionalChargesFormGroup.markAsUntouched();
    this.additionalChargesFormGroup.patchValue({
      amount: null,
      detail: '',
    });
  }

  deleteAC(charge: AdditionalCharges) {
    this.isLoading = true;
    this.additionalChargeService.deleteAdditionalCharge(charge.id).subscribe(
      (response) => {
        this.additionalLoader = false;
        let contractInstallment = this.contract.installments.find(
          (i) => i.id == charge.installmentId,
        );
        contractInstallment.additionalCharges =
          contractInstallment.additionalCharges.filter(
            (ac) => ac.id !== charge.id,
          );

        let signalInstallment = this.installments().findIndex(
          (i) => i.installment.id == charge.installmentId,
        );
        this.installments.update((arr) => {
          arr[signalInstallment].installment.additionalCharges =
            contractInstallment.additionalCharges;
          return [...arr];
        });
        this.toastService.showToast('success', 'Adicional eliminado');
        this.isLoading = false;
      },
      (err) => {
        this.toastService.showToast(
          'error',
          'No se ha podido eliminar el cargo',
        );
        this.isLoading = false;
      },
    );
  }

  // createRequirement() {
  //   let installmentRequirement;
  //   try {
  //     installmentRequirement = this.formatRequirement();
  //   } catch (e) {
  //     console.log(e);
  //     return;
  //   }
  //   this.isLoading = true;
  //   if (this.requirementFormGroup.value.id) {
  //     this.liabilityService
  //       .updateInstallmentRequirement(
  //         installmentRequirement,
  //         this.requirementFormGroup.value.id,
  //       )
  //       .subscribe(
  //         (result: any) => {
  //           this.selectedRequirement().req.status =
  //             installmentRequirement.installmentStatusId == 1
  //               ? 'pago'
  //               : 'parcial';
  //           this.toastService.showToast('success', 'Obligación saldada');
  //           this.isLoading = false;
  //           this.selectedRequirement.set(null);
  //         },
  //         (error) => {
  //           this.toastService.showToast(
  //             'error',
  //             'No se ha podido saldar la obligación',
  //           );
  //           this.isLoading = false;
  //         },
  //       );
  //   } else {
  //     this.liabilityService
  //       .createInstallmentRequirement(installmentRequirement)
  //       .subscribe(
  //         (result: any) => {
  //           this.selectedRequirement().req.id = result.installmentReqId;
  //           this.selectedRequirement().req.status =
  //             installmentRequirement.installmentStatusId == 1
  //               ? 'pago'
  //               : 'parcial';
  //           this.toastService.showToast('success', 'Obligación saldada');
  //           this.isLoading = false;
  //           this.selectedRequirement.set(null);
  //         },
  //         (error) => {
  //           this.toastService.showToast(
  //             'error',
  //             'No se ha podido saldar la obligación',
  //           );
  //           this.isLoading = false;
  //         },
  //       );
  //   }
  // }

  formatRequirement(): InstallmentRequirementForAdd {
    if (this.requirementFormGroup.invalid) {
      this.requirementFormGroup.markAllAsTouched();
      this.toastService.showToast(
        'warning',
        'Verifique que el monto sea mayor a 0',
      );
      throw new Error('Form inválido');
    }
    let dateObject = this.requirementFormGroup.value.paymentDate;
    let installmentRequirement: InstallmentRequirementForPayment = {
      id: this.requirementFormGroup.value.id
        ? this.requirementFormGroup.value.id
        : null,
      contractId: this.requirementFormGroup.value.contractId,
      contractRequirementId:
        this.requirementFormGroup.value.contractRequirementId,
      installmentId: this.requirementFormGroup.value.installmentId,
      amount: this.requirementFormGroup.value.amount,
      details: this.requirementFormGroup.value.details
        ? this.requirementFormGroup.value.details
        : '',
      paymentDate: dateObject
        ? new Date(dateObject.year, dateObject.month - 1, dateObject.day)
        : null,
      installmentStatusId: this.requirementFormGroup.value.installmentStatusId,
    };
    return installmentRequirement;
  }

  addRequirementToPayment() {
    let installmentRequirement;
    try {
      installmentRequirement = this.formatRequirement();
    } catch (e) {
      return;
    }
    if (installmentRequirement.id) {
      installmentRequirement.actionTypeId = ActionTypeEnum.Update;
    } else {
      installmentRequirement.actionTypeId = ActionTypeEnum.Add;
    }

    this.requirements.forEach((requirement) => {
      if (
        requirement.installmentId ==
        this.selectedRequirement().data.installmentId
      ) {
        requirement.requirements.forEach((r) => {
          if (
            r.contractRequirementId ==
            this.selectedRequirement().req.contractRequirementId
          ) {
            r.status =
              installmentRequirement.installmentStatusId == 1
                ? 'pago'
                : 'parcial';
            r.amount = installmentRequirement.amount;
            r.details = installmentRequirement.details;
            r.paymentDate = installmentRequirement.paymentDate;
            r.installmentStatusId = installmentRequirement.installmentStatusId;
          }
        });
      }
    });

    this.requirementsForPayment.update((array) => {
      const index = array.findIndex(
        (element) =>
          element.contractRequirementId ===
          installmentRequirement.contractRequirementId,
      );
      if (index !== -1) {
        array[index] = installmentRequirement;
        return [...array];
      } else {
        array.push(installmentRequirement);
        return [...array];
      }
    });

    this.selectedRequirement.set(null);
  }

  deleteRequirementForPayment() {
    let installmentRequirement;
    try {
      installmentRequirement = this.formatRequirement();
    } catch (e) {
      return;
    }

    this.requirements.forEach((requirement) => {
      if (
        requirement.installmentId ==
        this.selectedRequirement().data.installmentId
      ) {
        requirement.requirements.forEach((r) => {
          if (
            r.contractRequirementId ==
            this.selectedRequirement().req.contractRequirementId
          ) {
            r.status = 'pendiente';
            r.amount = null;
            r.details = '';
            r.paymentDate = null;
            r.installmentStatusId = 6;
          }
        });
      }
    });

    installmentRequirement.actionTypeId = ActionTypeEnum.Delete;

    this.requirementsForPayment.update((array) => {
      const index = array.findIndex(
        (element) =>
          element.contractRequirementId ===
          installmentRequirement.contractRequirementId,
      );
      if (index !== -1) {
        array[index] = installmentRequirement;
        return [...array];
      } else {
        array.push(installmentRequirement);
        return [...array];
      }
    });
    this.selectedRequirement.set(null);
  }

  addRetention() {
    let installmentRequirement;
    try {
      installmentRequirement = this.formatRequirement();
    } catch (e) {
      console.log(e);
      return;
    }
    let selected = this.selectedRequirement();
    this.retainedRequirements.update((array) => {
      array.push({
        ins: selected.data,
        req: selected.req,
        insReq: installmentRequirement,
        oldStatus: selected.req.status,
      });
      return [...array];
    });
    selected.req.status = 'retenido';
    this.selectedRequirement.set(null);
  }

  removeRetainedRequirement(req) {
    req.req.status = req.oldStatus;
    this.retainedRequirements.update((array) => {
      array.splice(array.indexOf(req), 1);
      return [...array];
    });
  }

  createRetentions() {
    if (this.retentionFormGroup.invalid) {
      this.retentionFormGroup.markAllAsTouched();
      this.toastService.showToast('warning', 'Verifique los campos.');
    } else {
      this.newRetentions.update((arr) => {
        arr.push({
          amount: this.retentionFormGroup.value.amount,
          detail: this.retentionFormGroup.value.detail,
        });
        return [...arr];
      });

      this.retentionFormGroup.reset();
    }
  }

  deleteRetention(ret) {
    this.newRetentions.update((array) => {
      array.splice(array.indexOf(ret), 1);
      return [...array];
    });
  }
}
