import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from './translate.pipe';
import { ReversePipe } from './reverse.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [TranslatePipe, ReversePipe],
  exports: [TranslatePipe, ReversePipe],
})
export class PipesModule {}
