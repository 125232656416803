import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { InstallmentsIncreasesService } from 'src/app/services/installments-increases.service';
import { InstallmentsSchemasService } from 'src/app/services/installments-schemas.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-increase-edit-form',
  templateUrl: './increase-edit-form.component.html',
  styleUrls: ['./increase-edit-form.component.css'],
})
export class IncreaseEditFormComponent implements OnInit {
  @Input() oldIncrease: any;
  @Input() edit: boolean = true;
  @Input() isComercial: boolean = false;
  @Input() increaseType: string;
  @Input() maxInstallments = null;

  @Output() closeAction = new EventEmitter();
  @Output() refresh = new EventEmitter();

  increaseForm = new UntypedFormGroup({
    newIncrease: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*[.,]?[0-9]*$'),
      Validators.min(0),
      Validators.max(2147483647),
    ]),
    instQuantity: new UntypedFormControl('', [
      Validators.pattern('^[0-9]*[.,]?[0-9]*$'),
      Validators.min(0),
      Validators.max(2147483647),
    ]),
  });

  constructor(
    private installmentsIncreasesService: InstallmentsIncreasesService,
    private installmentSchemaService: InstallmentsSchemasService,
    private toastService: ToastService,
    private router: Router,
  ) {}

  ngOnInit() {}

  onSubmit() {
    if (this.increaseForm.value.newIncrease <= 0) {
      this.toastService.showToast(
        'warning',
        'Complete el campo con un número mayor a 0',
      );
      return;
    }

    if (this.edit) {
      this.refresh.emit('Loading');

      this.installmentsIncreasesService
        .editInstallmentIncrease(
          this.oldIncrease.contractId,
          this.increaseForm.value.newIncrease,
        )
        .subscribe(
          (response) => {
            this.toastService.showToast('success', 'Cuotas modificadas');
            this.refresh.emit('Updating');
            this.closeAction.emit();
          },
          (err) => {
            this.refresh.emit('Error');
            if (err.error == 'HasPayment') {
              this.closeAction.emit();
              this.toastService.showToast(
                'warning',
                'Este período tiene pagos realizados y no se puede modificar actualmente',
              );
            }
          },
        );
    } else if (!this.edit && !this.isComercial) {
      this.refresh.emit('Loading');

      this.installmentsIncreasesService
        .addInstallmentIncrease(
          [this.oldIncrease.contractId],
          this.increaseForm.value.newIncrease,
        )
        .subscribe(
          (response) => {
            this.toastService.showToast('success', 'Cuotas creadas');
            this.refresh.emit('Updating');
            this.closeAction.emit();
            this.router.navigateByUrl(
              `/contracts/${this.oldIncrease.contractId}/installments`,
            );
          },
          (err) => {
            this.refresh.emit('Error');
            this.toastService.showToast('warning', 'Ha ocurrido un error');
          },
        );
    } else {
      if (
        this.increaseForm.value.instQuantity > 0 &&
        this.increaseForm.value.instQuantity <= this.maxInstallments
      ) {
        this.refresh.emit('Loading');
        let amount =
          this.oldIncrease.amount +
          (this.increaseForm.value.newIncrease * this.oldIncrease.amount) / 100;
        this.installmentSchemaService
          .addInstallmentSchema(
            this.oldIncrease.contractId,
            amount,
            this.increaseForm.value.instQuantity,
          )
          .subscribe(
            (response) => {
              this.toastService.showToast('success', 'Cuotas creadas');
              this.refresh.emit('Updating');
              this.closeAction.emit();
              this.router.navigateByUrl(
                `/contracts/${this.oldIncrease.contractId}/installments`,
              );
            },
            (err) => {
              this.refresh.emit('Error');
              this.toastService.showToast('warning', 'Ha ocurrido un error');
            },
          );
      } else {
        this.toastService.showToast(
          'warning',
          'La cantidad de cuotas debe ser mayor a 0 y no puede exceder el máximo del contrato, ingrese una cantidad válida',
        );
        return;
      }
    }
  }
}
