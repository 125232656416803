import { Injectable } from '@angular/core';
import { PaginatedResult } from 'src/app/shared/grid/paginate.model';
import { Observable } from 'rxjs';
import { IPaginatedServiceParamters } from '../core/models/ITable';
import { Contract } from '../core/models/Contract';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class InstallmentsSchemasService {
  constructor(private apiService: ApiService) {}

  getAllPaginated(
    paginatedArguments: IPaginatedServiceParamters,
  ): Observable<PaginatedResult<Contract[]>> {
    return this.apiService.getPaginated(
      '/api/Contract/ToDue',
      paginatedArguments,
    );
  }

  addInstallmentSchema(contractArray, amount, quantity) {
    const contractIds = Array.isArray(contractArray)
      ? contractArray
      : [contractArray];

    const installmentSchema = {
      contractIds: contractIds,
      newAmount: amount,
      installmentQuantity: quantity,
    };

    return this.apiService.post(
      `/api/Contract/AddInstallmentSchema`,
      installmentSchema,
    );
  }
}
