import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { faCalendar, faFaucet } from '@fortawesome/free-solid-svg-icons';
import { BAUP } from 'src/app/core/models/File';
import { DateService } from 'src/app/services/date.service';
import { LiabilitiesService } from 'src/app/services/liabilities.service';
import { ToastService } from 'src/app/services/toast.service';
import { ConfirmDialogSettings } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { FileViewerSettings } from '../image-viewer/image-viewer.component';
import { FileParentEnum } from 'src/app/core/models/FileParentEnum';

@Component({
  selector: 'app-installment-requirement-form',
  templateUrl: './installment-requirement-form.component.html',
  styleUrls: ['./installment-requirement-form.component.css'],
})
export class InstallmentRequirementFormComponent implements OnInit {
  iconCalendar = faCalendar;
  iconPaymentService = faFaucet;

  @Input() installment: any;
  @Input() requirement: any;

  @Output() closeAction = new EventEmitter();
  @Output() closePopover = new EventEmitter();

  confirmBoxTitles: ConfirmDialogSettings = {
    title: 'Confirmar Borrado de Requerimiento',
    subtitle: '',
  };

  installmentreqFormGroup = new UntypedFormGroup({
    amount: new UntypedFormControl('', [
      Validators.min(0),
      Validators.max(2147483647),
    ]),
    details: new UntypedFormControl(''),
    payDate: new UntypedFormControl(''),
  });

  insReqFiles: BAUP.File[];
  fileSettings: FileViewerSettings;

  constructor(
    private liabilityService: LiabilitiesService,
    private dateService: DateService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    if (this.requirement.hasInstallmentReq) {
      let date = this.requirement.installmentRequirement.paymentDate
        ? this.requirement.installmentRequirement.paymentDate
        : '';
      let details = this.requirement.installmentRequirement.details
        ? this.requirement.installmentRequirement.details
        : '';
      this.installmentreqFormGroup.patchValue({
        amount: this.requirement.installmentRequirement.amount,
        details: details,
        payDate: this.dateService.transformToDateHash(new Date(date)),
      });
      this.insReqFiles =
        this.requirement?.installmentRequirement?.installmentRequirementFiles.map(
          (irf) => irf.file,
        );
      this.fileSettings = {
        title: 'Comprobantes de servicio',
        isDisplay: false,
        parent: FileParentEnum.INSTALLMENTREQUIREMENT,
        id: this.requirement?.installmentRequirement?.id,
        contractId: this.installment.contractId,
        modalComponent: true,
      };
    }
  }

  createLiability(val) {
    let date = this.installmentreqFormGroup.value.payDate
      ? this.installmentreqFormGroup.value.payDate
      : null;

    let installmentRequirement = {
      contractId: this.installment.contractId,
      contractRequirementId: this.requirement.contractRequirementId,
      installmentId: this.installment.id,
      amount: this.installmentreqFormGroup.value.amount,
      details: this.installmentreqFormGroup.value.details,
      paymentDate: this.dateService.transformToDateObject(
        date?.day,
        date?.month,
        date?.year,
      ),
      installmentStatusId: val,
    };

    if (this.requirement.hasInstallmentReq) {
      this.liabilityService
        .updateInstallmentRequirement(
          installmentRequirement,
          this.requirement.installmentRequirement.id,
        )
        .subscribe(
          (result: any) => {
            this.requirement.installmentRequirement = {
              installmentStatusId: val,
            };
            this.closeAction.emit();
            this.closePopover.emit();
          },
          (error) => {},
        );
    } else {
      this.liabilityService
        .createInstallmentRequirement(installmentRequirement)
        .subscribe(
          (result: any) => {
            this.requirement.hasInstallmentReq
              ? (this.requirement.hasInstallmentReq = false)
              : (this.requirement.hasInstallmentReq = true);
            this.requirement.installmentRequirementId = result.installmentReqId;
            this.requirement.installmentRequirement = {
              installmentStatusId: val,
            };
            this.closeAction.emit();
            this.closePopover.emit();
          },
          (error) => {},
        );
    }
  }

  deleteLiability() {
    var requirementId;
    requirementId = this.requirement.installmentRequirement.id;
    this.liabilityService
      .deleteInstallmentRequirement(requirementId)
      .subscribe((result) => {
        this.toastService.showToast('success', 'Pago de servicio eliminado');
        this.requirement.hasInstallmentReq = false;
        this.closeAction.emit();
        this.closePopover.emit();
      });
  }

  updateFileArray(result: BAUP.File[]) {
    const insReqFileArray = result.map((file) => {
      return {
        file: file,
        fileId: null,
        installmentRequirementId: null,
      };
    });
    this.requirement.installmentRequirement.installmentRequirementFiles =
      insReqFileArray;
  }
}
